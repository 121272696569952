/* eslint-disable class-methods-use-this */
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { History } from "history";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths, import/order
import packageJson from "../../package.json";

import { WebSocketComponent } from "components";
import ENV from "config/Env";
import ApiLoadable, { Type } from "models/ApiLoadable";
import { PATHS } from "router/paths";

type ErrorType = AxiosError | Error | unknown;

type TokenData = {
  token: string;
  expire_at: string;
};

const SESSION_TOKEN = "_sessionToken";
const DEALER_ID = "_dealerId";
const LOCATION_ID = "_locationId";
const LOGGED_IN_WITH_QR_CODE = "_loggedInWithQrCode";
const SESSION_EXPIRATION_TIME = "_sessionExpirationDate";

class Api {
  private static _instance: Api;
  private _token: string = "";
  private _zohoAsapJWT: string = "";
  private history: History | undefined;
  private _dealerId: string = "";
  private _locationId: string = "";
  private _loggedInWithQrCode: string = "";
  private _sessionExpirationTime: number = 0;

  constructor() {
    if (Api._instance) {
      return Api._instance;
    }

    Api._instance = this;
    axios.defaults.headers.common["X-Claire-Clientversion"] = packageJson.version;
    axios.defaults.headers.common["X-Claire-Clientplatform"] = "web";
    this.setupInterceptors();
  }

  private progressBarHooks: ((progress: number) => void)[] = [];

  private updateProgress(progress: number) {
    this.progressBarHooks.forEach(hook => hook(progress));
  }

  private setupInterceptors() {
    axios.interceptors.request.use(config => {
      config.onUploadProgress = progressEvent => {
        const progress = Math.round((progressEvent.loaded * 100) / Number(progressEvent.total));
        this.updateProgress(progress);
      };

      return config;
    });

    axios.interceptors.response.use(
      response => {
        setTimeout(() => {
          this.updateProgress(100);
          setTimeout(() => this.updateProgress(0), 300);
        }, 100);

        return response;
      },
      error => {
        this.updateProgress(0);
        throw error;
      }
    );
  }

  public addProgressBarHook(hook: (progress: number) => void) {
    this.progressBarHooks.push(hook);
  }

  public removeProgressBarHook(hook: (progress: number) => void) {
    this.progressBarHooks = this.progressBarHooks.filter(existingHook => existingHook !== hook);
  }

  private getErrorMessage = (error: AxiosError): string => {
    if (error.response) {
      if (error.response.status === 401) {
        // TODO: to be improved this should be calling useAuth logout method,
        // instead of clearing things manually like this
        // for that we have to turn the interceptor into a functional component
        // that set it and return children, then wrap the app into it, see
        // https://dev.to/arianhamdi/react-hooks-in-axios-interceptors-3e1h and
        // reads comment about isSet
        WebSocketComponent.disconnect();
        this.clearCookies();

        if (this.history) {
          this.history.replace(PATHS.LOGIN);
        }
      }
      if ((error as any).response.data.errors) return (error as any).response.data.errors[0];

      return JSON.stringify(error.response.data);
    }

    return error.message;
  };

  private handleErrorMessage(error: ErrorType, path: string) {
    let errorMessage = "";

    if (axios.isAxiosError(error)) {
      errorMessage = this.getErrorMessage(error);
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = `unknown error for POST ${path}`;
    }

    return errorMessage;
  }

  public clearSessionCookie() {
    document.cookie = `${SESSION_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    this._token = "";
    sessionStorage.removeItem(SESSION_TOKEN);
  }

  public clearDealerIdCookie() {
    document.cookie = `${DEALER_ID}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    this._dealerId = "";
    sessionStorage.removeItem(DEALER_ID);
  }

  public clearLocationIdCookie() {
    document.cookie = `${LOCATION_ID}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    this._locationId = "";
    sessionStorage.removeItem(LOCATION_ID);
  }

  public clearLoggedInWithQrCodeCookie() {
    document.cookie = `${LOGGED_IN_WITH_QR_CODE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    this._loggedInWithQrCode = "";
    sessionStorage.removeItem(LOGGED_IN_WITH_QR_CODE);
  }

  public clearCookies() {
    this.clearSessionCookie();
    this.clearZohoASapCookie();
    this.clearDealerIdCookie();
    this.clearLocationIdCookie();
    this.clearLoggedInWithQrCodeCookie();
  }

  public getCookie(name: string): string {
    return (
      document.cookie
        .split(";")
        ?.find(x => x.includes(name))
        ?.trim()
        ?.replace(`${name}=`, "") || ""
    );
  }

  get authToken(): string {
    if (this._token) return this._token;
    this._token = sessionStorage.getItem(SESSION_TOKEN) || this.getCookie(SESSION_TOKEN) || "";
    return this._token;
  }

  set authToken(value: TokenData) {
    const expirationTimestamp = new Date(value.expire_at).getTime();
    const expirationDate = new Date(value.expire_at).toUTCString();
    this._token = value.token;
    this._sessionExpirationTime = expirationTimestamp;
    document.cookie = `${SESSION_TOKEN}=${value.token};expires=${expirationDate};path=/`;
    sessionStorage.setItem(SESSION_TOKEN, value.token);

    document.cookie = `${SESSION_EXPIRATION_TIME}=${expirationTimestamp};path=/`;
    sessionStorage.setItem(SESSION_EXPIRATION_TIME, String(expirationTimestamp));
  }

  get sessionExpirationTime(): number {
    if (this._sessionExpirationTime) return this._sessionExpirationTime;
    this._sessionExpirationTime = Number(sessionStorage.getItem(SESSION_EXPIRATION_TIME) || Number(this.getCookie(SESSION_EXPIRATION_TIME)) || 0);
    return this.sessionExpirationTime;
  }

  get dealerId(): string {
    this._dealerId = sessionStorage.getItem(DEALER_ID) || this.getCookie(DEALER_ID);
    return this._dealerId;
  }

  set dealerId(value: string) {
    this._dealerId = value;
    document.cookie = `${DEALER_ID}=${value};path=/`;
    sessionStorage.setItem(DEALER_ID, value);
  }

  get locationId(): string {
    this._locationId = sessionStorage.getItem(LOCATION_ID) || this.getCookie(LOCATION_ID);
    return this._locationId;
  }

  set locationId(value: string) {
    this._locationId = value;
    document.cookie = `${LOCATION_ID}=${value};path=/`;
    sessionStorage.setItem(LOCATION_ID, value);
  }

  get loggedInWithQrCode(): string {
    this._loggedInWithQrCode = sessionStorage.getItem(LOGGED_IN_WITH_QR_CODE) || this.getCookie(LOGGED_IN_WITH_QR_CODE);
    return this._loggedInWithQrCode;
  }

  set loggedInWithQrCode(value: string) {
    this._loggedInWithQrCode = value;
    document.cookie = `${LOGGED_IN_WITH_QR_CODE}=${value};path=/`;
    sessionStorage.setItem(LOGGED_IN_WITH_QR_CODE, value);
  }

  public clearZohoASapCookie() {
    document.cookie = "_zohoAsapJWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this._zohoAsapJWT = "";
    sessionStorage.removeItem("_zohoAsapJWT");
  }

  get zohoAsapJWT(): string {
    const zohoAsapJWT = sessionStorage.getItem("_zohoAsapJWT") || "";
    this._zohoAsapJWT = zohoAsapJWT;

    if (!this._zohoAsapJWT) {
      this._zohoAsapJWT =
        document.cookie
          .split(";")
          ?.find(x => x.includes("_zohoAsapJWT"))
          ?.trim()
          ?.replace("_zohoAsapJWT=", "") || "";
    }

    return this._zohoAsapJWT;
  }

  set zohoAsapJWT(value: string) {
    this._zohoAsapJWT = value;
    document.cookie = "_zohoAsapJWT=" + value + ";path=/";
    sessionStorage.setItem("_zohoAsapJWT", value);
  }

  private requestConfig(): AxiosRequestConfig | undefined {
    if (this.authToken) {
      return {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
          Pragma: "no-cache"
        }
      };
    }

    return {};
  }

  setHistory(history: any) {
    this.history = history;
  }

  async get(path: string, baseUrl: string = ENV.apiBase, config?: any): Promise<any> {
    try {
      const result = await axios.get(baseUrl + path, { ...this.requestConfig(), ...config });
      return result.data;
    } catch (error: ErrorType) {
      throw new Error(this.handleErrorMessage(error, path));
    }
  }

  async post(path: string, requestData?: any, baseUrl: string = ENV.apiBase, config?: any): Promise<any> {
    try {
      const result = await axios.post(baseUrl + path, requestData, { ...this.requestConfig(), ...config });

      return result.data;
    } catch (error: ErrorType) {
      throw new Error(this.handleErrorMessage(error, path));
    }
  }

  async getUnwrapped<T extends ApiLoadable>(baseUrl: string, path: string, returnType: Type<T>, config?: any): Promise<T> {
    try {
      const result = await axios.get<Envelope<T>>(baseUrl + path, { ...this.requestConfig(), ...config });

      return ApiLoadable.fromAPI(returnType, result.data.data);
    } catch (error: ErrorType) {
      throw new Error(this.handleErrorMessage(error, path));
    }
  }

  async postUnwrapped<T extends ApiLoadable>(baseUrl: string, path: string, requestData: any, returnType: Type<T>, config?: any): Promise<T> {
    try {
      const result = await axios.post<Envelope<T>>(baseUrl + path, requestData, { ...this.requestConfig(), ...config });

      return ApiLoadable.fromAPI(returnType, result.data.data);
    } catch (error: ErrorType) {
      throw new Error(this.handleErrorMessage(error, path));
    }
  }
}

interface Envelope<T> {
  data: T;
}

const ApiInstance = new Api();

export default ApiInstance;
