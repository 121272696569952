import { useTranslation } from "react-i18next";
import { Icon, Label, Popup } from "semantic-ui-react";

import { CircleIcon, DATE_FORMATS_FNS, useAppointmentPinColor } from "components";
import "components/FilterIcons/FilterIcons.scss";
import { APPOINTMENT_NOTE_TYPES, Appointment } from "models";
import { formatDate, isDateAfter } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type FilterIconsProps = {
  appointment: Appointment;
};

export const FilterIcons = ({ appointment }: FilterIconsProps) => {
  const t = useTranslation().t as ITranslation;
  const pinColor = useAppointmentPinColor(appointment);
  const recurringCarNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RecurringCar);

  const showKeyIcon =
    (appointment.key_dropped_at && !appointment.key_picked_up_at) ||
    (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
    (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
    (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at) ||
    (appointment.acses_key_dropped_at && !appointment.acses_key_picked_up_at) ||
    (appointment.acses_key_dropped_back_at && !appointment.acses_key_picked_up_back_at);

  return (
    <div className="AppointmentSpecialIndicators-container">
      {appointment.has_dbb && appointment.dbb_appointment_date && (
        <>
          {appointment.final_car_check_dbb_status > 0 && (
            <Label
              className="checkedTire"
              circular
              color={appointment.final_car_check_dbb_status === 1 ? "green" : appointment.final_car_check_dbb_status === 2 ? "orange" : "red"}
            >
              <Icon className="tire" />
              <Icon className="check" />
            </Label>
          )}
          {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status > 0 && (
            <Label className="bigTire" circular color={appointment.car_check_dbb_status === 1 ? "green" : appointment.car_check_dbb_status === 2 ? "orange" : "red"}>
              <Icon className="tire" />
            </Label>
          )}
          {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status === 0 && (
            <Label className="bigTire" circular color={isDateAfter(new Date(), appointment.dbb_appointment_date) ? "green" : "orange"}>
              <Icon className="tire" />
            </Label>
          )}
        </>
      )}
      {appointment.customer_waiting && <CircleIcon icon="coffee" />}
      {appointment.is_money && <CircleIcon icon="is_money" />}
      {appointment.is_star && <CircleIcon icon="is_star" color={appointment.is_star_color} />}
      {appointment.is_shop && <CircleIcon icon="is_shop" color={appointment.is_shop_color} />}
      {appointment.has_extra_check && (
        <Label circular color="blue">
          <Icon className="fill drip regular" />
        </Label>
      )}
      {appointment.num_approved_items_not_fixed > 0 && (
        <span className="-fix-items-count">
          <Label circular color="red">
            {appointment.num_approved_items_not_fixed}
          </Label>
        </span>
      )}
      {appointment.final_check_has_remarks && <CircleIcon icon="wrench" />}
      {(appointment.recall_pin_count > 0 || appointment.remark_pin_count > 0 || appointment.warranty_pin_count > 0) && (
        <CircleIcon icon={appointment.warranty_pin_count > 0 ? "shield" : "pin"} color={pinColor} />
      )}

      {(appointment.is_recurring || recurringCarNote) && (
        <Popup
          wide
          basic
          disabled={!recurringCarNote}
          content={
            recurringCarNote && (
              <div className="AppointmentNotePopup">
                <div className="AppointmentNoteTitle">
                  <Icon className="warning sign red" />
                  <span>{t("v8_recurring_car").message || "Recurring car"}</span>
                </div>
                <div className="AppointmentNoteDescription">
                  {recurringCarNote.updated_by
                    ? `${t("v8_updated_by").message || "Updated by"} ${recurringCarNote.updated_by.first_name} ${recurringCarNote.updated_by.last_name} ${
                        t("v8_at").message || "at"
                      } `
                    : `${t("v8_added_by").message || "Added by"}  ${recurringCarNote.user?.first_name} ${recurringCarNote.user?.last_name} ${
                        t("v8_at").message || "at"
                      } `}
                  {`${formatDate(recurringCarNote.updated_on, DATE_FORMATS_FNS.dateMonthYearTime)}`}
                </div>
                <div className="AppointmentNoteContent">{recurringCarNote.note}</div>
              </div>
            )
          }
          trigger={
            <Label circular color="red">
              <Icon name="warning sign" />
            </Label>
          }
        />
      )}

      {showKeyIcon && (
        <Label circular className="keylocker-icon">
          <Icon name="key" />
        </Label>
      )}

      {appointment.kiosk_label_number && (
        <Label className="FilterIcon-kiosk-label">
          <Icon className="tag" />
          <span>{appointment.kiosk_label_number}</span>
        </Label>
      )}
    </div>
  );
};
