import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { Form, PinLog } from "components";
import { Appointment, Intervention, PIN_ORDER, PinModel, PinOptions } from "models";
import { PinType } from "modules/AppointmentDetails/components";
import { PinOverview } from "modules/AppointmentDetails/components/Interventions/components";
import { PinCategoryForm, PinCategoryFormType } from "modules/AppointmentDetails/components/Interventions/components/PinCategory/components";
import "modules/AppointmentDetails/components/Interventions/components/PinCategory/PinCategory.scss";
import { usePinCategory } from "modules/AppointmentDetails/components/Interventions/components/PinCategory/usePinCategory";
import { ITranslation } from "util/interfaces";

type PinCategoryProps = {
  pinType: PinType;
  data: Intervention;
  hasChanges: boolean;
  pinTypeData: PinType[];
  latestPinData?: PinModel;
  appointment: Appointment | null;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
};

export const OrderStatusColors = {
  [PIN_ORDER.ToOrder]: "blue",
  [PIN_ORDER.SameDay]: "green",
  [PIN_ORDER.NextDay]: "orange",
  [PIN_ORDER.BackOrder]: "red",
  [PIN_ORDER.Arrived]: "yellow"
};

export const getShopIconColor = (orderStatus: PIN_ORDER) => OrderStatusColors[orderStatus] || "";

export type DropdownOption = {
  key: string;
  text: string | undefined;
  value: number;
};

export const PinCategory = ({ data, appointment, latestPinData, pinType, pinTypeData, hasChanges, setHasChanges }: PinCategoryProps) => {
  const t = useTranslation().t as ITranslation;
  const { pinOptions, attachments, pinStatusData, pinWarrantyData, showPrintOverview, pinLogAttachments, openModal, closeModal, handleSubmit, setAttachments } =
    usePinCategory({ data, pinType });

  const pinOrderData = pinOptions?.pin_order_statuses?.map((pinOrder: PinOptions) => ({
    key: pinOrder.name,
    text: t(`${pinOrder.name}`).message,
    value: pinOrder.id,
    image: <Icon className="shopping cart" color={OrderStatusColors[pinOrder.id as PIN_ORDER]} />
  }));

  const defaultFormValues: PinCategoryFormType = {
    claimNr: latestPinData?.claim_nr || "",
    supportNr: latestPinData?.support_nr || "",
    refNr: latestPinData?.ref_nr || "",
    orderStatus: latestPinData?.order_status || null,
    pinWarrantyType: latestPinData?.warranty_type_id || null,
    pinStatus: latestPinData?.pin_status_id || null,
    note: "",
    important: false,
    keepParts: latestPinData?.keep_parts || false,
    noteSuggestion: null
  };

  return (
    <div className="PinType">
      <Form<PinCategoryFormType> onSubmit={handleSubmit} defaultValues={defaultFormValues}>
        <PinCategoryForm
          data={data}
          pinType={pinType}
          hasChanges={hasChanges}
          appointment={appointment}
          attachments={attachments}
          pinOrderData={pinOrderData}
          pinStatusData={pinStatusData}
          pinWarrantyData={pinWarrantyData}
          openModal={openModal}
          setHasChanges={setHasChanges}
          setAttachments={setAttachments}
        />
        <PinLog
          intervention={data}
          appointment={appointment}
          pinLog={data.pin_history}
          pinTypeData={pinTypeData}
          pinOrderData={pinOrderData}
          pinStatusData={pinStatusData}
          attachments={pinLogAttachments}
          pinWarrantyData={pinWarrantyData}
        />
        <PinOverview
          intervention={data}
          pin={latestPinData}
          onClose={closeModal}
          pinLog={data.pin_history}
          appointment={appointment}
          pinTypeData={pinTypeData}
          pinOrderData={pinOrderData}
          pinStatusOptions={pinStatusData}
          pinWarrantyData={pinWarrantyData}
          showPrintOverview={showPrintOverview}
        />
      </Form>
    </div>
  );
};
