import { OnChangeFn, PaginationState, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { CopyToClipboard, DATE_FORMATS_FNS, ReactTable } from "components";
import { EXACT_ACTION_TYPES, ExactOnlineFailure } from "models";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

interface ExactFailuresTableProps {
  failures: ExactOnlineFailure[] | undefined;
  activePage: number;
  totalPages: number;
  totalItems: number;
  onPageChange: (event: React.MouseEvent<HTMLAnchorElement> | undefined, data: { activePage: number }) => void;
}

const PAGE_SIZE = 100;

export const ExactFailuresTable = ({ failures, activePage, totalPages, onPageChange, totalItems }: ExactFailuresTableProps) => {
  const t = useTranslation().t as ITranslation;
  const columnHelper = createColumnHelper<ExactOnlineFailure>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: activePage - 1,
    pageSize: totalPages
  });

  const handlePaginationChange: OnChangeFn<PaginationState> = updater => {
    setPagination(old => {
      const newState = typeof updater === "function" ? updater(old) : updater;
      onPageChange(undefined as any, { activePage: newState.pageIndex + 1 });
      return newState;
    });
  };

  const columns = [
    columnHelper.accessor(row => row.exact_action, {
      id: "exact_action",
      header: t("v8_exact_action").message || "Action",
      cell: ({ getValue }) => {
        const actionValue = getValue();
        const actionKey = Object.entries(EXACT_ACTION_TYPES).find(([_, value]) => value === actionValue)?.[0];
        return actionKey ? t(`exact_action_${actionKey.toLowerCase()}`).message || actionKey : actionValue;
      },
      size: 100
    }),
    columnHelper.accessor(row => row.status_code, {
      id: "status_code",
      header: t("v8_status_code").message || "Status Code",
      size: 80
    }),
    columnHelper.accessor(row => row.dealer_name, {
      id: "dealer_name",
      header: t("v8_dealer").message || "Dealer",
      size: 80
    }),
    columnHelper.accessor(row => row.location_name, {
      id: "location_name",
      header: t("v8_location").message || "Location",
      size: 80
    }),
    columnHelper.accessor(row => row.request_body, {
      id: "request_body",
      header: t("v8_request_body").message || "Request Body",
      cell: ({ getValue }) => (
        <CopyToClipboard text={getValue() ?? ""} showPopup onSuccess={() => toast.success(t("v8_copy_to_clipboard").message || "Copied to clipboard")} />
      ),
      size: 350
    }),
    columnHelper.accessor(row => row.created_on, {
      id: "created_on",
      header: t("v8_date").message || "Date",
      cell: ({ getValue }) => formatDate(getValue(), DATE_FORMATS_FNS.dateMonthYearTime),
      size: 100
    }),
    columnHelper.accessor(row => row.url, {
      id: "url",
      header: t("v8_url").message || "URL",
      cell: ({ getValue }) => <CopyToClipboard text={getValue()} showPopup onSuccess={() => toast.success(t("v8_copy_to_clipboard").message || "Copied to clipboard")} />,
      size: 250
    }),
    columnHelper.accessor(row => row.errormessage, {
      id: "error_message",
      header: t("v8_error_message").message || "Error Message",
      cell: ({ getValue }) => (
        <CopyToClipboard text={getValue() ?? ""} showPopup onSuccess={() => toast.success(t("v8_copy_to_clipboard").message || "Copied to clipboard")} />
      ),
      size: 250
    })
  ];

  return (
    <div className="ExactFailuresTable">
      <ReactTable
        columns={columns}
        data={failures || []}
        renderEmptyRow={!failures?.length}
        emptyRowMessage={t("v8_no_data").message || "No data"}
        pageCount={totalPages}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        totalItems={totalItems}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
};
