import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MenuItemProps } from "semantic-ui-react";

import { useDealersLocations, usePrevious } from "hooks";
import { DealerLicenseQueryKeys } from "modules/DealerLicenseOverview/queryKeys";
import { useDealerInvoices } from "modules/DealerLicenseOverview/service";
import { downloadInvoice } from "modules/LocationLicenseOverview/hooks";
import "modules/LocationLicenseOverview/LocationLicenseOverview.scss";
import { HistoricalInvoice, LOCATION_LICENSE_SUBPAGE } from "modules/LocationLicenseOverview/types";
import { ITranslation } from "util/interfaces";

type DealerLicenseTabsProps = {
  setSelectedInvoice: Dispatch<SetStateAction<HistoricalInvoice | null>>;
  setInvoicePDF: Dispatch<SetStateAction<Blob | null>>;
};

const useDealerLicenseTabs = ({ setSelectedInvoice, setInvoicePDF }: DealerLicenseTabsProps) => {
  const { selectedLocation, selectedDealer } = useDealersLocations();
  const t = useTranslation().t as ITranslation;
  const queryClient = useQueryClient();
  const previousDealer = usePrevious(selectedDealer);

  const [paginatedURL, setPaginatedURL] = useState("");
  const [currentInvoicePage, setCurrentInvoicePage] = useState(0);
  const [currentLocationLicenseSubpage, setCurrentLocationLicenseSubpage] = useState<LOCATION_LICENSE_SUBPAGE>(LOCATION_LICENSE_SUBPAGE.MonthlyLicenses);

  const { dealerInvoices, paginatedDealerInvoices, isLoadingDealerInvoices, errorDealerInvoices } = useDealerInvoices(paginatedURL, currentInvoicePage);

  const onInvoiceRowClick = async (rowData: HistoricalInvoice) => {
    if (!selectedLocation?.id) return;

    setSelectedInvoice(rowData);

    try {
      const pdfData = await downloadInvoice({ document_id: rowData.ID, dealer_location_id: selectedLocation?.id });
      setInvoicePDF(pdfData);
    } catch {
      toast.error(t("v8_failed_to_open_invoice").message || "Failed to open invoice");
    }
  };

  const handleChangeTab = (_e: React.MouseEvent<HTMLAnchorElement>, { index }: MenuItemProps) => {
    setCurrentLocationLicenseSubpage(index as LOCATION_LICENSE_SUBPAGE);
  };

  const invoicePageChange = (pageIndex: number) => {
    if (pageIndex < 0) return;

    if (paginatedDealerInvoices) {
      const existingDealerData = paginatedDealerInvoices.data.find(element => element.dealer === selectedDealer?.id);
      if (existingDealerData && existingDealerData.data[pageIndex]) return setCurrentInvoicePage(pageIndex);
    }

    if (!dealerInvoices?.__next) return;

    setPaginatedURL(dealerInvoices.__next);
    setCurrentInvoicePage(pageIndex);
  };

  const handleResetState = () => {
    setPaginatedURL("");
    setCurrentInvoicePage(0);
    setCurrentLocationLicenseSubpage(LOCATION_LICENSE_SUBPAGE.MonthlyLicenses);
    queryClient.setQueryData(DealerLicenseQueryKeys.paginatedInvoices, { data: [] });
  };

  useEffect(() => {
    if (selectedDealer && previousDealer && selectedDealer.id !== previousDealer.id) {
      setCurrentInvoicePage(0);
    }
  }, [selectedDealer, previousDealer]);

  return {
    handleResetState,
    handleChangeTab,
    invoicePageChange,
    onInvoiceRowClick,
    dealerInvoices,
    currentInvoicePage,
    errorDealerInvoices,
    isLoadingDealerInvoices,
    currentLocationLicenseSubpage
  };
};

export default useDealerLicenseTabs;
