import { UseQueryResult } from "@tanstack/react-query";
import { set, subDays } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Icon, Loader, Message } from "semantic-ui-react";

import { DatePicker, DetailsCards, NavPortal, Tabs } from "components";
import { useDealersLocations } from "hooks";
import { ReportData, ReportThreshold } from "models";
import LocationReportsTable from "modules/LocationReports/components/LocationReportsTable";
import PercentStackedBarChart from "modules/LocationReports/components/PercentStackedBarChart";
import SimpleBarChart from "modules/LocationReports/components/SimpleBarChart";
import SimplePieChart from "modules/LocationReports/components/SimplePieChart/index";
import StackedBarChart from "modules/LocationReports/components/StackedBarChart";
import TotalPieChart from "modules/LocationReports/components/TotalPieChart/index";
import { useLocationReports } from "modules/LocationReports/hooks/useLocationReports";
import "modules/LocationReports/LocationReports.scss";
import { addDaysToDate, toISOString } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

const subtractDays = (days: number): Date => {
  const currentDate = new Date();
  const dateAfterSubtractingDays = subDays(currentDate, days);
  const dateWithTime = set(dateAfterSubtractingDays, {
    hours: 12,
    minutes: 0,
    seconds: 0
  });
  return new Date(toISOString(dateWithTime));
};

export enum REPORT_PERIOD {
  Yesterday = 1,
  Week = 7,
  Month = 30,
  Quarter = 90,
  Custom = "custom"
}

export type PERIOD = REPORT_PERIOD.Yesterday | REPORT_PERIOD.Week | REPORT_PERIOD.Month | REPORT_PERIOD.Quarter | REPORT_PERIOD.Custom;

const LocationReports = () => {
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [dateFrom, setDateFrom] = useState<Date>(subtractDays(7));
  const [dateTo, setDateTo] = useState<Date>(subtractDays(1));
  const [expanded, setExpanded] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<PERIOD>(REPORT_PERIOD.Week);

  const t = useTranslation().t as ITranslation;

  const { selectedLocation } = useDealersLocations();

  const { groups, selectedGroup, setSelectedGroup, reports, loading } = useLocationReports({
    dealer_location_id: Number(selectedLocation?.id),
    date_from: dateFrom?.toISOString(),
    date_to: dateTo?.toISOString()
  });

  const tabsData = new Map(
    reports.map(report => [
      `report_${report.data?.id}`,
      { id: `report_${report.data?.id}`, title: (report.data && t(report.data?.report_name).message) || report.data?.report_name, data: [] }
    ])
  );

  const onSelectPeriod = (value: PERIOD) => {
    setSelectedPeriod(value);

    const period = (value === REPORT_PERIOD.Custom ? value : Number(value)) as PERIOD;

    switch (period) {
      case REPORT_PERIOD.Week:
      case REPORT_PERIOD.Month:
      case REPORT_PERIOD.Quarter:
        setDateFrom(subtractDays(period));
        setDateTo(subtractDays(1));
        setShowCustomRange(false);
        break;
      case REPORT_PERIOD.Yesterday:
        setDateFrom(subtractDays(1));
        setDateTo(subtractDays(1));
        setShowCustomRange(false);
        break;
      case REPORT_PERIOD.Custom:
        setShowCustomRange(true);
        break;
      default:
        break;
    }
  };

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const handleDateChange = (dates: Date[]) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setDateFrom(start);
      setDateTo(end);
    }
  };

  const periodLabels: Record<PERIOD, string> = {
    [REPORT_PERIOD.Yesterday]: `${t("v8_yesterday").message || "Yesterday"}`,
    [REPORT_PERIOD.Week]: `7 ${t("v8_days").message || "Days"}`,
    [REPORT_PERIOD.Month]: `30 ${t("v8_days").message || "Days"}`,
    [REPORT_PERIOD.Quarter]: `90 ${t("v8_days").message || "Days"}`,
    [REPORT_PERIOD.Custom]: t("v8_custom").message || "Custom"
  };

  const renderReports = (report: UseQueryResult<ReportData>) => {
    if (report.isLoading) return <Loader active inline />;

    if (report.isError) return <Message>{t("v8_failed_to_get_report").message || "Failed to get the report"}</Message>;

    if (!report.data) return null;

    if (!report.data.report_data?.length)
      return (
        <DetailsCards title={tabsData.get(`report_${report.data?.id}`)?.title} id={`report_${report.data?.id}`} isExpanded={expanded}>
          <div className="LocationReports__container-no-data">{t("no_data").message || "No data"}</div>
        </DetailsCards>
      );

    const thresholds = report.data?.thresholds || [];

    if (thresholds?.length > 0) {
      thresholds.forEach((th: ReportThreshold) => {
        if (th.name) th.name = t(th.name).message || th.name;
      });
    }

    if (report.data.report_type === "SimplePieChart")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <SimplePieChart data={report.data.report_data} colors={report.data.report_colors || []} />
        </DetailsCards>
      );
    if (report.data.report_type === "TotalPieChart")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <TotalPieChart data={report.data.report_data} colors={report.data.report_colors || []} />
        </DetailsCards>
      );
    if (report.data.report_type === "SimpleBarChart")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <SimpleBarChart
            key={report.data.report_name}
            colors={report.data.report_colors || []}
            thresholds={thresholds}
            data={report.data.report_data}
            unit={report.data.unit}
          />
        </DetailsCards>
      );
    if (report.data.report_type === "StackedBarChart")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <StackedBarChart
            key={report.data.report_name}
            colors={report.data.report_colors || []}
            data={report.data.report_data}
            unit={t(report.data.unit).message || report.data.unit}
          />
        </DetailsCards>
      );
    if (report.data.report_type === "PercentStackedBarChart")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <PercentStackedBarChart
            key={report.data.report_name}
            colors={report.data.report_colors || []}
            data={report.data.report_data}
            unit={t(report.data.unit).message || report.data.unit}
          />
        </DetailsCards>
      );
    if (report.data.report_type === "Tabular")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <LocationReportsTable data={report.data.report_data} />
        </DetailsCards>
      );
  };

  return (
    <>
      <NavPortal>
        <div className="nav-bar-start LocationReports__dropdowns">
          <div className="LocationReports__dropdowns-container">
            <label htmlFor="location_report_group">{t("report").message || "Report"}</label>
            <Dropdown
              id="location_report_group"
              className="nav-bar-map"
              button
              floating
              labeled
              selection
              value={Number(selectedGroup)}
              onChange={(_e, { value }) => setSelectedGroup(Number(value))}
              options={groups.data?.map(i => ({ key: i, value: Number(i), text: t(`v8_report_group_${i}`).message || i }))}
            />

            <label htmlFor="location_report_period">{t("select_time_period").message || "Select time period"}</label>
            <Dropdown
              id="location_report_period"
              className="nav-bar-map"
              button
              floating
              labeled
              selection
              value={selectedPeriod}
              onChange={(_e, { value }) => onSelectPeriod(value as PERIOD)}
              options={Object.values(REPORT_PERIOD)
                .filter(value => !!periodLabels[value as PERIOD])
                .map(value => ({
                  key: value,
                  text: periodLabels[value as PERIOD],
                  value
                }))}
            />
            {showCustomRange && (
              <>
                {t("v8_select_date_range").message || "Select date range"}
                <DatePicker onDateChange={handleDateChange} selectsRange startDate={dateFrom} endDate={dateTo} maxDate={addDaysToDate(dateFrom, 90)} showLabel />
              </>
            )}
          </div>
        </div>
      </NavPortal>
      <div className="LocationReports__container">
        <div className="LocationReports__container-header">
          <div className="LocationReports__container-header-wrapper">
            <div className="row">
              <Tabs data={tabsData} hideBorder offset={-250} />
              <div className="sub-row">
                <Button className="-appointment-status icon" size="small" onClick={toggleExpand}>
                  <Icon className={`arrows ${expanded ? "minimize" : "maximize"}`} color="green" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="LocationReports__container-content">
          {loading && <Loader active />}
          {reports.map(r => renderReports(r))}
        </div>
      </div>
    </>
  );
};

export default LocationReports;
