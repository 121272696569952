import { Popup } from "semantic-ui-react";

import { DATE_FORMATS_FNS, StatusIcon, getAppointmentStatus } from "components";
import { useDealersLocations } from "hooks";
import { COMMUNICATION_STATUS, STATUS_IDENTIFIER } from "models";
import "modules/AppointmentDetails/components/StatusBarIcon/StatusBarIcon.scss";
import { formatDate } from "util/dateHelperFunctions";

type StatusIconProps = {
  status: STATUS_IDENTIFIER;
  active?: boolean;
  name?: string;
  timestamp?: string | null;
  disabled?: boolean;
  color?: string;
  isMechanicState?: boolean;
  onClick?: () => void;
  customcomStatus?: COMMUNICATION_STATUS | null;
  checkPausedAt?: string | null;
};

export const StatusBarIcon = ({
  status: statusIdentifier,
  active,
  disabled,
  onClick,
  isMechanicState = false,
  name,
  timestamp,
  customcomStatus,
  checkPausedAt
}: StatusIconProps) => {
  const { selectedLocation } = useDealersLocations();
  const status = getAppointmentStatus(statusIdentifier, selectedLocation?.statuses);
  const iconColor = (disabled && !active) || isMechanicState ? "#a1a1a1" : "#000000";
  let backgroundColor = "#ffffff";

  if (active && status.color) backgroundColor = status.color;
  else if (isMechanicState) backgroundColor = "#ffffff";
  else if (disabled) backgroundColor = "#E6E6E6";

  const borderColor = isMechanicState ? "#B4B4B4" : !active && !disabled ? "#000000" : backgroundColor;

  return (
    <Popup
      content={
        <div className="StatusIcon-content">
          {timestamp || name ? (
            <div className="grid-container">
              <div className="left-section">
                <StatusIcon status={status.identifier} />
              </div>

              <div className="right-section">
                <div className="status-time">
                  <span>{timestamp && formatDate(timestamp, DATE_FORMATS_FNS.hoursMinutes)}</span>
                  <span>{status.name}</span>
                </div>
                <span>{name}</span>
              </div>
            </div>
          ) : (
            status.name
          )}
        </div>
      }
      trigger={
        <div
          className={`StatusIcon ${disabled ? "disabled" : ""} ${active ? "active" : ""}`}
          style={{ backgroundColor, borderColor }}
          onClick={() => {
            if (!disabled && onClick) onClick();
          }}
        >
          <StatusIcon
            status={status.identifier}
            color={iconColor}
            value={{ id: statusIdentifier, customcom_status: customcomStatus, check_paused_at: checkPausedAt }}
            showSubIcon={false}
          />
        </div>
      }
    />
  );
};
