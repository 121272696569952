import { useQuery } from "@tanstack/react-query";
import { subMonths } from "date-fns";

import { DATE_FORMATS_FNS, statusData } from "components";
import { useDealersLocations } from "hooks";
import { DEFAULT_PIN_TYPES } from "modules/WarrantyDashboard/components/WarrantyDashboardTable/hooks";
import { ORDER_BY, WARRANTY_TIME_PERIODS } from "modules/WarrantyDashboard/hooks";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyDashboardFiltersPayload } from "modules/WarrantyDashboard/types";
import { FAKE_STATUSES } from "util/appointmentUtils";
import { getPreference } from "util/common";
import { formatDate } from "util/dateHelperFunctions";

export const WARRANTY_DASHBOARD_PIN_STATUS_SELECTION = "warranty-dashboard-pin-status-selection";

export const useDefaultWarrantyDashboardFilters = () => {
  const { selectedLocation } = useDealersLocations();

  const storedPinStatus = getPreference(WARRANTY_DASHBOARD_PIN_STATUS_SELECTION);
  const appointmentStatusIdentifiers = statusData.filter(status => !FAKE_STATUSES.includes(status.id));
  const date_from = formatDate(subMonths(new Date(), 1), DATE_FORMATS_FNS.ISOFormatEndOfDay);
  const date_to = formatDate(new Date(), DATE_FORMATS_FNS.ISOFormatEndOfDay);

  const defaultWarrantyFilters: WarrantyDashboardFiltersPayload = {
    appointment_statuses: appointmentStatusIdentifiers,
    brands: null,
    claimed: false,
    deleted: false,
    date_from,
    date_to,
    dealer_ids: [],
    location_ids: selectedLocation ? [selectedLocation.id] : [],
    mechanic_fixed: null,
    order_by: ORDER_BY.AppointmentDateAscending,
    page: 1,
    pin_status_id: storedPinStatus,
    pin_type_id: DEFAULT_PIN_TYPES,
    search_term: "",
    status: null,
    warranty_type_id: null,
    claim_order_by: null,
    time_period: WARRANTY_TIME_PERIODS.LastMonth,
    pin_status_refresh_timestamp: null
  };

  return { defaultWarrantyFilters };
};

export const useGetWarrantyDashboardFilters = () => {
  const { selectedLocation } = useDealersLocations();
  const { defaultWarrantyFilters } = useDefaultWarrantyDashboardFilters();
  const { data, isLoading } = useQuery<WarrantyDashboardFiltersPayload>({
    queryKey: WarrantyQueryKeys.warrantyFilters,
    placeholderData: defaultWarrantyFilters,
    queryFn: () => ({ ...defaultWarrantyFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] }),
    enabled: !!selectedLocation,
    gcTime: Infinity
  });

  return { filters: data || defaultWarrantyFilters, isLoading };
};
