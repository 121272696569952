import ENV from "config/Env";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

const component = "LeadsDashboard";

type ChecklistQueryKeys = {
  dealer_ids: number[] | null;
  location_ids: number[] | null;
};

export type SnoozedQueryParams = {
  snoozedFilters: LeadsSnoozedFilters | undefined;
};

export type NotSnoozedQueryParams = {
  notSnoozedFilters: LeadsNotSnoozedFilters | undefined;
};

export type CarsQueryParams = {
  carsFilters: LeadsCarsFilter | undefined;
};

export const LeadsQueryKeys = {
  listCars: ({ carsFilters }: CarsQueryParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/leads/cars",
      params: { filters: carsFilters }
    })
  ],
  listSnoozed: ({ snoozedFilters }: SnoozedQueryParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/leads/snoozed",
      params: { filters: snoozedFilters }
    })
  ],
  listNotSnoozed: ({ notSnoozedFilters }: NotSnoozedQueryParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/leads/not_snoozed",
      params: { filters: notSnoozedFilters }
    })
  ],
  tags: backendQueryKey({
    updateType: UPDATE_TYPE.Static,
    component,
    baseUrl: ENV.systemBaseURL,
    endpoint: "/tags/list"
  }),
  checklists: ({ dealer_ids, location_ids }: ChecklistQueryKeys) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.dealerBaseUrl,
      endpoint: "/checklists/list",
      params: { dealer_ids, location_ids }
    })
  ],
  snoozedFilters: [localQueryKey({ component, data: "snoozedFilters" })] as const,
  notSnoozedFilters: [localQueryKey({ component, data: "notSnoozedFilters" })] as const,
  carsFilters: [localQueryKey({ component, data: "carsFilters" })] as const,
  leadsPageType: [localQueryKey({ component, data: "leadsPageType" })] as const
};
