import { Icon } from "semantic-ui-react";

import "components/TableFilters/TableQuestionStatusButton/TableQuestionStatusButton.scss";

type Props = {
  selectedQuestionStatuses: string[];
};

export const TableQuestionStatusButton = ({ selectedQuestionStatuses }: Props) => (
  <div className="table-question-status-card-user">
    <Icon className={`${selectedQuestionStatuses.includes("all") ? "filter" : "filters"} table-question-status-card-icon`} size="small" />
  </div>
);
