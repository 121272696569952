import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn } from "semantic-ui-react";

import { ANSWER_STATUS_SELECTOR_TYPE, DetailsCards, QuestionResultData, TabData } from "components";
import { ActionModalSelectorData, ExtendedInterventionData, MODAL_TYPE } from "components/CheckList/ActionModalSelector";
import InterventionModal from "components/InterventionModal";
import SnoozeModal from "components/SnoozeModal";
import { Appointment, Check, QuestionResult, SnoozeItem as SnoozeItemModel } from "models";
import "modules/AppointmentDetails/components/SnoozedItems/SnoozedItems.scss";
import SnoozeItem from "modules/AppointmentDetails/components/SnoozedItems/SnoozeItem";
import { SnoozeTable } from "modules/AppointmentDetails/components/SnoozedItems/SnoozeTable";
import { useAppointmentHistorySnoozeItems } from "modules/AppointmentDetails/hooks/useAppointmentHistorySnoozeItems";
import { ITranslation } from "util/interfaces";

type SnoozedItemsProps = {
  isExpanded: boolean;
  tabData: TabData;
  appointment: Appointment;
};

export const getChecklistSnoozedItems = (checklist: Check[] = []) => {
  return checklist.reduce(
    (acc: QuestionResult[], curr: Check) =>
      curr.checklist?.template && !curr.checklist.template.snooze_enabled
        ? acc
        : curr.question_items
          ? [...acc, ...curr.question_items.filter(item => item.snoozed)]
          : acc,
    []
  );
};

export const SnoozedItems = ({ isExpanded, tabData, appointment }: SnoozedItemsProps) => {
  const t = useTranslation().t as ITranslation;
  const checkListColumns = [
    { header: t("v8_title").message || "Title" },
    { header: t("v8_snooze_type").message || "Snooze type" },
    { header: t("v8_snooze_date").message || "Snooze date" },
    { header: t("v8_snooze_status").message || "Snooze status" },
    { header: t("v8_department").message || "Department" },
    { header: t("v8_note").message || "Note" },
    { header: "" }
  ];

  const [snoozeData, setSnoozeData] = useState<QuestionResult[] | undefined>(tabData.data);
  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [modalData, setModalData] = useState<ActionModalSelectorData | null>(null);
  const { appointmentHistorySnoozeItems, loading, setCurrentPage, currentPage, updateAnswerStatus, updateAppointmentHistorySnoozeItems } =
    useAppointmentHistorySnoozeItems(appointment.id);

  const handlePageChange = (data: { activePage: number }) => {
    setCurrentPage(data.activePage);
  };

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType === MODAL_TYPE.Intervention) {
      setModalData(data as ExtendedInterventionData);
      setShowInterventionModal(true);
    } else if (data.modalType === MODAL_TYPE.EditSnooze) {
      setModalData(data as QuestionResultData);
      setShowSnoozeModal(true);
    }
  };

  const handleAnswerStatus = (id: number, status: number, type: ANSWER_STATUS_SELECTOR_TYPE) => {
    updateAnswerStatus(id, status, type);
  };

  const onRequestCloseModal = () => {
    setShowInterventionModal(false);
    setShowSnoozeModal(false);
    setModalData(null);
  };

  const handleSnoozeItemUpdate = (data: Partial<SnoozeItemModel>) => {
    updateAppointmentHistorySnoozeItems(data);
    onRequestCloseModal();
  };

  useEffect(() => {
    setSnoozeData(tabData.data);
  }, [tabData.data]);

  return (
    <>
      <DetailsCards
        id={tabData.id}
        title={`${t("snoozed_item").message || "Snoozed Items"} ${tabData.data?.length > 0 ? `(${tabData.data.length})` : ""}`}
        icon="clock"
        isExpanded={isExpanded}
      >
        {!!snoozeData?.length && (
          <div className="Appointment-SnoozedItems">
            <Grid columns={7}>
              {checkListColumns.map(item => (
                <GridColumn key={item.header} className="header">
                  <p>{item.header}</p>
                </GridColumn>
              ))}
              {snoozeData.map((item, index) => (
                <SnoozeItem key={item.id} questionResult={item} appointment={appointment} noBorder={index === snoozeData.length - 1} onSelect={handleSelect} />
              ))}
            </Grid>
          </div>
        )}

        <SnoozeTable
          snoozeItems={appointmentHistorySnoozeItems?.items || []}
          activePage={currentPage}
          totalPages={appointmentHistorySnoozeItems?.nb_pages || 1}
          onPageChange={handlePageChange}
          totalItems={appointmentHistorySnoozeItems?.nb_items || 0}
          appointment={appointment}
          onSelect={handleSelect}
          loading={loading}
          onUpdateAnswerStatus={handleAnswerStatus}
        />
      </DetailsCards>
      <InterventionModal size="large" open={showInterventionModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
      <SnoozeModal size="large" open={showSnoozeModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} onSave={handleSnoozeItemUpdate} />
    </>
  );
};
