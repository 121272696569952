import { faRectangleList, faShieldHalved, faThumbTack, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInMilliseconds } from "date-fns";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, GridColumn, Icon, Label } from "semantic-ui-react";

import { BasicAttachment, DATE_FORMATS_FNS, FileDropdown, PinUserLabel } from "components";
import "components/PinLog/PinLog.scss";
import { Appointment, AppointmentNoteAttachment, Intervention, PIN_STATUS, PinModel, SnoozeAttachment } from "models";
import { getShopIconColor } from "modules/AppointmentDetails/components/Interventions/components";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type PinOptions = {
  key: string;
  text: string | undefined;
  value: number;
};

type PinLogProps = {
  pinTypeData: PinOptions[];
  pinWarrantyData: PinOptions[];
  pinStatusData: PinOptions[];
  pinOrderData: PinOptions[];
  pinLog?: PinModel[];
  showTitle?: boolean;
  attachments?: AppointmentNoteAttachment[] | SnoozeAttachment[] | BasicAttachment[];
  appointment?: Appointment | null;
  intervention?: Intervention;
};

export const PinLog = ({ attachments, pinTypeData, pinWarrantyData, pinStatusData, pinOrderData, pinLog, showTitle = true, appointment, intervention }: PinLogProps) => {
  const t = useTranslation().t as ITranslation;
  const { updatePinVisibility } = useIntervention(Number(appointment?.id));

  if (!pinLog?.length) return;

  const sortedPinLog = pinLog.sort((a, b) => differenceInMilliseconds(new Date(b.created_on), new Date(a.created_on)));

  const onImportantClick = (currentPin: PinModel) => {
    if (!intervention) return;
    const payload = {
      pin_id: currentPin.id,
      intervention_id: intervention.id,
      visible_mechanic: currentPin.visible_mechanic,
      visible_important_items: !currentPin.visible_important_items
    };
    updatePinVisibility.mutate(payload);
  };

  return (
    <div className="PinLog">
      {showTitle && (
        <Grid className="PinLogHistoryHeading">
          <GridColumn width={14}>
            <p>{t("v8_log_history").message || "Log History"}</p>
            <FileDropdown attachments={attachments} iconColor="grey" />
          </GridColumn>
        </Grid>
      )}
      <div className="PinLogHistory">
        {sortedPinLog.map((currentPin: PinModel, i: number) => {
          const previousPin = sortedPinLog[i + 1];
          const pinType = currentPin.pin_type_id !== previousPin?.pin_type_id && pinTypeData?.find(item => item.value === currentPin.pin_type_id)?.text;
          const pinWarrantyType =
            currentPin.warranty_type_id !== previousPin?.warranty_type_id && pinWarrantyData?.find(item => item.value === currentPin.warranty_type_id)?.text;
          const pinStatusType = currentPin.pin_status_id !== previousPin?.pin_status_id && pinStatusData?.find(item => item.value === currentPin.pin_status_id)?.text;
          const pinOrderType = currentPin.order_status !== previousPin?.order_status && pinOrderData?.find(item => item.value === currentPin.order_status)?.text;

          const currentPinAttachments = [...(currentPin?.note_attachments || [])].map(attachment => ({ url: attachment }));

          return (
            <Grid key={currentPin.id} className="pin-log-item">
              <GridColumn width={16}>
                <div className="pin-info">
                  <PinUserLabel currentPin={currentPin} />

                  <Label basic>{formatDate(currentPin.updated_on, DATE_FORMATS_FNS.dateMonthYearTime)}</Label>

                  <FileDropdown attachments={currentPinAttachments} iconColor="grey" />

                  <>
                    {currentPin.manufacturer_id && currentPin.manufacturer_id > 0 && currentPin.manufacturer_id !== previousPin?.manufacturer_id && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong>
                          {t("v8_granted_access_to_the_manufacturer").message || "granted access to the manufacturer"} {currentPin.manufacturer?.first_name}{" "}
                          {currentPin.manufacturer?.last_name}
                        </strong>
                      </Label>
                    )}
                    {currentPin.pin_status_id === PIN_STATUS.Deleted && <>{<strong style={{ color: "red" }}>{t("v8_deleted").message || "Deleted"}</strong>}</>}
                    {currentPin.mechanic_fixed && currentPin.mechanic_fixed !== previousPin?.mechanic_fixed && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong style={{ color: "blue" }}>{t("v8_marked_as_fixed").message || "Marked as fixed"}</strong>
                      </Label>
                    )}
                    {currentPin.info && currentPin.info !== previousPin?.info && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong style={{ color: "blue" }}>{t("v8_marked_as_info").message || "Marked as info"}</strong>
                      </Label>
                    )}
                  </>

                  {(pinWarrantyType || pinType) && (
                    <Label basic className="log-label">
                      <FontAwesomeIcon icon={pinWarrantyType ? faShieldHalved : faThumbTack} className="i-grey mr-5" />
                      {pinWarrantyType || pinType}
                    </Label>
                  )}

                  {pinOrderType && (
                    <Label basic className="log-label">
                      <Icon className="shopping cart mr-5" color={getShopIconColor(currentPin.order_status as number)} />
                      {pinOrderType}
                    </Label>
                  )}
                  {pinStatusType && (
                    <Label basic className="log-label">
                      <FontAwesomeIcon icon={faRectangleList} className="i-grey mr-5" />
                      {pinStatusType}
                    </Label>
                  )}
                  {currentPin.claim_nr && currentPin.claim_nr !== previousPin?.claim_nr && (
                    <Label basic className="log-label">
                      # {t("v8_claim").message || "Claim"}: {currentPin.claim_nr}
                    </Label>
                  )}
                  {currentPin.support_nr && currentPin.support_nr !== previousPin?.support_nr && (
                    <Label basic className="log-label">
                      # {t("v8_support").message || "Support"}: {currentPin.support_nr}
                    </Label>
                  )}
                  {currentPin.ref_nr && currentPin.ref_nr !== previousPin?.ref_nr && (
                    <Label basic className="log-label">
                      # {t("v8_reference").message || "Reference"}: {currentPin.ref_nr}
                    </Label>
                  )}

                  {(currentPin.keep_parts || currentPin.pin_status_id === PIN_STATUS.Deleted) && (
                    <p className="logHistoryParagraph">
                      {currentPin.pin_status_id === PIN_STATUS.Deleted ? (
                        t("v8_deleted").message || "Deleted"
                      ) : (
                        <>
                          <Label basic className="label-container">
                            <Icon className="gear i-green mr-5" />
                            {t("v8_keep_parts").message || "Keep Parts"}
                          </Label>
                        </>
                      )}
                    </p>
                  )}

                  {!!currentPin.note && (
                    <Label basic className="important-container">
                      <Icon className="exclamation circle mr-5" color="red" />
                      <div>{t("v8_important").message || "Important"}</div>
                      <Checkbox checked={currentPin.visible_important_items} onChange={() => onImportantClick(currentPin)} />
                    </Label>
                  )}

                  {currentPin.note && <span>{currentPin.note}</span>}
                </div>
              </GridColumn>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};
