import { useTranslation } from "react-i18next";
import { Button, GridColumn, GridRow, Icon, Popup } from "semantic-ui-react";

import { Editable, useCan } from "components";
import ENV from "config/Env";
import { useDealersLocations, useUser } from "hooks";
import { Appointment, QuestionResult, TYRE_TEAM_CHANNEL, Tyre, TyreReplacement } from "models";
import { useTyreTeam } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/hooks";
import "modules/AppointmentDetails/components/Tyre/components/TyreReplacementTableItem/TyreReplacementTableItem.scss";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";
import { getTyreDescription, size } from "modules/AppointmentDetails/components/Tyre/TyreTable";
import { isCarReadyOrQualityCheckDoneOrCanceled } from "util/appointmentUtils";
import { getTyreSeasonIcon } from "util/common";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

type TyreTableItemProps = {
  data: TyreReplacement;
  appointment: Appointment;
  onRequestReplacementOrder?: (data: QuestionResult) => void;
};

const DELIVERY_TYPES = {
  PICKUP: "PICKUP",
  SAMEDAY: "SAMEDAY",
  OVERNIGHT: "OVERNIGHT"
};

export const TyreReplacementTableItem = ({ data, appointment, onRequestReplacementOrder }: TyreTableItemProps) => {
  const t = useTranslation().t as ITranslation;
  const { tyreReplacementDeleteMutation, tyreReplacementRemarkMutation } = useTyre(appointment.id);
  const { getDeliveryChannel, getDelivery } = useTyreTeam({ appointmentId: appointment.id });
  const { selectedLocation } = useDealersLocations();
  const user = useUser();
  const canUpdateTyreReplacements = useCan("update", "appointments") && !isCarReadyOrQualityCheckDoneOrCanceled(appointment.appointment_status_identifier);

  const handleRemark = (remark: string) => {
    tyreReplacementRemarkMutation.mutate({ ...data, remark });
  };

  const getDeliveryColor = (delivery: string | null) => {
    if (!delivery) return;

    if (delivery.includes(DELIVERY_TYPES.PICKUP)) return "orange";
    if (delivery.includes(DELIVERY_TYPES.SAMEDAY)) return "green";
    if (delivery.includes(DELIVERY_TYPES.OVERNIGHT)) return "#2185d0";
  };

  const openOrderPortal = (channel: TYRE_TEAM_CHANNEL | null) => {
    if (!channel) return;

    if (channel === TYRE_TEAM_CHANNEL.WheelTyre) window.open(ENV.wheelTireOrderPortalURL, "_blank");
    else if (channel === TYRE_TEAM_CHANNEL.BandenExpress) window.open(ENV.bandenExpressOrderPortalURL, "_blank");
  };

  const onOrderClick = () => {
    if (!user?.tyre_ordering_enabled) return;
    if (data.tyre_team_order_placed) return openOrderPortal(data.tyre_team_channel);
    onRequestReplacementOrder?.(data as unknown as QuestionResult);
  };

  const isEditable = () => {
    if (selectedLocation?.is_tyre_team_enabled) {
      return true;
    }

    return canUpdateTyreReplacements && !isCarReadyOrQualityCheckDoneOrCanceled(appointment.appointment_status_identifier);
  };

  const renderDeliveryInfo = () => {
    if (!data.tyre_team_delivery) return null;

    const deliveryInfo = getDelivery(data.tyre_team_delivery);

    return (
      <div>
        {deliveryInfo ? deliveryInfo.content : data.tyre_team_delivery}
        <br />
        {t("ordering_channel").message || "Ordering Channel"}: {getDeliveryChannel(data.tyre_team_channel)}
      </div>
    );
  };

  const showOrderButton = selectedLocation?.is_tyre_team_enabled && data.tyre_team_system_number && (data.tyre_team_order_placed || canUpdateTyreReplacements);

  return (
    <GridRow>
      <GridColumn width={2} className="TyreReplacementTableItem">
        <p className="replace-with">
          <strong>{t("v8_replace_with").message || "Replace with"}:</strong>
        </p>
      </GridColumn>
      <GridColumn width={2}>
        <Popup
          hoverable
          content={size(data.tyre as Tyre)}
          trigger={
            <div className="tyre-season-container">
              <Icon className={getTyreSeasonIcon(Number(data.tyre?.season))} />
              <p>{size(data.tyre as Tyre)}</p>
            </div>
          }
        />
      </GridColumn>
      <GridColumn width={4}>
        <Popup hoverable content={getTyreDescription(data.tyre as Tyre)} trigger={<p>{getTyreDescription(data.tyre as Tyre)}</p>} />
      </GridColumn>
      <GridColumn width={1}>
        <Popup hoverable content={data.tyre?.manufacturer} trigger={<p>{data.tyre?.manufacturer}</p>} />
      </GridColumn>
      <GridColumn width={1}>{data.profile ? `${data.profile} mm` : ""}</GridColumn>
      <GridColumn width={1}>{data.price ? formatPrice(data.price) : ""}</GridColumn>

      <GridColumn width={2}>
        <Editable hoverable value={data.remark} onSubmit={handleRemark} disabled={!isEditable()} />
      </GridColumn>
      {canUpdateTyreReplacements && (
        <GridColumn width={1} style={{ marginLeft: "auto" }}>
          <div className={`TyreReplacementTableItem__actions ${showOrderButton ? "-composite-button" : ""}`}>
            <Button className="tiGreenBg off af rounded" onClick={() => tyreReplacementDeleteMutation.mutate(data)}>
              <Icon className="trash grey" />
            </Button>

            {showOrderButton && (
              <Popup
                flowing
                position="top left"
                trigger={
                  <Button
                    className={`af off rounded tyre-team-order-button ${!user?.tyre_ordering_enabled ? " -order-disabled" : ""}`}
                    style={data.tyre_team_order_placed ? { color: "#FFF", backgroundColor: getDeliveryColor(data.tyre_team_delivery) } : {}}
                    onClick={onOrderClick}
                  >
                    <Icon className="shopping cart" />
                  </Button>
                }
                content={renderDeliveryInfo()}
              />
            )}
          </div>
        </GridColumn>
      )}
    </GridRow>
  );
};
