import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment, Check, QuestionResult, SearchTyreReplacement, TYRE_TEAM_CHANNEL, TyreReplacement } from "models";
import { TYRE_TEAM_ORDER_STATUS, TyreLocationData } from "modules/AppointmentDetails/components";
import { useTyreTeam } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/hooks";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";
import { ITranslation } from "util/interfaces";

interface TyreTeamOrderPayload {
  check_id: number;
  system_number: string;
  quantity: number;
  delivery: string;
  channel: TYRE_TEAM_CHANNEL;
  ref4?: string;
  ref5?: string;
  ref6?: string;
}

interface Props {
  data: QuestionResult | null;
  appointment: Appointment;
  tireData: TyreLocationData;
  onClose?: () => void;
  open: boolean;
}

interface LocalReplacementToOrder extends TyreReplacement {
  quantity: number;
  status?: TYRE_TEAM_ORDER_STATUS;
  initial_quantity?: number;
}

export const useTyreReplacementOrder = ({ appointment, tireData, onClose, open }: Props) => {
  const t = useTranslation().t as ITranslation;
  const queryClient = useQueryClient();
  const checklistQueryKey = AppointmentDetailsKeys.checks(appointment.id);

  const [loading, setLoading] = useState(false);
  const [ref5, setRef5] = useState("");
  const [ref6, setRef6] = useState("");
  const [replacementToOrder, setReplacementToOrder] = useState<LocalReplacementToOrder[]>([]);

  const { getDelivery, getDeliveryChannel } = useTyreTeam({ appointmentId: appointment.id });
  const { selectedLocation } = useDealersLocations();

  const hasLoadedData = useRef(false);

  useEffect(() => {
    if (replacementToOrder.length > 0) {
      const replacementTyre = replacementToOrder[0];
      if (replacementTyre.tyre?.ean) setRef5(`EAN: ${replacementTyre.tyre.ean}`);
    }
  }, [replacementToOrder]);

  const getQuantityOptions = (stock: number) => {
    const options = [];

    for (let i = 0; i <= stock; i++) {
      options.push({
        key: i,
        text: i,
        value: i
      });
    }
    return options;
  };

  const fetchStockMutation = useMutation({
    mutationFn: async (toOrder: LocalReplacementToOrder[]) => {
      if (!tireData?.onCar[0]?.check_id || !toOrder.length) return [];

      const params = {
        check_id: tireData?.onCar[0]?.check_id,
        tyre_replacement_ids: toOrder.map(rep => rep.tyre_replacement_id)
      };

      const response = await ApiInstance.post("/catalog/stock", params, ENV.tyreteamBaseURL);
      return response.data.tyres as SearchTyreReplacement[];
    },
    onSuccess: stockData => {
      setReplacementToOrder(prev =>
        prev.map(replacement => {
          const stockItem = stockData?.find(
            stock =>
              stock.system_number === replacement.tyre_team_system_number &&
              stock.channel === replacement.tyre_team_channel &&
              stock.delivery === replacement.tyre_team_delivery
          );

          if (stockItem) {
            return {
              ...replacement,
              stock: stockItem.stock,
              gross_price: stockItem.gross_price,
              net_price: stockItem.net_price,
              currency: stockItem.currency
            };
          }

          return replacement;
        })
      );
    },
    onError: () => {
      toast.error(t("v8_error_loading_stock").message || "Error loading stock data");
    }
  });

  const getReplacementsNotOrdered = () => {
    const onCarReplacements = tireData.onCar.flatMap(item => item?.tyre_replacements || []);
    const inStorageReplacements = tireData.inStorage.flatMap(item => item?.tyre_replacements || []);
    const allReplacements = [...onCarReplacements, ...inStorageReplacements];

    return allReplacements.filter(replacement => Boolean(replacement.tyre_team_system_number) && !replacement.tyre_team_order_placed);
  };

  const handleGetReplacementsToOrder = async () => {
    const replacementTyres = getReplacementsNotOrdered();
    const toOrder: LocalReplacementToOrder[] = [];

    replacementTyres.forEach(replacement => {
      const existingIndex = toOrder.findIndex(
        item =>
          item.tyre_team_channel === replacement.tyre_team_channel &&
          item.tyre_team_delivery === replacement.tyre_team_delivery &&
          item.tyre_team_system_number === replacement.tyre_team_system_number
      );

      if (existingIndex > -1) toOrder[existingIndex].quantity = (toOrder[existingIndex].quantity || 0) + 1;
      else
        toOrder.push({
          ...replacement,
          stock: replacement.stock,
          quantity: 1
        });
    });

    toOrder.forEach(item => {
      item.initial_quantity = item.quantity;
    });

    setReplacementToOrder(toOrder);

    if (toOrder.length > 0 && open) fetchStockMutation.mutate(toOrder);
  };

  useEffect(() => {
    if (open && !hasLoadedData.current) {
      handleGetReplacementsToOrder();
      hasLoadedData.current = true;
    }

    if (!open) hasLoadedData.current = false;
  }, [open]);

  const handleQuantityChange = (ean: string, delivery: string, newValue: number) => {
    setReplacementToOrder(prev =>
      prev.map(rep => {
        if (rep.tyre?.ean === ean && rep.tyre_team_delivery === delivery) return { ...rep, quantity: newValue };
        return rep;
      })
    );
  };

  const tyreTeamPlaceOrder = async (orderPayload: TyreTeamOrderPayload) => {
    const response = await ApiInstance.post("/catalog/order", orderPayload, ENV.tyreteamBaseURL);
    return response.data as SearchTyreReplacement[];
  };

  const tyreTeamPlaceOrderMutation = useMutation({
    mutationFn: tyreTeamPlaceOrder,
    onMutate: variables => {
      setReplacementToOrder(prev =>
        prev.map(rep => {
          if (rep.tyre_team_system_number === variables.system_number && rep.tyre_team_delivery === variables.delivery) {
            return { ...rep, status: TYRE_TEAM_ORDER_STATUS.Pending };
          }
          return rep;
        })
      );

      return { variables };
    },
    onSuccess: (_, variables) => {
      setReplacementToOrder(prev =>
        prev.map(rep => {
          if (rep.tyre_team_system_number === variables.system_number && rep.tyre_team_delivery === variables.delivery) {
            let newStock;
            if (rep.stock?.includes(">")) {
              newStock = rep.stock;
            } else {
              newStock = Number(rep.stock || 0) - variables.quantity;
              newStock = newStock > 0 ? newStock.toString() : "0";
            }

            const originalQuantity = rep.initial_quantity || 0;
            const remainingQuantity = Math.max(0, originalQuantity - variables.quantity);

            return {
              ...rep,
              status: TYRE_TEAM_ORDER_STATUS.Fulfilled,
              initial_quantity: remainingQuantity,
              quantity: 0,
              stock: newStock
            };
          }
          return rep;
        })
      );

      const checklistSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);
      if (checklistSnapshot) {
        const updatedChecklist = checklistSnapshot.map(check => {
          const updatedQuestions = check.question_items?.map(question => {
            let remainingToMark = variables.quantity;

            const updatedReplacements = question.tyre_replacements?.map(replacement => {
              if (
                replacement.tyre_team_system_number === variables.system_number &&
                replacement.tyre_team_delivery === variables.delivery &&
                !replacement.tyre_team_order_placed &&
                remainingToMark > 0
              ) {
                remainingToMark--;
                return {
                  ...replacement,
                  tyre_team_order_placed: true
                };
              }
              return replacement;
            });

            return { ...question, tyre_replacements: updatedReplacements };
          });

          return { ...check, question_items: updatedQuestions };
        });

        queryClient.setQueryData(checklistQueryKey, updatedChecklist);
      }
    },
    onError: (error, variables: TyreTeamOrderPayload) => {
      toast.error(error.message || t("v8_error_placing_order").message || "Error placing order");

      setReplacementToOrder(prev =>
        prev.map(rep => {
          if (rep.tyre_team_system_number === variables.system_number && rep.tyre_team_delivery === variables.delivery)
            return {
              ...rep,
              status: TYRE_TEAM_ORDER_STATUS.Rejected
            };
          return rep;
        })
      );
    }
  });

  const handlePlaceOrders = async () => {
    setLoading(true);

    try {
      const ordersToPlace = replacementToOrder.filter(item => (item.quantity || 0) > 0);

      if (!ordersToPlace.length) {
        setLoading(false);
        return;
      }

      const orderPromises = ordersToPlace.map(item => {
        const payload: TyreTeamOrderPayload = {
          check_id: tireData?.onCar[0]?.check_id || 0,
          system_number: item.tyre_team_system_number || "",
          quantity: item.quantity || 0,
          delivery: item.tyre_team_delivery || "",
          channel: item.tyre_team_channel || TYRE_TEAM_CHANNEL.WheelTyre,
          ref5,
          ref6
        };

        if (selectedLocation?.is_tyre_team_autofill_price_ref_on_order && item.price) payload.ref4 = String(item.price);

        return tyreTeamPlaceOrderMutation.mutateAsync(payload);
      });

      await Promise.all(orderPromises);
      const remainingItems = replacementToOrder.filter(item => (item.quantity || 0) > 0 && item.status !== TYRE_TEAM_ORDER_STATUS.Fulfilled);
      if (!remainingItems.length) onClose?.();
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : t("v8_error_placing_orders").message || "Error placing orders";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const alphaNumericAndSpaceOnlyRegex = /[^a-zA-Z0-9 ]/g;
    const maxInputLength = 30;
    const sanitizedValue = value.replace(alphaNumericAndSpaceOnlyRegex, "").substring(0, maxInputLength);

    if (name === "ref5") setRef5(sanitizedValue);
    else if (name === "ref6") setRef6(sanitizedValue);
  };

  return {
    loading,
    ref5,
    ref6,
    replacementToOrder,
    handleInputChange,
    handleQuantityChange,
    handlePlaceOrders,
    getDeliveryChannel,
    getDelivery,
    getQuantityOptions,
    fetchStockMutation
  };
};
