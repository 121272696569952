import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "semantic-ui-react";

import { Message } from "components";
import { DealerInvoiceHistoryTable, MonthlyTable, OnceTable } from "modules/DealerLicenseOverview/components";
import useDealerLicenseTabs from "modules/DealerLicenseOverview/components/DealerLicenseTabs/useDealerLicensesTabs";
import "modules/LocationLicenseOverview/LocationLicenseOverview.scss";
import { HistoricalInvoice, LicenseItem, Licenses, Pane } from "modules/LocationLicenseOverview/types";
import { ITranslation } from "util/interfaces";

type DealerLicenseTabsProps = {
  setSelectedInvoice: Dispatch<SetStateAction<HistoricalInvoice | null>>;
  setInvoicePDF: Dispatch<SetStateAction<Blob | null>>;
  dealerItems: LicenseItem[];
  dealerLicenses: Licenses | undefined;
  isLoadingDealerLicenses: boolean;
};

export const DealerLicenseTabs = ({ setSelectedInvoice, setInvoicePDF, dealerItems, dealerLicenses, isLoadingDealerLicenses }: DealerLicenseTabsProps) => {
  const t = useTranslation().t as ITranslation;

  const {
    handleChangeTab,
    onInvoiceRowClick,
    invoicePageChange,
    handleResetState,
    dealerInvoices,
    currentInvoicePage,
    errorDealerInvoices,
    isLoadingDealerInvoices,
    currentLocationLicenseSubpage
  } = useDealerLicenseTabs({ setInvoicePDF, setSelectedInvoice });

  const tabPanes: Pane[] = [
    {
      menuItem: t("v8_monthly").message || "Monthly",
      content: <MonthlyTable items={dealerItems} licensesData={dealerLicenses} isLoadingLicenses={isLoadingDealerLicenses} />
    },
    {
      menuItem: t("v8_once").message || "Once",
      content: <OnceTable items={dealerItems} licensesData={dealerLicenses} isLoadingLicenses={isLoadingDealerLicenses} />
    },
    {
      menuItem: t("v8_invoice_history").message || "Invoice History",
      content: (
        <DealerInvoiceHistoryTable
          invoices={dealerInvoices}
          isLoadingInvoices={isLoadingDealerInvoices}
          currentInvoicePage={currentInvoicePage}
          onPageChange={invoicePageChange}
          onInvoiceRowClick={onInvoiceRowClick}
        />
      )
    }
  ];

  useEffect(() => {
    return () => handleResetState();
  }, []);

  if (errorDealerInvoices) return <Message content={<span className="error-text">{t("v8_failed_to_load_invoices").message || "Failed to load invoices"}.</span>} />;

  return (
    <>
      <div className="LocationLicenseOverview-Header">
        <div className="LocationLicenseOverview-Header-Tabs">
          <Menu secondary>
            {tabPanes.map((pane, index) => (
              <Menu.Item
                key={index}
                name={pane.menuItem}
                className="location-overview-header-tab"
                active={currentLocationLicenseSubpage === index}
                index={index}
                onClick={handleChangeTab}
              />
            ))}
          </Menu>
        </div>
      </div>
      <div className="LocationLicenseOverview-container mt-20">{tabPanes[currentLocationLicenseSubpage].content}</div>
    </>
  );
};
