import { UseMutateFunction } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

import "modules/ManufacturerDashboard/components/ManufacturerConfirmRequestModal/ManufacturerConfirmRequestModal.scss";
import { ITranslation } from "util/interfaces";

interface ManufacturerConfirmRequestModalTypes {
  showConfirmModal: boolean;
  onCloseModal: () => void;
  onCreateRequest: UseMutateFunction<{ data: { id: number } } | undefined, Error, any, unknown>;
  onHandleRequestReason: (input: string) => void;
  isConfirmButtonDisabled: boolean;
  onSuccessfulRequest: () => void;
}

export const ManufacturerConfirmRequestModal = ({
  showConfirmModal,
  onCloseModal,
  onCreateRequest,
  onHandleRequestReason,
  isConfirmButtonDisabled,
  onSuccessfulRequest
}: ManufacturerConfirmRequestModalTypes) => {
  const t = useTranslation().t as ITranslation;
  return (
    <Modal open={showConfirmModal} size="tiny">
      <Modal.Header className="Manufacturer__ConfirmModal-header">{t("v8_confirmation_required").message || "Confirmation required"}</Modal.Header>
      <Modal.Content className="Manufacturer__ConfirmModal-content">
        <span>{t("v8_enter_reason_for_asking_access").message || "Enter reason for asking access."}</span>
        <textarea onChange={e => onHandleRequestReason(e.target.value)} />
      </Modal.Content>
      <Modal.Actions className="Manufacturer__ConfirmModal-actions">
        <Button onClick={onCloseModal} basic>
          {t("v8_cancel").message || "Cancel"}
        </Button>
        <Button
          className="Manufacturer__ConfirmModal-actions--confirmBtn"
          disabled={isConfirmButtonDisabled}
          onClick={() => onCreateRequest(null, { onSuccess: onSuccessfulRequest })}
        >
          {t("v8_confirm").message || "Confirm"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
