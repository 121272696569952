import { Checklist, DealerLocation, Domain, ReportThreshold, SMS_GATEWAYS, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum REPORT_COLUMNS {
  WO_HANDLED = 1,
  CHECK_AANNAME,
  CHECK_COMMERCIAL,
  CHECK_MEDIA,
  CHECK_COUNT,
  STATUS_QUALITY,
  CC_EXTRA_SERVICES
}

export enum REPORT_COLUMN_TYPE {
  Number = 1,
  Percentage
}

export class Dealer extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  country_code: string = "";
  head_line: string = "";
  footer_line: string = "";
  logo: string = "";
  active: boolean = false;
  can_add_users: boolean = false;
  accepted_api_agreement: boolean = false;
  dbb_enabled: boolean = false;
  custom_domain_enabled: boolean = false;
  sms_gateway_id: SMS_GATEWAYS = SMS_GATEWAYS.Disabled;
  website_url: string = "";
  sys_admin_name: string = "";
  sys_admin_email: string = "";
  sys_admin_phone: string = "";
  dms_server_info: string = "";
  network_info: string = "";
  zoho_key: string = "";
  city: string = "";
  street: string = "";
  postal_code: string = "";
  phone_nr: string = "";
  private _users?: User[] | null = null;
  private _checklists?: Checklist[] | null = null;
  private _locations?: DealerLocation[] | null = null;
  domain_name: string = "";
  enable_car_ready: boolean = false;
  cdk_contract_code: string = "";
  icar_key?: string | null = null;
  icar_emp: string = "";
  icar_last_update?: string | null = null;
  chatshipper_email: string = "";
  chatshipper_enabled: boolean = false;
  exact_dealer_startup_costs_invoiced: boolean = false;
  exact_onei_startup_costs_invoiced: boolean = false;
  domain?: Domain | null = null;
  is_exact_invoicing_enabled: boolean = false;
  exact_account_id: string = "";
  is_snooze_type_next_visit_customer_enabled: boolean = false;
  is_snooze_type_make_offer_enabled: boolean = false;
  is_snooze_type_make_new_appointment_enabled: boolean = false;
  is_snooze_type_remind_customer_enabled: boolean = false;
  is_snooze_status_enabled: boolean = false;
  is_snooze_department_enabled: boolean = false;
  is_snooze_attachment_enabled: boolean = false;
  enabled_warranty_types: number[] | null = null;
  onei_planning_enabled: boolean = false;
  planit_planning_enabled: boolean = false;
  pon_username: string = "";
  pon_password: string = "";

  public get users(): User[] {
    return this._users ? this._users : [];
  }

  public set users(newValue: User[] | null | undefined) {
    this._users = newValue;
  }

  public get checklists(): Checklist[] {
    return this._checklists ? this._checklists : [];
  }

  public set checklists(newValue: Checklist[] | null | undefined) {
    this._checklists = newValue;
  }

  public get locations(): DealerLocation[] {
    return this._locations ? this._locations : [];
  }

  public set locations(newValue: DealerLocation[] | null | undefined) {
    this._locations = newValue;
  }
}

export class MCCDealer extends ApiLoadable {
  dealer_name: string = "";
  dealer_number: string = "";
  dealer_brand: string = "";
}

export class DealerReportTemplate extends ApiLoadable {
  id: number = 0;
  filename: string = "";
  name: string = "";
  unit: string = "";
  thresholds: string = "";
  report_type_id: number = 0;
  order: number = 0;
  report_type: string = "";
}

export class DealerReportData extends ApiLoadable {
  id: number = 0;
  private _report_data?: string[] | null = null;
  report_type: string = "";
  report_name: string = "";
  unit: string = "";
  thresholds: ReportThreshold[] = [];
  report_order: number = 0;

  public get report_data(): string[] {
    return this._report_data ? this._report_data : [];
  }

  public set report_data(newValue: string[] | null) {
    this._report_data = newValue;
  }
}

export class DealerReportThreshold extends ApiLoadable {
  id: number = 0;
  dealer_id: number = 0;
  dealer_report_column: REPORT_COLUMNS = REPORT_COLUMNS.WO_HANDLED;
  report_column_type: REPORT_COLUMN_TYPE = REPORT_COLUMN_TYPE.Number;
  high_value: number | null = null;
  low_value: number | null = null;
  created_on: string = "";
  updated_on: string = "";
}
