import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Icon, Modal, Segment } from "semantic-ui-react";

import { DATE_FORMATS_FNS } from "components/DatePicker";
import RobSection from "components/ROB/components/RobSection";
import useGetROBData from "components/ROB/hooks/useROB";
import "components/ROB/ROB.scss";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

enum ROB_STATUS {
  Contract = 1,
  NoData,
  Error
}

export type ROBResultData = {
  leaseContract?: Record<string, any>;
  supplierContract?: Record<string, any>;
};

type RobProps = {
  car_id: number;
};

const formatData = (data: ROBResultData) => {
  if (!data) {
    return {
      leaseContractData: {},
      supplierContractData: {},
      rentalVehicleData: {}
    };
  }

  const { leaseContract, supplierContract } = data;

  if (!leaseContract && !supplierContract) {
    return {
      leaseContractData: {},
      supplierContractData: {},
      rentalVehicleData: {}
    };
  }

  let newRentalVehicleRest = {};

  const leaseContractData = leaseContract
    ? {
        leaseContract: {
          endDate: leaseContract.endDate ? formatDate(leaseContract.endDate, DATE_FORMATS_FNS.yearMonthDateDash) : null,
          maximumMileage: leaseContract.maximumMileage,
          repairAndMaintenance: leaseContract.repairAndMaintenance.inContract
        },
        tires: leaseContract.tires
      }
    : {};

  let supplierContractData = {};
  if (supplierContract) {
    const { allowedCosts: allowedCostsTires, ...tires } = supplierContract.tires;
    const { allowedCosts: allowedCostsRentalVehicle, ...rentalVehicle } = supplierContract.rentalVehicle;
    supplierContractData = {
      repairAndMaintenance: supplierContract.repairAndMaintenance,
      tires,
      allowedCostsTires,
      rentalVehicle,
      allowedCostsRentalVehicle
    };
  }

  if (leaseContract) {
    const { rentalClassesInContract, rentalClassesNotInContract, ...rentalVehicleRest } = leaseContract.rentalVehicle;
    newRentalVehicleRest = { rentalVehicle: rentalVehicleRest, rentalClassesInContract, rentalClassesNotInContract };
  }

  return {
    leaseContractData,
    supplierContractData,
    rentalVehicleData: newRentalVehicleRest
  };
};

export const ROB = ({ car_id }: RobProps) => {
  const { data, fetchROBData, error } = useGetROBData(car_id);
  const [status, setStatus] = useState<ROB_STATUS | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    if (car_id) {
      fetchROBData();
    }
  }, [car_id]);

  useEffect(() => {
    if (error) {
      setStatus(ROB_STATUS.Error);
    } else if (!data || (data && Object.keys(data).length === 0)) {
      setStatus(ROB_STATUS.NoData);
    } else {
      setStatus(ROB_STATUS.Contract);
    }
  }, [data, error]);

  const toggleModal = () => {
    if (status === ROB_STATUS.Contract) {
      setIsModalVisible(prev => !prev);
    }
  };

  const { leaseContractData, supplierContractData, rentalVehicleData } = formatData(data);

  return (
    <div className="ROB">
      <Button className={`-appointment-status ${status ? ROB_STATUS[status] : ""}`} size="small" onClick={toggleModal}>
        ROB
      </Button>

      <Modal open={isModalVisible} className="RobModal" size="fullscreen" dimmer="blurring">
        <Modal.Header className="title_header">
          <div>{t("v8_rob_info").message || "ROB Info"}</div>
          <Button basic onClick={toggleModal} className="close_button">
            <Icon className="xmark grey" size="big" />
          </Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <RobSection data={leaseContractData} showDivider={false} />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <RobSection title={t("Supplier Contract").message || "Supplier Contract"} data={supplierContractData} showDivider={false} />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <RobSection data={rentalVehicleData} showDivider={false} />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  );
};
