import { Loader } from "semantic-ui-react";

import SnoozeModal from "components/SnoozeModal";
import { SnoozedLeadsFilters, SnoozedLeadsTable } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { useSnoozedLeads } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";

export const SnoozedLeads = () => {
  const { departmentOptions, isLoading, locations, modalData, snoozedLeadsData, tags, onSnoozeDelete, refetch, setModalData } = useSnoozedLeads();

  return (
    <>
      <SnoozedLeadsFilters tagsOptions={tags} departmentOptions={departmentOptions} />
      {isLoading ? (
        <Loader active />
      ) : (
        <SnoozedLeadsTable
          locations={locations}
          setModalData={setModalData}
          snoozedData={snoozedLeadsData?.items || []}
          nbPages={snoozedLeadsData?.nb_pages || 1}
          nbItems={snoozedLeadsData?.nb_items || 0}
        />
      )}
      <SnoozeModal
        size="large"
        open={modalData !== null}
        onClose={() => setModalData(null)}
        closeOnDimmerClick
        data={modalData}
        onDelete={(questionResultId: number) => onSnoozeDelete(questionResultId)}
        onSave={() => refetch()}
      />
    </>
  );
};
