import { useTranslation } from "react-i18next";
import { Checkbox, GridColumn, GridRow, Icon } from "semantic-ui-react";

import { Editable } from "components";
import { Appointment, SearchTyreReplacement } from "models";
import { isCarReadyOrQualityCheckDoneOrCanceled } from "util/appointmentUtils";
import { getTyreSeasonIcon } from "util/common";
import { ITranslation } from "util/interfaces";

interface TyreListProps {
  segmentedResults: SearchTyreReplacement[];
  selection: Record<string, any> | null;
  handleSelect: (index: number, checked: boolean) => void;
  handlePriceUpdate: (price: string, tyreId: number, index: number) => void;
  canUpdateAppointments: boolean;
  appointment: Appointment;
}

export const TyreList = ({ segmentedResults, selection, handleSelect, handlePriceUpdate, canUpdateAppointments, appointment }: TyreListProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <>
      <GridRow className="header">
        <GridColumn width={1} />
        <GridColumn width={6}>
          <p>{t("v8_description").message || "Description"}</p>
        </GridColumn>
        <GridColumn width={6}>
          <p>{t("v8_brand").message || "Brand"}</p>
        </GridColumn>
        <GridColumn width={2}>
          <p>{t("v8_price").message || "Price"}</p>
        </GridColumn>
        <GridColumn width={1}>
          <p>{t("v8_add").message || "Add"}</p>
        </GridColumn>
      </GridRow>

      {segmentedResults.map((item, index: number) => (
        <GridRow key={index}>
          <GridColumn width={1}>
            <Icon className={getTyreSeasonIcon(Number(item.season))} />
          </GridColumn>
          <GridColumn width={6}>
            <p>{item.description}</p>
          </GridColumn>
          <GridColumn width={6}>
            <p>{item.manufacturer}</p>
          </GridColumn>
          <GridColumn width={2}>
            <Editable
              value={String(item.gross_price || "")}
              placeholder="0,00"
              onSubmit={(price: string) => handlePriceUpdate(price, item.id, index)}
              disabled={
                !canUpdateAppointments ||
                isCarReadyOrQualityCheckDoneOrCanceled(appointment.appointment_status_identifier) ||
                Boolean(selection?.[item.ean]?.preselected) ||
                Boolean(selection?.[item.ean]) === false
              }
            />
          </GridColumn>
          <GridColumn width={1}>
            <Checkbox
              className="check"
              checked={Boolean(selection?.[item.ean])}
              disabled={Boolean(selection?.[item.ean]?.preselected)}
              onChange={(_e, data) => handleSelect(index, Boolean(data.checked))}
            />
          </GridColumn>
        </GridRow>
      ))}
    </>
  );
};
