import { createColumnHelper } from "@tanstack/react-table";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Loader, Popup } from "semantic-ui-react";

import { AppointmentStatusCell, PinModal, QuestionStatusIcon, ReactTable, TableDropdownSelector, TableQuestionStatusButton } from "components";
import { useDealersLocations } from "hooks";
import { PIN_STATUS, PIN_WARRANTY, PinModel, QUESTION_STATUSES } from "models";
import { getFormattedDate } from "modules/LeadsDashboard/util";
import { LocationCard, OrderStatus, PinTypeIcon } from "modules/WarrantyDashboard/components/WarrantyDashboardTable/components";
import { useGetSelectedFilterValues, useWarrantyDashboardTableFilterHandlers } from "modules/WarrantyDashboard/components/WarrantyDashboardTable/hooks";
import "modules/WarrantyDashboard/components/WarrantyDashboardTable/WarrantyDashboardTable.scss";
import {
  REG_VIN_FILTER,
  useGetPinStatusOptions,
  useGetPinTypeOptions,
  useGetWarrantyDashboardFilters,
  useRegVinNumberOptions,
  useWarrantyPinModal
} from "modules/WarrantyDashboard/hooks";
import { useWarrantyQuery } from "modules/WarrantyDashboard/service";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

interface WarrantyWithHistory extends PinModel {
  history: PinModel[];
}

export const PAGE_SIZE = 100;

export const WarrantyDashboardTable = () => {
  const t = useTranslation().t as ITranslation;
  const pinTypeOptions = useGetPinTypeOptions();
  const pinStatusOptions = useGetPinStatusOptions();
  const regVinNumberOptions = useRegVinNumberOptions();
  const { locations, dealers } = useDealersLocations();
  const {
    filters: { page: currentPage }
  } = useGetWarrantyDashboardFilters();
  const { warrantyData, nbItems, nbPages, isLoading } = useWarrantyQuery();
  const { selectedPinForModal, handleOpenPinModal, handleClosePinModal } = useWarrantyPinModal({ warrantyData });
  const {
    setRegVinNumberFilter,
    selectedStatus,
    claimOrderArrow,
    selectedPinTypes,
    regVinNumberFilter,
    warrantyTypeOptions,
    selectedWarrantyType,
    questionStatusOptions,
    isDateRangeSelected,
    selectedAppointmentOrder,
    selectedAppointmentOrderText,
    appointmentOrderingOptions,
    isRecallOrRemarksSelected
  } = useGetSelectedFilterValues();
  const {
    handleClaimChange,
    handlePinTypeChange,
    handleQuestionChange,
    handleCopyToClipboard,
    handlePaginationChange,
    handleAppointmentChange,
    handleRegVinNumberChange,
    handleWarrantyTypeChange
  } = useWarrantyDashboardTableFilterHandlers({ setRegVinNumberFilter });
  const tablePagination = nbPages > 1 ? { pageIndex: currentPage - 1, pageSize: PAGE_SIZE } : undefined;

  const columnHelper = createColumnHelper<WarrantyWithHistory>();
  const columns = [
    columnHelper.accessor((row: PinModel) => row.appointment, {
      id: "appointment",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr WarrantyDashboardTable__table-header-text">{selectedAppointmentOrderText}</span>
          <TableDropdownSelector options={appointmentOrderingOptions} selected={[selectedAppointmentOrder]} handleOnSelect={handleAppointmentChange} />
        </div>
      ),
      size: 30,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        return <span> {getFormattedDate(appointment?.time_car_app)} </span>;
      }
    }),
    columnHelper.accessor((row: PinModel) => row, {
      id: "appointment_status",
      header: () => <span className="no-wrap-text WarrantyDashboardTable__table-header-text"> {t("v8_status").message || "Status"} </span>,
      size: 30,
      cell: ({ getValue }) => {
        const data = getValue();
        const status = {
          id: data.appointment!.appointment_status_identifier,
          customcom_status: data.appointment?.customer_communication?.status,
          check_paused_at: data.appointment?.check_paused_at,
          car_out_of_shop: data.appointment?.car_out_of_shop,
          car_in_shop: data.appointment?.car_in_shop
        };

        return <AppointmentStatusCell shadow={false} value={status} />;
      }
    }),

    columnHelper.accessor((row: PinModel) => row, {
      id: "wo",
      header: () => <span className="no-wrap-text WarrantyDashboardTable__table-header-text">WO # {nbItems}</span>,
      size: 30,
      cell: ({ getValue }) => {
        const { appointment } = getValue();
        if (!appointment) return "";

        const { wo_nr, id } = appointment;

        return (
          <Popup
            hoverable
            content={wo_nr}
            trigger={
              <p className="ellipsis">
                <Link to={`${PATHS.APPOINTMENTS}/${id}`}>
                  <Icon className="arrow up right from square" />
                  {wo_nr}
                </Link>
              </p>
            }
          />
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row, {
      id: "reg_number",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr WarrantyDashboardTable__table-header-text">
            {regVinNumberFilter === REG_VIN_FILTER.Reg ? t("v8_reg_number").message || "Reg #" : t("v8_vin_number").message || "Vin #"}
          </span>
          <TableDropdownSelector options={regVinNumberOptions} selected={[String(regVinNumberFilter)]} handleOnSelect={handleRegVinNumberChange} />
        </div>
      ),
      size: 30,
      cell: ({ getValue }) => {
        const { appointment } = getValue();
        if (!appointment) return "";
        const { car_id, is_lease_company, reg_number, vin_nr } = appointment;

        if (regVinNumberFilter === REG_VIN_FILTER.Vin) {
          return (
            vin_nr && (
              <div
                className="flex WarrantyDashboardTable__copy-to-clipboard"
                onClick={() => handleCopyToClipboard(vin_nr.toString(), t("v8_vin_nr_copied").message || "Vin # copied to clipboard")}
              >
                <div className="WarrantyDashboardTable__ref-container">
                  <Icon name="copy outline" className="WarrantyDashboardTable__copy-icon" />
                  <Popup hoverable content={vin_nr} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{vin_nr}</p>} />
                </div>
              </div>
            )
          );
        }

        return (
          <Popup
            hoverable
            content={reg_number}
            trigger={
              <p className="ellipsis">
                <Link to={`${PATHS.CAR_DETAIL}/${car_id}`}>
                  <Icon className="arrow up right from square" />
                  {reg_number}
                  {is_lease_company && <Icon name="building" />}
                </Link>
              </p>
            }
          />
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.pin_type_id, {
      id: "pin_type",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr table-header-text"> {t("v8_pin_type").message || "Pin Type"}</span>
          <TableDropdownSelector multiple={true} options={pinTypeOptions} selected={selectedPinTypes} handleOnSelect={handlePinTypeChange} />
        </div>
      ),
      size: 50,
      cell: ({ renderValue }) => {
        const content = pinTypeOptions.find(option => option.value === renderValue())?.text ?? "";

        return (
          <div className="WarrantyDashboardTable__warranty-pin-type-container">
            <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
          </div>
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.warranty_type_id, {
      id: "warranty_type",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="ellipsis ellipsis-wo-nr WarrantyDashboardTable__table-header-text"> {t("v8_warranty_type").message || "Warranty Type"}</span>
          <TableDropdownSelector
            disabled={isRecallOrRemarksSelected}
            options={warrantyTypeOptions}
            selected={[selectedWarrantyType]}
            handleOnSelect={handleWarrantyTypeChange}
          />
        </div>
      ),
      size: 30,
      cell: ({ renderValue }) => {
        const warrantyType = renderValue();
        const content = warrantyType !== PIN_WARRANTY.NotSet ? (warrantyTypeOptions.find(option => option.value === renderValue())?.text ?? "") : "";

        return (
          <div className="WarrantyDashboardTable__pin-status-container">
            <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
          </div>
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.pin_status_id, {
      id: "pin_status",
      header: () => <span className="no-wrap-text WarrantyDashboardTable__table-header-text"> {t("v8_pin_status").message || "Pin Status"}</span>,
      size: 30,
      cell: ({ renderValue }) => {
        const pinStatus = renderValue();
        const content = pinStatus !== PIN_STATUS.NotSet ? (pinStatusOptions.find(option => option.value === renderValue())?.text ?? "") : "";

        return (
          <div className="WarrantyDashboardTable__pin-status-container">
            <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
          </div>
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.support_nr, {
      id: "support",
      header: () => <span className="no-wrap-text WarrantyDashboardTable__table-header-text">{t("v8_support").message || "Support"} #</span>,
      size: 30,
      cell: ({ renderValue }) => {
        const supportNr = renderValue();
        const content = supportNr?.toString() || "";

        return (
          supportNr && (
            <div
              className="flex WarrantyDashboardTable__copy-to-clipboard"
              onClick={() => handleCopyToClipboard(supportNr.toString(), t("v8_support_nr_copied").message || "Support # copied to clipboard")}
            >
              <div className="WarrantyDashboardTable__ref-container">
                <Icon name="copy outline" className="WarrantyDashboardTable__copy-icon" />
                <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
              </div>
            </div>
          )
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.claim_nr, {
      id: "claim",
      header: () => (
        <div className="appointment-table-wrapper">
          <span className="no-wrap-text WarrantyDashboardTable__table-header-text">{t("v8_claim").message || "Claim"} #</span>
          <div>
            <Icon
              className={`caret ${claimOrderArrow} -cursor-pointer ${claimOrderArrow !== "down" && "WarrantyDashboardTable__claim-icon-filter"}`}
              onClick={handleClaimChange}
            />
          </div>
        </div>
      ),
      size: 30,
      cell: ({ renderValue }) => {
        const claimNr = renderValue();
        const content = claimNr ? claimNr.toString() : "";

        return (
          claimNr && (
            <div
              className="flex WarrantyDashboardTable__copy-to-clipboard"
              onClick={() => handleCopyToClipboard(content, t("v8_claim_nr_copied").message || "Claim # copied to clipboard")}
            >
              <div className="WarrantyDashboardTable__ref-container">
                <Icon name="copy outline" className="WarrantyDashboardTable__copy-icon" />
                <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
              </div>
            </div>
          )
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.ref_nr, {
      id: "ref",
      header: () => <span className="no-wrap-text WarrantyDashboardTable__table-header-text">{t("v8_ref").message || "Ref"} #</span>,
      size: 30,
      cell: ({ renderValue }) => {
        const refNr = renderValue();
        return (
          refNr && (
            <div
              className="flex WarrantyDashboardTable__copy-to-clipboard"
              onClick={() => handleCopyToClipboard(refNr.toString(), t("v8_ref_nr_copied").message || "Ref # copied to clipboard")}
            >
              <div className="WarrantyDashboardTable__ref-container">
                <Icon name="copy outline" className="WarrantyDashboardTable__copy-icon" />
                <Popup hoverable content={refNr} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{refNr}</p>} />
              </div>
            </div>
          )
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row.appointment, {
      id: "brand",
      header: () => <span className="no-wrap-text WarrantyDashboardTable__table-header-text">{t("v8_brand").message || "Brand"}</span>,
      size: 40,
      cell: ({ getValue }) => {
        const appointment = getValue();
        const content = !appointment ? "" : appointment.car_make;

        return (
          <div className="WarrantyDashboardTable__ref-container">
            <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
          </div>
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row, {
      id: "intervention_question_result",
      header: () => (
        <span className="no-wrap-text WarrantyDashboardTable__table-header-text">{t("v8_intervention_question_result").message || "Intervention/Question Result"}</span>
      ),
      size: 100,
      cell: ({ getValue }) => {
        const pinModel = getValue();
        if (!pinModel) return "";
        const { intervention, question_result } = pinModel;
        const content = question_result ? question_result.title : intervention?.title;

        return (
          <div className="WarrantyDashboardTable__intervention-question-result-container">
            <Popup hoverable content={content} trigger={<p className="WarrantyDashboardTable__table-ellipsis">{content}</p>} />
          </div>
        );
      }
    }),

    columnHelper.accessor((row: PinModel) => row, {
      id: "actions",
      header: () => (
        <div className="WarrantyDashboardTable__table-action-buttons-container">
          <TableDropdownSelector
            selected={[selectedStatus]}
            options={questionStatusOptions}
            handleOnSelect={handleQuestionChange}
            trigger={<TableQuestionStatusButton selectedQuestionStatuses={[selectedStatus]} />}
            scrolling={false}
          />
          <div className="WarrantyDashboardTable__customer-approved-table-column">
            <Icon className="face smile" />
          </div>
          <div>
            <Icon className="car" />
          </div>
          <div className="WarrantyDashboardTable__table-icon-placeholder-div" />
          <div className="WarrantyDashboardTable__table-icon-placeholder-div" />
          <div className="WarrantyDashboardTable__table-icon-placeholder-div" />
        </div>
      ),
      size: 40,
      cell: ({ getValue }) => {
        const data = getValue();
        const status = data?.question_result_id ? data?.question_result?.customer_approved : data?.intervention?.customer_ok;

        return (
          <div className="WarrantyDashboardTable__table-action-buttons-container">
            {data.question_result?.status !== QUESTION_STATUSES.NotApplicable ? (
              <div className="WarrantyDashboardTable__table-action-buttons-container">
                <QuestionStatusIcon status={data.question_result?.status} />
              </div>
            ) : (
              <div className="WarrantyDashboardTable__table-icon-placeholder-div" />
            )}
            <div>
              <Icon className={`circle ${status ? "check i-green" : "remove red"}`} />
            </div>
            {data.mechanic_fixed ? (
              <div className="WarrantyDashboardTable__warranty-mechanic-fixed-status-container">
                <Icon className="wrench i-green" />
                <Icon className="check circle i-green fixed-icon-container" />
              </div>
            ) : (
              <div className="WarrantyDashboardTable__mechanic-not-fixed ">
                <Icon className="wrench regular grey rotate-270 " />
              </div>
            )}
            {data.order_status ? <OrderStatus orderStatus={data.order_status} /> : <div className="WarrantyDashboardTable__table-icon-placeholder-div" />}
            <PinTypeIcon pinType={data.pin_type_id} onClick={() => handleOpenPinModal(data)} />
            <LocationCard dealerLocationId={data.dealer_location_id} locations={locations} dealers={dealers} />
          </div>
        );
      }
    })
  ];

  if (isLoading) return <Loader active />;

  return (
    <>
      <div className="WarrantyDashboardTable">
        <ReactTable
          columns={columns}
          data={warrantyData}
          pageCount={nbPages}
          pageSize={PAGE_SIZE}
          totalItems={nbItems}
          pagination={tablePagination}
          onPaginationChange={handlePaginationChange}
          overrideTableBodyMessage={
            !isDateRangeSelected ? `${t("v8_please_select_at_least_one_date_range_to_filter_on").message || "Please select at least one date range to filter on"}.` : null
          }
        />
      </div>
      {selectedPinForModal && <PinModal isOpen={!!selectedPinForModal} onClose={handleClosePinModal} pin={selectedPinForModal} />}
    </>
  );
};
