import _join from "lodash/join";
import _split from "lodash/split";
import { useCallback, useMemo } from "react";

import { useCan } from "components";
import { useDealersLocations } from "hooks";
import { APPOINTMENT_NOTE_TYPES, Appointment, STATUS_IDENTIFIER } from "models";
import { isCarReadyOrQualityCheck } from "util/appointmentUtils";
import { capitalizeFirstLetter } from "util/common";
import { ITranslation } from "util/interfaces";

type UseGetDropdownOptionsParams = {
  appointment: Appointment;
  t: ITranslation;
};

export type PhoneOption =
  | {
      key: number;
      text: string;
      value: string;
      description: any;
    }
  | { text: any; value: string; key?: undefined; description?: undefined };

export const useGetDropdownOptions = ({ appointment, t }: UseGetDropdownOptionsParams) => {
  const { selectedLocation } = useDealersLocations();

  const canBackOrder = useCan("new-status", "appointments-backorder");
  const canCreateNoteBill = useCan("create", "appointment-notes-bills");
  const canSetAppointmentsInfo = useCan("new-status", "appointments-info");

  const isCarReadyQualityCheckOrCustomerOKStatus =
    appointment.appointment_status_identifier === STATUS_IDENTIFIER.CustomerOKStatus || isCarReadyOrQualityCheck(appointment.appointment_status_identifier);

  const isCarCheckOrBackOrderStatusActive = [STATUS_IDENTIFIER.CarCheckStartedStatus, STATUS_IDENTIFIER.BackOrderStatus].includes(
    appointment?.appointment_status_identifier
  );

  const appointmentNoteTypeEnabled: Partial<{ [key in APPOINTMENT_NOTE_TYPES]: boolean }> = {
    [APPOINTMENT_NOTE_TYPES.Info]: canSetAppointmentsInfo && !isCarCheckOrBackOrderStatusActive,
    [APPOINTMENT_NOTE_TYPES.BackOrder]: canBackOrder && isCarReadyQualityCheckOrCustomerOKStatus,
    [APPOINTMENT_NOTE_TYPES.BillNote]: canCreateNoteBill && isCarReadyQualityCheckOrCustomerOKStatus && selectedLocation?.repair_overview_enabled,
    [APPOINTMENT_NOTE_TYPES.Parking]: useCan("create", "appointment-notes-parking-location"),
    [APPOINTMENT_NOTE_TYPES.Attachment]: useCan("create", "appointment-notes-attachments"),
    [APPOINTMENT_NOTE_TYPES.Wo]: useCan("create", "appointment-notes-wo"),
    [APPOINTMENT_NOTE_TYPES.TemporaryDriverNote]: useCan("create", "appointment-notes-temp-driver"),
    [APPOINTMENT_NOTE_TYPES.RecurringCar]: useCan("create", "appointment-notes-recurring-car"),
    [APPOINTMENT_NOTE_TYPES.CallCustomer]: useCan("create", "appointment-notes-customer-call"),
    [APPOINTMENT_NOTE_TYPES.Main]: useCan("create", "appointment-notes"),
    [APPOINTMENT_NOTE_TYPES.TireScanner]: false
  };

  const normalizeKey = useCallback((key: string) => {
    const splitKey = _split(key, /(?=[A-Z])/);
    const normalizedKey = _join(splitKey, "_").toLocaleLowerCase();
    return normalizedKey === "wo" ? "add_note" : normalizedKey;
  }, []);

  const getNoteTypeOptions = () => {
    const noteTypeEnumKeys = Object.keys(APPOINTMENT_NOTE_TYPES).filter(el => isNaN(Number(el)));
    const noteTypesToDisplay = noteTypeEnumKeys.filter(key => {
      const noteType = APPOINTMENT_NOTE_TYPES[key as keyof typeof APPOINTMENT_NOTE_TYPES];
      if (!selectedLocation?.enabled_appointment_note_type_ids.includes(noteType)) return;
      return ![APPOINTMENT_NOTE_TYPES.DmsNote, APPOINTMENT_NOTE_TYPES.TireScanner].includes(noteType);
    });

    return noteTypesToDisplay.map((key: string) => {
      const value = APPOINTMENT_NOTE_TYPES[key as keyof typeof APPOINTMENT_NOTE_TYPES];
      const normalizedKey = normalizeKey(key);

      return {
        key,
        text: t(`v8_${normalizedKey}`).message || capitalizeFirstLetter(normalizedKey.replace(/_/g, " ")),
        value,
        disabled: !appointmentNoteTypeEnabled[value]
      };
    });
  };

  const phoneOptions = useMemo(() => {
    const options: PhoneOption[] = [];

    if (!appointment) return options;

    if (appointment.customer_driver?.id) {
      if (appointment.customer_driver.tel_mobile_business) {
        options.push({
          key: 1,
          text: appointment.customer_driver.tel_mobile_business,
          value: `${appointment.customer_driver.tel_mobile_business};${appointment.customer_driver.id}`,
          description: t("v8_driver_mobile_business").message || "Driver Mobile Business"
        });
      }

      if (appointment.customer_driver.tel_mobile_private) {
        options.push({
          key: 2,
          text: appointment.customer_driver.tel_mobile_private,
          value: `${appointment.customer_driver.tel_mobile_private};${appointment.customer_driver.id}`,
          description: t("v8_driver_mobile_private").message || "Driver Mobile Private"
        });
      }

      if (appointment.customer_driver.tel_business_nr) {
        options.push({
          key: 3,
          text: appointment.customer_driver.tel_business_nr,
          value: `${appointment.customer_driver.tel_business_nr}";"${appointment.customer_driver.id}`,
          description: t("v8_driver_business").message || "Driver Business"
        });
      }

      if (appointment.customer_driver.tel_private_nr) {
        options.push({
          key: 4,
          text: appointment.customer_driver.tel_private_nr,
          value: `${appointment.customer_driver.tel_private_nr}";"${appointment.customer_driver.id}`,
          description: t("v8_driver_private").message || "Driver Private"
        });
      }
    }

    if (appointment.customer_contract?.id) {
      if (appointment.customer_contract.tel_mobile_business) {
        options.push({
          key: 5,
          text: appointment.customer_contract.tel_mobile_business,
          value: `${appointment.customer_contract.tel_mobile_business}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_mobile_business").message || "Contract Mobile Business"
        });
      }

      if (appointment.customer_contract.tel_mobile_private) {
        options.push({
          key: 6,
          text: appointment.customer_contract.tel_mobile_private,
          value: `${appointment.customer_contract.tel_mobile_private}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_mobile_private").message || "Contract Mobile Private"
        });
      }

      if (appointment.customer_contract.tel_business_nr) {
        options.push({
          key: 7,
          text: appointment.customer_contract.tel_business_nr,
          value: `${appointment.customer_contract.tel_business_nr}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_business").message || "Contract Business"
        });
      }

      if (appointment.customer_contract.tel_private_nr) {
        options.push({
          key: 8,
          text: appointment.customer_contract.tel_private_nr,
          value: `${appointment.customer_contract.tel_private_nr}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_private").message || "Contract Private"
        });
      }
    }

    if (appointment.customer_owner?.id) {
      if (appointment.customer_owner.tel_mobile_business) {
        options.push({
          key: 9,
          text: appointment.customer_owner.tel_mobile_business,
          value: `${appointment.customer_owner.tel_mobile_business}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_mobile_business").message || "Owner Mobile Business"
        });
      }

      if (appointment.customer_owner.tel_mobile_private) {
        options.push({
          key: 10,
          text: appointment.customer_owner.tel_mobile_private,
          value: `${appointment.customer_owner.tel_mobile_private}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_mobile_private").message || "Owner Mobile Private"
        });
      }

      if (appointment.customer_owner.tel_business_nr) {
        options.push({
          key: 11,
          text: appointment.customer_owner.tel_business_nr,
          value: `${appointment.customer_owner.tel_business_nr}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_business").message || "Owner Business"
        });
      }

      if (appointment.customer_owner.tel_private_nr) {
        options.push({
          key: 11,
          text: appointment.customer_owner.tel_private_nr,
          value: `${appointment.customer_owner.tel_private_nr}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_private").message || "Owner Private"
        });
      }
    }

    options.push({
      text: t("v8_other").message || "Other",
      value: "other"
    });

    return options;
  }, []);

  return { phoneOptions, noteTypeOptions: getNoteTypeOptions() };
};
