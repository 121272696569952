import { useState } from "react";

import { QuestionResultData } from "components";
import { useDealersLocations } from "hooks";
import { LeadsSnoozed } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { useGetDepartmentOptions } from "modules/LeadsDashboard/hooks";
import { useGetSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetTagsQuery, useLeadsSnoozedQuery } from "modules/LeadsDashboard/service";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useSnoozedLeads = () => {
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);
  const tags = useGetTagsQuery();
  const { locations } = useDealersLocations();
  const { departmentOptions } = useGetDepartmentOptions();
  const { snoozedFilters } = useGetSnoozedFilters();

  const { updateFiltersWithCallback } = useGetFilterUpdaterFunctions<LeadsSnoozed>({ queryKey: LeadsQueryKeys.listSnoozed({ snoozedFilters }) });
  const { refetch, isLoading, data: snoozedLeadsData } = useLeadsSnoozedQuery({ snoozedFilters });

  const onSnoozeDelete = (questionResultId: number) => {
    const updatedItems = snoozedLeadsData?.items?.filter(item => item.question_result_id !== questionResultId) || [];

    if (updatedItems.length !== snoozedLeadsData?.items?.length) {
      updateFiltersWithCallback(oldData => ({ ...oldData, items: updatedItems, nb_items: updatedItems.length }));
    }
  };

  return {
    refetch,
    onSnoozeDelete,
    setModalData,
    tags,
    modalData,
    locations,
    isLoading,
    snoozedFilters,
    snoozedLeadsData,
    departmentOptions
  };
};
