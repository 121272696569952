import { useState } from "react";

import { SEARCH_OPTION_TYPE } from "components";
import { SearchResultLocations } from "components/NavBarSearchAppointments/components";
import "components/NavBarSearchAppointments/components/SearchDropdown/SearchDropdown.scss";
import useSearchDropdown from "components/NavBarSearchAppointments/components/SearchDropdown/useSearchDropdown";
import { SearchResultsData } from "components/NavBarSearchAppointments/hooks/useAppointmentSearchResults";

type SearchDropdownProps = {
  data: SearchResultsData;
  onSelect: (id: string) => void;
  onClose: () => void;
};

export const SearchDropdown = ({ data, onSelect, onClose }: SearchDropdownProps) => {
  const { dropdownRef, importAppointment, isPending } = useSearchDropdown({ onClose, onSelect });
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleOnClick = (id: string, type: SEARCH_OPTION_TYPE) => {
    if (type === SEARCH_OPTION_TYPE.Claire) onSelect(id);
    else {
      setSelectedId(id);
      importAppointment(id);
    }
  };

  return (
    <div className="SearchDropdown-wrapper" ref={dropdownRef}>
      <SearchResultLocations data={data} handleOnClick={handleOnClick} isPending={isPending} selectedId={selectedId} />
    </div>
  );
};
