import { useState } from "react";

import { PinModel } from "models";
import { PinModelWithHistory } from "modules/WarrantyDashboard/util";

type UseWarrantyPinModalProps = {
  warrantyData: PinModelWithHistory[];
};

export const useWarrantyPinModal = ({ warrantyData }: UseWarrantyPinModalProps) => {
  const [showPinModal, setShowPinModal] = useState<PinModel | null>(null);

  const handleClosePinModal = () => setShowPinModal(null);
  const handleOpenPinModal = (pinData: PinModel) => setShowPinModal(pinData);

  const selectedPinForModal = warrantyData.find(
    pin =>
      (pin.question_result_id && pin.question_result_id === showPinModal?.question_result_id) ||
      (pin.intervention_id && pin.intervention_id === showPinModal?.intervention_id)
  );

  return { selectedPinForModal, handleClosePinModal, handleOpenPinModal };
};
