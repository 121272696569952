import { compareDesc } from "date-fns";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { PinModelWithHistory } from "components";
import "components/PinModal/PinModal.scss";
import { PinTypeIcon } from "components/PinTypeIcon";
import { useDealersLocations, usePinOptions } from "hooks";
import { PIN_TYPE, PinModel, PinOptions } from "models";
import { getShopIconColor } from "modules/AppointmentDetails/components/Interventions/components";
import { PinToNoteTypeMapping } from "modules/AppointmentDetails/components/Interventions/components/PinCategory/components/PinCategoryForm/usePinCategoryForm";
import { PinType } from "modules/AppointmentDetails/components/Interventions/InterventionItem";
import { useGetNotes } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type PinProps = {
  pin: Partial<PinModelWithHistory>;
  pinType?: PIN_TYPE;
};

export const useGetPinOptionsData = ({ pin, pinType }: PinProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedDealer } = useDealersLocations();
  const { data: pinOptions } = usePinOptions();

  const pinTypeData: PinType[] = pinOptions?.pin_types?.map((pinType: PinOptions) => ({
    key: pinType.name,
    text: t(pinType.name).message,
    value: pinType.id,
    icon: <PinTypeIcon pin_type_id={pinType.id} />
  }));

  const pinStatusData = pinOptions?.pin_statuses?.map((pinStatus: PinOptions) => ({
    key: pinStatus.name,
    text: t(pinStatus.name).message,
    value: pinStatus.id
  }));

  const pinOrderData = pinOptions?.pin_order_statuses?.map((pinOrder: PinOptions) => ({
    key: pinOrder.name,
    text: t(pinOrder.name).message,
    value: pinOrder.id,
    icon: <Icon className="shopping cart" color={getShopIconColor(pinOrder.id)} />
  }));

  const pinWarrantyData = pinOptions?.warranty_types
    ?.filter(pinWarranty => selectedDealer?.enabled_warranty_types?.includes(pinWarranty.id))
    ?.map((pinWarranty: PinOptions) => ({
      key: pinWarranty.name,
      text: t(pinWarranty.name).message,
      value: pinWarranty.id
    }));

  const noteTypeId = PinToNoteTypeMapping[pinType as keyof typeof PinToNoteTypeMapping];
  const noteSuggestionOptions = useGetNotes(noteTypeId);

  const pinLogAttachments = (pin?.history || []).flatMap(history => history.note_attachments || []).map(attachment => ({ url: attachment }));
  const latestPinData = [...(pin?.history ?? [])]?.sort((a: PinModel, b: PinModel) => compareDesc(a.created_on, b.created_on))[0];

  return { pinTypeData, pinStatusData, pinOrderData, pinWarrantyData, noteSuggestionOptions, pinLogAttachments, latestPinData };
};
