import { useTranslation } from "react-i18next";

import { QUESTION_STATUSES } from "models";
import { QualityCheckReportData } from "modules/DealerReports/components/DealerQualityChecksTabular";
import { ITranslation } from "util/interfaces";

type Props = {
  reportData: QualityCheckReportData[];
};

type DropdownOption = {
  key: string | number;
  value: string | number;
  text: string;
};

export const questionStatusLookup: { translationKey: string; defaultMessage: string; key: QUESTION_STATUSES }[] = [
  { key: QUESTION_STATUSES.OK, translationKey: "v8_ok", defaultMessage: "OK" },
  { key: QUESTION_STATUSES.OKWithRemarks, translationKey: "v8_ok_with_remarks", defaultMessage: "OK With Remarks" },
  { key: QUESTION_STATUSES.Necessary, translationKey: "v8_necessary", defaultMessage: "Necessary" },
  { key: QUESTION_STATUSES.Advice, translationKey: "v8_advised", defaultMessage: "Advised" },
  { key: QUESTION_STATUSES.NVT, translationKey: "v8_not_worked_on", defaultMessage: "Not Worked On" }
];

export const useDealerQualityChecksFilterOptions = ({ reportData }: Props) => {
  const t = useTranslation().t as ITranslation;

  const mechanicsDropdownOptions = reportData.reduce((acc, { mechanic, mechanic_id }) => {
    if (acc.find(item => item.value === mechanic_id)) return acc;
    acc.push({ key: mechanic_id, value: mechanic_id, text: mechanic });
    return acc;
  }, [] as DropdownOption[]);

  const locationsDropdownOptions = reportData.reduce((acc, { location }) => {
    if (acc.find(item => item.value === location)) return acc;
    acc.push({ key: location, value: location, text: location });
    return acc;
  }, [] as DropdownOption[]);

  const qcOfficersDropdownOptions = reportData.reduce((acc, { qc_officer, qc_officer_id }) => {
    if (acc.find(item => item.value === qc_officer_id)) return acc;
    acc.push({ key: qc_officer_id, value: qc_officer_id, text: qc_officer });
    return acc;
  }, [] as DropdownOption[]);

  const qcChecklistsDropdownOptions = reportData.reduce((acc, { qc_checklist }) => {
    if (acc.find(item => item.value === qc_checklist)) return acc;
    acc.push({ key: qc_checklist, value: qc_checklist, text: qc_checklist });
    return acc;
  }, [] as DropdownOption[]);

  const qcStatusesDropdownOptions = questionStatusLookup.map(({ key }: { key: QUESTION_STATUSES }) => ({
    key: questionStatusLookup[key].key,
    value: questionStatusLookup[key].key,
    text: t(questionStatusLookup[key as QUESTION_STATUSES].translationKey).message || questionStatusLookup[key as QUESTION_STATUSES].defaultMessage
  }));

  return { mechanicsDropdownOptions, locationsDropdownOptions, qcOfficersDropdownOptions, qcStatusesDropdownOptions, qcChecklistsDropdownOptions };
};
