import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessageListener } from "components";
import { DealerLocation, ExactCategory, ExactStandaloneLicense } from "models";
import { Licenses } from "modules/LocationLicenseOverview/types";
import { updateCategoryArray, updateLicenseArray, updateLicenseData } from "modules/LocationLicenseOverview/utils";

type LocationLicenseOverviewListenersProps = {
  selectedLocation: DealerLocation | undefined;
};

export const useLocationLicenseOverviewListeners = ({ selectedLocation }: LocationLicenseOverviewListenersProps) => {
  const queryClient = useQueryClient();

  const listeners = useMemo((): WebSocketMessageListener[] => {
    if (!selectedLocation?.id) return [];

    return [
      {
        model: "ExactStandaloneLicense",
        action: "create",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];
              if (standaloneLicenses.some(lc => lc.id === license.id)) return licenses;

              return {
                ...licenses,
                monthlyStandalones: !license.invoice_on ? [...(licenses.monthlyStandalones ?? []), license] : licenses.monthlyStandalones,
                oneTimeStandalones: license.invoice_on ? [...(licenses.oneTimeStandalones ?? []), license] : licenses.oneTimeStandalones
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      },
      {
        model: "ExactStandaloneLicense",
        action: "update",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              return {
                ...licenses,
                monthlyStandalones: updateLicenseArray(licenses.monthlyStandalones, license, !license.invoice_on),
                oneTimeStandalones: updateLicenseArray(licenses.oneTimeStandalones, license, !!license.invoice_on)
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      },
      {
        model: "ExactStandaloneLicense",
        action: "delete",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];

              if (!standaloneLicenses.some(lc => lc.id === license.id)) return licenses;
              return {
                ...licenses,
                monthlyStandalones: licenses.monthlyStandalones.filter(lc => lc.id !== license.id),
                oneTimeStandalones: licenses.oneTimeStandalones.filter(lc => lc.id !== license.id)
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      },
      {
        model: "ExactCategory",
        action: "update",
        callback: message => {
          updateLicenseData(
            licenses => {
              const category = message.data as ExactCategory;
              return {
                ...licenses,
                monthlyCategories: updateCategoryArray(licenses.monthlyCategories, category, !category.one_time),
                oneTimeCategories: updateCategoryArray(licenses.oneTimeCategories, category, !!category.one_time)
              } as Licenses;
            },
            selectedLocation.id,
            queryClient
          );
        }
      }
    ];
  }, [queryClient, selectedLocation?.id]);

  return { listeners };
};
