import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { CommunicationSettings } from "models";
import { CommunicationSettingsQueryKeys } from "modules/ComunicationSettings/queryKeys";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

interface PDFTemplateType {
  created_on: string;
  updated_on: string;
  id: number;
  name: string;
  is_previewable: boolean;
  description: string;
  dealer_id: number | null;
}

export const useGetCommunicationSettingsData = () => {
  const { selectedLocation } = useDealersLocations();
  const getCommunicationSettingsData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    if (!selectedLocation?.id) throw new Error("invalid location");

    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);
    return res?.data?.settings;
  };

  const { data, isLoading } = useQuery({
    queryFn: getCommunicationSettingsData,
    queryKey: CommunicationSettingsQueryKeys.settings(selectedLocation?.id),
    enabled: !!selectedLocation?.id
  });

  return { data, isLoading };
};

export function useGetPDFTemplates() {
  const { selectedDealer } = useDealersLocations();

  const getPDFTemplates = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, endpoint } = queryKey[0];
    const res = await ApiInstance.post(endpoint, { dealer_id: selectedDealer?.id }, baseUrl);
    return res.data.report_templates;
  };

  const { data, isLoading } = useQuery({
    queryFn: getPDFTemplates,
    queryKey: CommunicationSettingsQueryKeys.pdf_templates
  });

  return { data, isLoading } as { data: PDFTemplateType[]; isLoading: boolean };
}

export const useCommunicationSettingsMutation = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();
  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: CommunicationSettings) => ApiInstance.post("/communication/settings/update", { settings: formValues }, ENV.dealerBaseUrl),
    onMutate: () => {
      return CommunicationSettingsQueryKeys.settings(selectedLocation?.id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: CommunicationSettingsQueryKeys.settings(selectedLocation?.id) }),
    onError: (err, _, context) => {
      toast.error(err.message);
      queryClient.setQueryData(CommunicationSettingsQueryKeys.settings(selectedLocation?.id), context);
    }
  });

  return { mutate, isPending };
};
