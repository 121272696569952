import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import { DATE_FORMATS_FNS, ReactTable } from "components";
import AttachmentsList from "components/Logs/AttachmentsList";
import useLogs from "components/Logs/hooks/useLogs";
import "components/Logs/Logs.scss";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type LogsProps = {
  appointment_id: number;
};

type LogsData = {
  date: string;
  name: string;
  affectedElement: string;
  description: string;
  id: number;
};

export const Logs = ({ appointment_id }: LogsProps) => {
  const [showLogs, setShowLogs] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [logsRequested, setLogsRequested] = useState(false);
  const t = useTranslation().t as ITranslation;

  const { fetchLogs, logs, attachments } = useLogs(appointment_id);

  const onRequestClose = () => {
    setShowLogs(false);
  };

  const onRequestOpen = () => {
    if (!logs) {
      fetchLogs();
    }
    setLogsRequested(true);
  };

  useEffect(() => {
    if (logsRequested && logs) {
      setLogsRequested(false);
      setShowLogs(true);
    }
  }, [logsRequested, logs]);

  const columnHelper = createColumnHelper<LogsData>();
  const columns = [
    columnHelper.accessor(row => row.date, {
      id: "date",
      header: t("v8_time").message || "Time",
      cell: info => {
        const value = info.renderValue();
        return value ? <div>{formatDate(value, DATE_FORMATS_FNS.dateMonthYearTime)}</div> : "";
      }
    }),
    columnHelper.accessor(row => row.name, {
      id: "name",
      header: t("v8_user_name").message || "Name",
      cell: info => <div>{info.renderValue()}</div>
    }),
    columnHelper.accessor(row => row.affectedElement, {
      id: "affectedElement",
      header: t("v8_affected_element").message || "Affected element",
      cell: info => <div>{info.renderValue()}</div>
    }),
    columnHelper.accessor(row => row.description, {
      id: "description",
      header: t("v8_description").message || "Description",
      size: 500,
      cell: info => <div className="description">{info.renderValue()}</div>
    })
  ];

  return (
    <>
      <Button className="-appointment-status" size="small" onClick={onRequestOpen}>
        {t("v8_logs").message || "LOGS"}
      </Button>

      <Modal size="large" open={showLogs} onClose={onRequestClose} dimmer="blurring" closeOnDimmerClick className="Logs">
        <Modal.Header className="Logs-title">
          <p className="log-modal-title">{t("v8_appointment_detail_log").message || "Appointment Detail Log"}</p>
          <div className="car-check">
            {attachments && (
              <Button secondary color="blue" size="small" onClick={() => setShowAttachments(true)}>
                {t("v8_car_check_files").message || "Car check files"}
              </Button>
            )}
            <Button className="-appointment-status" size="small" onClick={onRequestClose}>
              <Icon className="xmark grey" size="big" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <div>
            <ReactTable columns={columns} data={logs} />
          </div>
        </Modal.Content>
      </Modal>
      <AttachmentsList onRequestClose={() => setShowAttachments(false)} showAttachments={showAttachments} data={attachments} />
    </>
  );
};
