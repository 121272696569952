import { AppointmentNoteAttachment, SnoozeAttachment } from "models";

export const checkIsFileImage = (type: string, name: string, selectedFile: AppointmentNoteAttachment | SnoozeAttachment) => {
  return (
    [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/webp",
      "image/gif",
      "image/bmp",
      "image/svg",
      "image/tiff",
      "image/heif",
      "png",
      "jpeg",
      "jpg",
      "webp",
      "gif",
      "bmp",
      "svg",
      "tiff",
      "heif"
    ].includes(type) || /\.(png|jpeg|jpg|webp|gif|bmp|svg|tiff|heif)(\?.*)?$/.test(name || selectedFile.url)
  );
};

export const checkShouldRenderIFrame = (type: string) => {
  return [
    "text/html",
    "text/css",
    "application/javascript",
    "text/plain",
    "application/xml",
    "application/pdf",
    "video/mp4",
    "video/webm",
    "video/ogg",
    "audio/mpeg",
    "audio/wav",
    "audio/ogg",
    "application/rss+xml",
    "text/csv",
    "pdf",
    "mp4",
    "webm",
    "ogg",
    "wav",
    "csv"
  ].includes(type);
};
