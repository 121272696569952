import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DATE_FORMATS_FNS } from "components";
import { useDealersLocations } from "hooks";
import { SearchTyreReplacement, TYRE_TEAM_CHANNEL, TyreBrandDiscount, TyreDiscount } from "models";
import { Delivery } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/components";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type Props = {
  appointmentId: number;
};

const deliveryChannels: Record<TYRE_TEAM_CHANNEL, string> = {
  [TYRE_TEAM_CHANNEL.WheelTyre]: "Wheel-Tyre",
  [TYRE_TEAM_CHANNEL.BandenExpress]: "Banden Express"
};

export const useTyreTeam = ({ appointmentId }: Props) => {
  const t = useTranslation().t as ITranslation;
  const { deliveryQuery, discountsQuery } = useTyre(appointmentId);
  const { selectedLocation } = useDealersLocations();

  const [inStock, setInStock] = useState(true);
  const [delivery, setDelivery] = useState<string[]>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<Delivery[]>([]);
  const [discountOptions, setDiscountOptions] = useState<TyreDiscount[]>([]);
  const isTyreTeamEnabled = selectedLocation?.is_tyre_team_enabled;
  const [selectedDiscount, setSelectedDiscount] = useState<TyreDiscount | null>(null);

  useEffect(() => {
    if (deliveryQuery.data) {
      setDeliveryOptions(deliveryQuery.data);

      if (!delivery.length && deliveryQuery.data.length > 0) {
        const deliveryIds = deliveryQuery.data.filter((d: Delivery) => !d.id.includes("PICKUP")).map((d: Delivery) => d.id);
        setDelivery(deliveryIds);
      }
    }
  }, [deliveryQuery.data]);

  useEffect(() => {
    if (discountsQuery.data) {
      const activeDiscounts = discountsQuery.data.filter((discount: TyreDiscount) => discount.active);
      if (activeDiscounts?.length > 0) setDiscountOptions(activeDiscounts);
    }
  }, [discountsQuery.data]);

  const formatTime = (time: string | null) => (time ? formatDate(time, DATE_FORMATS_FNS.hoursMinutes) : null);
  const getDeliveryChannel = (channel: TYRE_TEAM_CHANNEL | null) => (channel ? deliveryChannels[channel] : t("v8_unknown_channel").message || "Unknown channel");

  const getFormattedDeliveryTimes = (delivery: Delivery | undefined) => ({
    order_before: delivery?.order_before ? formatTime(delivery.order_before) || "" : "",
    delivery_before: delivery?.delivery_before ? formatTime(delivery.delivery_before) || "" : "",
    delivery_from: delivery?.delivery_from ? formatTime(delivery.delivery_from) || "" : ""
  });

  const getDelivery = (delivery: string | null) => {
    if (!delivery) return null;

    const selectedDelivery = deliveryQuery.data?.find((d: Delivery) => d.id === delivery);

    const { delivery_before, delivery_from, order_before } = getFormattedDeliveryTimes(selectedDelivery);

    if (delivery.includes("PICKUPSAMEDAY")) {
      return {
        trigger: t("v8_pickup_sameday").message || "Pickup sameday",
        content: t("v8_tyre_team_pickupsameday_detail", { order_before, delivery_from }).message || t("v8_pickup_sameday").message || "Pickup sameday",
        long: t("v8_tyre_team_pickup_sameday", { order_before, delivery_from }).message || t("v8_pickup_same_day").message || "Pickup same day"
      };
    }

    if (delivery.includes("SAMEDAY")) {
      return {
        trigger: t("v8_sameday").message || "Sameday",
        content: t("v8_tyre_team_sameday_detail", { order_before, delivery_before }).message || t("v8_sameday").message || "Sameday",
        long: t("v8_tyre_team_sameday", { order_before, delivery_before }).message || t("v8_same_day").message || "Same day"
      };
    }

    if (delivery.includes("PICKUPOVERNIGHT")) {
      return {
        trigger: t("v8_pickup_overnight").message || "Pickup Overnight",
        content: t("v8_tyre_team_pickupovernight_detail", { order_before, delivery_from }).message || t("v8_pickup_overnight").message || "Pickup Overnight",
        long: t("v8_tyre_team_pickupovernight", { order_before, delivery_from }).message || t("v8_pickup_overnight").message || "Pickup overnight"
      };
    }

    if (delivery.includes("OVERNIGHT")) {
      return {
        trigger: t("v8_overnight").message || "Overnight",
        content: t("v8_tyre_team_overnight_detail", { order_before, delivery_before }).message || t("v8_overnight").message || "Overnight",
        long: t("v8_tyre_team_overnight", { order_before, delivery_before }).message || t("v8_overnight").message || "Overnight"
      };
    }

    return null;
  };

  const getDeliveriesData = () =>
    deliveryOptions.map((item: Delivery) => ({
      key: item.id,
      value: item.id,
      text: getDelivery(item.id)?.long
    }));

  const getDiscountsData = () =>
    discountOptions.map((item: TyreDiscount) => ({
      key: item.id,
      value: item.id,
      text: item.name
    }));

  const getCalculatedPrices = (searchResult: SearchTyreReplacement, discount: TyreDiscount | TyreBrandDiscount) => {
    let result = { ...searchResult };

    if (discount.discount) {
      result = {
        ...result,
        price_reduction: null,
        net_percent_discount: null,
        discount: discount.discount,
        customerPrice: result.gross_price * (1 - discount.discount / 100)
      };
    } else if (discount.net_margin_euro) {
      result = {
        ...result,
        discount: null,
        price_reduction: discount.net_margin_euro,
        net_percent_discount: null,
        customerPrice: result.net_price + discount.net_margin_euro
      };
    } else if (discount.net_margin_percent) {
      result = {
        ...result,
        discount: null,
        price_reduction: null,
        net_percent_discount: discount.net_margin_percent,
        customerPrice: result.net_price * (1 + discount.net_margin_percent / 100)
      };
    }

    return result;
  };

  const processResultsWithDiscount = (results: SearchTyreReplacement[], discount: TyreDiscount | null) => {
    const searchResults = [...results];
    if (!discount) {
      return searchResults.map(result => ({
        ...result,
        discount: null,
        price_reduction: null,
        net_percent_discount: null,
        customerPrice: result.gross_price
      }));
    }

    return searchResults.map(result => {
      if (discount?.tyre_brand_discounts && discount.tyre_brand_discounts.length > 0) {
        const brandDiscount = discount.tyre_brand_discounts.find(bd => bd.active && bd.brand.toLowerCase() === result.manufacturer?.toLowerCase());
        if (brandDiscount) return getCalculatedPrices(result, brandDiscount);
      }

      return getCalculatedPrices(result, discount);
    });
  };

  return {
    inStock,
    delivery,
    deliveryQuery,
    deliveryOptions,
    discountsQuery,
    isTyreTeamEnabled,
    setInStock,
    getDelivery,
    setDelivery,
    getDeliveriesData,
    getDeliveryChannel,
    discountOptions,
    processResultsWithDiscount,
    selectedDiscount,
    setSelectedDiscount,
    getCalculatedPrices,
    getDiscountsData
  };
};
