import { useTranslation } from "react-i18next";
import { Grid, GridColumn } from "semantic-ui-react";

import { DealersAndLocationsDropdown, ExportDataButton, NavPortal, ResetFiltersButton, SearchFilter } from "components";
import { useUser } from "hooks";
import "modules/BackordersDashboard/components/BackordersDashboardFilters/BackordersDashboardFilters.scss";
import { useBackordersDashboardFilterHandlers } from "modules/BackordersDashboard/components/BackordersDashboardFilters/hooks";
import { useGetBackordersDashboardFilters, useGetBackordersExportModalSetters } from "modules/BackordersDashboard/hooks";
import { ITranslation } from "util/interfaces";

export const BackordersDashboardFilters = () => {
  const user = useUser();
  const t = useTranslation().t as ITranslation;
  const {
    filters: { dealer_ids, location_ids, search_term }
  } = useGetBackordersDashboardFilters();
  const { handleResetFilters, handleSearchChange, handleDealerLocationChange } = useBackordersDashboardFilterHandlers();
  const { handleOpenExportModal } = useGetBackordersExportModalSetters();

  return (
    <>
      <NavPortal>
        <SearchFilter value={search_term} handleSearchChange={handleSearchChange} />
      </NavPortal>

      <div className="BackordersDashboardFilters__toolbar-container">
        <Grid columns={16} className="BackordersDashboardFilters__toolbar-grid">
          <GridColumn width={7} className="BackordersDashboardFilters__no-left-gutter">
            <DealersAndLocationsDropdown
              values={{ dealerIds: dealer_ids, locationIds: location_ids }}
              handleOnChange={({ dealerIds, locationIds }) => handleDealerLocationChange({ dealerIds, locationIds })}
              allowSelectAllDealers={false}
            />
          </GridColumn>
        </Grid>

        <div className="BackordersDashboardFilters__toolbar-reset-export-buttons-container">
          <ResetFiltersButton handleReset={handleResetFilters} />
          {user?.csv_download && (
            <ExportDataButton
              contentMessage={`${t("v8_export_disabled_as_no_dealer_location_selected").message || "Export disabled as no dealer or location have been selected"}.`}
              handleOnClick={handleOpenExportModal}
              hasWarning={dealer_ids.length === 0 && location_ids.length === 0}
            />
          )}
        </div>
      </div>
    </>
  );
};
