import { ExportDataModal } from "components";
import { useBackordersExportModalQuery, useGetBackordersDashboardFilters, useGetBackordersExportModalSetters } from "modules/BackordersDashboard/hooks";
import { exportBackordersDashboardData } from "modules/BackordersDashboard/service";

export const BackordersExportModal = () => {
  const { filters } = useGetBackordersDashboardFilters();
  const { isExportModalOpen } = useBackordersExportModalQuery();
  const { handleCloseExportModal } = useGetBackordersExportModalSetters();

  return <ExportDataModal open={isExportModalOpen} onClose={handleCloseExportModal} onExportData={() => exportBackordersDashboardData(filters)} />;
};
