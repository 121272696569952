import { useTranslation } from "react-i18next";
import { Icon, Label, Popup } from "semantic-ui-react";

import { DATE_FORMATS_FNS, useCan } from "components";
import { MANUFACTURER_REQUEST_STATUS, ManufacturerRequest } from "models/Manufacturer";
import "modules/ManufacturerDashboard/components/ManufacturerStatus/ManufacturerStatus.scss";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

interface ManufacturerStatusPropTypes {
  request: ManufacturerRequest | null;
  openRequestModal: (request: ManufacturerRequest) => void;
}

export const ManufacturerStatus = ({ request, openRequestModal }: ManufacturerStatusPropTypes) => {
  const t = useTranslation().t as ITranslation;
  const canHandleRequest = useCan("update-request", "manufacturer");

  if (!request) return null;

  if (request.status === MANUFACTURER_REQUEST_STATUS.Approved) {
    const message = `${t("v8_this_access_will_expire_on").message || "This access will expire on:"}  ${formatDate(request.expire_on!, DATE_FORMATS_FNS.dateMonthYear)}`;
    return (
      <Popup
        trigger={
          <Label className="Manufacturer__status-approved Manufacturer__status">
            <Icon className="check" />
          </Label>
        }
        content={message}
      />
    );
  }
  if (request.status === MANUFACTURER_REQUEST_STATUS.Declined) {
    const message = `${t("decline_for_the_following_reason").message || "Declined for the following reason:"} ${request.decline_reason}`;
    return (
      <Popup
        trigger={
          <Label className="Manufacturer__status-declined Manufacturer__status">
            <Icon className="x mark" color="white" />
          </Label>
        }
        content={message}
      />
    );
  }

  if (request.status === MANUFACTURER_REQUEST_STATUS.Pending) {
    const message = canHandleRequest
      ? `${request.manufacturer?.first_name} ${request.manufacturer?.last_name} ${t("v8_requested_access_for_the_following_reason").message || "requested access for the following reason:"} ${request.request_reason}`
      : t("v8_request_pending_approval").message || "Request pending approval";
    return (
      <span
        onClick={() => {
          if (!canHandleRequest) return;
          openRequestModal(request);
        }}
      >
        <Popup
          trigger={
            <Label className="Manufacturer__status-pending Manufacturer__status">
              <Icon className={canHandleRequest ? "question" : "clock rotate left"} />
            </Label>
          }
          content={message}
        />
      </span>
    );
  }
};
