import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { ExportDataResponseStatus } from "components";
import ENV from "config/Env";
import { Checklist, LeadsCars, LeadsNotSnoozed, LeadsSnoozed, Tag } from "models";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { CarsQueryParams, LeadsQueryKeys, NotSnoozedQueryParams, SnoozedQueryParams } from "modules/LeadsDashboard/queryKeys";
import { groupWOResults, normalizeData } from "modules/LeadsDashboard/util";
import ApiInstance from "util/Api";
import { ITranslation, SelectOption } from "util/interfaces";
import { IBackendQueryKey } from "util/keyFactory";

type ChecklistProps = {
  location_ids: number[] | null;
  dealer_ids: number[] | null;
};

export interface InterventionQuestionType extends SelectOption<number | string> {
  filterValue: number | null;
  groupTitle?: string;
}

const getLeadsSnoozedData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint, params } = queryKey[0];
  if (!params) return;

  const payload = params.filters as LeadsSnoozedFilters;
  const response = await ApiInstance.post(endpoint, payload, baseUrl);
  const snoozedData = response.data as LeadsSnoozed;

  return { ...snoozedData, items: normalizeData(snoozedData?.items || []) };
};

const getLeadsNotSnoozedData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint, params } = queryKey[0];
  if (!params) return;

  const payload = params.filters as LeadsNotSnoozedFilters;
  const response = await ApiInstance.post(endpoint, payload, baseUrl);
  const notSnoozedData = response.data as LeadsNotSnoozed;

  return { ...notSnoozedData, items: groupWOResults(notSnoozedData?.items || []) };
};

const getLeadsCarsData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint, params } = queryKey[0];
  if (!params) return;

  const payload = params.filters as LeadsCarsFilter;
  const response = await ApiInstance.post(endpoint, payload, baseUrl);
  return response.data as LeadsCars;
};

const getTags = async () => {
  const { data } = await ApiInstance.get(LeadsQueryKeys.tags.endpoint, LeadsQueryKeys.tags.baseUrl);
  return data.tags.reduce((acc: SelectOption<number>[], tag: Tag) => {
    if (tag.system) acc.push({ key: tag.id, value: tag.id, text: tag.name });

    return acc;
  }, []);
};

export const useGetTagsQuery = () => {
  const { data } = useQuery({ queryKey: [LeadsQueryKeys.tags], queryFn: getTags, enabled: true });
  return data;
};

export const useGetChecklistsQuestionsQuery = ({ dealer_ids, location_ids }: ChecklistProps) => {
  const t = useTranslation().t as ITranslation;
  const isEnabled = (location_ids && location_ids?.length > 0) || (dealer_ids && dealer_ids?.length > 0);
  const selectAllOption = { key: "all", value: "all", text: t("v8_all").message || "All", filterValue: null };

  const getQuestions = (checklists: Checklist[] | undefined) => {
    if (!checklists) return [];

    const questions = checklists
      .filter(el => el.active && el.questions?.length)
      .reduce((acc, checklist) => {
        const key = `${checklist.id}-group-title`;
        acc.push({ key, value: key, text: checklist.name || "", filterValue: null, groupTitle: checklist.name });

        checklist.questions?.forEach(question => {
          const key = `${checklist.id}-${question.id}`;
          if (!acc.some(el => el.key === key)) {
            acc.push({ key, value: key, text: question.title || "", filterValue: question.id });
          }
        });
        return acc;
      }, [] as InterventionQuestionType[]);

    return questions.length > 0 ? [selectAllOption, ...questions] : [];
  };

  const getChecklists = async ({ queryKey }: QueryFunctionContext<IBackendQueryKey[]>) => {
    const { baseUrl, endpoint, params } = queryKey[0];
    const { data } = await ApiInstance.post(endpoint, { ...params, embed: "question" }, baseUrl);
    const checklists: Checklist[] | undefined = data.checklists ?? [];

    return getQuestions(checklists);
  };

  const { data } = useQuery({
    queryKey: LeadsQueryKeys.checklists({ dealer_ids, location_ids }),
    queryFn: getChecklists,
    enabled: !!isEnabled
  });

  return data;
};

export const useLeadsCarsQuery = ({ carsFilters }: CarsQueryParams) => {
  let isEnabled = !!carsFilters;

  if (carsFilters) {
    const hasDealerLocationSelected = carsFilters.location_ids?.length > 0 || carsFilters.dealer_ids?.length > 0;
    const hasDateRangeSelected = carsFilters.date_from && carsFilters.date_to;
    isEnabled = !!(hasDealerLocationSelected && hasDateRangeSelected);
  }

  const { data, isLoading } = useQuery({
    queryKey: LeadsQueryKeys.listCars({ carsFilters }),
    queryFn: getLeadsCarsData,
    enabled: !!isEnabled
  });

  return { data, isLoading };
};

export const useLeadsNotSnoozedQuery = ({ notSnoozedFilters }: NotSnoozedQueryParams) => {
  let isEnabled = !!notSnoozedFilters;

  if (notSnoozedFilters) {
    const hasDealerLocationSelected = notSnoozedFilters.location_ids?.length > 0 || notSnoozedFilters.dealer_ids?.length > 0;
    const hasDateRangeSelected = notSnoozedFilters.date_from && notSnoozedFilters.date_to;

    isEnabled = !!(hasDealerLocationSelected && hasDateRangeSelected);
  }

  const { data, isLoading } = useQuery({
    queryKey: LeadsQueryKeys.listNotSnoozed({ notSnoozedFilters }),
    queryFn: getLeadsNotSnoozedData,
    enabled: !!isEnabled
  });

  return { data, isLoading };
};

export const useLeadsSnoozedQuery = ({ snoozedFilters }: SnoozedQueryParams) => {
  let isEnabled = !!snoozedFilters;

  if (snoozedFilters) {
    const hasDealerLocationSelected = snoozedFilters.location_ids?.length > 0 || snoozedFilters.dealer_ids?.length > 0;
    const hasAppointmentDateRangeSelected = snoozedFilters.appointment_date_from && snoozedFilters.appointment_date_to;
    const hasSnoozeDateRangeSelected = snoozedFilters.snooze_date_from && snoozedFilters.snooze_date_to;
    const hasDateRangeSelected = hasAppointmentDateRangeSelected || hasSnoozeDateRangeSelected;

    isEnabled = !!(hasDealerLocationSelected && hasDateRangeSelected);
  }

  const { data, refetch, isLoading } = useQuery({
    queryKey: LeadsQueryKeys.listSnoozed({ snoozedFilters }),
    queryFn: getLeadsSnoozedData,
    enabled: !!isEnabled
  });

  return { data, refetch, isLoading };
};

export const exportLeadsCars = async (carsFilters: LeadsCarsFilter): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/leads/export_cars", carsFilters, ENV.apiBase);
    window.open(response.data, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};

export const exportLeadsNotSnoozed = async ({ location_ids, ...rest }: LeadsNotSnoozedFilters): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/leads/export/not_snoozed", { ...rest, location_id: location_ids[0] }, ENV.appointmentBaseURL);
    window.open(response.data.url, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};

export const exportLeadsSnoozed = async ({ location_ids, ...rest }: LeadsSnoozedFilters): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/leads/export/snoozed", { ...rest, location_id: location_ids[0] }, ENV.appointmentBaseURL);
    window.open(response.data.url, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};
