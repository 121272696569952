import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DATE_FORMATS_FNS, FileDropdown } from "components";
import { NoteEditBtn, NoteTypeIconLabel } from "components/Notes";
import "components/Notes/NoteRow/NoteRow.scss";
import { CarNote } from "models";
import { AppointmentNote } from "models/AppointmentNote";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type NoteRowProps = {
  appointmentNote?: AppointmentNote;
  carNote?: CarNote;
  isCarNote?: boolean;
  onSelectNote?: () => void;
};

export const NoteRow = ({ appointmentNote, carNote, onSelectNote, isCarNote = false }: NoteRowProps) => {
  const t = useTranslation().t as ITranslation;
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const noteType = appointmentNote ? appointmentNote.appointment_note_type_id : carNote?.car_note_type_id || 0;
  const attachments = appointmentNote ? appointmentNote.attachments : carNote?.attachments;
  const note = appointmentNote ? appointmentNote.note : carNote?.note || "";

  const user = appointmentNote ? appointmentNote.user : carNote?.created_by;
  const userId = appointmentNote ? appointmentNote.user_id : carNote?.updated_by_id || carNote?.created_by_id || 0;
  const updatedBy = appointmentNote ? appointmentNote.updated_by : carNote?.updated_by;

  const updatedOn = appointmentNote ? appointmentNote.updated_on : carNote?.updated_on;
  const createdOn = appointmentNote ? appointmentNote.created_on : carNote?.created_on;
  const phoneNr = appointmentNote ? appointmentNote.phone_nr : "";
  const appointmentName = appointmentNote?.name || "";
  const email = appointmentNote?.email || "";

  useEffect(() => {
    if (note) setIsTextExpanded(false);
  }, [note]);

  return (
    <div className="AppointmentNoteRow">
      <div className="note-details-info-container">
        <div className="icon-btn-container">
          <NoteTypeIconLabel type={noteType} isCarNote={isCarNote} />
          <NoteEditBtn type={noteType} isCarNote={isCarNote} onSelectNote={onSelectNote} />
        </div>
        {phoneNr && <div className="appointment-note-label">{phoneNr}</div>}
        {appointmentName && <div className="appointment-note-label">{appointmentName}</div>}
        {email && <div className="appointment-note-label">{email}</div>}
        {!!attachments?.length && (
          <div>
            <FileDropdown attachments={attachments} />
          </div>
        )}
        <div className={`appointment-note-text ${!isTextExpanded ? "ellipsis" : ""}`} onClick={() => setIsTextExpanded(prev => !prev)}>
          {note}
        </div>
        <div className="note-user-date-info-container">
          <div className="note-user-date-info">
            {updatedBy ? (
              userId !== 0 ? (
                <>
                  <span className="user-name">{`${t("v8_updated_by").message || "Updated by"} ${updatedBy.first_name} ${updatedBy.last_name} ${t("v8_on").message || "on"}`}</span>
                  {updatedOn && <span>{formatDate(updatedOn, DATE_FORMATS_FNS.dateMonthYearTime)}</span>}
                </>
              ) : (
                <>{updatedOn ? `${t("v8_updated_on").message || "Updated on"} ${formatDate(updatedOn, DATE_FORMATS_FNS.dateMonthYearTime)}` : ""}</>
              )
            ) : userId !== 0 && user ? (
              <>
                <span className="user-name">{`${t("v8_added_by").message || "Added by"} ${user.first_name} ${user.last_name} ${t("v8_on").message || "on"}`}</span>
                {createdOn && <span>{formatDate(createdOn, DATE_FORMATS_FNS.dateMonthYearTime)}</span>}
              </>
            ) : (
              <>{createdOn ? `${t("v8_added_on").message || "Added on"} ${formatDate(createdOn, DATE_FORMATS_FNS.dateMonthYearTime)}` : ""}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
