import { differenceInMilliseconds, differenceInMinutes, differenceInSeconds, getDate, getMonth, getYear, intervalToDuration, set } from "date-fns";
import { useEffect, useState } from "react";

import { DATE_FORMATS_FNS } from "components";
import { useDealersLocations } from "hooks";
import "modules/Appointments/components/Timer/Timer.scss";
import Timerslot from "modules/Appointments/components/Timer/Timerslot";
import { formatDate, isDateAfter } from "util/dateHelperFunctions";

type FormattedSlot = {
  timeLeft: string;
  color: string;
};

export const Timer = () => {
  const { selectedLocation } = useDealersLocations();
  const [formattedTimeSlots, setFormattedTimeSlots] = useState<FormattedSlot[]>([]);

  const getFormattedTimeSlots = () => {
    const currentTime = new Date();

    if (!selectedLocation?.timeslots) {
      setFormattedTimeSlots([]);
      return;
    }

    const formattedTimeSlots = selectedLocation?.timeslots
      .filter(time => time.active)
      .sort((a, b) => differenceInMilliseconds(new Date(b.deadline), new Date(a.deadline)))
      .map(slot => {
        const deadline = set(new Date(slot.deadline), {
          year: getYear(currentTime),
          month: getMonth(currentTime),
          date: getDate(currentTime)
        });
        if (isDateAfter(currentTime, deadline)) {
          return {
            color: "red",
            timeLeft: "00:00"
          };
        } else if (
          (differenceInMinutes(deadline, currentTime) > 0 && differenceInMinutes(deadline, currentTime) < 30) ||
          (differenceInSeconds(deadline, currentTime) > 0 && differenceInSeconds(deadline, currentTime) <= 60)
        ) {
          const diff = intervalToDuration({ start: currentTime, end: deadline });
          const seconds = (diff.seconds ?? 0 >= 10) ? diff.seconds : "0" + diff.seconds;
          return {
            color: "blue",
            timeLeft: `-${diff.minutes}:${seconds}`
          };
        } else {
          return {
            color: "green",
            timeLeft: formatDate(slot.deadline, DATE_FORMATS_FNS.hoursMinutes)
          };
        }
      });

    setFormattedTimeSlots(formattedTimeSlots || []);
  };

  useEffect(() => {
    getFormattedTimeSlots();
  }, [selectedLocation?.id]);

  if (!formattedTimeSlots.length) {
    return null;
  }

  return (
    <div className="Timer">
      {formattedTimeSlots.map((s, k) => {
        return <Timerslot key={k} color={s.color} timeLeft={s.timeLeft} />;
      })}
    </div>
  );
};
