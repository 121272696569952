import { useMutation } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";

import { useCan } from "components";
import ENV from "config/Env";
import { PinModel } from "models";
import ApiInstance from "util/Api";

const DEBOUNCE_DURATION = 250;

const claimRequestSearch = async (searchTerm: string) => {
  const response = await ApiInstance.post("/pins/search_by_claim_nr", { claim_nr: searchTerm }, ENV.appointmentBaseURL);
  return (response.data?.pins || []) as PinModel[];
};

export const useCreateManufacturerRequestModal = () => {
  const canCreateRequest = useCan("create-request", "manufacturer");

  const [pinClaims, setPinClaims] = useState<PinModel[]>([]);
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false);

  const closeCreateRequestModal = () => setShowCreateRequestModal(false);
  const openCreateRequestModal = () => setShowCreateRequestModal(true);

  const debouncedSearch = useCallback(
    debounce(async (searchTerm: string) => {
      const results = await claimRequestSearch(searchTerm);
      setPinClaims(results);
    }, DEBOUNCE_DURATION),
    []
  );

  const { mutate: searchManufacturerClaim, isPending: isSearchingPin } = useMutation({
    mutationFn: async (searchTerm: string) => {
      if (!searchTerm || !canCreateRequest) return;
      debouncedSearch(searchTerm);
    }
  });

  return { searchManufacturerClaim, pinClaims, showCreateRequestModal, closeCreateRequestModal, openCreateRequestModal, isSearchingPin };
};
