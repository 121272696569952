import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { useGetLeaseCompanyOptions, useGetQuestionResultStatusOptions } from "modules/LeadsDashboard/hooks";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedFilters";
import { QUESTION_STATUSES_DEFAULT_STATUSES } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetChecklistsQuestionsQuery } from "modules/LeadsDashboard/service";
import { useGetFilterUpdaterFunctions } from "util/common";

type NotSnoozedLeadsTableFilterHandlersProps = {
  pageSize: number;
};

export const useNotSnoozedLeadsTableFilterHandlers = ({ pageSize }: NotSnoozedLeadsTableFilterHandlersProps) => {
  const queryClient = useQueryClient();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const { questionResultStatusOptions } = useGetQuestionResultStatusOptions();
  const { updateFiltersWithCallback, updateFilters } = useGetFilterUpdaterFunctions<LeadsNotSnoozedFilters>({ queryKey: LeadsQueryKeys.notSnoozedFilters });
  const notSnoozedFilters = queryClient.getQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters);

  const currentPage = notSnoozedFilters?.page ?? 1;
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === notSnoozedFilters?.is_lease_company)?.value || "";
  const interventionQuestionOptions =
    useGetChecklistsQuestionsQuery({ dealer_ids: notSnoozedFilters?.dealer_ids || [], location_ids: notSnoozedFilters?.location_ids || [] }) || [];

  const getSelectedQuestionStatuses = () => {
    if (!notSnoozedFilters) return [];

    if (notSnoozedFilters.statuses?.length === QUESTION_STATUSES_DEFAULT_STATUSES.length) return ["all"];
    return notSnoozedFilters.statuses?.map(String) ?? ["all"];
  };

  const selectedQuestions = notSnoozedFilters?.questions
    ? notSnoozedFilters.questions.reduce((acc, questionId) => {
        const question = interventionQuestionOptions.find(element => element.filterValue === questionId);
        if (question) acc.push(question.key.toString());

        return acc;
      }, [] as string[])
    : ["all"];

  const onPageChange = (data: { activePage: number }) => updateFilters({ page: data.activePage }, false);
  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange({ activePage: newState.pageIndex + 1 });
  };

  const handleQuestionResultStatusSelect = (questionStatuses: string[]) => {
    if (!questionStatuses.length) return;

    updateFiltersWithCallback(({ statuses }) => {
      const isAllOptionSelected = questionStatuses.includes("all");
      const areAllStatusesNotSelected = statuses?.length !== QUESTION_STATUSES_DEFAULT_STATUSES.length;
      const newStatuses = isAllOptionSelected && areAllStatusesNotSelected ? QUESTION_STATUSES_DEFAULT_STATUSES : questionStatuses.map(Number).filter(Boolean);
      return { statuses: newStatuses };
    });
  };

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    const selectedLeaseCompanyValue = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    updateFilters({ is_lease_company: selectedLeaseCompanyValue ? selectedLeaseCompanyValue.filterValue : selectedLeaseCompanyValue });
  };

  const handleOnQuestionSelect = (questions: string[]) => {
    updateFiltersWithCallback(oldData => {
      const isResetNotSelected = questions.includes("all") && oldData.questions !== null;
      const newQuestions = isResetNotSelected ? null : questions.filter(el => el !== "all").map(el => Number(el.split("-")[1]));
      return { questions: newQuestions };
    });
  };

  const handleDateRangeChange = ([start, end]: [Date | null, Date | null]) => {
    if (start && end) updateFilters({ date_from: start, date_to: end });
  };

  return {
    handleOnLeaseSelect,
    handleDateRangeChange,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleQuestionResultStatusSelect,
    currentPage,
    selectedQuestions,
    notSnoozedFilters,
    leaseCompanyOptions,
    selectedQuestionStatuses: getSelectedQuestionStatuses(),
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions
  };
};
