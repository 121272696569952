import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { TimeInput } from "semantic-ui-calendar-react";
import { Modal } from "semantic-ui-react";

import { DATE_FORMATS_FNS, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Appointment } from "models";
import "modules/AppointmentDetails/components/AppointmentDate/AppointmentDate.scss";
import { useUpdateAppointment } from "modules/AppointmentDetails/hooks";
import { addDaysToDate, formatDate, isSameOrAfter, setHoursMinutesAndFormat, toDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type AppointmentDateProps = {
  appointment: Appointment;
};

export const AppointmentDate = ({ appointment }: AppointmentDateProps) => {
  if (!appointment.time_car_app || appointment.time_car_app.startsWith("0001-01-01T00:00:00")) return null;
  const t = useTranslation().t as ITranslation;
  const { isKeyLoopLocation } = useDealersLocations();
  const { appointmentKeyloopDateMutation, appointmentDatesMutation } = useUpdateAppointment(Number(appointment.id));
  const canUpdateAppointmentDates = useCan("update-appointment-dates", "appointments");
  const canUpdateKeyloopAppointmentDate = canUpdateAppointmentDates && !!isKeyLoopLocation && isSameOrAfter(appointment.time_car_app, new Date());
  const canUpdateManualAppointmentDates = canUpdateAppointmentDates && appointment.is_local;

  const [appointmentSchedule, setAppointmentSchedule] = useState({
    showOutDate: false,
    showOutTime: false,
    showInDate: false,
    showInTime: false
  });
  const [didSelectMinutes, setDidSelectMinutes] = useState<boolean>(false);

  const [scheduleInDateTime, setScheduleInDateTime] = useState<string>(appointment.due_in || "");
  const [scheduleOutDateTime, setScheduleOutDateTime] = useState<string>(appointment.car_return_time || "");
  const [showManualAppointmentDateCalendar, setShowManualAppointmentDateCalendar] = useState(false);
  const handleModalClose = () => {
    setAppointmentSchedule(prevState => ({
      ...prevState,
      showOutDate: false,
      showOutTime: false,
      showInDate: false,
      showInTime: false
    }));
    setDidSelectMinutes(false);
    setShowManualAppointmentDateCalendar(false);
  };

  const onAppointmentScheduleInDateChange = (value: Date | null) => {
    if (!value) return;
    setScheduleInDateTime(formatDate(value));
    setAppointmentSchedule(prevState => ({
      ...prevState,
      showInTime: true
    }));
  };

  const onAppointmentScheduleInTimeChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
    const [hour, minute] = value.split(":").map(Number);
    const scheduleInDateWithTime = setHoursMinutesAndFormat(scheduleInDateTime, hour, minute);
    setScheduleInDateTime(scheduleInDateWithTime);
    if (didSelectMinutes) {
      setAppointmentSchedule(prevState => ({
        ...prevState,
        showOutDate: true,
        showInDate: false,
        showInTime: false
      }));
    }
    setDidSelectMinutes(prev => !prev);
  };

  const onAppointmentScheduleOutDateChange = (value: Date | null) => {
    if (!value) return;
    setScheduleOutDateTime(formatDate(value));
    setAppointmentSchedule(prevState => ({
      ...prevState,
      showOutTime: true
    }));
  };

  const onAppointmentScheduleOutTimeChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
    const [hour, minute] = value.split(":").map(Number);
    const scheduleOutDateWithTime = setHoursMinutesAndFormat(scheduleOutDateTime, hour, minute);
    setScheduleOutDateTime(scheduleOutDateWithTime);
    if (didSelectMinutes) {
      appointmentKeyloopDateMutation.mutateAsync({
        time_car_app: formatDate(scheduleInDateTime),
        due_in: formatDate(scheduleInDateTime),
        car_return_time: formatDate(scheduleOutDateWithTime)
      });
      setAppointmentSchedule(prevState => ({
        ...prevState,
        showOutDate: false,
        showOutTime: false
      }));
    }
    setDidSelectMinutes(prev => !prev);
  };

  const handleSetDateView = () => {
    if (canUpdateManualAppointmentDates) return setShowManualAppointmentDateCalendar(true);
    else if (canUpdateAppointmentDates)
      setAppointmentSchedule(prevState => ({
        ...prevState,
        showInDate: canUpdateKeyloopAppointmentDate && !prevState.showInDate
      }));
  };

  const onManualAppointmentDateChange = (value: Date | null) => {
    if (!value) return null;
    const formattedDate = formatDate(value, DATE_FORMATS_FNS.timeCarAppDate);
    appointmentDatesMutation.mutate({
      time_car_app: formattedDate
    });
    handleModalClose();
  };
  return (
    <div className={`AppointmentDate ${canUpdateManualAppointmentDates || canUpdateKeyloopAppointmentDate ? "isEditable" : ""}`}>
      <span onClick={handleSetDateView}>{formatDate(appointment.time_car_app, DATE_FORMATS_FNS.dateMonthYear)}</span>
      <Modal
        size="tiny"
        open={(appointmentSchedule.showOutDate && !!scheduleOutDateTime) || appointmentSchedule.showInDate || showManualAppointmentDateCalendar}
        className="AppointmentDateModal"
        onClose={handleModalClose}
      >
        <Modal.Content>
          {appointmentSchedule.showInDate &&
            (appointmentSchedule.showInTime ? (
              <>
                <div className="AppointmentDateModal-heading">{t("v8_select_scheduled_in_time").message || "Select Scheduled-In time"}</div>
                <TimeInput value={formatDate(scheduleInDateTime, DATE_FORMATS_FNS.hoursMinutes)} onChange={onAppointmentScheduleInTimeChange} inline />
              </>
            ) : (
              <>
                <div className="AppointmentDateModal-heading">{t("v8_select_appointment_scheduled_in_date").message || "Select Appointment / Scheduled-In date"}</div>
                <ReactDatePicker
                  selected={toDate(scheduleInDateTime)}
                  onChange={onAppointmentScheduleInDateChange}
                  showMonthDropdown
                  showYearDropdown
                  minDate={toDate(new Date())}
                  maxDate={addDaysToDate(new Date(), 90)}
                  todayButton={<span>{t("v8_today").message || "Today"}</span>}
                  dateFormat="dd-MM-yyyy"
                  inline
                />
              </>
            ))}

          {appointmentSchedule.showOutDate &&
            scheduleOutDateTime &&
            (appointmentSchedule.showOutTime ? (
              <>
                <div className="AppointmentDateModal-heading">{t("v8_select_scheduled_out_time").message || "Select Scheduled-Out time"}</div>
                <TimeInput value={formatDate(scheduleOutDateTime, DATE_FORMATS_FNS.hoursMinutes)} onChange={onAppointmentScheduleOutTimeChange} inline />
              </>
            ) : (
              <>
                <div className="AppointmentDateModal-heading">{t("v8_select_scheduled_out_date").message || "Select Scheduled-Out date"}</div>
                <ReactDatePicker
                  selected={toDate(scheduleOutDateTime)}
                  onChange={onAppointmentScheduleOutDateChange}
                  showMonthDropdown
                  showYearDropdown
                  minDate={toDate(scheduleInDateTime)}
                  maxDate={addDaysToDate(scheduleInDateTime, 90)}
                  todayButton={<span>{t("v8_today").message || "Today"}</span>}
                  dateFormat="dd-MM-yyyy"
                  inline
                />
              </>
            ))}

          {canUpdateAppointmentDates && showManualAppointmentDateCalendar && (
            <ReactDatePicker
              selected={toDate(appointment.time_car_app)}
              onChange={onManualAppointmentDateChange}
              showMonthDropdown
              showYearDropdown
              inline
              minDate={toDate(new Date())}
              maxDate={addDaysToDate(new Date(), 90)}
              todayButton={<span>{t("v8_today").message || "Today"}</span>}
              dateFormat="dd-MM-yyyy"
            />
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
};
