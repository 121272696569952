import { ReactElement, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion, Breadcrumb, Button, Icon, Modal } from "semantic-ui-react";

import { BounceLoader, Calendar, CircleIcon, FileDragDrop, TableView, Tabs, UploadImage } from "components";
import ENV from "config/Env";
import { AppointmentSteps, Buttons, InfoMessages, Inputs, Loaders, Pagination, Table, TablePagination, TopBar } from "modules/Styleguide/components";
import { mockTabsData } from "modules/Styleguide/mockData";
import "modules/Styleguide/Styleguide.scss";

const Styleguide = (): ReactElement => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | Array<Date | null> | null): void => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(date);
    }
  };

  const [accordionIndex, setAccordionIndex] = useState(0);

  const handleAccordion = (index: number) => {
    if (index !== accordionIndex) setAccordionIndex(index);
    else setAccordionIndex(0);
  };

  const [infoModal, setInfoModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  return (
    <>
      <TopBar />
      <br />

      <div className="container-styleguide">
        <AppointmentSteps />

        <Buttons />

        <h4>Round icons</h4>
        <div className="default-buttons">
          <CircleIcon icon="is_star" />
          <CircleIcon icon="is_money" />
          <CircleIcon icon="shopping cart" />
          <CircleIcon icon="thumb tack" />
          <CircleIcon icon="triangle exclamation" />
          <CircleIcon icon="coffee" />
          <CircleIcon icon="wrench" />
          <CircleIcon icon="fill" />
          <CircleIcon icon="tire orange" />
          <CircleIcon icon="tire green" />
        </div>

        <h4>Date picker</h4>
        <br />
        <Calendar
          selected={startDate}
          startDate={startDate ? startDate : undefined}
          endDate={endDate ? endDate : undefined}
          onChange={handleDateChange}
          selectsRange
          shouldCloseOnSelect
          isClearable
        />
        <Calendar selected={startDate} startDate={startDate ? startDate : undefined} onChange={handleDateChange} shouldCloseOnSelect />

        <h4>Typography</h4>
        <div>
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>

          <div className="-subheader-one">Subheader 1</div>
          <br />
          <div className="-subheader-two">Subheader 2</div>
          <br />

          <p>
            Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which
            lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of
            an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in
            the clouds.
          </p>

          <p className="-body-text">
            Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which
            lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of
            an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in
            the clouds.
          </p>

          <div>
            <h4>Table</h4>

            <table>
              <th>Column 1</th>
              <th>Column 2</th>
              <tr>
                <td>Value 1</td>
                <td>Value 2</td>
              </tr>
            </table>
          </div>
        </div>

        <Modal
          size="large"
          open={infoModal}
          onOpen={() => setInfoModal(true)}
          onClose={() => setInfoModal(false)}
          closeOnDimmerClick
          closeIcon
          trigger={<Button>Show info modal</Button>}
        >
          <Modal.Header>Modal Header Title</Modal.Header>
          <Modal.Content>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem accusamus fugiat amet numquam ex, cupiditate odio illum, saepe quas natus praesentium
            accusantium voluptate aliquam minus aspernatur modi? Vitae, natus accusamus.
          </Modal.Content>
        </Modal>
        <br />
        <br />

        <Modal
          size="large"
          open={editModal}
          onOpen={() => setEditModal(true)}
          onClose={() => setEditModal(false)}
          closeOnDimmerClick
          closeIcon
          trigger={<Button>Show edit modal</Button>}
        >
          <Modal.Header>Edit Modal</Modal.Header>
          <Modal.Content>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem accusamus fugiat amet numquam ex, cupiditate odio illum, saepe quas natus praesentium
              accusantium voluptate aliquam minus aspernatur modi? Vitae, natus accusamus.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="delete-button">
              <Button color="red">
                Delete <Icon name="trash" />
              </Button>
            </div>
            <div className="action-buttons">
              <Button secondary>Cancel</Button>
              <Button primary>Save</Button>
            </div>
          </Modal.Actions>
        </Modal>

        <Breadcrumb>
          <Breadcrumb.Section link>Home</Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section link>Registration</Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active>Personal Information</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <Breadcrumb>
          <Breadcrumb.Section link>Home</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link>Registration</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Personal Information</Breadcrumb.Section>
        </Breadcrumb>

        <Accordion>
          <Accordion.Title active={accordionIndex === 1} index={1} onClick={() => handleAccordion(1)}>
            What is a dog?
            <Icon name="dropdown" />
          </Accordion.Title>
          <Accordion.Content active={accordionIndex === 1}>
            <p>A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.</p>
          </Accordion.Content>

          <Accordion.Title active={accordionIndex === 2} index={2} onClick={() => handleAccordion(2)}>
            What kinds of dogs are there?
            <Icon name="dropdown" />
          </Accordion.Title>
          <Accordion.Content active={accordionIndex === 2}>
            <p>
              There are many breeds of dogs. Each breed varies in size and temperament. Owners often select a breed of dog that they find to be compatible with their own
              lifestyle and desires from a companion.
            </p>
          </Accordion.Content>
        </Accordion>

        <Inputs />
        <br />
        <UploadImage path="/communication/welcome_pic/upload" baseURL={ENV.dealerBaseUrl} formId="image-2" />
        <br />

        <FileDragDrop id="styleguide-image" onFileUpload={_e => null} />
        <br />

        <Tabs data={mockTabsData} />
        <br />
        <Table />

        <br />
        <TablePagination />

        <br />

        <InfoMessages />

        <BounceLoader show />

        <Loaders />

        <TableView
          cells={[
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN"
          ]}
          nrOfCells={3}
        />

        <Pagination />
      </div>
    </>
  );
};

export default Styleguide;
