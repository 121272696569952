import { Icon, Label, SemanticCOLORS } from "semantic-ui-react";

import { CircleIcon, useAppointmentPinColor } from "components";
import { APPOINTMENT_NOTE_TYPES, Appointment, AppointmentNote } from "models";
import { CallCustomerNote, MainNote, RecurringCarNote } from "modules/Dayplanner/components/AppointmentCard/components/NotificationIcons/components";
import { isDateAfter } from "util/dateHelperFunctions";

interface NotificationIconsProps {
  appointment: Appointment;
}

export interface NoteProps {
  note: AppointmentNote;
}

enum DBB_STATUS {
  NoDBBStatus = 0,
  DBBPushed,
  DBBIsPending,
  DBBFailed
}

const statusColorMap: { [key in DBB_STATUS]: SemanticCOLORS } = {
  [DBB_STATUS.NoDBBStatus]: "red",
  [DBB_STATUS.DBBPushed]: "green",
  [DBB_STATUS.DBBIsPending]: "orange",
  [DBB_STATUS.DBBFailed]: "red"
};

export const NotificationIcons = ({ appointment }: NotificationIconsProps) => {
  const mainNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.Main);
  const callCustomerNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CallCustomer);
  const recurringCarNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RecurringCar);

  const checkedTireLabelColor = statusColorMap[appointment.final_car_check_dbb_status as DBB_STATUS] || statusColorMap[DBB_STATUS.DBBFailed];
  const bigTireLabelColor = statusColorMap[appointment.car_check_dbb_status as DBB_STATUS] || statusColorMap[DBB_STATUS.DBBFailed];

  const pinColor = useAppointmentPinColor(appointment);

  return (
    <div className="NotificationIcons">
      {mainNote && <MainNote note={mainNote} />}
      {callCustomerNote && <CallCustomerNote note={callCustomerNote} />}

      {appointment.has_dbb && appointment.dbb_appointment_date && (
        <>
          {appointment.final_car_check_dbb_status > DBB_STATUS.NoDBBStatus && (
            <Label className="checkedTire" circular color={checkedTireLabelColor}>
              <Icon className="tire" />
              <Icon className="check" />
            </Label>
          )}
          {appointment.final_car_check_dbb_status === DBB_STATUS.NoDBBStatus && appointment.car_check_dbb_status > DBB_STATUS.NoDBBStatus && (
            <Label className="bigTire" circular color={bigTireLabelColor}>
              <Icon className="tire" />
            </Label>
          )}
          {appointment.final_car_check_dbb_status === DBB_STATUS.NoDBBStatus && appointment.car_check_dbb_status === DBB_STATUS.NoDBBStatus && (
            <Label className="bigTire" circular color={isDateAfter(new Date(), appointment.dbb_appointment_date) ? "green" : "orange"}>
              <Icon className="tire" />
            </Label>
          )}
        </>
      )}

      {(appointment.is_recurring || recurringCarNote) && <RecurringCarNote note={recurringCarNote} />}
      {appointment.customer_waiting && <CircleIcon icon="coffee" />}
      {appointment.is_money && <CircleIcon icon="is_money" />}
      {appointment.is_star && <CircleIcon icon="is_star" color={appointment.is_star_color} />}
      {appointment.is_shop && <CircleIcon icon="is_shop" color={appointment.is_shop_color} />}

      {appointment.has_extra_check && (
        <Label circular color="blue">
          <Icon className="fill drip regular" />
        </Label>
      )}

      {appointment.num_approved_items_not_fixed > 0 && (
        <span className="-fix-items-count">
          <Label circular color="red">
            {appointment.num_approved_items_not_fixed}
          </Label>
        </span>
      )}

      {appointment.final_check_has_remarks && <CircleIcon icon="wrench" />}

      {(appointment.recall_pin_count > 0 || appointment.remark_pin_count > 0 || appointment.warranty_pin_count > 0) && (
        <CircleIcon icon={appointment.warranty_pin_count > 0 ? "shield" : "pin"} color={pinColor} />
      )}

      {((appointment.key_dropped_at && !appointment.key_picked_up_at) ||
        (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
        (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
        (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at)) && (
        <Label circular className="keylocker-icon">
          <Icon name="key" />
        </Label>
      )}
    </div>
  );
};
