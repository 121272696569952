import { Appointment, User } from "models";
export interface DayplannerAppointment extends Appointment {
  inactive?: boolean;
  workingOn?: User[];
}

export interface MechanicWithAppointments extends User {
  appointments: DayplannerAppointment[];
  expanded?: boolean;
  auto_expand?: boolean;
}

export interface DayplannerColumns {
  appointmentsColumn: DayplannerAppointment[];
  unassignedColumn: DayplannerAppointment[];
  mechanicsColumn: MechanicWithAppointments[];
  carReadyColumn: DayplannerAppointment[];
  qualityControlColumn: DayplannerAppointment[];
}

export enum DAYPLANNER_COLUMNS {
  Appointments = 1,
  Unassigned,
  Mechanics,
  CarReady,
  QualityCheck
}

export enum MECHANIC_FILTERS {
  All = 1,
  HideOccupiedMechanics,
  HideUnoccupiedMechanics
}

export enum WO_INTERNAL_FILTER {
  All = 1,
  WO,
  Internal
}

export enum MECHANIC_SORTING {
  Ascending = "ascending",
  Descending = "descending"
}
