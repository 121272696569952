import { Loader } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { CarsLeadsFilters, CarsLeadsTable } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { useCarsFiltersQuery } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { useLeadsCarsQuery } from "modules/LeadsDashboard/service";

export const CarsLeads = () => {
  const { locations } = useDealersLocations();
  const { carsFilters } = useCarsFiltersQuery();
  const { isLoading } = useLeadsCarsQuery({ carsFilters });

  return (
    <>
      <CarsLeadsFilters />
      {isLoading ? <Loader active /> : <CarsLeadsTable locations={locations} />}
    </>
  );
};
