import { useState } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

import { BrandOption } from "modules/Dayplanner";
import "modules/Dayplanner/components/AppointmentBrandsFilter/AppointmentBrandsFilter.scss";

type AppointmentBrandsFilterProps = {
  brandsFilter: string[];
  brandOptions: BrandOption[];
  setSelectedBrand: (value: string) => void;
};

export const AppointmentBrandsFilter = ({ brandsFilter, brandOptions, setSelectedBrand }: AppointmentBrandsFilterProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleFilterDropdown = () => {
    setOpen(prev => !prev);
  };

  const isSelectedOption = (option: BrandOption) => {
    return (brandsFilter.length === 0 && option.key === "all") || brandsFilter.includes(option.value);
  };

  return (
    <div className="Dayplanner-AppointmentBrandsFilter" onClick={toggleFilterDropdown}>
      <Dropdown
        multiple={true}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <div className="Dayplanner-AppointmentBrandsFilter-Dropdown">
            <div className="composite-icons">
              <Icon className="solid car" />
              <Icon className="tag" />
            </div>
            <div className="filter-length">{brandsFilter.length}</div>
          </div>
        }
      >
        <Dropdown.Menu>
          {brandOptions.map((option: BrandOption, index: number) => {
            return (
              <Dropdown.Item
                selected={isSelectedOption(option)}
                key={`appointment-brands-filter-${index}`}
                onClick={() => {
                  setOpen(false);
                  setSelectedBrand(option.key);
                }}
              >
                <div className="Dayplanner-AppointmentBrandsFilter-Dropdown-Selection">
                  <p className="text">{option.text}</p>
                </div>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
