import { useTranslation } from "react-i18next";
import { Checkbox, Form } from "semantic-ui-react";

import { FormCheckbox, FormInput, FormTextArea } from "components";
import { Element } from "components/ReactScroll/index";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface DiagnoseOverviewProps {
  tabId: string;
}

export const DiagnoseOverview = ({ tabId }: DiagnoseOverviewProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_diagnose_overview").message || "DIAGNOSE OVERVIEW"}</h3>
      <div>
        <Form.Group className="mt-16">
          <Form.Field width={3}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_testing_mode").message || "Testing mode"}
            </p>
          </Form.Field>
          <Form.Field width={5}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <FormInput<CommunicationSettings> inputProps={{ placeholder: t("v8_email").message || "E-mail", fluid: true }} name="diagnose_cc_testing_email" />
          </Form.Field>
          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <FormInput<CommunicationSettings>
              inputProps={{ placeholder: t("v8_phone").message || "Phone", fluid: true, type: "Phone" }}
              name="diagnose_cc_testing_phone"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_welcome_text").message || "Welcome text"}</label>
            <FormTextArea<CommunicationSettings> textAreaProps={{ placeholder: `${t("v8_hello").message || "Hello"}!` }} name="repair_overview_welcome_text" />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_success_text").message || "Success text"}</label>
            <FormTextArea<CommunicationSettings> textAreaProps={{ placeholder: `${t("v8_thank_you").message || "Thank You"}!` }} name="success_text" />
          </Form.Field>
        </Form.Group>

        <div className="row mt-24">
          <div className="full">
            <Form.Field>
              <p className="items-center">
                <span>
                  <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="diagnose_overview_remarks_enabled" />
                </span>
                {t("v8_enable_remarks").message || "Enable remarks"}
              </p>
            </Form.Field>

            <Form.Field>
              <p className="items-center">
                <span>
                  <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="diagnose_overview_name_visible" />
                </span>
                {t("v8_ask_the_customer_to_enter_his_name").message || "Ask the Customer to enter his name"}
              </p>
            </Form.Field>
          </div>

          <div className="full">
            <Form.Field>
              <p className="title">{t("v8_dont_fix_button").message || "Don't Fix Button"}</p>
              <p className="items-center">
                <span>
                  <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="enable_not_fixed_advised" />
                </span>
                {t("v8_enable_for_advised_items").message || "Enable for Advised Items"}
              </p>
              <div className="items-center">
                <span>
                  <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="enable_not_fixed_critical" />
                </span>
                {t("v8_enable_for_critical_items").message || "Enable for Critical Items"}
              </div>
              <p className="items-center">
                <span>
                  <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="enable_not_fix_without_status" />
                </span>
                {t("v8_enable_for_interventions_without_status").message || "Enable for interventions without status"}
              </p>
            </Form.Field>

            <Form.Field>
              <p className="items-center">
                <span>
                  <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="skip_optional_items" />
                </span>
                {t("v8_skip_advised_items").message || "Skip advised items"}
              </p>
            </Form.Field>
          </div>
        </div>
      </div>
    </Element>
  );
};
