import { OnChangeFn, PaginationState } from "@tanstack/react-table";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";

import { PIN_TYPE } from "models";
import { PAGE_SIZE } from "modules/WarrantyDashboard/components/WarrantyDashboardTable";
import { DEFAULT_PIN_TYPES } from "modules/WarrantyDashboard/components/WarrantyDashboardTable/hooks/useGetSelectedFilterValues";
import { ORDER_BY, REG_VIN_FILTER, useGetWarrantyDashboardFilters } from "modules/WarrantyDashboard/hooks";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyDashboardFiltersPayload } from "modules/WarrantyDashboard/types";
import { copyToClipboard, useGetFilterUpdaterFunctions } from "util/common";

type WarrantyDashboardTableFilterHandlersProps = {
  setRegVinNumberFilter: Dispatch<SetStateAction<REG_VIN_FILTER>>;
};

const getClaimOrder = (claimed: ORDER_BY | null | undefined) => {
  if (!claimed) return ORDER_BY.ClaimAscending;
  return claimed === ORDER_BY.ClaimAscending ? ORDER_BY.ClaimDescending : null;
};

export const useWarrantyDashboardTableFilterHandlers = ({ setRegVinNumberFilter }: WarrantyDashboardTableFilterHandlersProps) => {
  const {
    filters: { page: currentPage }
  } = useGetWarrantyDashboardFilters();
  const { updateFilters, updateFiltersWithCallback } = useGetFilterUpdaterFunctions<WarrantyDashboardFiltersPayload>({ queryKey: WarrantyQueryKeys.warrantyFilters });

  const handleCopyToClipboard = (content: string, message: string) => copyToClipboard(content).then(() => toast.success(message));
  const onPageChange = (data: { activePage: number }) => {
    updateFilters({ page: data.activePage }, false);
  };
  const handlePaginationChange: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize: PAGE_SIZE }) : updater;
    onPageChange({ activePage: newState.pageIndex + 1 });
  };

  const handleRegVinNumberChange = (value: string[]) => {
    const isReg = value.some(el => Number(el) === REG_VIN_FILTER.Reg);
    setRegVinNumberFilter(isReg ? REG_VIN_FILTER.Reg : REG_VIN_FILTER.Vin);
  };

  const handleClaimChange = () => {
    updateFiltersWithCallback(oldData => {
      const claimOrder = getClaimOrder(oldData?.claim_order_by);
      return { ...oldData, claim_order_by: claimOrder, ...(claimOrder && { order_by: null }) };
    });
  };

  const handlePinTypeChange = (pinType: string[]) => {
    if (!pinType.length) return;

    updateFiltersWithCallback(oldData => {
      const isAllOptionSelected = pinType.includes("all");
      const areAllPinTypesNotSelected = oldData?.pin_type_id?.length !== DEFAULT_PIN_TYPES.length;
      const pinTypes = isAllOptionSelected && areAllPinTypesNotSelected ? DEFAULT_PIN_TYPES : pinType.map(Number).filter(Boolean);
      const isOnlyRemarksSelected = pinTypes.every(element => element === PIN_TYPE.Remarks);
      const isOnlyRecallsSelected = pinTypes.every(element => element === PIN_TYPE.Recall);

      return {
        ...oldData,
        pin_type_id: pinTypes,
        ...(isOnlyRemarksSelected && { warranty_type_id: null, claimed: false, pin_status_id: null }),
        ...(isOnlyRecallsSelected && { warranty_type_id: null })
      };
    });
  };
  const handleAppointmentChange = (order: string[]) => updateFilters({ order_by: Number(order[0]), claim_order_by: null });
  const handleWarrantyTypeChange = (warrantyType: string[]) => {
    const isAllOptionSelected = warrantyType.includes("all");
    updateFilters({ warranty_type_id: isAllOptionSelected ? null : Number(warrantyType[0]) });
  };
  const handleQuestionChange = (status: string[]) => {
    updateFiltersWithCallback(oldData => {
      const isAllOptionSelected = status.includes("all");
      const selectedStatus = isAllOptionSelected ? null : Number(status[0]);
      return { ...oldData, status: selectedStatus } as WarrantyDashboardFiltersPayload;
    });
  };

  return {
    handleClaimChange,
    handlePinTypeChange,
    handleQuestionChange,
    handleCopyToClipboard,
    handlePaginationChange,
    handleAppointmentChange,
    handleWarrantyTypeChange,
    handleRegVinNumberChange
  };
};
