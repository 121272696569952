import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";

import { useDealersLocations } from "hooks";
import { QUESTION_STATUSES } from "models";
import { PossibleQuestionStatusOptions } from "modules/LeadsDashboard/hooks";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { toDate } from "util/dateHelperFunctions";

export const QUESTION_STATUSES_DEFAULT_STATUSES: PossibleQuestionStatusOptions[] = [
  QUESTION_STATUSES.OKWithRemarks,
  QUESTION_STATUSES.Advice,
  QUESTION_STATUSES.Necessary
];

export const useDefaultSnoozedFilters = () => {
  const { selectedLocation } = useDealersLocations();

  const defaultSnoozedFilters: LeadsSnoozedFilters = {
    page: 1,
    dealer_ids: [],
    appointment_date_from: subDays(new Date(), 30),
    appointment_date_to: toDate(new Date()),
    search_term: "",
    is_lease_company: null,
    location_ids: selectedLocation?.id ? [selectedLocation.id] : [],
    customer_approved: false,
    interventions: false,
    statuses: QUESTION_STATUSES_DEFAULT_STATUSES,
    questions: null,
    tags: null,
    snooze_date_from: null,
    snooze_date_to: null,
    snooze_department_id: null,
    snooze_status_id: null,
    snooze_type_id: null,
    last_customer_ok_is_me: false
  };

  return { defaultSnoozedFilters };
};

export const useGetSnoozedFilters = () => {
  const { selectedLocation } = useDealersLocations();
  const { defaultSnoozedFilters } = useDefaultSnoozedFilters();

  const { data } = useQuery<LeadsSnoozedFilters>({
    queryKey: LeadsQueryKeys.snoozedFilters,
    queryFn: () => ({ ...defaultSnoozedFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] }),
    placeholderData: defaultSnoozedFilters,
    enabled: !!selectedLocation
  });

  return { snoozedFilters: data };
};
