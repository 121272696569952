import { useTranslation } from "react-i18next";

import { SNOOZE_STATUS, SNOOZE_TYPE } from "components";
import { useDealersLocations } from "hooks";
import { ITranslation } from "util/interfaces";

export enum SNOOZE_DEPARTMENT {
  Sales = 1,
  CCC,
  BodyRepair,
  External,
  Digital,
  Parts
}

type SnoozeTypeConfig = {
  [key in SNOOZE_TYPE]: { enabled: boolean; text: string };
};

export const useGetSnoozeTypeData = () => {
  const t = useTranslation().t as ITranslation;
  const { selectedDealer } = useDealersLocations();

  const allSnoozeTypeData = [
    { key: SNOOZE_TYPE.NextVisitCustomer, value: SNOOZE_TYPE.NextVisitCustomer, text: t("v8_next_visit_customer").message || "Next visit customer" },
    { key: SNOOZE_TYPE.MakeOffer, value: SNOOZE_TYPE.MakeOffer, text: t("v8_make_an_offer").message || "Make an offer" },
    { key: SNOOZE_TYPE.MakeNewAppointment, value: SNOOZE_TYPE.MakeNewAppointment, text: t("v8_make_new_appointment").message || "Make new appointment" },
    { key: SNOOZE_TYPE.RemindCustomer, value: SNOOZE_TYPE.RemindCustomer, text: t("v8_remind_customer").message || "Remind customer" }
  ];

  const snoozeTypeConfig: SnoozeTypeConfig = {
    [SNOOZE_TYPE.NextVisitCustomer]: {
      enabled: !!selectedDealer?.is_snooze_type_next_visit_customer_enabled,
      text: t("v8_next_visit_customer").message || "Next visit customer"
    },
    [SNOOZE_TYPE.MakeOffer]: {
      enabled: !!selectedDealer?.is_snooze_type_make_offer_enabled,
      text: t("v8_make_an_offer").message || "Make an offer"
    },
    [SNOOZE_TYPE.MakeNewAppointment]: {
      enabled: !!selectedDealer?.is_snooze_type_make_new_appointment_enabled,
      text: t("v8_make_new_appointment").message || "Make new appointment"
    },
    [SNOOZE_TYPE.RemindCustomer]: {
      enabled: !!selectedDealer?.is_snooze_type_remind_customer_enabled,
      text: t("v8_remind_customer").message || "Remind customer"
    }
  };

  const dealerSnoozeTypeData = Object.entries(snoozeTypeConfig)
    .filter(([_, config]) => config.enabled)
    .map(([type, config]) => ({ key: Number(type), value: Number(type), text: config.text }));

  return { dealerSnoozeTypeData, allSnoozeTypeData };
};

export const useGetSnoozeStatusData = () => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: SNOOZE_STATUS.New, value: SNOOZE_STATUS.New, text: t("v8_new").message || "New" },
    { key: SNOOZE_STATUS.InProgress, value: SNOOZE_STATUS.InProgress, text: t("v8_in_progress").message || "In progress" },
    { key: SNOOZE_STATUS.Approved, value: SNOOZE_STATUS.Approved, text: t("v8_approved").message || "Approved" },
    { key: SNOOZE_STATUS.Declined, value: SNOOZE_STATUS.Declined, text: t("v8_declined").message || "Declined" },
    { key: SNOOZE_STATUS.Fixed, value: SNOOZE_STATUS.Fixed, text: t("v8_fixed").message || "Fixed" },
    { key: SNOOZE_STATUS.Done, value: SNOOZE_STATUS.Done, text: t("v8_done").message || "Done" }
  ];
};

export const useGetSnoozeDepartmentData = () => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: SNOOZE_DEPARTMENT.Sales, value: SNOOZE_DEPARTMENT.Sales, text: t("v8_sales").message || "Sales" },
    { key: SNOOZE_DEPARTMENT.CCC, value: SNOOZE_DEPARTMENT.CCC, text: t("v8_ccc").message || "CCC (customer communication center)" },
    { key: SNOOZE_DEPARTMENT.BodyRepair, value: SNOOZE_DEPARTMENT.BodyRepair, text: t("v8_body_repair").message || "Body repair" },
    { key: SNOOZE_DEPARTMENT.External, value: SNOOZE_DEPARTMENT.External, text: t("v8_external").message || "External" },
    { key: SNOOZE_DEPARTMENT.Digital, value: SNOOZE_DEPARTMENT.Digital, text: t("v8_digital").message || "Digital (external crm via api)" },
    { key: SNOOZE_DEPARTMENT.Parts, value: SNOOZE_DEPARTMENT.Parts, text: t("v8_parts").message || "Parts" }
  ];
};
