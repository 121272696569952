import { endOfDay } from "date-fns";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import "components/DatePicker/DatePicker.scss";
import { CustomDatePickerInput } from "components/TableFilters/TableDatePickerInput";
import { ITranslation } from "util/interfaces";

type Props = {
  onChange: (dates: [Date | null, Date | null]) => void;
  dateStart: Date | null;
  dateEnd: Date | null;
  clearable?: boolean;
  inputClassName: string;
};

export const TableDatePicker = ({ dateStart, dateEnd, onChange, inputClassName, clearable = false }: Props) => {
  const t = useTranslation().t as ITranslation;
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([dateStart, dateEnd]);

  const [startDate, endDate] = dateRange;
  const handleClear = () => {
    setDateRange([null, null]);
    onChange([null, null]);
  };
  const handleChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);

    if (dates[1]) {
      setIsOpen(false);
      onChange([endOfDay(dates[0] ?? new Date()), endOfDay(dates[1] ?? new Date())]);
    }
  };

  return (
    <DatePicker
      calendarClassName="CalendarDatepicker"
      open={isOpen}
      selectsRange
      onInputClick={() => setIsOpen(prev => !prev)}
      onClickOutside={(e: MouseEvent) => {
        const targetElement = e.target as HTMLElement;
        if (targetElement?.closest(`.${inputClassName}`)) return;
        setIsOpen(false);
      }}
      startDate={startDate || undefined}
      endDate={endDate || undefined}
      onChange={handleChange}
      customInput={<CustomDatePickerInput inputClassName={inputClassName} />}
      selected={endDate || new Date()}
    >
      {clearable && (
        <div className="react-datepicker-clear-date-container">
          <Button
            content={t("v8_clear_date_range").message || "Clear date range"}
            icon="calendar"
            labelPosition="left"
            onClick={handleClear}
            className="react-datepicker-clear-date-button"
          />
        </div>
      )}
    </DatePicker>
  );
};
