import { Icon } from "semantic-ui-react";

import "modules/LeadsDashboard/components/SnoozeButtonIcon/SnoozeButtonIcon.scss";

type Props = {
  handleClick: () => void;
  isSnoozed: boolean;
};

export const SnoozeButtonIcon = ({ handleClick, isSnoozed }: Props) => (
  <div className={`snooze-button-card-user ${isSnoozed ? "snoozed-button-snoozed" : ""}`} onClick={handleClick}>
    <div>
      <Icon className={`clock snooze-button-card-icon ${isSnoozed ? "snoozed-button-icon-snoozed" : ""}`} />
    </div>
  </div>
);
