import { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { Appointment, ChecklistTemplate, QuestionResult } from "models";
import { parseQuestionResultAttachments } from "modules/AppointmentDetails/utils";

type TyreActionsProps = {
  data: QuestionResult;
  appointment: Appointment;
  onSelect: (data: ActionModalSelectorData) => void;
  template?: ChecklistTemplate | null;
  toggleShowMedia?: () => void;
};

const TyreActions = ({ data, template, onSelect, toggleShowMedia, appointment }: TyreActionsProps) => {
  const attachments = parseQuestionResultAttachments(data);

  const isConvertToInterventionButtonDisabled = useMemo(
    () => appointment?.interventions?.some(intervention => intervention.question_result_id === data.id),
    [appointment.interventions, data.id]
  );

  return (
    <div className="tyre-action">
      <div className="tyre-container">
        <AnswerStatusSelector data={data} />
        {!!attachments?.length && (
          <div onClick={toggleShowMedia}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{attachments.length}</small>
            </p>
          </div>
        )}
        <ActionModalSelector
          data={data}
          template={template}
          onSelect={onSelect}
          appointment={appointment}
          attachments={attachments}
          isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
        />
      </div>
    </div>
  );
};

export default TyreActions;
