import { useQueryClient } from "@tanstack/react-query";
import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { PinNotification, WebSocketMessageListener, pinStatusTranslations } from "components";
import { useRealTimeQuery } from "hooks";
import { Dealer, PinModel, User } from "models";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";
import { BackendQueryKey, QUERY_KEY_COMPONENTS, queryKeys, staticLocalKey } from "util/keyFactory";

const pinTypeNotificationContainerStyle: CSSProperties = {
  width: "400px",
  right: "80px"
};

export const useUser = () => {
  const t = useTranslation().t as ITranslation;
  const queryClient = useQueryClient();
  const userDataKey = staticLocalKey(QUERY_KEY_COMPONENTS.UserData);
  const dealersListKey = [queryKeys.dealers.list as BackendQueryKey];

  const listeners = useMemo((): WebSocketMessageListener[] => {
    return [
      {
        model: "User",
        action: "update",
        callback: message => {
          const user = queryClient.getQueryData<User>(userDataKey);
          const updatedUserData = message.data as User;

          if (!user || user.id !== updatedUserData.id) return;

          queryClient.setQueryData(userDataKey, { ...user, ...updatedUserData });
        }
      },
      {
        model: "Pin",
        action: "notification",
        callback: message => {
          const pinData = message.data as PinModel;
          if (!pinData) return;

          const user = queryClient.getQueryData<User>(userDataKey);
          const pinStatusNotificationsEnabled = user?.pin_status_notification_enabled;
          if (!pinStatusNotificationsEnabled) return;

          const dealers = queryClient.getQueryData<Dealer[] | undefined>(dealersListKey);
          const dealerLocations = dealers?.flatMap(dealer => dealer.locations || []) ?? [];
          const dealerLocation = dealerLocations.find(loc => loc.id === pinData.dealer_location_id);

          const wo = pinData?.appointment?.wo_nr;
          const statusMessage = pinData.pin_status_id
            ? t(pinStatusTranslations[pinData.pin_status_id].translation_key).message || pinStatusTranslations[pinData.pin_status_id].default_message
            : "";
          const dealer = dealers?.find(dealer => dealer.id === dealerLocation?.dealer_id)?.name || "";
          const car = (pinData.appointment && `${pinData.appointment.car_make} ${pinData.appointment.car_model}`) ?? "";

          toast(() => <PinNotification pin={pinData} location={dealerLocation?.name || ""} statusMessage={statusMessage} dealer={dealer} car={car} wo={wo} />, {
            style: pinTypeNotificationContainerStyle,
            autoClose: false,
            onClick: () => window.open(`${window.location.origin}${PATHS.APPOINTMENTS}/${pinData.appointment_id}`, "_blank")
          });
        }
      }
    ];
  }, [queryClient]);

  const realTimeQuery = useRealTimeQuery({
    queryKey: userDataKey,
    listeners
  });

  return realTimeQuery.data as User | undefined;
};
