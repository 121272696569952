import _cloneDeep from "lodash/cloneDeep";

import { DATE_FORMATS_FNS } from "components";
import { Appointment, DealerLocation, SnoozeItem } from "models";
import { SnoozeItemWithHistory } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { formatDate, isDate } from "util/dateHelperFunctions";

export const getLocationName = (locationId: number | null, locations: DealerLocation[]) => locations.find(location => location.id === locationId)?.name || "";

export const getFormattedDate = (date: string | null | undefined) => {
  if (!date) return "";

  const isValidDate = !date.startsWith("0001-01-01T00:00:00");
  if (isDate(date) || isValidDate) return formatDate(date, DATE_FORMATS_FNS.dateMonthYear);
};

export const groupWOResults = (snoozeItems: SnoozeItem[]) => {
  const clonedSnoozeItems = _cloneDeep(snoozeItems);

  clonedSnoozeItems.forEach((item, index) => {
    if (clonedSnoozeItems[index + 1] && item.appointment_id === clonedSnoozeItems[index + 1].appointment_id) {
      clonedSnoozeItems[index + 1].appointment = {
        ...new Appointment(),
        ...clonedSnoozeItems[index + 1].appointment,
        reg_number: "",
        wo_nr: "0",
        time_car_app: "",
        customer_contract: null,
        customer_driver: null,
        customer_owner: null
      };
    }
  });

  return clonedSnoozeItems;
};

export const groupSnoozeItems = (items: SnoozeItem[]) => {
  const interventions = new Map<number, SnoozeItemWithHistory>();
  const questions = new Map<number, SnoozeItemWithHistory>();

  const updateMap = (map: Map<number, SnoozeItemWithHistory>, id: number, snoozeItem: SnoozeItem) => {
    if (map.has(id)) {
      const existing = map.get(id);

      if (existing && snoozeItem.created_on > existing.created_on) {
        map.set(id, { ...snoozeItem, history: existing.history.concat(snoozeItem) });
      } else if (existing) {
        existing.history.push(snoozeItem);
        map.set(id, existing);
      }
    } else map.set(id, { ...snoozeItem, history: [snoozeItem] });
  };

  items.forEach(snoozeItem => {
    if (snoozeItem.intervention_id) {
      updateMap(interventions, snoozeItem.intervention_id, snoozeItem);
    } else if (snoozeItem.question_result_id) {
      updateMap(questions, snoozeItem.question_result_id, snoozeItem);
    }
  });

  return [...Array.from(interventions.values()), ...Array.from(questions.values())];
};

export const normalizeData = (items: SnoozeItem[]) => {
  const groupedItems = groupSnoozeItems(items);

  return groupWOResults(
    groupedItems.sort((a, b) => {
      if (a.appointment && b.appointment) return b.appointment.time_car_app > a.appointment.time_car_app ? 1 : -1;

      return 0;
    })
  );
};
