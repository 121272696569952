import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { StatusIcon, getAppointmentStatus } from "components";
import { useDealersLocations } from "hooks";
import {
  CHECK_EVENT_TYPE,
  COMMUNICATION_EVENT_TYPE,
  DESK_COMMUNICATION_EVENT_TYPE,
  KEYLOCKER_COMMUNICATION_EVENT_TYPE,
  KIOSK_COMMUNICATION_EVENT_TYPE,
  STATUS_IDENTIFIER
} from "models";
import { ACSES_EVENT_TYPE } from "models/AcsesCommunication";
import { ACTIVITY_TYPE_FILTERS } from "modules/AppointmentDetails/components/ActivityLog";
import "modules/AppointmentDetails/components/ActivityLog/components/ActivityType/ActivityType.scss";
import { ITranslation } from "util/interfaces";

export type EVENT_TYPES =
  | COMMUNICATION_EVENT_TYPE
  | CHECK_EVENT_TYPE
  | DESK_COMMUNICATION_EVENT_TYPE
  | KEYLOCKER_COMMUNICATION_EVENT_TYPE
  | KIOSK_COMMUNICATION_EVENT_TYPE
  | ACSES_EVENT_TYPE;

type ActivityTypeProps = {
  activityType: ACTIVITY_TYPE_FILTERS;
  statusIdentifier?: STATUS_IDENTIFIER;
  event_type?: EVENT_TYPES;
};

const CustomerCommunicationIconAndTitle = ({ event_type }: { event_type?: EVENT_TYPES }) => {
  const t = useTranslation().t as ITranslation;
  let title = "";

  switch (event_type) {
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInSent:
      title = t("v8_sent_online_checkin").message || "Sent online checkin";
      break;

    case COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened:
      title = t("v8_opened_online_checkin").message || "Opened online checkin";
      break;

    case COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered:
      title = t("v8_answered_online_checkin").message || "Answered online checkin";
      break;

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent:
      title = t("v8_sent_diagnose_overview").message || "Sent diagnose overview";
      break;

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened:
      title = t("v8_opened_diagnose_overview").message || "Opened diagnose overview";
      break;

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered:
      title = t("v8_answered_diagnose_overview").message || "Answered diagnose overview";
      break;

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent:
      title = t("v8_resent_diagnose_overview").message || "Resent diagnose overview";
      break;

    case COMMUNICATION_EVENT_TYPE.RepairOverviewSent:
      title = t("v8_sent_repair_overview").message || "Sent repair overview";
      break;

    case COMMUNICATION_EVENT_TYPE.RepairOverviewResent:
      title = t("v8_resent_repair_overview").message || "Resent repair overview";
      break;

    case COMMUNICATION_EVENT_TYPE.RepairOverviewOpened:
      title = t("v8_repair_overview_opened").message || "Repair overview opened";
      break;

    default:
      return null;
  }

  return (
    <div>
      <Icon className="envelope activity-step-icon-color" /> {title}
    </div>
  );
};

const CheckEventIconAndTitle = ({ event_type }: { event_type?: EVENT_TYPES }) => {
  const t = useTranslation().t as ITranslation;

  switch (event_type) {
    case CHECK_EVENT_TYPE.Started:
      return (
        <div>
          <Icon className="list ul activity-step-icon-color" /> {t("v8_checklist_started").message || "Checklist started"}
        </div>
      );
    case CHECK_EVENT_TYPE.Resumed:
      return (
        <div>
          <Icon className="circle quarter stroke activity-step-icon-color" /> {t("v8_checklist_resumed").message || "Checklist resumed"}
        </div>
      );
    case CHECK_EVENT_TYPE.Paused:
      return (
        <div>
          <Icon className="pause activity-step-icon-color" /> {t("v8_checklist_resumed").message || "Checklist paused"}
        </div>
      );
    case CHECK_EVENT_TYPE.Aborted:
      return (
        <div>
          <Icon className="xmark activity-step-icon-color" /> {t("v8_checklist_aborted").message || "Checklist aborted"}
        </div>
      );
    case CHECK_EVENT_TYPE.Completed:
      return (
        <div>
          <Icon className="list check activity-step-icon-color" /> {t("v8_checklist_completed").message || "Checklist completed"}
        </div>
      );
    default:
      return null;
  }
};

const DeskCommunicationIconAndTitle = ({ event_type }: { event_type?: EVENT_TYPES }) => {
  const t = useTranslation().t as ITranslation;
  let title = "";

  switch (event_type) {
    case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInSent:
      title = t("v8_desk_checkin_sent").message || "Desk check-in sent";
      break;

    case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInOpened:
      title = t("v8_desk_checkin_opened").message || "Desk check-in opened";
      break;

    case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInFailedToOpen:
      title = t("v8_desk_checkin_failed_to_open").message || "Desk check-in failed to open";
      break;

    case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInCanceled:
      title = t("v8_desk_checkin_canceled").message || "Desk check-in canceled";
      break;

    case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInReceived:
      title = t("v8_desk_checkin_received").message || "Desk check-in received";
      break;

    case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInAnswered:
      title = t("v8_desk_checkin_answered").message || "Desk check-in answered";
      break;

    default:
      return null;
  }

  return (
    <div>
      <Icon className="tablet activity-step-icon-color" /> {title}
    </div>
  );
};

const KeylockerCommunicationIconAndTitle = ({ event_type }: { event_type?: EVENT_TYPES }) => {
  const t = useTranslation().t as ITranslation;
  let title = "";

  switch (event_type) {
    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerCheckInOpened:
      title = t("v8_keylocker_checkin_opened").message || "Keylocker check-in opened";

      break;

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerCheckInAnswered:
      title = t("v8_keylocker_checkin_answered").message || "Keylocker check-in answered";
      break;

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerCheckInClosed:
      title = t("v8_keylocker_checkin_closed").message || "Keylocker check-in closed";
      break;

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerDropPinSent:
      title = t("v8_keylocker_drop_pin_sent").message || "Keylocker drop pin sent";
      break;

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerPickUpPinSent:
      title = t("v8_keylocker_pick_up_pin_sent").message || "Keylocker pick up pin sent";
      break;

    default:
      title = t("v8_keylocker_communication").message || "Keylocker communication";
  }

  return (
    <div>
      <Icon className="key activity-step-icon-color" /> {title}
    </div>
  );
};

const KioskCommunicationIconAndTitle = ({ event_type }: { event_type?: EVENT_TYPES }) => {
  const t = useTranslation().t as ITranslation;
  let title = "";

  switch (event_type) {
    case KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInOpened:
      title = t("v8_kiosk_checkin_opened").message || "Kiosk check-in opened";
      break;

    case KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInAnswered:
      title = t("v8_kiosk_checkin_answered").message || "Kiosk check-in answered";
      break;

    case KIOSK_COMMUNICATION_EVENT_TYPE.LabelDetached:
      title = t("v8_label_detached").message || "Label detached";
      break;

    default:
      return null;
  }

  return (
    <div>
      <Icon className="tag activity-step-icon-color" /> {title}
    </div>
  );
};

const AcsesReceiverCommunicationIconAndTitle = ({ event_type }: { event_type?: EVENT_TYPES }) => {
  const t = useTranslation().t as ITranslation;

  if (event_type === ACSES_EVENT_TYPE.AcsesEventTypeDropBack)
    return (
      <div>
        <Icon className="user activity-step-icon-color" /> {t("v8_acses_event_type_drop_back").message || "Acses event type drop back"}
      </div>
    );

  return null;
};

const AppointmentStatusIconAndTitle = ({ statusIdentifier }: { statusIdentifier?: STATUS_IDENTIFIER }) => {
  const { selectedLocation } = useDealersLocations();
  const status = getAppointmentStatus(statusIdentifier, selectedLocation?.statuses);

  return (
    <div>
      <StatusIcon status={status.identifier} className="activity-step-icon-color" /> {status?.name}
    </div>
  );
};

export const ActivityType = ({ activityType, event_type, statusIdentifier }: ActivityTypeProps) => {
  switch (activityType) {
    case ACTIVITY_TYPE_FILTERS.Appointment:
      return <AppointmentStatusIconAndTitle statusIdentifier={statusIdentifier} />;

    case ACTIVITY_TYPE_FILTERS.CheckEvent:
      return <CheckEventIconAndTitle event_type={event_type} />;

    case ACTIVITY_TYPE_FILTERS.Customer:
      return <CustomerCommunicationIconAndTitle event_type={event_type} />;

    case ACTIVITY_TYPE_FILTERS.Desk:
      return <DeskCommunicationIconAndTitle event_type={event_type} />;

    case ACTIVITY_TYPE_FILTERS.Keylocker:
      return <KeylockerCommunicationIconAndTitle event_type={event_type} />;

    case ACTIVITY_TYPE_FILTERS.Kiosk:
      return <KioskCommunicationIconAndTitle event_type={event_type} />;

    case ACTIVITY_TYPE_FILTERS.AcsesReceiver:
      return <AcsesReceiverCommunicationIconAndTitle event_type={event_type} />;

    default:
      return null;
  }
};
