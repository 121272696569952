import { Button, Icon, Popup } from "semantic-ui-react";

import "components/ExportDataModal/ExportDataModal.scss";

type Props = {
  handleOnClick: () => void;
  hasWarning: boolean;
  contentMessage?: string;
};

export const ExportDataButton = ({ handleOnClick, hasWarning, contentMessage = "" }: Props) => (
  <Popup
    content={contentMessage}
    on="hover"
    position="bottom right"
    pinned
    style={{ opacity: hasWarning ? 1 : 0 }}
    trigger={
      <Button basic icon className="ExportDataModal__toolbar-button" onClick={hasWarning ? () => {} : handleOnClick}>
        <Icon className="file export ExportDataModal__limegreen-color" />
      </Button>
    }
  />
);
