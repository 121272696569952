import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SyntheticEvent, useMemo } from "react";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useUser } from "hooks";
import { DMS, Dealer, DealerLocation } from "models";
import ApiInstance from "util/Api";
import { DealerLocationDropdownOption } from "util/interfaces";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

const MAX_DEALER_NAME_CHARS = 3;

export const useDealersLocations = (searchTerm?: string) => {
  const user = useUser();
  const queryClient = useQueryClient();
  const dealerListKey = [queryKeys.dealers.list as BackendQueryKey];

  const { data: selectedDealer }: { data: Dealer | undefined } = useQuery({ queryKey: [queryKeys.dealer.details] });
  const { data: locationId }: { data: number | undefined } = useQuery({ queryKey: [queryKeys.location.id] });
  const { data: dealers }: { data: Dealer[] | undefined } = useQuery({ queryKey: dealerListKey });

  const userNotFound = () => {
    toast.error("User not found");
  };

  const fetchLocation = async (id: number): Promise<Dealer[]> => {
    if (!id) throw new Error("No selected location");
    const response = await ApiInstance.post(`/locations/details`, { id }, ENV.dealerBaseUrl);
    return response.data;
  };

  const locationMutation = useMutation({
    mutationFn: fetchLocation,
    onSuccess: (data, selectedLocationId) => {
      queryClient.setQueryData([{ ...queryKeys.location.details, params: { id: selectedLocationId } }], data);
      queryClient.setQueryData([queryKeys.location.id], selectedLocationId);
    },
    onMutate: () => {
      const previousLocationId = queryClient.getQueryData<number>([queryKeys.location.id]);
      return { previousLocationId };
    },
    onError: (error, _selectedLocationId, context) => {
      const previousLocationId = context?.previousLocationId;
      if (previousLocationId) queryClient.setQueryData([queryKeys.location.id], previousLocationId);
      console.error(error);
    }
  });

  const fetchDealers = async (queryKey: BackendQueryKey[]): Promise<Dealer[]> => {
    const [{ endpoint, params, baseUrl }] = queryKey;
    const response = await ApiInstance.post(`/${endpoint}`, params, baseUrl);
    return response.data.dealers;
  };

  const locations = useMemo(() => {
    if (dealers) return dealers.flatMap((dealer: Dealer) => dealer.locations || []);
    return [];
  }, [dealers]);

  const dealerMutation = useMutation({
    mutationFn: () => fetchDealers(dealerListKey),
    onSuccess: data => {
      if (user) {
        const dealerPreferenceID = Number(ApiInstance.dealerId || user.dealer_id);
        const locationPreferenceID = Number(ApiInstance.locationId || user.dealer_location_id);

        const dealer = data.find((d: Dealer) => d.id === dealerPreferenceID);

        queryClient.setQueryData(dealerListKey, data);
        queryClient.setQueryData([queryKeys.dealer.details], dealer);
        queryClient.setQueryData([queryKeys.dealer.id], dealerPreferenceID);
        queryClient.setQueryData([queryKeys.location.id], locationPreferenceID);
        locationMutation.mutate(locationPreferenceID);
      } else {
        userNotFound();
      }
    }
  });

  const { data: selectedLocation }: { data: DealerLocation | undefined } = useQuery({
    queryKey: [{ ...queryKeys.location.details, params: { id: locationId } }]
  });

  const isFeatureEnabled = (feature: string | null) => {
    if (!feature || !selectedLocation) return false;

    const VERSION = selectedLocation.version?.features || [];
    const vI = VERSION.findIndex(v => v.name === feature);
    return vI > -1 && VERSION[vI].active;
  };

  const dealerAndLocationsGroupedOptions = useMemo(() => {
    const options: DealerLocationDropdownOption[] = [];

    const activeDealers = dealers?.filter(dealer => dealer.active);
    if (!activeDealers?.length) return options;

    const lowerCaseSearchTerm = searchTerm?.toLowerCase();

    activeDealers
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach(dealer => {
        const { name: dealerName, id: dealerId } = dealer;

        const activeLocations = dealer.locations?.filter(location => location.active) || [];
        const isDealerMatch = lowerCaseSearchTerm ? dealerName.toLowerCase().includes(lowerCaseSearchTerm) : true;
        const filteredLocations = lowerCaseSearchTerm ? activeLocations.filter(location => location.name.toLowerCase().includes(lowerCaseSearchTerm)) : activeLocations;

        if (isDealerMatch) {
          options.push({
            icon: "house",
            disabled: true,
            key: `dealer-${dealerId}`,
            value: `dealer-${dealerId}`,
            labelText: dealerName,
            text: dealerName
          });

          activeLocations
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((loc, index) => {
              options.push({
                icon: "location dot regular",
                text: loc.name,
                labelText: `${dealerName.substring(0, MAX_DEALER_NAME_CHARS)} - ${loc.name}`,
                key: `location-${loc.id}`,
                value: loc.id.toString(),
                disabled: false,
                dealer_id: `dealer-${dealerId}`,
                isLastLocation: index === activeLocations.length - 1
              });
            });
        } else if (filteredLocations.length > 0) {
          options.push({
            icon: "house",
            disabled: true,
            key: `dealer-${dealerId}`,
            value: `dealer-${dealerId}`,
            labelText: dealerName,
            text: dealerName
          });

          filteredLocations
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((loc, index) => {
              options.push({
                icon: "location dot regular",
                text: loc.name,
                labelText: `${dealerName.substring(0, MAX_DEALER_NAME_CHARS)} - ${loc.name}`,
                key: `location-${loc.id}`,
                value: loc.id.toString(),
                disabled: false,
                dealer_id: `dealer-${dealerId}`,
                isLastLocation: index === filteredLocations.length - 1
              });
            });
        }
      });

    return options;
  }, [dealers, searchTerm]);

  const handleOnLocationChange = async (_: SyntheticEvent, data: DealerLocationDropdownOption) => {
    if (!data.value) return;

    const location_id = Number(data.value);
    const location = locations.find(loc => loc.id === location_id);
    if (!location) return;

    ApiInstance.locationId = location_id.toString();
    await locationMutation.mutateAsync(Number(location_id));

    const dealers = queryClient.getQueryData<Dealer[] | undefined>(dealerListKey);
    const dealerId = Number(location?.dealer_id);
    const dealer = dealers?.find(d => dealerId === d.id);
    if (!dealer) return;

    ApiInstance.dealerId = dealerId.toString();
    queryClient.setQueryData([queryKeys.dealer.details], dealer);
    queryClient.setQueryData([queryKeys.dealer.id], dealerId);
  };

  const isKeyLoopLocation = selectedLocation?.dms_id && [DMS.KeyLoopJobs, DMS.KeyLoopMenus].includes(selectedLocation.dms_id);
  const isNextLaneLocation = selectedLocation?.dms_id && DMS.NextLane === selectedLocation.dms_id;
  const isWincarLocation = selectedLocation?.dms_id && DMS.WincarApi === selectedLocation.dms_id;

  return {
    dealerMutation,
    locationMutation,
    selectedDealer,
    selectedLocation,
    dealers: dealers || [],
    locations,
    dealerAndLocationsGroupedOptions,
    handleOnLocationChange,
    isFeatureEnabled,
    isKeyLoopLocation,
    isNextLaneLocation,
    isWincarLocation
  };
};
