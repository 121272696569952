import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { useGetLeaseCompanyOptions } from "modules/LeadsDashboard/hooks";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

type CarsTableFiltersHandlersProps = {
  pageSize: number;
};

export const useCarsTableFiltersHandlers = ({ pageSize }: CarsTableFiltersHandlersProps) => {
  const queryClient = useQueryClient();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const { updateFilters } = useGetFilterUpdaterFunctions<LeadsCarsFilter>({ queryKey: LeadsQueryKeys.carsFilters });
  const carsFilters = queryClient.getQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters);

  const currentPage = carsFilters?.page ?? 1;
  const isDateRangeNotSet = !(carsFilters?.date_from && carsFilters?.date_to);
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === carsFilters?.is_lease_company)?.value || "";

  const onPageChange = (data: { activePage: number }) => updateFilters({ page: data.activePage }, false);
  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange({ activePage: newState.pageIndex + 1 });
  };

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    const selectedLeaseCompanyValue = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    updateFilters({ is_lease_company: selectedLeaseCompanyValue ? selectedLeaseCompanyValue.filterValue : selectedLeaseCompanyValue });
  };

  return { isDateRangeNotSet, handleOnLeaseSelect, selectedLeaseCompanyOption, leaseCompanyOptions, currentPage, handlePaginationSelect };
};
