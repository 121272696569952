import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import "components/Calendar/Calendar.scss";
import { getFormattedDateRange, useTimePeriodDropdown } from "modules/WarrantyDashboard/components/WarrantyDashboardFilters/components/TimePeriodDropdown/hooks";
import "modules/WarrantyDashboard/components/WarrantyDashboardFilters/components/TimePeriodDropdown/TimePeriodDropdown.scss";
import { WARRANTY_TIME_PERIODS, useGetTimePeriodOptions } from "modules/WarrantyDashboard/hooks";
import { ITranslation } from "util/interfaces";

const CustomDatePickerInput = forwardRef<HTMLButtonElement>(() => null);
CustomDatePickerInput.displayName = "CustomDatePickerInput";

type TimePeriodDropdownProps = {
  value: WARRANTY_TIME_PERIODS;
  handleTimePeriodChange: (value: WARRANTY_TIME_PERIODS) => void;
  handleDateChange: (dates: [Date | null, Date | null]) => void;
};

export const TimePeriodDropdown = ({ handleTimePeriodChange, value, handleDateChange }: TimePeriodDropdownProps) => {
  const t = useTranslation().t as ITranslation;
  const timePeriodOptions = useGetTimePeriodOptions();
  const { show, startDate, datePickerRef, endDate, handleOpenDatePicker, onDateChange, handleCloseDatePicker, updatedTimePeriodOptions, currentTimePeriodLabel } =
    useTimePeriodDropdown({ handleDateChange, selectedWarrantyTimePeriod: value });

  return (
    <div className="TimePeriodDropdown">
      <Dropdown selection fluid value={value} placeholder={t("v8_time_period").message || "Time period"} options={timePeriodOptions} text={currentTimePeriodLabel}>
        <Dropdown.Menu>
          {updatedTimePeriodOptions.map(option => (
            <Dropdown.Item
              {...option}
              key={option.key}
              selected={value === option.value}
              className={`${value === option.value ? "bold-text" : ""}`}
              onClick={(_, { value }) => {
                handleTimePeriodChange(value as WARRANTY_TIME_PERIODS);
                option.value === WARRANTY_TIME_PERIODS.CustomTimePeriod && handleOpenDatePicker();
              }}
            >
              <p className="ellipsis">
                {option.text}{" "}
                {value === WARRANTY_TIME_PERIODS.CustomTimePeriod && option.value === WARRANTY_TIME_PERIODS.CustomTimePeriod && getFormattedDateRange(startDate, endDate)}
              </p>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div className="no-size">
        <ReactDatePicker
          calendarClassName="CalendarDatepicker"
          ref={datePickerRef}
          selectsRange={true}
          withPortal={true}
          startDate={startDate ?? undefined}
          endDate={endDate ?? undefined}
          customInput={<CustomDatePickerInput />}
          onChange={dates => onDateChange(dates)}
          open={show}
          todayButton={<span>{t("v8_today").message || "Today"}</span>}
          showMonthDropdown
          showYearDropdown
          onClickOutside={handleCloseDatePicker}
        />
      </div>
    </div>
  );
};
