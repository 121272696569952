import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { ANSWER_STATUS_SELECTOR_TYPE } from "components";
import { AppointmentHistorySnoozedItems, Intervention, QuestionResult, SnoozeItem } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export type AppointmentHistorySnoozeItemsParams = {
  appointment_id: number;
  page: number;
};

const groupAppointmentHistorySnoozeItems = (snoozeItems: SnoozeItem[]) => {
  if (!snoozeItems?.length) return [];

  const interventions = new Map<number, SnoozeItem>();
  const questions = new Map<number, SnoozeItem>();

  snoozeItems
    .sort((item1, item2) => item2.created_on.localeCompare(item1.created_on))
    .forEach(item => {
      if (item.intervention_id) {
        const existing = interventions.get(item.intervention_id);
        if (existing) {
          questions.set(item.intervention_id, {
            ...existing,
            intervention: { ...item.intervention, snooze_history: [...(existing.intervention?.snooze_history ?? []), item] } as Intervention
          });
        } else {
          interventions.set(item.intervention_id, { ...item, intervention: { ...item.intervention, snooze_history: [item] } as Intervention });
        }
      }

      if (item.question_result_id) {
        const existing = questions.get(item.question_result_id);
        if (existing) {
          questions.set(item.question_result_id, {
            ...existing,
            question_result: { ...item.question_result, snooze_history: [...(existing.question_result?.snooze_history ?? []), item] } as QuestionResult
          });
        } else {
          questions.set(item.question_result_id, { ...item, question_result: { ...item.question_result, snooze_history: [item] } as QuestionResult });
        }
      }
    });

  return [...interventions.values(), ...questions.values()].sort((a: SnoozeItem, b: SnoozeItem) =>
    (b.appointment?.time_car_app ?? "") > (a.appointment?.time_car_app ?? "") ? 1 : -1
  );
};

export const useAppointmentHistorySnoozeItems = (appointment_id: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();

  const updateAnswerStatus = async (id: number, status: number, type: ANSWER_STATUS_SELECTOR_TYPE) => {
    const appointmentHistorySnoozeItemsKey = AppointmentDetailsKeys.appointmentHistorySnoozeItems({ appointment_id, page: currentPage });

    const appointmentHistorySnoozeItems = queryClient.getQueryData<AppointmentHistorySnoozedItems>(appointmentHistorySnoozeItemsKey);
    if (!appointmentHistorySnoozeItems?.items?.length) return;

    if (type === ANSWER_STATUS_SELECTOR_TYPE.questionResult) {
      const snoozeItemIdx = appointmentHistorySnoozeItems.items.findIndex(i => i.question_result_id === id);
      if (snoozeItemIdx < 0) return;

      const snoozeItem = appointmentHistorySnoozeItems.items[snoozeItemIdx];
      queryClient.setQueryData(appointmentHistorySnoozeItemsKey, {
        ...appointmentHistorySnoozeItems,
        items: appointmentHistorySnoozeItems.items.with(snoozeItemIdx, { ...snoozeItem, question_result: { ...snoozeItem.question_result, status } as QuestionResult })
      });
      return;
    }

    const snoozeItemIdx = appointmentHistorySnoozeItems.items.findIndex(i => i.intervention_id === id);
    if (snoozeItemIdx < 0) return;

    const snoozeItem = appointmentHistorySnoozeItems.items[snoozeItemIdx];
    queryClient.setQueryData(appointmentHistorySnoozeItemsKey, {
      ...appointmentHistorySnoozeItems,
      items: appointmentHistorySnoozeItems.items.with(snoozeItemIdx, { ...snoozeItem, intervention: { ...snoozeItem.intervention, status } as Intervention })
    });
  };

  const updateAppointmentHistorySnoozeItems = async (data: Partial<SnoozeItem>) => {
    const appointmentHistorySnoozeItemsKey = AppointmentDetailsKeys.appointmentHistorySnoozeItems({ appointment_id, page: currentPage });

    const appointmentHistorySnoozeItems = queryClient.getQueryData<AppointmentHistorySnoozedItems>(appointmentHistorySnoozeItemsKey);
    if (!appointmentHistorySnoozeItems?.items?.length) return;

    const snoozeItemIdx = appointmentHistorySnoozeItems.items.findIndex(i => i.question_result_id === data.question_result_id);
    if (snoozeItemIdx < 0) return;

    const snoozeItem = appointmentHistorySnoozeItems.items[snoozeItemIdx];

    queryClient.setQueryData(appointmentHistorySnoozeItemsKey, {
      ...appointmentHistorySnoozeItems,
      items: appointmentHistorySnoozeItems.items.with(snoozeItemIdx, {
        ...snoozeItem,
        question_result: {
          ...snoozeItem.question_result,
          snooze_history: [...(snoozeItem.question_result?.snooze_history ?? []), { ...data }]
        } as QuestionResult,
        ...data
      })
    });
  };

  const getAppointmentHistorySnoozeItems = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);
    return {
      ...res.data,
      items: groupAppointmentHistorySnoozeItems(res.data.items)
    } as AppointmentHistorySnoozedItems;
  };

  const query = useQuery({
    queryKey: AppointmentDetailsKeys.appointmentHistorySnoozeItems({ appointment_id, page: currentPage }),
    queryFn: getAppointmentHistorySnoozeItems
  });

  return {
    currentPage,
    setCurrentPage,
    appointmentHistorySnoozeItems: query.data,
    loading: query.isFetching,
    error: query.error,
    updateAnswerStatus,
    updateAppointmentHistorySnoozeItems
  };
};
