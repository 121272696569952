import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DealersLocationsDropdownValue } from "components";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { uploadFile, useCreateDocumentationMtn, useUpdateDocumentationMtn } from "modules/DealerDocumentations/service";
import { ITranslation } from "util/interfaces";

type FileInputEvent = React.ChangeEvent<HTMLInputElement> | React.DragEvent;

export const useAddEditDealerDocumentationModal = (documentation: DealerDocumentation) => {
  const t = useTranslation().t as ITranslation;
  const { mutate: createDocumentationMutation } = useCreateDocumentationMtn();
  const { mutate: updateDocumentationMutation } = useUpdateDocumentationMtn();
  const { handleSetShowAddEditDealerDocumentationModal } = useDealerDocumentationContext();

  const [url, setUrl] = useState(documentation.url);
  const [title, setTitle] = useState(documentation.title);
  const [content, setContent] = useState(documentation.content);
  const [isPinned, setIsPinned] = useState(documentation.is_pinned);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [visibleToMechanic, setVisibleToMechanic] = useState(documentation.visible_to_mechanic);
  const [publishDate, setPublishDate] = useState<Date | null>(documentation.publish_date as Date);
  const [categoryId, setCategoryId] = useState<DOCUMENTATION_CATEGORIES>(documentation.category_id);
  const [selectedDealersLocations, setSelectedDealersLocations] = useState<DealersLocationsDropdownValue>({
    dealerIds: documentation.dealer_ids ? documentation.dealer_ids : [],
    locationIds: documentation.dealer_location_ids ? documentation.dealer_location_ids : []
  });

  const closeModal = () => handleSetShowAddEditDealerDocumentationModal(null);

  const getFileFromEvent = (e: FileInputEvent): File | null => {
    if (e.type === "drop") {
      e.preventDefault();
      return (e as React.DragEvent).dataTransfer.files[0] || null;
    }
    if (e.type === "change") return (e as React.ChangeEvent<HTMLInputElement>).target.files?.[0] || null;

    return null;
  };

  const onFileDragOver = (e: React.SyntheticEvent) => e.preventDefault();
  const handleTitleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
  const onFileChange = async (event: FileInputEvent) => {
    const file = getFileFromEvent(event);
    if (!file) return;

    const result = await uploadFile(file);
    setUrl(result);
  };

  const handleSave = () => {
    if (!title || !content || !categoryId || !selectedDealersLocations || !publishDate)
      return toast.error(t("v8_input_mandatory_fields_dealer_docs").message || "Input all mandatory fields in order to save the document");

    const saveMutationFn = documentation.id ? updateDocumentationMutation : createDocumentationMutation;
    saveMutationFn(
      {
        url,
        title,
        content,
        is_pinned: isPinned,
        id: documentation.id,
        category_id: categoryId,
        publish_date: publishDate,
        visible_to_mechanic: visibleToMechanic,
        visible_to_all: documentation.visible_to_all,
        dealer_ids: selectedDealersLocations.dealerIds,
        dealer_location_ids: selectedDealersLocations.locationIds
      },
      { onSuccess: closeModal }
    );
  };

  return {
    url,
    title,
    content,
    isPinned,
    categoryId,
    publishDate,
    visibleToMechanic,
    showDeleteConfirmation,
    selectedDealersLocations,
    setUrl,
    closeModal,
    setContent,
    handleSave,
    setIsPinned,
    onFileChange,
    setCategoryId,
    onFileDragOver,
    setPublishDate,
    setVisibleToMechanic,
    handleTitleInputChange,
    setShowDeleteConfirmation,
    setSelectedDealersLocations
  };
};
