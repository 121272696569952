import { useState } from "react";

import { useDealersLocations } from "hooks";
import { DealerInvoiceDisabledMessage, DealerLicenseNavbar, DealerLicenseTabs } from "modules/DealerLicenseOverview/components";
import { useDealerItems, useDealerLicenses } from "modules/DealerLicenseOverview/service";
import { InvoiceModal } from "modules/LocationLicenseOverview/components";
import "modules/LocationLicenseOverview/LocationLicenseOverview.scss";
import { HistoricalInvoice } from "modules/LocationLicenseOverview/types";

const DealerLicenseOverview = () => {
  const [invoicePDF, setInvoicePDF] = useState<Blob | null>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<HistoricalInvoice | null>(null);

  const { selectedDealer } = useDealersLocations();
  const { dealerItems } = useDealerItems();
  const { dealerLicenses, isLoadingDealerLicenses } = useDealerLicenses();

  const isDealerLevelInvoicingEnabled = !!(selectedDealer?.is_exact_invoicing_enabled && selectedDealer?.exact_account_id);

  const handleCloseInvoiceModal = () => {
    setInvoicePDF(null);
    setSelectedInvoice(null);
  };

  return (
    <>
      <div className="LocationLicenseOverview mt-20">
        <DealerLicenseNavbar items={dealerItems} licensesData={dealerLicenses} isDealerLevelInvoicingEnabled={isDealerLevelInvoicingEnabled} />
        {isDealerLevelInvoicingEnabled ? (
          <DealerLicenseTabs
            dealerItems={dealerItems}
            dealerLicenses={dealerLicenses}
            isLoadingDealerLicenses={isLoadingDealerLicenses}
            setInvoicePDF={setInvoicePDF}
            setSelectedInvoice={setSelectedInvoice}
          />
        ) : (
          <DealerInvoiceDisabledMessage />
        )}
      </div>
      <InvoiceModal isOpen={!!(selectedInvoice && invoicePDF)} onClose={handleCloseInvoiceModal} invoice={selectedInvoice as HistoricalInvoice} invoicePDF={invoicePDF} />
    </>
  );
};

export default DealerLicenseOverview;
