import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";

import { ACL, Brand, Dealer, DealerLocation } from "models";
import ApiLoadable from "models/ApiLoadable";

export class User extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  first_name: string = "";
  last_name: string = "";
  email: string = "";
  username: string = "";
  mobile: string = "";
  phone: string = "";
  birthday?: string;
  last_active?: string;
  address1: string = "";
  address2: string = "";
  channel_token: string = "";
  client_id: string = "";
  last_password_change?: string;
  google_id: string = "";
  status: number = 0;
  apk_version: string = "";
  location_column_visible: boolean = false;
  multi_device_login_enabled: boolean = false;
  profile_picture: string = "";
  language_code: string = "";
  my_locations?: number[];
  dealer_id: number = 0;
  dealer_location_id: number = 0;
  role_id: number = 0;
  zoho_id: string = "";
  is_admin: boolean = false;
  is_available: boolean = false;
  landing_page_id: LANDING_PAGE_ID | null = null;
  shop_status_same_day_filter_enabled: boolean = false;
  shop_status_next_day_filter_enabled: boolean = false;
  shop_status_to_order_filter_enabled: boolean = false;
  shop_status_backorder_filter_enabled: boolean = false;
  shop_status_arrived_parts_filter_enabled: boolean = false;
  is_counter_tablet_user: boolean = false;
  list_appointments_by_scheduled_range: boolean = false;
  pin_status_notification_enabled: boolean = false;
  tyre_ordering_enabled: boolean = false;
  counter_tablet_key?: string;
  dealers?: Dealer[];
  notification_element_ids?: number[];
  dealer_ids?: number[];
  location_ids?: number[];
  dealer_location?: DealerLocation;
  device_id?: number;
  role_name?: string;
  dpo: boolean = false;
  roleACLs: Record<string, ACL[]> | null = null;
  acl: ACL[] | null = null;
  brands?: Brand[];
  csv_download?: boolean = false;
  working_on: number = 0;

  static roleACLs(acl: ACL[] | null): Record<string, ACL[]> {
    if (!acl) return {};
    return _groupBy(_sortBy(acl, "resource"), "resource");
  }
}

export class NetworkMetrics extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  ping_claire: number = 0;
  ping_outside: number = 0;
  delay_claire: number = 0;
  delay_outside: number = 0;
  wifi_strength: number = 0;
  wifi_name: string = "";
  user_id: number = 0;
  dealer_location_id: number = 0;
  acl: ACL[] = [];
}

export enum USER_STATUS_ID {
  UserActive = 1,
  UserInactive,
  UserDeleted,
  UserArchived
}

export class AuthUser extends ApiLoadable {
  fullname: string = "";
  private _brands?: Brand[] | null;

  get brands(): Brand[] {
    return this._brands ? this._brands : [];
  }

  set brands(newValue: Brand[] | undefined | null) {
    this._brands = newValue;
  }
}

export enum LANDING_PAGE_ID {
  Main = 1,
  LeadDashboard,
  WarrantyDashboard,
  ManufacturerDashboard,
  Dayplanner
}

export class LandingPage extends ApiLoadable {
  id: LANDING_PAGE_ID = LANDING_PAGE_ID.Main;
  name: string = "";
}
