import { useQuery } from "@tanstack/react-query";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { useAppointmentHeaderDropdownOptions } from "modules/Appointments/components/AppointmentTable/hooks";
import { queryKeys } from "util/keyFactory";

type ColumnDataSelectorType = {
  selectedColumn: string | string[];
  column: string;
  multiple?: boolean;
  setSelectedColumn: (value: string) => void;
};

export type ColumnDataSelectorChildMethods = {
  toggleDropdown: () => void;
};

export const ColumnDataSelector = forwardRef<ColumnDataSelectorChildMethods, ColumnDataSelectorType>(
  ({ selectedColumn, multiple = false, column, setSelectedColumn }, ref) => {
    const { data } = useQuery({ queryKey: [queryKeys.appointments.carMakes] });
    const { selectedDealer } = useDealersLocations();

    const dropdownOptions = useAppointmentHeaderDropdownOptions(data as string[], selectedDealer?.enable_car_ready);
    type DropdownOptionType = Record<string, string>;

    const [open, setOpen] = useState<boolean>(false);

    const toggleFilterDropdown = () => {
      setOpen(p => !p);
    };

    useImperativeHandle(ref, () => ({
      toggleDropdown: toggleFilterDropdown
    }));

    const DropdownOptionKey = column as keyof typeof dropdownOptions;

    const handleSelection = (selectedValue: string): boolean => {
      if (Array.isArray(selectedColumn)) {
        return selectedValue ? selectedColumn.includes(selectedValue) : selectedColumn.length === 0;
      }
      return selectedValue === selectedColumn;
    };

    return (
      <div className="appointment-table-flex divider" onClick={toggleFilterDropdown}>
        <Dropdown multiple={multiple} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} icon="caret down" className="appointment-table-dd-margin">
          <Dropdown.Menu>
            {dropdownOptions[DropdownOptionKey]?.map((option: DropdownOptionType, index: number) => {
              return (
                <Dropdown.Item
                  selected={handleSelection(option.value)}
                  key={`filter-appointment-table-${index}`}
                  onClick={() => {
                    setOpen(false);
                    setSelectedColumn(option.key);
                  }}
                >
                  <div className="last-user-dropdown-selection">
                    <p className="text">{option.text}</p>
                    {option.icon && <Icon className={option.icon} />}
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
);

ColumnDataSelector.displayName = "ColumnDataSelector";
