import { useTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";

import { NOTE_TYPES } from "models";
import { SortableItems, SortableList } from "modules/NoteSuggestions/components";
import "modules/NoteSuggestions/components/NotesSuggestionModal/NoteSuggestionsModal.scss";
import { noteTypeLookup, useNoteSuggestionsModal } from "modules/NoteSuggestions/hooks";
import { NoteTypeWithSuggestion } from "modules/NoteSuggestions/service";
import { ITranslation } from "util/interfaces";

export type NoteModalProps = {
  modalId: NOTE_TYPES | null;
  closeModal: () => void;
  notes: NoteTypeWithSuggestion[];
};

export const NoteSuggestionsModal = ({ modalId, closeModal, notes }: NoteModalProps) => {
  const t = useTranslation().t as ITranslation;
  const matchingNoteData = modalId ? noteTypeLookup[modalId] : null;
  const { items, handleOnSave, handleVisibleInNote, handleAddItem, handleRemoveItem, handleContentEdit, noteVisibility, setItems } = useNoteSuggestionsModal({
    modalId,
    closeModal,
    notes
  });

  const resortNotesSuggestions = (items: SortableItems[]) => {
    const sortedItems = items.map((item, index) => ({ ...item, order: index + 1 }));
    setItems(sortedItems);
  };

  if (!matchingNoteData) return null;

  return (
    <Modal open={!!modalId} className="note-suggestions-modal">
      <Modal.Header>
        <div className="noteSuggestionHeader">
          <Icon className={`${matchingNoteData.icon} green`} />
          <h3>{t(matchingNoteData.translation_key).message || matchingNoteData.default_message}</h3>
        </div>
      </Modal.Header>
      <Modal.Content>
        <SortableList
          items={items}
          onChange={resortNotesSuggestions}
          renderItem={item => (
            <SortableList.Item {...item}>
              <div className="input-wrapper">
                <input type="text" defaultValue={item.content} onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleContentEdit(item.id, e.target.value)} />
                <SortableList.DragHandle id={item.id} />
              </div>
              <Button onClick={() => handleRemoveItem(item.id)} className="delete-note-suggestion">
                <Icon className="trash red" />
              </Button>
            </SortableList.Item>
          )}
        />
        <div className="note-controls">
          <div onClick={handleAddItem} className="add-note-button">
            <div className="plus-wrapper">
              <Icon className="plus" />
            </div>
            <span>{t("v8_add_answer").message || "Add answer"}</span>
          </div>
          <div className="note-toggle-wrapper">
            <label htmlFor="note-visibility">
              <b>{t("v8_visible_in_note").message || "Visible in note"}</b>
            </label>
            <Checkbox name="note-visibility" toggle onChange={handleVisibleInNote} checked={noteVisibility} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className="note-modal-actions">
        <Button onClick={closeModal} basic>
          <span>{t("v8_cancel").message || "Cancel"}</span>
          <Icon className="xmark" />
        </Button>
        <Button onClick={handleOnSave} color="green">
          <span>{t("v8_save").message || "Save"}</span>
          <Icon className="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
