import { Button, Icon } from "semantic-ui-react";

import "components/ToolbarFilters/ResetFiltersButton/ResetFiltersButton.scss";

type Props = {
  handleReset: () => void;
};

export const ResetFiltersButton = ({ handleReset }: Props) => (
  <Button basic icon onClick={handleReset} className="ResetFiltersButton__toolbar-button">
    <div className="ResetFiltersButton__reset-button-icons-container">
      <Icon className="filter ResetFiltersButton__limegreen-color" />
      <Icon className="circle xmark ResetFiltersButton__limegreen-color ResetFiltersButton__reset-button-icon-circle-xmark" />
    </div>
  </Button>
);
