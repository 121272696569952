import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, GridColumn, Icon, Message, Form as SemanticForm } from "semantic-ui-react";

import { FormCheckbox, FormDropdown, FormInput, FormTextArea } from "components";
import { Appointment, Intervention, InterventionAttachment, PIN_TYPE } from "models";
import { PinType } from "modules/AppointmentDetails/components";
import { DropdownOption, NoteAttachments } from "modules/AppointmentDetails/components/Interventions/components";
import { usePinCategoryForm } from "modules/AppointmentDetails/components/Interventions/components/PinCategory/components/PinCategoryForm/usePinCategoryForm";
import { ITranslation } from "util/interfaces";

type PinOrderDataType = {
  key: string;
  text: string | undefined;
  value: number;
  image: JSX.Element;
};

type PinCategoryFormProps = {
  pinType: PinType;
  data: Intervention;
  hasChanges: boolean;
  pinStatusData: DropdownOption[];
  appointment: Appointment | null;
  pinOrderData: PinOrderDataType[];
  pinWarrantyData: DropdownOption[];
  attachments: InterventionAttachment[];
  openModal: () => void;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  setAttachments: Dispatch<SetStateAction<InterventionAttachment[]>>;
};

export type PinCategoryFormType = {
  supportNr: string | number;
  claimNr: string | number;
  refNr: string | number;
  keepParts: boolean;
  orderStatus: number | null;
  pinWarrantyType: number | null;
  pinStatus: number | null;
  noteSuggestion: number | null;
  note: string;
  important: boolean;
};

export const PinCategoryForm = ({
  data,
  pinType,
  hasChanges,
  appointment,
  attachments,
  pinOrderData,
  pinStatusData,
  pinWarrantyData,
  openModal,
  setHasChanges,
  setAttachments
}: PinCategoryFormProps) => {
  const t = useTranslation().t as ITranslation;
  const { isButtonDisabled, noteSuggestionOptions, orderStatus, isNoteRequired, handleKeepPartsCheckboxChange, handleNoteSuggestionDropdownChange } = usePinCategoryForm({
    pinType,
    hasChanges,
    appointment,
    attachments,
    setHasChanges,
    setAttachments
  });

  return (
    <>
      <Grid columns={16}>
        <GridColumn width={5}>
          <SemanticForm.Field>
            <label>{t("v8_support").message || "Support"} #</label>
            <FormInput<PinCategoryFormType> name="supportNr" inputProps={{ fluid: true }} />
          </SemanticForm.Field>
        </GridColumn>

        <GridColumn width={5}>
          <SemanticForm.Field>
            <label>{t("v8_claim").message || "Claim"} #</label>
            <FormInput<PinCategoryFormType> name="claimNr" inputProps={{ fluid: true }} />
          </SemanticForm.Field>
        </GridColumn>

        <GridColumn width={4}>
          <SemanticForm.Field>
            <label>{t("v8_ref").message || "Ref"} #</label>
            <FormInput<PinCategoryFormType> name="refNr" inputProps={{ fluid: true }} />
          </SemanticForm.Field>
        </GridColumn>
        <GridColumn width={2} verticalAlign="bottom">
          <SemanticForm.Field>
            <div className="checkbox-container">
              <Icon className="setting" color="grey" />
              <span>{t("v8_keep_parts").message || "Keep Parts"}</span>
              <FormCheckbox<PinCategoryFormType>
                name="keepParts"
                formFieldProps={{ className: "checkbox-form-field" }}
                checkboxProps={{ className: "checkbox-box" }}
                onChange={value => handleKeepPartsCheckboxChange(value as boolean)}
              />
            </div>
          </SemanticForm.Field>
        </GridColumn>
      </Grid>

      <Grid columns={3}>
        <GridColumn>
          <SemanticForm.Field>
            <label>{t("v8_order_status").message || "Order status"}</label>
            <FormDropdown<PinCategoryFormType>
              name="orderStatus"
              dropdownProps={{
                fluid: true,
                options: pinOrderData,
                placeholder: t("v8_select_order_status").message || "Select order status",
                clearable: !!orderStatus
              }}
            />
          </SemanticForm.Field>
        </GridColumn>

        {pinType.value === PIN_TYPE.Warranty && (
          <GridColumn>
            <SemanticForm.Field>
              <label>{t("v8_warranty_type").message || "Warranty type"}</label>
              <FormDropdown<PinCategoryFormType>
                name="pinWarrantyType"
                dropdownProps={{
                  fluid: true,
                  options: pinWarrantyData,
                  placeholder: t("v8_select_type").message || "Select type"
                }}
              />
            </SemanticForm.Field>
          </GridColumn>
        )}
        {pinType.value !== PIN_TYPE.Remarks && (
          <GridColumn>
            <SemanticForm.Field>
              <label>{t("v8_pin_status").message || "Pin Status"}</label>
              <FormDropdown<PinCategoryFormType>
                name="pinStatus"
                dropdownProps={{
                  fluid: true,
                  options: pinStatusData,
                  placeholder: t("v8_select_status").message || "Select status"
                }}
              />
            </SemanticForm.Field>
          </GridColumn>
        )}
      </Grid>

      <Grid columns={16}>
        <GridColumn width={14}>
          {noteSuggestionOptions.length > 0 && (
            <SemanticForm.Field>
              <label>{t("v8_note").message || "Note"}</label>
              <FormDropdown<PinCategoryFormType>
                name="noteSuggestion"
                dropdownProps={{ fluid: true, options: noteSuggestionOptions, placeholder: t("choose_answer").message || "Choose answer" }}
                onChange={value => handleNoteSuggestionDropdownChange(value as number)}
              />
            </SemanticForm.Field>
          )}
        </GridColumn>

        <GridColumn width={2} verticalAlign="bottom">
          <div className="checkbox-container">
            <Icon className="circle exclamation" color="red" />
            <span>{t("v8_important").message || "Important"}</span>
            <FormCheckbox<PinCategoryFormType> name="important" formFieldProps={{ className: "checkbox-form-field" }} checkboxProps={{ className: "checkbox-box" }} />
          </div>
        </GridColumn>
      </Grid>

      <Grid>
        <GridColumn>
          <SemanticForm.Field>
            {!noteSuggestionOptions.length && <label>{t("v8_note").message || "Note"}</label>}
            <FormTextArea<PinCategoryFormType> textAreaProps={{ placeholder: `${t("v8_note").message || "Note"}`, rows: 2 }} name="note" />
            {isNoteRequired && <Message negative content={t("v8_enter_note_to_save").message || "Enter a note in order to save"} />}
          </SemanticForm.Field>
        </GridColumn>
      </Grid>

      <Grid>
        <GridColumn>
          <NoteAttachments appointmentId={data.appointment_id} interventionId={data.id} attachments={attachments} setAttachments={setAttachments} />
        </GridColumn>
      </Grid>

      <div className="warranty-actions">
        <Button color="grey" className="-appointment-status" onClick={openModal}>
          <Icon className="print black" />
          {t("v8_print_overview").message || "Print Overview"}
        </Button>

        <Button color="green" type="submit" disabled={isButtonDisabled}>
          <span>{data.pin_history && data.pin_history.length > 0 ? t("v8_update").message || "Update" : t("v8_save").message || "Save"}</span>
          <Icon className="check" />
        </Button>
      </div>
    </>
  );
};
