import { format, startOfMonth, subMonths } from "date-fns";

import { DATE_FORMATS_FNS, DealersLocationsDropdownValue, StatusData } from "components";
import { PIN_TYPE } from "models";
import { WARRANTY_TIME_PERIODS, useGetMechanicFixedOptions } from "modules/WarrantyDashboard/hooks";
import {
  WARRANTY_DASHBOARD_PIN_STATUS_SELECTION,
  useDefaultWarrantyDashboardFilters,
  useGetWarrantyDashboardFilters
} from "modules/WarrantyDashboard/hooks/useGetWarrantyDashboardFilters";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyDashboardFiltersPayload } from "modules/WarrantyDashboard/types";
import { setPreference, useGetFilterUpdaterFunctions } from "util/common";
import { formatDate } from "util/dateHelperFunctions";

export const useWarrantyDashboardFilterHandlers = () => {
  const { filters } = useGetWarrantyDashboardFilters();
  const { defaultWarrantyFilters } = useDefaultWarrantyDashboardFilters();
  const mechanicFixedOptions = useGetMechanicFixedOptions();
  const { updateFilters } = useGetFilterUpdaterFunctions<WarrantyDashboardFiltersPayload>({ queryKey: WarrantyQueryKeys.warrantyFilters });

  const selectedMechanicFixed = filters?.mechanic_fixed !== null ? mechanicFixedOptions.find(element => element.filterValue === filters?.mechanic_fixed)?.value : "";
  const isOnlyRemarksSelected = filters?.pin_type_id?.every(pinType => pinType === PIN_TYPE.Remarks);

  const handleResetFilters = () => {
    setPreference(WARRANTY_DASHBOARD_PIN_STATUS_SELECTION, null);
    updateFilters({ ...defaultWarrantyFilters, pin_status_id: null, pin_status_refresh_timestamp: formatDate(new Date(), DATE_FORMATS_FNS.ISOFormat) });
  };

  const handleTimePeriodChange = (timePeriod: WARRANTY_TIME_PERIODS) => {
    if (timePeriod === WARRANTY_TIME_PERIODS.CustomTimePeriod) {
      return updateFilters({ time_period: timePeriod });
    }

    const date_from = format(startOfMonth(subMonths(new Date(), timePeriod)), DATE_FORMATS_FNS.ISOFormatEndOfDay);
    const date_to = formatDate(new Date(), DATE_FORMATS_FNS.ISOFormatEndOfDay);

    updateFilters({ date_from, date_to, time_period: timePeriod });
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const date_from = start ? formatDate(start, DATE_FORMATS_FNS.ISOFormatEndOfDay) : null;
    const date_to = end ? formatDate(end, DATE_FORMATS_FNS.ISOFormatEndOfDay) : null;

    updateFilters({ date_from, date_to });
  };
  const handleSearchChange = (value: string) => updateFilters({ search_term: value });
  const handleBrandsChange = (brands: string[]) => updateFilters({ brands });
  const handleClaimedChange = (claimed: boolean) => updateFilters({ claimed });
  const handleDeletedChange = (deleted: boolean) => updateFilters({ deleted, ...(deleted && { pin_status_id: null }), claimed: false });
  const handlePinStatusChange = (value: number[]) => {
    setPreference(WARRANTY_DASHBOARD_PIN_STATUS_SELECTION, value.length ? value : null);
    updateFilters({ pin_status_id: value, pin_status_refresh_timestamp: formatDate(new Date(), DATE_FORMATS_FNS.ISOFormat) });
  };
  const handleMechanicFixedChange = (value: number | null) => {
    const mechanicFixed = value ? (mechanicFixedOptions.find(element => element.value === value)?.filterValue ?? null) : null;
    updateFilters({ mechanic_fixed: mechanicFixed });
  };
  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => updateFilters({ dealer_ids: dealerIds, location_ids: locationIds });
  const handleAppointmentStatusUpdate = (appointmentStatuses: StatusData[]) => updateFilters({ appointment_statuses: appointmentStatuses });

  return {
    mechanicFixedOptions,
    isOnlyRemarksSelected,
    selectedMechanicFixed,
    handleDateChange,
    handleResetFilters,
    handleSearchChange,
    handleBrandsChange,
    handleClaimedChange,
    handleDeletedChange,
    handlePinStatusChange,
    handleTimePeriodChange,
    handleMechanicFixedChange,
    handleDealerLocationChange,
    handleAppointmentStatusUpdate
  };
};
