import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm } from "components";
import { useUser } from "hooks";
import { Appointment } from "models";
import { CounterTabletModal, DeskCommunicationModal } from "modules/AppointmentDetails/components/DeskCommunication/components";
import { isCarReadyOrQualityCheck } from "util/appointmentUtils";
import { ITranslation } from "util/interfaces";

type DeskCommunicationProps = {
  appointment: Appointment;
};

export const DeskCommunication = ({ appointment }: DeskCommunicationProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCounterTabletModal, setShowCounterTabletModal] = useState(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);

  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const isDeskCheckOut = isCarReadyOrQualityCheck(appointment.appointment_status_identifier);

  const handleTabletClick = () => setShowConfirmModal(true);

  const handleConfirmDeskCommunication = () => {
    setShowConfirmModal(false);
    setShowCommunicationModal(true);
  };

  const handleCommunicationSend = async () => {
    setShowCommunicationModal(false);
    setShowCounterTabletModal(true);
  };

  const handleCounterTabletClose = () => setShowCounterTabletModal(false);

  const handleCloseCommunicationModal = () => {
    setShowCommunicationModal(false);
  };

  const handleCancelConfirm = () => setShowConfirmModal(false);

  const renderTabletConfirmModal = () => {
    if (!showConfirmModal) return null;

    const confirmMessage = isDeskCheckOut
      ? t("v8_action_will_display_desk_checkout_on_counter_tablet").message ||
        "This action will display the desk check-out on the counter tablet. Do you want to proceed?"
      : t("v8_action_will_display_desk_checkin_on_counter_tablet").message || "This action will display the desk check-in on the counter tablet. Do you want to proceed?";

    if (user?.counter_tablet_key)
      return (
        <CustomConfirm
          customTitle={isDeskCheckOut ? t("v8_desk_check_out").message || "Desk check-out" : t("v8_desk_check_in").message || "Desk check-in"}
          type={CUSTOM_CONFIRM_TYPES.Warning}
          handleConfirm={handleConfirmDeskCommunication}
          isOpen={showConfirmModal}
          confirmMsg={confirmMessage}
          confirmButtonText={t("v8_confirm").message || "Confirm"}
          handleCancel={handleCancelConfirm}
        />
      );

    return (
      <CustomConfirm
        customTitle={t("v8_counter_tablet_missing").message || "Counter tablet missing"}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        isOpen={showConfirmModal}
        handleCancel={handleCancelConfirm}
        confirmMsg={t("v8_please_connect_your_profile_with_counter_tablet").message || "Please connect your profile with a counter tablet"}
        cancelButtonText={t("v8_close").message || "Close"}
      />
    );
  };

  return (
    <>
      <Can I="create" the="desk_checkin">
        <Button className="-appointment-status" size="small" onClick={handleTabletClick}>
          <Icon className="tablet screen" />
        </Button>
      </Can>

      {renderTabletConfirmModal()}
      {showCounterTabletModal && <CounterTabletModal appointment={appointment} onClose={handleCounterTabletClose} />}
      {showCommunicationModal && <DeskCommunicationModal appointment={appointment} onSend={handleCommunicationSend} onClose={handleCloseCommunicationModal} />}
    </>
  );
};
