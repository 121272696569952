import ApiLoadable from "models/ApiLoadable";
import { COMMUNICATION_RECEIVER_STATUS } from "models/CommunicationEvent";
import { Customer } from "models/Customer";
import { User } from "models/User";

export enum ACSES_EVENT_TYPE {
  AcsesEventTypeDropBack = 1
}

export class AcsesCommunication extends ApiLoadable {
  id: number = 0;
  customer_id: number = 0;
  appointment_id: number = 0;
  car_id: number = 0;
  receptionist_id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  events?: AcsesCommunicationEvent[];
}

export class AcsesCommunicationEvent extends ApiLoadable {
  id: number = 0;
  acses_event_type: ACSES_EVENT_TYPE = ACSES_EVENT_TYPE.AcsesEventTypeDropBack;
  acses_communication_id: number = 0;
  receivers?: AcsesCommunicationReceiver[] | null = null;
  user?: User | null = null;
  customer?: Customer | null = null;
  created_on: string = "";
  updated_on: string = "";
}

export class AcsesCommunicationReceiver extends ApiLoadable {
  id: number = 0;
  acses_event_id: number = 0;
  communcation_channel_id: number | null = null;
  destination: string = "";
  status: COMMUNICATION_RECEIVER_STATUS | null = null;
  reason: string = "";
  sms_gateway_id: string = "";
  twilio_message_id: string = "";
  mailgun_message_id: string = "";
  chatshipper_conversation_id: string = "";
  created_on: string = "";
  updated_on: string = "";
}
