import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown, DropdownDivider, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";

import { Can, LocationDropdown, ProgressBar, useCan } from "components";
import documentationLinks, { DocumentationSupportedLanguages, SUPPORT_PAGES } from "components/NavBar/documentationLinks";
import "components/NavBar/NavBar.scss";
import { appointmentDetailRegex, backordersRegex, carDetailRegex, getRouteProperties, leadsRegex, manufacturerRegex, warrantyRegex } from "components/NavBar/routes";
import { toggleZohoWidget } from "components/NavBar/utils";
import { useAuth, useDealersLocations, useNavAction, useUser } from "hooks";
import { useGetManufacturerNotificationCount } from "modules/ManufacturerDashboard/service";
import { PATHS } from "router/paths";
import ApiInstance from "util/Api";
import { ITranslation } from "util/interfaces";

type NavBarProps = {
  openSidebar?: () => void;
};

export const NavBar = ({ openSidebar }: NavBarProps) => {
  const location = useLocation();
  const action = useNavAction();
  const user = useUser();
  const history = useHistory();
  const { selectedLocation } = useDealersLocations();
  const { logout } = useAuth();
  const t = useTranslation().t as ITranslation;
  const { manufacturerNotificationCount } = useGetManufacturerNotificationCount();

  const pathsToHideDealerLocationsDropdowns = [appointmentDetailRegex, leadsRegex, manufacturerRegex, warrantyRegex, backordersRegex];
  const hideDealerLocationsDropdowns = pathsToHideDealerLocationsDropdowns.some(path => path.test(location.pathname));
  const canUpdateManufacturerRequests = useCan("update-request", "manufacturer");

  const profilePicture = user?.profile_picture;
  const profileName = `${user?.first_name || ""} ${user?.last_name || ""}`;

  const isDayPlannerEnabled = !carDetailRegex.test(location.pathname) && selectedLocation?.is_dayplanner_enabled;
  const isNotExcludedPath = ![
    PATHS.DAYPLANNER,
    PATHS.ACCOUNT,
    PATHS.DEALER_DOCUMENTATION,
    PATHS.DEALER_REPORTS,
    PATHS.LOCATION_REPORTS,
    PATHS.LOCATION_LICENSE_OVERVIEW,
    PATHS.LEADS_DASHBOARD,
    PATHS.MANUFACTURER_DASHBOARD,
    PATHS.WARRANTY_DASHBOARD,
    PATHS.BACKORDERS_DASHBOARD
  ].includes(location.pathname);
  const currentLanguage: DocumentationSupportedLanguages = i18next.language?.split("-")?.[0] as DocumentationSupportedLanguages;
  const route = getRouteProperties(t, location);
  const supportLinkRoutes = [
    PATHS.DEALER_REPORTS,
    PATHS.LOCATION_REPORTS,
    PATHS.LEADS_DASHBOARD,
    PATHS.WARRANTY_DASHBOARD,
    PATHS.BACKORDERS_DASHBOARD,
    PATHS.MANUFACTURER_DASHBOARD
  ];
  const getSupportLink = () => {
    const supportPage = Object.values(SUPPORT_PAGES).find(page => location.pathname === page);
    if (!supportPage) return "";

    return documentationLinks[supportPage][currentLanguage] || documentationLinks[supportPage].en;
  };

  return (
    <div className={`nav-bar-wrapper ${route.defaultClassname}`}>
      <div className="nav-bar-content">
        <ProgressBar />
        <div className="nav-bar-start">
          {!ApiInstance.loggedInWithQrCode && (
            <div className="pointer" onClick={openSidebar}>
              <Icon name="bars" color="black" />
            </div>
          )}
          {route.title}
        </div>

        <div id="navbar-portal-container" />

        <div className="nav-bar-start">
          {!ApiInstance.loggedInWithQrCode && (
            <>
              {!hideDealerLocationsDropdowns && <LocationDropdown />}
              {action && (
                <Link className="button" to="#" onClick={action.onClick}>
                  <Icon className="arrows rotate regular i-grey" />
                </Link>
              )}
              {appointmentDetailRegex.test(location.pathname) && (
                <div className="pointer" onClick={() => window.open(PATHS.DEALER_DOCUMENTATION, "_blank")}>
                  <Icon className="book open cover" color="black" />
                </div>
              )}
              {supportLinkRoutes.includes(location.pathname) && (
                <Link className="button" to="#" onClick={() => window.open(getSupportLink(), "_blank")}>
                  <Icon className="question solid" />
                </Link>
              )}

              {isDayPlannerEnabled && isNotExcludedPath && (
                <Can I="browse" the="dayplanner-page">
                  <Link className="button" to={PATHS.DAYPLANNER}>
                    <Icon className="square kanban regular i-grey" />
                  </Link>
                </Can>
              )}
              {canUpdateManufacturerRequests && (
                <Link to={PATHS.MANUFACTURER_DASHBOARD} className="manufacturer-link-wrapper">
                  <Icon className="factory" />
                  {manufacturerNotificationCount && <span className="manufacturer-count">{manufacturerNotificationCount}</span>}
                </Link>
              )}
              {location.pathname === PATHS.DAYPLANNER && (
                <Link className="button" to={PATHS.APPOINTMENTS}>
                  <Icon className="list solid regular i-grey" />
                </Link>
              )}
            </>
          )}
          <div className="pointer">
            <Dropdown
              trigger={
                profilePicture ? (
                  <img title={profileName} className="small_profile_icon" src={profilePicture} />
                ) : (
                  <Icon title={profileName} className="circle user small_icon" />
                )
              }
              icon={null}
            >
              <DropdownMenu className="account_dropdown" direction="left">
                <DropdownItem className="profile_details">
                  {profilePicture ? (
                    <img title={profileName} className="profile_avatar" src={profilePicture} />
                  ) : (
                    <Icon title={profileName} size="huge" className="circle user" />
                  )}
                  <h1 className="mt-15">{profileName}</h1>
                  <p>{user?.email}</p>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem text={t("v8_account_settings").message || "Account Settings"} value="account_settings" onClick={() => history.push(PATHS.ACCOUNT)} />
                <DropdownDivider />
                <DropdownItem text={t("v8_tickets_help_center").message || "Tickets & Help Center"} value="tickets_help_center" onClick={toggleZohoWidget} />
                <DropdownDivider />
                <DropdownItem text={t("v8_logout").message || "Log out"} value="Logout" onClick={() => logout()} />
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};
