import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { MANUFACTURER_REQUEST_STATUS, ManufacturerRequest, ManufacturerResponseModel } from "models/Manufacturer";
import { useGetManufacturerDashboardFilters } from "modules/ManufacturerDashboard/hooks/useManufacturerFilters";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import ApiInstance from "util/Api";

export interface handleRequestMutationType {
  status: MANUFACTURER_REQUEST_STATUS;
  declineReason?: string;
}

const updateRequest = async (status: MANUFACTURER_REQUEST_STATUS, selectedRequest: ManufacturerRequest | null, declineReason?: string) => {
  if (!selectedRequest) return null;

  const payload = {
    manufacturer_request_status: status,
    manufacturer_request_id: selectedRequest.id,
    decline_reason: status === MANUFACTURER_REQUEST_STATUS.Declined ? declineReason : null
  };

  await ApiInstance.post("/manufacturers/requests/update", payload, ENV.appointmentBaseURL);
};

export const usehandleManufacturerRequest = () => {
  const queryClient = useQueryClient();
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();

  const [selectedRequestToHandle, setSelectedRequestToHandle] = useState<ManufacturerRequest | null>(null);

  const closeRequestModal = () => setSelectedRequestToHandle(null);
  const openRequestModal = (request: ManufacturerRequest) => setSelectedRequestToHandle(request);

  const { mutate: handleManufacturerRequest, isPending: isUpdatingManufacturerRequest } = useMutation({
    mutationFn: ({ status, declineReason }: handleRequestMutationType) => updateRequest(status, selectedRequestToHandle, declineReason),
    onMutate: ({ status, declineReason }) => {
      const manufacturerCache = queryClient.getQueryData<ManufacturerResponseModel>(
        ManufacturerQueryKeys.manufacturerListRequests({ manufacturerFilters: manufacturerDashboardFilters })
      );
      if (!manufacturerCache) return;

      const currentManufacturerRequests = [...manufacturerCache.manufacturer_requests];
      const matchingIndex = currentManufacturerRequests.findIndex(request => request.id === selectedRequestToHandle?.id);

      if (matchingIndex < 0) return;

      const staleManufacturerRequest = currentManufacturerRequests.at(matchingIndex);
      const updatedManufacturerRequest = { ...staleManufacturerRequest, decline_reason: declineReason, status } as ManufacturerRequest;

      const updatedManufacturerRequests = currentManufacturerRequests.with(matchingIndex, updatedManufacturerRequest);
      queryClient.setQueryData<ManufacturerResponseModel>(ManufacturerQueryKeys.manufacturerListRequests({ manufacturerFilters: manufacturerDashboardFilters }), {
        ...manufacturerCache,
        manufacturer_requests: updatedManufacturerRequests
      });

      return { manufacturerCache };
    },
    onError: (error, __, context) => {
      toast.error(error.message);
      queryClient.setQueryData(ManufacturerQueryKeys.manufacturerListRequests({ manufacturerFilters: manufacturerDashboardFilters }), context?.manufacturerCache);
    },
    onSettled: () => setSelectedRequestToHandle(null)
  });

  return { selectedRequestToHandle, closeRequestModal, openRequestModal, handleManufacturerRequest, isUpdatingManufacturerRequest };
};
