import { ExportDataModal } from "components";
import { useGetWarrantyDashboardFilters, useGetWarrantyExportModalSetters, useWarrantyExportModalQuery } from "modules/WarrantyDashboard/hooks";
import { exportWarrantyDashboardData } from "modules/WarrantyDashboard/service";

export const WarrantyExportModal = () => {
  const { filters } = useGetWarrantyDashboardFilters();
  const { isExportModalOpen } = useWarrantyExportModalQuery();
  const { handleCloseExportModal } = useGetWarrantyExportModalSetters();

  return <ExportDataModal open={isExportModalOpen} onClose={handleCloseExportModal} onExportData={() => exportWarrantyDashboardData(filters)} />;
};
