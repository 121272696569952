import { useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { Appointment } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import apiInstance from "util/Api";

interface CheckInRemarkMutationData {
  checkin_remark_id: number;
  handled: boolean;
}

interface DiagnoseOverviewRemarkMutationData {
  diagnose_overview_remark_id: number;
  handled_remark: boolean; // TODO: ask backend to be consistent and name this handled
}

interface KeylockerRemarkMutationData {
  keylocker_communication_id: number;
  keylocker_remark_id: number;
  handled_remark: boolean; // TODO: ask backend to be consistent and name this handled
}

interface KioskRemarkMutationData {
  kiosk_communication_id: number;
  remark_id: number;
  handled_remark: boolean;
}

export const useCommunicationRemarkMutation = (appointment_id: number) => {
  const queryClient = useQueryClient();

  const appointmentQueryKey = AppointmentDetailsKeys.view(appointment_id);

  const updateCheckInRemarkData = (variables: CheckInRemarkMutationData) => {
    const appointment = queryClient.getQueryData<Appointment>(appointmentQueryKey);
    if (!appointment?.customer_communication?.check_in_remarks?.length) return;

    const checkInRemarkIdx = appointment.customer_communication.check_in_remarks.findIndex(remark => remark.id === variables.checkin_remark_id);
    if (checkInRemarkIdx < 0) return;

    const customer_communication = {
      ...appointment.customer_communication,
      check_in_remarks: appointment.customer_communication.check_in_remarks.with(checkInRemarkIdx, {
        ...appointment.customer_communication.check_in_remarks[checkInRemarkIdx],
        receptionist_handled: variables.handled
      })
    };

    queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication });

    return appointment;
  };

  const updateDiagnoseOverviewRemarkData = (variables: DiagnoseOverviewRemarkMutationData) => {
    const appointment = queryClient.getQueryData<Appointment>(appointmentQueryKey);
    if (!appointment?.customer_communication?.diagnose_overview_remarks?.length) return;

    const diagnoseRemarkIdx = appointment.customer_communication.diagnose_overview_remarks.findIndex(remark => remark.id === variables.diagnose_overview_remark_id);
    if (diagnoseRemarkIdx < 0) return;

    const customer_communication = {
      ...appointment.customer_communication,
      diagnose_overview_remarks: appointment.customer_communication.diagnose_overview_remarks.with(diagnoseRemarkIdx, {
        ...appointment.customer_communication.diagnose_overview_remarks[diagnoseRemarkIdx],
        receptionist_handled: variables.handled_remark
      })
    };

    queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication });

    return appointment;
  };

  const updateKeylockerRemarkData = (variables: KeylockerRemarkMutationData) => {
    const appointment = queryClient.getQueryData<Appointment>(appointmentQueryKey);
    if (!appointment?.keylocker_communications?.length) return;

    const keylockerCommIdx = appointment.keylocker_communications.findIndex(kc => kc.id === variables.keylocker_communication_id);
    if (keylockerCommIdx < 0 || appointment.keylocker_communications[keylockerCommIdx].remark?.id !== variables.keylocker_remark_id) return;

    const keylocker_communications = appointment.keylocker_communications.with(keylockerCommIdx, {
      ...appointment.keylocker_communications[keylockerCommIdx],
      remark: {
        ...appointment.keylocker_communications[keylockerCommIdx].remark,
        receptionist_handled: variables.handled_remark
      }
    });

    queryClient.setQueryData(appointmentQueryKey, { ...appointment, keylocker_communications });

    return appointment;
  };

  const updateKioskRemarkData = (variables: KioskRemarkMutationData) => {
    const appointment = queryClient.getQueryData<Appointment>(appointmentQueryKey);
    if (!appointment?.kiosk_communications?.length) return;

    const kioskCommIdx = appointment.kiosk_communications.findIndex(kc => kc.id === variables.kiosk_communication_id);
    if (kioskCommIdx < 0 || appointment.kiosk_communications[kioskCommIdx].remark?.id !== variables.remark_id) return;

    const kiosk_communications = appointment.kiosk_communications.with(kioskCommIdx, {
      ...appointment.kiosk_communications[kioskCommIdx],
      remark: {
        ...appointment.kiosk_communications[kioskCommIdx].remark,
        receptionist_handled: variables.handled_remark
      }
    });

    queryClient.setQueryData(appointmentQueryKey, { ...appointment, kiosk_communications });

    return appointment;
  };

  const checkInRemarkMutation = useMutation({
    mutationFn: async (data: CheckInRemarkMutationData) => {
      await apiInstance.post(`/check_in/remark/handle`, data, ENV.appointmentBaseURL);
    },
    onMutate: (variables: CheckInRemarkMutationData) => updateCheckInRemarkData(variables),
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  const diagnoseOverviewRemarkMutation = useMutation({
    mutationFn: async (data: DiagnoseOverviewRemarkMutationData) => {
      await apiInstance.post(`/diagnose_overview/remark/handle`, data, ENV.appointmentBaseURL);
    },
    onMutate: (variables: DiagnoseOverviewRemarkMutationData) => updateDiagnoseOverviewRemarkData(variables),
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  const keylockerRemarkMutation = useMutation({
    mutationFn: async (data: KeylockerRemarkMutationData) => {
      await apiInstance.post(`/dashboard/remark/handle`, data, ENV.keylockerBaseURL);
    },
    onMutate: (variables: KeylockerRemarkMutationData) => updateKeylockerRemarkData(variables),
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  const kioskRemarkMutation = useMutation({
    mutationFn: async (data: KioskRemarkMutationData) => {
      await apiInstance.post(`/dashboard/remark/handle`, data, ENV.kioskBaseURL);
    },
    onMutate: (variables: KioskRemarkMutationData) => updateKioskRemarkData(variables),
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  return {
    checkInRemarkMutation,
    diagnoseOverviewRemarkMutation,
    keylockerRemarkMutation,
    kioskRemarkMutation,
    updateCheckInRemarkData,
    updateDiagnoseOverviewRemarkData,
    updateKeylockerRemarkData,
    updateKioskRemarkData
  };
};
