import { Tyre } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum INTERVENTION_ELEMENT_TYPE {
  Task = 1,
  Labor,
  Part,
  Text,
  Discount,
  SmallPart,
  Fee,
  Rent,
  Menu
}

// TODO deprecated model (is_tyre_replacement and customer_approved need to be added in the DB and model to be able to distinguish is it tyre replacement or not CLA-I6499)
export class InterventionElement extends ApiLoadable {
  created_on?: string = "";
  updated_on?: string = "";
  id?: number = 0;
  dms_nr: string = "";
  description: string = "";
  price?: number | null = null;
  quantity?: number | null = null;
  private_note?: string = "";
  is_local?: boolean = true;
  element_type: INTERVENTION_ELEMENT_TYPE = INTERVENTION_ELEMENT_TYPE.Text;
  status?: number = 0;
  parent_dms_nr?: string = "";
  intervention_id?: number = 0;
  unit?: number | null = null;
  intervention_dms_nr?: string = "";
  labor_minutes?: number = 0;
  tyre?: Tyre | null = null;
  brand_code?: string = "";
  reference_code?: string = "";
}
