import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Dropdown, Form, Icon, Input, Message, Modal, TextArea } from "semantic-ui-react";

import { PinLog, PinModelWithHistory } from "components";
import { useGetPinOptionsData } from "components/PinModal/hooks";
import "components/PinModal/PinModal.scss";
import { useUser } from "hooks";
import { InterventionAttachment, PIN_STATUS, PIN_TYPE, PinModel } from "models";
import { NoteAttachments } from "modules/AppointmentDetails/components/Interventions/components";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type PinProps = {
  pin: Partial<PinModelWithHistory>;
  isOpen: boolean;
  onClose: () => void;
  externalPinHistory?: PinModel[];
  openPrintOverviewModal: () => void;
  openDeleteConfirmationModal: () => void;
  isDeleteButtonDisabled: boolean;
};

export const PinModalForm = ({ pin, isOpen, onClose, externalPinHistory, openPrintOverviewModal, openDeleteConfirmationModal, isDeleteButtonDisabled }: PinProps) => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const { addPinItem } = useIntervention(Number(pin.appointment_id));

  const [pinWarrantyID, setPinWarrantyID] = useState(pin?.warranty_type_id || null);
  const [supportNr, setSupportNr] = useState(pin?.support_nr || "");
  const [claimNr, setClaimNr] = useState(pin?.claim_nr || "");
  const [refNr, setRefNr] = useState(pin?.ref_nr || "");
  const [note, setNote] = useState("");
  const [keepParts, setKeepParts] = useState(pin?.keep_parts || false);
  const [important, setImportant] = useState(pin?.visible_important_items || false);
  const [status, setStatus] = useState(pin?.pin_status_id || null);
  const [orderStatus, setOrderStatus] = useState<number | null>(pin?.order_status || null);
  const [pinTypeID, setPinTypeID] = useState<PIN_TYPE>(pin.pin_type_id || 0);
  const [attachments, setAttachments] = useState<InterventionAttachment[]>([]);
  const [noteSuggestion, setNoteSuggestion] = useState<number | null>(null);

  const hasUpdated = () => {
    if (pin?.support_nr !== supportNr) return true;
    if (pin?.note_attachments?.map(attachment => ({ url: attachment })) !== attachments) return true;
    if (pin?.claim_nr !== claimNr) return true;
    if (pin?.ref_nr !== refNr) return true;
    if ((pin?.warranty_type_id || null) !== pinWarrantyID) return true;
    if ((pin?.pin_status_id || null) !== status) return true;
    if ((pin?.order_status || null) !== orderStatus) return true;
    if (note !== "") return true;
    if (pinTypeID !== pin.pin_type_id) return true;
    if (!supportNr && !claimNr && !refNr && !note) return false;
    return false;
  };

  const isNoteRequired = important && !note;
  const isUpdateButtonDisabled = !hasUpdated() || addPinItem.isPending || isNoteRequired;
  const { noteSuggestionOptions, pinLogAttachments, pinOrderData, pinStatusData, pinTypeData, pinWarrantyData } = useGetPinOptionsData({ pin, pinType: pinTypeID });

  const onDropdownChange = (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
    switch (name) {
      case "warranty_type_id":
        setPinWarrantyID(Number(value));
        break;
      case "pin_status_id":
        setStatus(Number(value));
        break;
      case "order_status":
        setOrderStatus(Number(value));
        break;
      default:
        break;
    }
  };

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    switch (evt.target.name) {
      case "support_nr":
        setSupportNr(evt.target.value);
        break;
      case "claim_nr":
        setClaimNr(evt.target.value);
        break;
      case "ref_nr":
        setRefNr(evt.target.value);
        break;
      default:
        break;
    }
  };

  const onTextAreaChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (evt.target.name) {
      case "note":
        setNote(evt.target.value);
        break;
      default:
        break;
    }
  };

  const onCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    switch (data.name) {
      case "keep_parts":
        setKeepParts(!!data.checked);
        setImportant(!!data.checked);
        break;
      case "important":
        setImportant(!!data.checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (addPinItem.isSuccess) {
      setNote("");
    }
  }, [addPinItem.isSuccess]);

  const handleNewPinItem = async () => {
    const pinPayload: Partial<PinModel> = {
      warranty_type_id: pinWarrantyID,
      support_nr: supportNr,
      pin_status_id: status,
      pin_type_id: pinTypeID,
      order_status: orderStatus,
      ref_nr: refNr,
      claim_nr: claimNr,
      intervention_id: pin?.intervention_id || null,
      appointment_id: pin?.appointment_id,
      user_id: user ? user.id : 0,
      user,
      question_result: null,
      question_result_id: pin?.question_result_id || null,
      note_attachments: attachments.map(attach => attach.url),
      note,
      keep_parts: keepParts,
      visible_mechanic: true
    };

    await addPinItem.mutateAsync(pinPayload).then(() => onClose());
  };

  const handlePinType = (pinTypeID: PIN_TYPE) => {
    setPinTypeID(pinTypeID);
    if (pinTypeID === PIN_TYPE.Remarks) {
      setPinWarrantyID(null);
      setStatus(null);
    }
    if (pinTypeID === PIN_TYPE.Recall) {
      setPinWarrantyID(null);
    }
  };

  const handleNoteSuggestion = (value: number | null) => {
    const noteSuggestion = noteSuggestionOptions.find(item => item.value === value)?.text || "";
    setNote(noteSuggestion);
    setNoteSuggestion(value);
  };

  if (pin.pin_status_id === PIN_STATUS.Deleted) return null;

  return (
    <Modal className="PinModal" size="large" closeOnDimmerClick open={isOpen} onClose={onClose}>
      <Modal.Header>
        <div className="PinModal__modal-header">
          <p>
            <span>
              <Icon className="pin" />
            </span>
            {t("v8_pin_item").message || "Pin Item"}
          </p>

          <Button color="grey" className="-appointment-status" onClick={openPrintOverviewModal}>
            <Icon className="print black" />
            {t("v8_print_overview").message || "Print Overview"}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <div className="PinType">
          <Form>
            <Form.Group>
              <Form.Field id="pin_type" width={8} className="label-dropdown-container" required>
                <label>{t("v8_pin_type").message || "Pin Type"}</label>
                <Dropdown
                  value={pinTypeID}
                  onChange={(_, { value }) => handlePinType(value as PIN_TYPE)}
                  name="pin_type_id"
                  placeholder={t("v8_select_pin_type").message || "Select pin type"}
                  fluid
                  selection
                  options={pinTypeData}
                  trigger={
                    pinTypeID ? (
                      <div>
                        <span>{pinTypeData?.find(orderData => orderData.value === pinTypeID)?.icon}</span>
                        <span className="orderStatusTitle">{pinTypeData?.find(orderData => orderData.value === pinTypeID)?.text}</span>
                      </div>
                    ) : null
                  }
                />
              </Form.Field>

              <Form.Field id="support_nr" width={8}>
                <label>{t("v8_support").message || "Support"} #</label>
                <Input value={supportNr} fluid name="support_nr" onChange={onInputChange} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field id="claim_nr" width={8}>
                <label>{t("v8_claim").message || "Claim"} #</label>
                <Input value={claimNr} fluid name="claim_nr" onChange={onInputChange} />
              </Form.Field>

              <Form.Field id="ref_nr" width={8}>
                <label>{t("v8_ref").message || "Ref"} #</label>
                <Input value={refNr} fluid name="ref_nr" onChange={onInputChange} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field id="order_status" width={8}>
                <label>{t("v8_order_status").message || "Order status"}</label>
                <Dropdown
                  value={Number(orderStatus)}
                  onChange={(_, { name, value }) => onDropdownChange(name, value)}
                  name="order_status"
                  placeholder={t("v8_select_order_status").message || "Select order status"}
                  fluid
                  selection
                  clearable={!!orderStatus}
                  options={pinOrderData}
                  trigger={
                    orderStatus ? (
                      <div>
                        <span>{pinOrderData?.find(orderData => orderData.value === orderStatus)?.icon}</span>
                        <span className="orderStatusTitle">{pinOrderData?.find(orderData => orderData.value === orderStatus)?.text}</span>
                      </div>
                    ) : null
                  }
                />
              </Form.Field>

              <Form.Field id="warranty_type" width={8}>
                {pinTypeID === PIN_TYPE.Warranty && (
                  <>
                    <label>{t("v8_warranty_type").message || "Warranty type"}</label>
                    <Dropdown
                      value={pinWarrantyID || undefined}
                      onChange={(_, { name, value }) => onDropdownChange(name, value)}
                      name="warranty_type_id"
                      placeholder={t("v8_select_type").message || "Select type"}
                      fluid
                      selection
                      options={pinWarrantyData}
                    />
                  </>
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field id="pin_status" width={10}>
                {pinTypeID !== PIN_TYPE.Remarks && (
                  <>
                    <label>{t("v8_pin_status").message || "Pin Status"}</label>
                    <Dropdown
                      value={status || undefined}
                      onChange={(_, { name, value }) => onDropdownChange(name, value)}
                      name="pin_status_id"
                      placeholder={t("v8_select_status").message || "Select status"}
                      fluid
                      selection
                      options={pinStatusData}
                    />
                  </>
                )}
              </Form.Field>

              <Form.Field id="checkbox_fields" width={6} className="PinModal__checkbox-fields-group">
                <Form.Group className="PinModal__checkbox-fields">
                  <Form.Field id="keep_parts" className="PinModal__checkbox-container-align">
                    <div className="PinModal__checkbox-container">
                      <div>
                        <Icon className="setting" color="grey" />
                      </div>
                      <span>{t("v8_keep_parts").message || "Keep Parts"}</span>
                      <Checkbox name="keep_parts" checked={keepParts} className="PinModal__checkbox-box" onChange={onCheckboxChange} />
                    </div>
                  </Form.Field>

                  <Form.Field id="important" className="PinModal__checkbox-container-align">
                    <div className="PinModal__checkbox-container">
                      <div>
                        <Icon className="circle exclamation" color="red" />
                      </div>
                      <span>{t("v8_important").message || "Important"}</span>
                      <Checkbox name="important" checked={important} className="PinModal__checkbox-box" onChange={onCheckboxChange} />
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form.Field>
            </Form.Group>

            {noteSuggestionOptions.length > 0 && (
              <Form.Group>
                <Form.Field id="note_suggestion" width={16}>
                  <label>{t("v8_note").message || "Note"}</label>
                  <Dropdown
                    name="note_suggestion"
                    selection
                    placeholder={t("choose_answer").message || "Choose answer"}
                    options={noteSuggestionOptions}
                    fluid
                    value={noteSuggestion || undefined}
                    onChange={(_, { value }) => handleNoteSuggestion(value as number | null)}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Field id="note" width={16}>
                <TextArea name="note" rows={2} placeholder={t("v8_note").message || "Note"} value={note} onChange={onTextAreaChange} />
                {isNoteRequired && <Message negative content={t("v8_enter_note_to_save").message || "Enter a note in order to save"} />}
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field id="attachments" width={16}>
                <NoteAttachments appointmentId={pin.appointment_id ?? 0} interventionId={pin.id ?? 0} attachments={attachments} setAttachments={setAttachments} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field id="pin_log" width={16}>
                <PinLog
                  pinLog={pin.history || externalPinHistory}
                  intervention={pin?.intervention ?? undefined}
                  appointment={pin.appointment}
                  pinTypeData={pinTypeData}
                  pinOrderData={pinOrderData}
                  pinStatusData={pinStatusData}
                  pinWarrantyData={pinWarrantyData}
                  attachments={pinLogAttachments}
                  showTitle={false}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={openDeleteConfirmationModal} color="red" className="PinModal__delete-button" disabled={isDeleteButtonDisabled}>
          {t("v8_delete").message || "Delete"}
          <Icon className="trash" />
        </Button>

        <div className="PinModal__modal-footer">
          <Button onClick={onClose} basic>
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>
          <Button color="green" disabled={isUpdateButtonDisabled} loading={addPinItem.isPending} type="button" onClick={handleNewPinItem}>
            {t("v8_update").message || "Update"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
