import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useGetWarrantyDashboardFilters } from "modules/WarrantyDashboard/hooks";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyWithHistory } from "modules/WarrantyDashboard/types";
import ApiInstance from "util/Api";

type DeletePinRequest = {
  question_result_id?: number | null;
  intervention_id?: number | null;
  note: string;
};

const handleRemovePinItem = async (data: DeletePinRequest) => {
  await ApiInstance.post("/pins/delete", data, ENV.appointmentBaseURL);
};

export const useDeletePin = () => {
  const queryClient = useQueryClient();
  const { filters } = useGetWarrantyDashboardFilters();
  const queryKey = WarrantyQueryKeys.listWarranties({ filters });

  const deletePinItem = useMutation({
    mutationFn: handleRemovePinItem,
    onMutate: (data: DeletePinRequest) => {
      const warrantyData = queryClient.getQueryData<WarrantyWithHistory>(queryKey);
      if (!warrantyData?.items?.length) return;

      const updatedPinItems = warrantyData.items.filter(pin => pin.question_result_id !== data.question_result_id || pin.intervention_id !== data.intervention_id);

      queryClient.setQueryData(queryKey, { ...warrantyData, items: updatedPinItems });
      return warrantyData;
    },
    onError: (err, _, warrantyData) => {
      toast.error(err.message);
      queryClient.setQueryData(queryKey, warrantyData);
    }
  });

  return { deletePinItem };
};
