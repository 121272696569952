import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import { DATE_FORMATS_FNS, ReactTable } from "components";
import { useDealersLocations } from "hooks";
import { PIN_TYPE, PinModel } from "models";
import { useGetManufacturerDropdownOptions } from "modules/ManufacturerDashboard/hooks";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

interface ManufacturerClaimsTableProps {
  claimsResults: PinModel[];
  onSelectRow: (row: PinModel) => void;
}

export const ManufacturerClaimsTable = ({ claimsResults, onSelectRow }: ManufacturerClaimsTableProps) => {
  const t = useTranslation().t as ITranslation;
  const { dealers, locations } = useDealersLocations();
  const { warrantyDropdownOptions, pinTypeDropdownOptions, pinStatusDropdownOptions } = useGetManufacturerDropdownOptions();
  const columnHelper = createColumnHelper<PinModel>();

  const selectPinItem = (row: PinModel) => onSelectRow(row);

  const columns = [
    columnHelper.accessor(row => row.reg_number, {
      id: "reg_num",
      header: `${t("v8_reg").message || "Reg"} #`,
      cell: ({ renderValue }) => {
        return <Popup basic content={renderValue()} trigger={<p className="ellipsis">{renderValue()}</p>} />;
      }
    }),

    columnHelper.accessor(row => row.dealer_id, {
      id: "dealer_id",
      header: t("v8_dealer").message || "Dealer",
      cell: ({ renderValue }) => {
        const matchingDealer = dealers.find(dealer => dealer.id === renderValue())?.name || "";
        return <Popup basic content={matchingDealer} trigger={<p className="ellipsis">{matchingDealer}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.dealer_location_id, {
      id: "location_id",
      header: t("v8_location").message || "Location",
      cell: ({ renderValue }) => {
        const location = locations.find(location => location.id === renderValue())?.name || "";
        return <Popup basic content={location} trigger={<p className="ellipsis">{location}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.created_on, {
      id: "pin_date",
      header: t("v8_pin_date").message || "Pin Date",
      cell: ({ renderValue }) => {
        const formattedDate = formatDate(renderValue() as string, DATE_FORMATS_FNS.dateMonthYear);
        return <Popup basic content={formattedDate} trigger={<p className="ellipsis">{formattedDate}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.pin_type_id, {
      id: "pin_type",
      header: t("v8_pin_type").message || "Pin Type",
      cell: info => {
        const pinTypeId = info.getValue();
        if (!pinTypeId || pinTypeId === PIN_TYPE.NotSet) return;
        const matchingPinStatus = pinTypeDropdownOptions.find(option => option.value === pinTypeId)?.text ?? "";
        return <Popup basic content={matchingPinStatus} trigger={<p className="ellipsis">{matchingPinStatus}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.warranty_type_id, {
      id: "warranty_type",
      header: () => t("v8_warranty").message || "Warranty",
      cell: info => {
        const warrantyTypeId = info.getValue();
        if (!warrantyTypeId) return;
        const matchingWarrantyStatus = warrantyDropdownOptions.find(option => option.value === warrantyTypeId)?.text ?? "";
        return <span>{matchingWarrantyStatus}</span>;
      }
    }),
    columnHelper.accessor(row => row.pin_status_id, {
      id: "pin_status",
      header: t("v8_pin_status").message || "Pin Status",
      cell: info => {
        const pinStatusId = info.getValue();
        const matchingPinStatus = pinStatusDropdownOptions.find(option => option.value === pinStatusId)?.text ?? "";

        return <Popup basic content={matchingPinStatus} trigger={<p className="ellipsis">{matchingPinStatus}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.support_nr, {
      id: "support_nr",
      header: `${t("v8_support").message || "Support"} #`,
      cell: info => {
        const supportNum = info.getValue();
        return <span>{supportNum}</span>;
      }
    }),
    columnHelper.accessor(row => row.claim_nr, {
      id: "claim_nr",
      header: ` ${t("v8_claim").message || "Claim"} # `,
      cell: info => {
        const claimNum = info.getValue();
        return <Popup basic content={claimNum} trigger={<p className="ellipsis">{claimNum}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.ref_nr, {
      id: "ref_nr",
      header: t("v8_ref").message + " #" || "Ref #",
      cell: info => {
        const refNum = info.getValue();
        return <Popup basic content={refNum} trigger={<p className="ellipsis">{refNum}</p>} />;
      }
    })
  ];
  return (
    <ReactTable columns={columns} emptyRowMessage={t("v8_no_results_found").message || "No results found."} data={claimsResults} onRowClick={row => selectPinItem(row)} />
  );
};
