import { PinModel, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum WARRANTY_TYPE {
  NotSet = 0,
  Factory,
  Leniency,
  PaintBody,
  OnRepair,
  Occasion,
  Service
}

export enum MANUFACTURER_REQUEST_STATUS {
  Pending = 1,
  Approved,
  Declined
}

export enum MANUFACTURER_STATUS {
  Advise = 2,
  Critical
}

export interface ManufacturerResponseModel {
  manufacturer_requests: ManufacturerRequest[];
  nb_pages: number;
}

export class ManufacturerRequest extends ApiLoadable {
  appointment_id: number = 0;
  car_id: number = 0;
  created_on: Date | null = null;
  dealer_id: number = 0;
  dealer_location_id: number = 0;
  decline_reason: string = "";
  expire_on: Date | null = null;
  id: number = 0;
  intervention_id: number = 0;
  manufacturer: User | null = null;
  manufacturer_id: number = 0;
  pin_history: PinModel[] = [];
  question_result_id: number = 0;
  request_reason: string = "";
  status: MANUFACTURER_REQUEST_STATUS | null = null;
  updated_by_id: number = 0;
  updated_on: Date | null = null;
}
