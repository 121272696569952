import { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { Appointment } from "models";
import { QuestionResult } from "models/QuestionResult";
import "modules/AppointmentDetails/components/AdvisedCriticalItemsHistory/components/AdvisedCriticalItemsHistoryActions/AdvisedCriticalItemsHistoryActions.scss";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { parseQuestionResultAttachments } from "modules/AppointmentDetails/utils";

type AdvisedCriticalItemsHistoryActionsProps = {
  questionResult: QuestionResult;
  onSelect: (data: ActionModalSelectorData) => void;
  toggleShowMedia?: (id: number) => void;
  onUpdateHiddenItems: () => void;
  appointment: Appointment;
};

const AdvisedCriticalItemsHistoryActions = ({ questionResult, appointment, onSelect, toggleShowMedia, onUpdateHiddenItems }: AdvisedCriticalItemsHistoryActionsProps) => {
  const { updateCheckQuestionItem } = useChecklist(appointment.id);
  const isConvertToInterventionButtonDisabled = useMemo(
    () => appointment?.interventions?.some(intervention => intervention.question_result_id === questionResult.id),
    [appointment.interventions, questionResult.id]
  );
  const attachments = parseQuestionResultAttachments(questionResult);

  const handleVisibilityInHistoryItems = async () => {
    const updatedData = { ...questionResult, is_hidden_in_history: !questionResult.is_hidden_in_history } as QuestionResult;
    await updateCheckQuestionItem.mutateAsync({ data: updatedData });
    onUpdateHiddenItems();
  };

  return (
    <div className="AppointmentDetails-AdvisedCriticalItemsHistory-AdvisedCriticalItemsHistoryActions">
      <div className="status-container">
        {!!attachments?.length && toggleShowMedia && (
          <div onClick={() => toggleShowMedia(questionResult.id)}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{attachments.length}</small>
            </p>
          </div>
        )}
        <AnswerStatusSelector data={questionResult} disabled />
        <ActionModalSelector
          data={questionResult}
          attachments={attachments}
          onSelect={onSelect}
          appointment={appointment}
          isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
          onlyIntervention
        />
      </div>
      <div className="hidden-items-button">
        <Icon className={`clock rotate left ${questionResult.is_hidden_in_history ? "green" : "grey"}`} onClick={handleVisibilityInHistoryItems} />
      </div>
    </div>
  );
};

export default AdvisedCriticalItemsHistoryActions;
