import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";

import { useDealersLocations } from "hooks";
import { QUESTION_STATUSES } from "models";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { toDate } from "util/dateHelperFunctions";

export const useDefaultNotSnoozedFilters = () => {
  const { selectedLocation } = useDealersLocations();

  const defaultNotSnoozedFilters: LeadsNotSnoozedFilters = {
    page: 1,
    dealer_ids: [],
    search_term: "",
    is_lease_company: null,
    date_from: subDays(new Date(), 30),
    date_to: toDate(new Date()),
    location_ids: selectedLocation?.id ? [selectedLocation.id] : [],
    customer_approved: false,
    interventions: false,
    statuses: [QUESTION_STATUSES.OKWithRemarks, QUESTION_STATUSES.Advice, QUESTION_STATUSES.Necessary],
    questions: null,
    tags: null
  };

  return { defaultNotSnoozedFilters };
};

export const useGetNotSnoozedFilters = () => {
  const { selectedLocation } = useDealersLocations();
  const { defaultNotSnoozedFilters } = useDefaultNotSnoozedFilters();

  const { data } = useQuery<LeadsNotSnoozedFilters>({
    queryKey: LeadsQueryKeys.notSnoozedFilters,
    queryFn: () => ({ ...defaultNotSnoozedFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] }),
    placeholderData: defaultNotSnoozedFilters,
    enabled: !!selectedLocation
  });

  return { notSnoozedFilters: data };
};
