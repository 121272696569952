import { useTranslation } from "react-i18next";

import { useDealersLocations } from "hooks";
import { DATE_ORDER_BY, WO_FILTERS } from "modules/Appointments";
import { ITranslation } from "util/interfaces";

export const useAppointmentHeaderDropdownOptions = (carMakeValues: string[], enableCarReady?: boolean) => {
  const t = useTranslation().t as ITranslation;
  const { selectedDealer } = useDealersLocations();

  const date = [
    {
      key: DATE_ORDER_BY.APPOINTMENT_DATE_ASCENDING,
      value: DATE_ORDER_BY.APPOINTMENT_DATE_ASCENDING,
      text: t("v8_appointment_date").message || "Appointment Date",
      icon: "arrow down a z"
    },
    {
      key: DATE_ORDER_BY.APPOINTMENT_DATE_DESCENDING,
      value: DATE_ORDER_BY.APPOINTMENT_DATE_DESCENDING,
      text: t("v8_appointment_date").message || "Appointment Date",
      icon: "arrow up a z"
    },
    ...(enableCarReady
      ? [
          {
            key: DATE_ORDER_BY.SCHEDULED_OUT_DATE_ASCENDING,
            value: DATE_ORDER_BY.SCHEDULED_OUT_DATE_ASCENDING,
            text: t("v8_scheduled_out").message || "Scheduled Out",
            icon: "arrow down a z"
          },
          {
            key: DATE_ORDER_BY.SCHEDULED_OUT_DATE_DESCENDING,
            value: DATE_ORDER_BY.SCHEDULED_OUT_DATE_DESCENDING,
            text: t("v8_scheduled_out").message || "Scheduled Out",
            icon: "arrow up a z"
          }
        ]
      : [])
  ];

  return {
    date,
    customer: [
      { key: "driver", value: "driver", text: t("v8_driver_name").message || "Driver Name" },
      { key: "contractor", value: "contractor", text: t("v8_contractor_name").message || "Contractor Name" },
      { key: "owner", value: "owner", text: t("v8_owner_name").message || "Owner Name" }
    ],
    lastUser: [
      { key: "last_user", value: "last_user", text: t("v8_last_updated_by").message || "Last Updated By" },
      ...(selectedDealer?.onei_planning_enabled || selectedDealer?.planit_planning_enabled
        ? [{ key: "assigned_to", value: "assigned_to", text: t("v8_assigned_to").message || "Assigned to" }]
        : []),
      { key: "customer_owner", value: "customer_owner", text: t("v8_customer_owner").message || "Customer Owner" },
      { key: "receptionist_changed", value: "last_receptionist_update_by", text: t("v8_receptionist").message || "Receptionist" }
    ],
    wo: [
      { key: WO_FILTERS.ALL, value: WO_FILTERS.ALL, text: t("v8_all").message || "All" },
      { key: WO_FILTERS.WO, value: WO_FILTERS.WO, text: t("v8_WO").message || "WO" },
      { key: WO_FILTERS.INTERNAL, value: WO_FILTERS.INTERNAL, text: t("v8_internal").message || "Internal" }
    ],
    carMake: carMakeValues?.length
      ? [{ key: "All", value: "", text: t("v8_all").message || "All" }].concat(...carMakeValues.map(make => ({ key: make, value: make, text: make })))
      : []
  };
};
