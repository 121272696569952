import { Appointment, KeyloopImportStatus, KeyloopRepairOrder, NextLaneRepairOrder, STATUS_IDENTIFIER, WincarRepairOrder } from "models";
import { updateAppointmentStatusIdentifier } from "util/appointmentUtils";

import { AppointmentSearchOption, SEARCH_OPTION_TYPE } from ".";

export interface AppointmentSearchResults {
  claire: Appointment[];
  keyloop?: KeyloopRepairOrder[];
  nextlane?: NextLaneRepairOrder[];
  win_car?: WincarRepairOrder[];
}

const keyloopStatusMapping: { [key in KeyloopImportStatus]: STATUS_IDENTIFIER } = {
  CREATED: STATUS_IDENTIFIER.NewCarStatus,
  ONHOLD: STATUS_IDENTIFIER.NewCarStatus,
  CHECKEDIN: STATUS_IDENTIFIER.CarInShop,
  WORKSTARTED: STATUS_IDENTIFIER.CarCheckStartedStatus,
  WORKCOMPLETED: STATUS_IDENTIFIER.CarCheckStatus,
  CHECKEDOUT: STATUS_IDENTIFIER.CarReadyStatus,
  CLOSED: STATUS_IDENTIFIER.CarReadyStatus
};

const nextlaneStatusMapping = (repairOrder: NextLaneRepairOrder): STATUS_IDENTIFIER => {
  if (repairOrder.IsVehHandedBack) return STATUS_IDENTIFIER.CarOutOfShop;
  if (repairOrder.IsFinished) return STATUS_IDENTIFIER.CarReadyStatus;
  if (repairOrder.VehicleMileageIn > 0) return STATUS_IDENTIFIER.CarInShop;

  return STATUS_IDENTIFIER.NewCarStatus;
};

export const getFormattedAndSortedSearchResults = (data: AppointmentSearchResults) => {
  const options: AppointmentSearchOption[] = [];

  if (data.keyloop) {
    options.push(
      ...data.keyloop.map(item => ({
        id: item.repairOrderId,
        car_make: "",
        car_model: item.vehicle.description,
        due_in: item.appointment.dueInDateTime,
        wo_nr: item.repairOrderId,
        reg_number: item.vehicle.licensePlate,
        appointment_status_identifier: keyloopStatusMapping[item.status] || 0,
        type: SEARCH_OPTION_TYPE.Keyloop
      }))
    );

    options.sort((a, b) => new Date(b.due_in).valueOf() - new Date(a.due_in).valueOf());
  }

  if (data.nextlane) {
    const nextlaneOptions: AppointmentSearchOption[] = data.nextlane.map(item => ({
      id: item.InternalFolderID,
      car_make: item?.Vehicle?.BrandDescr || "",
      car_model: "",
      due_in: item?.AppointmentDate || "",
      wo_nr: item?.InternalFolderID || "",
      reg_number: item?.Vehicle?.RegistrationNumber || "",
      appointment_status_identifier: nextlaneStatusMapping(item),
      type: SEARCH_OPTION_TYPE.Nextlane
    }));

    nextlaneOptions.sort((a, b) => new Date(b.due_in).valueOf() - new Date(a.due_in).valueOf());
    options.push(...nextlaneOptions);
  }

  if (data.win_car) {
    const wincarOptions: AppointmentSearchOption[] = data.win_car.map(item => ({
      id: item.id,
      car_make: item.voertuig.merk,
      car_model: item.voertuig.model,
      wo_nr: item.werkordernummer,
      due_in: item.planning.objectAanwezigDatum,
      reg_number: item.voertuig.kenteken,
      appointment_status_identifier: STATUS_IDENTIFIER.NewCarStatus,
      search_category: "wincar",
      type: SEARCH_OPTION_TYPE.Wincar
    }));

    wincarOptions.sort((a, b) => new Date(b.due_in).valueOf() - new Date(a.due_in).valueOf());
    options.push(...wincarOptions);
  }

  const claireOptions: AppointmentSearchOption[] = data.claire.map(item => {
    const appointment = updateAppointmentStatusIdentifier(item);

    return {
      id: appointment.id + "",
      car_make: appointment.car_make,
      car_model: appointment.car_model,
      due_in: appointment.time_car_app,
      wo_nr: appointment.wo_nr,
      reg_number: appointment.reg_number,
      appointment_status_identifier: appointment.appointment_status_identifier,
      dealer_location: appointment.dealer_location,
      customcom_status: appointment.customer_communication?.status,
      check_paused_at: appointment.check_paused_at,
      car_out_of_shop: appointment.car_out_of_shop,
      type: SEARCH_OPTION_TYPE.Claire,
      dealer_location_id: appointment.dealer_location_id
    };
  });

  claireOptions.sort((a, b) => new Date(b.due_in).valueOf() - new Date(a.due_in).valueOf());
  options.push(...claireOptions);

  return options;
};
