import { add, format, isAfter, isEqual, isSameDay, setHours, setMinutes, subSeconds } from "date-fns";
import { toZonedTime } from "date-fns-tz";

import { DATE_FORMATS_FNS } from "components";

export const toDate = (date: string | Date) => (typeof date === "string" ? new Date(date) : date);

export const formatDate = (date: string | Date, formatString: DATE_FORMATS_FNS = DATE_FORMATS_FNS.ISOFormat) => format(toDate(date), formatString);

export const formatCurrentUTCDate = (formatString: DATE_FORMATS_FNS) => {
  const now = new Date();
  const utcDate = toZonedTime(now, "UTC");

  return format(utcDate, formatString).replace(/([+-]\d{2}:\d{2})$/, "+00:00");
};

export const isSameOrAfter = (date1: string | Date, date2: string | Date) => {
  const d1 = toDate(date1);
  const d2 = toDate(date2);

  return isAfter(d1, d2) || isEqual(d1, d2);
};

export const setHoursMinutesAndFormat = (date: string | Date, hour: number, minute: number, formatString: DATE_FORMATS_FNS = DATE_FORMATS_FNS.ISOFormat) => {
  const dateObj = toDate(date);

  const updatedDateObj = setHours(dateObj, hour);
  const finalDateObj = setMinutes(updatedDateObj, minute);

  return format(finalDateObj, formatString);
};

export const addDaysToDate = (date: string | Date, days: number) => add(toDate(date), { days });

export const isDate = (date: string | Date | null) => {
  if (typeof date === "string" && date !== null) {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime());
  }
  return date instanceof Date && !isNaN(date.getTime());
};

export const subtractSecondsAndFormatDate = (date: string | Date | null | undefined, duration: number, dateFormat: DATE_FORMATS_FNS) => {
  if (!date) return null;

  const dateObj = toDate(date);
  const newDate = subSeconds(dateObj, duration);
  return format(newDate, dateFormat);
};

export const isSameDayHelper = (date1: string | Date, date2: string | Date = new Date()) => isSameDay(toDate(date1), toDate(date2));

export const toISOString = (date: string | Date | null | undefined) => (date ? toDate(date) : new Date()).toISOString();

export const isDateAfter = (date1: string | Date | null | undefined, date2: string | Date | null | undefined): boolean => {
  const d1 = date1 ? toDate(date1) : new Date();
  const d2 = date2 ? toDate(date2) : new Date();
  return isAfter(d1, d2);
};

export const formatDurationFromMinutes = (minutes: number | undefined) => {
  if (!minutes) return "00:00";

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainingMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};
