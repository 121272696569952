import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";

import { useDealersLocations } from "hooks";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { toDate } from "util/dateHelperFunctions";

export const useDefaultCarsFilters = () => {
  const { selectedLocation } = useDealersLocations();

  const defaultLeadsCarsFilters: LeadsCarsFilter = {
    page: 1,
    dealer_ids: [],
    search_term: "",
    is_lease_company: null,
    date_from: subDays(new Date(), 30),
    date_to: toDate(new Date()),
    location_ids: selectedLocation?.id ? [selectedLocation.id] : []
  };

  return { defaultLeadsCarsFilters };
};

export const useCarsFiltersQuery = () => {
  const { selectedLocation } = useDealersLocations();
  const { defaultLeadsCarsFilters } = useDefaultCarsFilters();

  const { data } = useQuery<LeadsCarsFilter>({
    queryKey: LeadsQueryKeys.carsFilters,
    queryFn: () => ({ ...defaultLeadsCarsFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] }),
    placeholderData: defaultLeadsCarsFilters,
    enabled: !!selectedLocation
  });

  return { carsFilters: data };
};
