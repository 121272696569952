import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { ReactTable } from "components";
import "modules/DealerLicenseOverview/components/DealerInvoiceHistoryTable/DealerInvoiceHistoryTable.scss";
import { HistoricalInvoice, PaginatedHistoricalInvoices } from "modules/LocationLicenseOverview/types";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

type InvoiceTableProps = {
  invoices: PaginatedHistoricalInvoices | undefined;
  currentInvoicePage: number;
  onInvoiceRowClick: (rowData: HistoricalInvoice) => void;
  onPageChange: (pageIndex: number) => void;
  isLoadingInvoices: boolean;
};

export const DealerInvoiceHistoryTable = ({ invoices, onInvoiceRowClick, isLoadingInvoices, currentInvoicePage, onPageChange }: InvoiceTableProps) => {
  const t = useTranslation().t as ITranslation;

  const handleNextPage = () => onPageChange(currentInvoicePage + 1);
  const handlePreviousPage = () => onPageChange(currentInvoicePage - 1);

  const columnHelper = createColumnHelper<HistoricalInvoice>();
  const columns = [
    columnHelper.accessor(row => row.Subject, {
      id: "subject",
      header: t("v8_invoice_name").message || "Invoice name",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.Modified, {
      id: "modified",
      header: t("v8_invoice_date").message || "Invoice date",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.AmountFC, {
      id: "amount_fc",
      header: t("v8_total_price").message || "Total price",
      cell: info => `€ ${formatPrice(info.renderValue())}`
    })
  ];

  if (isLoadingInvoices) return <Loader active inline="centered" size="small" />;

  return (
    <div className="InvoiceHistoryTable">
      <ReactTable
        columns={columns}
        data={invoices?.results || []}
        renderEmptyRow={!invoices?.results?.length}
        emptyRowMessage={t("v8_no_invoices_found").message || "No invoices found"}
        onRowClick={onInvoiceRowClick}
      />

      <div className="ReactTablePagination pagination-container">
        <div className="pagination-container">
          <div className="pagination-controls">
            <button className="nav-button" disabled={currentInvoicePage === 0} onClick={handlePreviousPage}>
              <Icon className="angle left" />
            </button>
            <button className="nav-button" disabled={!invoices?.__next} onClick={handleNextPage}>
              <Icon className="angle right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
