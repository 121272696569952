import { useState } from "react";

import { PIN_TYPE } from "models";
import {
  ORDER_BY,
  REG_VIN_FILTER,
  useAppointmentOrderingOptions,
  useGetPinWarrantyTypeOptions,
  useGetQuestionStatusOptions,
  useGetWarrantyDashboardFilters
} from "modules/WarrantyDashboard/hooks";

export const DEFAULT_PIN_TYPES = [PIN_TYPE.Recall, PIN_TYPE.Remarks, PIN_TYPE.Warranty];

const getSelectedPinTypes = (pinTypeIds: PIN_TYPE[] | null) => {
  if (pinTypeIds?.length === DEFAULT_PIN_TYPES.length) return ["all"];
  return pinTypeIds?.map(String) ?? ["all"];
};

const getClaimOrderArrow = (claimOrderBy: ORDER_BY | null) => {
  if (!claimOrderBy) return "up grey";
  return claimOrderBy === ORDER_BY.ClaimAscending ? "up" : "down";
};

export const useGetSelectedFilterValues = () => {
  const [regVinNumberFilter, setRegVinNumberFilter] = useState(REG_VIN_FILTER.Reg);

  const { filters } = useGetWarrantyDashboardFilters();
  const questionStatusOptions = useGetQuestionStatusOptions();
  const warrantyTypeOptions = useGetPinWarrantyTypeOptions();
  const appointmentOrderingOptions = useAppointmentOrderingOptions();

  const selectedStatus = String(questionStatusOptions.find(option => option.filterValue === filters?.status)?.value);
  const selectedAppointmentOrder = String(appointmentOrderingOptions.find(option => option.value === filters?.order_by)?.value || "");
  const selectedWarrantyType = warrantyTypeOptions.find(option => option.value === filters?.warranty_type_id)?.value.toString() || "all";
  const isDateRangeSelected = filters?.date_from && filters?.date_to;
  const isRecallOrRemarksSelected = filters?.pin_type_id?.every(pinType => pinType === PIN_TYPE.Recall || pinType === PIN_TYPE.Remarks);
  const selectedAppointmentOrderText = appointmentOrderingOptions.find(option => option.value === filters?.order_by)?.text;

  return {
    setRegVinNumberFilter,
    isRecallOrRemarksSelected,
    selectedStatus,
    regVinNumberFilter,
    warrantyTypeOptions,
    selectedWarrantyType,
    questionStatusOptions,
    isDateRangeSelected,
    selectedAppointmentOrder,
    selectedAppointmentOrderText,
    appointmentOrderingOptions,
    selectedPinTypes: getSelectedPinTypes(filters?.pin_type_id ?? null),
    claimOrderArrow: getClaimOrderArrow(filters?.claim_order_by ?? null)
  };
};
