import { useQueryClient } from "@tanstack/react-query";

import { DealersLocationsDropdownValue } from "components";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import "modules/LeadsDashboard/Leads/CarsLeads/components/CarsLeadsFilters/CarsLeadsFilters.scss";
import { useDefaultCarsFilters } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useCarsFilterHandlers = () => {
  const queryClient = useQueryClient();
  const { updateFilters, updateFiltersWithCallback } = useGetFilterUpdaterFunctions<LeadsCarsFilter>({ queryKey: LeadsQueryKeys.carsFilters });
  const { defaultLeadsCarsFilters } = useDefaultCarsFilters();
  const carsFilters = queryClient.getQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters);

  const handleResetFilters = () => updateFiltersWithCallback(oldData => ({ ...oldData, ...defaultLeadsCarsFilters }));
  const handleSearchChange = (value: string) => updateFilters({ search_term: value });
  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => updateFilters({ dealer_ids: dealerIds, location_ids: locationIds });
  const handleDateRangeChange = (dates: Date[]) => {
    const [start, end] = dates;
    updateFilters({ date_from: start, date_to: end });
  };

  return { carsFilters, handleResetFilters, handleSearchChange, handleDealerLocationChange, handleDateRangeChange };
};
