import ENV from "config/Env";
import { BackordersDashboardFiltersPayload } from "modules/BackordersDashboard/types";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

type BackordersParams = {
  filters: BackordersDashboardFiltersPayload;
};

const component = "BackordersDashboard";

export const BackordersQueryKeys = {
  listBackorders: ({ filters }: BackordersParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/backorder/list",
      params: { filters }
    })
  ],
  backordersFilters: [localQueryKey({ component, data: "backordersFilters" })] as const,
  backordersExportModal: [localQueryKey({ component, data: "backordersExportModal" })] as const
};
