import { useTranslation } from "react-i18next";

import { useDealersLocations } from "hooks";
import { PIN_STATUS, PIN_TYPE, PIN_WARRANTY } from "models";
import { MANUFACTURER_REQUEST_STATUS, MANUFACTURER_STATUS } from "models";
import { REG_VIN_FILTER } from "modules/WarrantyDashboard/hooks";
import { ITranslation } from "util/interfaces";

export const useGetManufacturerDropdownOptions = () => {
  const t = useTranslation().t as ITranslation;
  const { selectedDealer } = useDealersLocations();

  const regHeaderOptions = [
    { key: 0, text: t("v8_reg").message || "Reg #", value: REG_VIN_FILTER.Reg },
    { key: 1, text: t("v8_vin").message || "Vin #", value: REG_VIN_FILTER.Vin }
  ];

  if (!selectedDealer) {
    return {
      pinTypeDropdownOptions: [],
      questionStatusDropdownOptions: [],
      manufacturerRequestStatusDropdownOptions: [],
      pinStatusDropdownOptions: [],
      warrantyDropdownOptions: [],
      mechanicFixedDropdownOptions: [],
      regHeaderOptions
    };
  }

  const pinTypeDropdownOptions = [
    { key: 0, text: t("v8_all").message || "All", value: "all" },
    { key: 1, text: t("v8_remarks").message || "Remarks", value: PIN_TYPE.Remarks, icon: "thumb tack", iconColor: "#2084CE" },
    { key: 2, text: t("v8_warranty").message || "Warranty", value: PIN_TYPE.Warranty, icon: "shield halved", iconColor: "#C83627" },
    { key: 3, text: t("v8_recall").message || "Recall", value: PIN_TYPE.Recall, icon: "registered", iconColor: "#2084CE" }
  ];

  const questionStatusDropdownOptions = [
    { key: 0, text: t("v8_all").message || "All", value: "all" },
    { key: 1, text: t("v8_advise").message || "Advise", value: MANUFACTURER_STATUS.Advise, icon: "exclamation triangle", iconColor: "orange" },
    { key: 2, text: t("v8_critical").message || "Critical", value: MANUFACTURER_STATUS.Critical, icon: "exclamation circle", iconColor: "red" }
  ];

  const manufacturerRequestStatusDropdownOptions = [
    { key: 0, text: t("v8_all").message || "All", value: "all" },
    { key: 1, text: t("v8_pending").message || "Pending", value: MANUFACTURER_REQUEST_STATUS.Pending, icon: "question", iconColor: "#054299" },
    { key: 2, text: t("v8_approved").message || "Approved", value: MANUFACTURER_REQUEST_STATUS.Approved, icon: "check", iconCcolor: "#21BA45" },
    { key: 3, text: t("v8_declined").message || "Declined", value: MANUFACTURER_REQUEST_STATUS.Declined, icon: "x", iconColor: "#FF0001" }
  ];

  const pinStatusDropdownOptions = [
    { key: 0, text: t("v8_not_set").message || "Not Set", value: PIN_STATUS.NotSet },
    { key: 1, text: t("v8_claimed").message || "Claimed", value: PIN_STATUS.Claimed },
    { key: 2, text: t("v8_support").message || "Support", value: PIN_STATUS.Support },
    { key: 3, text: t("v8_approval_pending").message || "Approval Pending", value: PIN_STATUS.ApprovalPending },
    { key: 4, text: t("v8_more_info").message || "More Info", value: PIN_STATUS.MoreInfo },
    { key: 5, text: t("v8_approved").message || "Approved", value: PIN_STATUS.Approved },
    { key: 6, text: t("v8_declined").message || "Declined", value: PIN_STATUS.Declined },
    { key: 7, text: t("v8_to_claim").message || "To Claim", value: PIN_STATUS.ToClaim },
    { key: 8, text: t("v8_billed").message || "Billed", value: PIN_STATUS.Billed },
    { key: 9, text: t("v8_request").message || "Request", value: PIN_STATUS.Request },
    { key: 10, text: t("v8_technical_info").message || "Technical Info", value: PIN_STATUS.TechnicalInfo }
  ];

  const mechanicFixedDropdownOptions = [
    { key: 1, text: t("v8_yes").message || "Yes", value: true },
    { key: 2, text: t("v8_no").message || "No", value: false }
  ];

  const warrantyDropdownOptions = [
    { key: `pin-warranty-all`, value: "all", text: t("v8_all").message || "All" },
    { key: `pin-warranty-${PIN_WARRANTY.NotSet}`, value: PIN_WARRANTY.NotSet, text: t("v8_not_set").message || "Not Set" },
    { key: `pin-warranty-${PIN_WARRANTY.Factory}`, value: PIN_WARRANTY.Factory, text: t("v8_factory").message || "Factory" },
    { key: `pin-warranty-${PIN_WARRANTY.Leniency}`, value: PIN_WARRANTY.Leniency, text: t("v8_leniency").message || "Leniency" },
    { key: `pin-warranty-${PIN_WARRANTY.BodyPaint}`, value: PIN_WARRANTY.BodyPaint, text: t("v8_body_paint").message || "Body Paint" },
    { key: `pin-warranty-${PIN_WARRANTY.OnRepair}`, value: PIN_WARRANTY.OnRepair, text: t("v8_on_repair").message || "On Repair" },
    { key: `pin-warranty-${PIN_WARRANTY.Occasion}`, value: PIN_WARRANTY.Occasion, text: t("v8_occasion").message || "Occasion" },
    { key: `pin-warranty-${PIN_WARRANTY.Service}`, value: PIN_WARRANTY.Service, text: t("v8_service").message || "Service" }
  ];

  return {
    pinTypeDropdownOptions: pinTypeDropdownOptions,
    questionStatusDropdownOptions: questionStatusDropdownOptions,
    manufacturerRequestStatusDropdownOptions: manufacturerRequestStatusDropdownOptions,
    pinStatusDropdownOptions: pinStatusDropdownOptions,
    warrantyDropdownOptions: warrantyDropdownOptions,
    mechanicFixedDropdownOptions,
    regHeaderOptions
  };
};
