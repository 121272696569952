import { createColumnHelper } from "@tanstack/react-table";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactTable } from "components/ReactTable";
import { useDealersLocations } from "hooks";
import { REPORT_COLUMNS } from "models";
import DealerUserReportsCustomHeader from "modules/DealerReports/components/DealerUsersReport/components/DealerUserReportsCustomHeader";
import "modules/DealerReports/components/DealerUsersReport/DealerUsersReport.scss";
import { useDealerThresholds } from "modules/DealerReports/hooks/useDealerThresholds";
import { ParsedReportData } from "modules/DealerReports/util/types";
import { ITranslation } from "util/interfaces";

enum THRESHOLD_COLORS {
  Min = "#FCD6D0",
  Max = "#DAEFDA",
  Default = "transparent"
}

enum THRESHOLD_VALUES {
  Min = "min",
  Max = "max"
}

type ThresholdRangeType = {
  [key: string]: { min: number | null; max: number | null };
};

interface ReportRow {
  [key: string]: any;
}

interface DealerUsersReportTableProps {
  data: ParsedReportData[];
}

const formatTableCell = (value: any, color: THRESHOLD_COLORS): React.ReactNode => {
  if (value === null || value === undefined) return "0";

  if (typeof value === "number")
    return (
      <span className="threshold-value-cell" style={{ backgroundColor: color }}>
        {value.toString().includes(".") ? Number(value.toFixed(2)) : value}
      </span>
    );
  return value;
};

export const DealerUsersReportTable = ({ data }: DealerUsersReportTableProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedDealer } = useDealersLocations();
  const thresholdsList = useDealerThresholds(Number(selectedDealer?.id));
  const [thresholdRanges, setThresholdRanges] = useState<ThresholdRangeType | null>(null);

  useEffect(() => {
    if (!thresholdsList.data) return;
    const defaultThresholdRanges: ThresholdRangeType = (thresholdsList.data || []).reduce<ThresholdRangeType>((acc, threshold) => {
      const columnName = REPORT_COLUMNS[threshold.dealer_report_column]?.toLowerCase();
      if (!columnName) return acc;
      acc[columnName] = {
        min: threshold.low_value ?? null,
        max: threshold.high_value ?? null
      };
      return acc;
    }, {});
    setThresholdRanges(defaultThresholdRanges);
  }, [thresholdsList.data]);

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className="Table__no-results">
        <p>{t("v8_no_data").message || "No data"}</p>
      </div>
    );
  }

  const getThresholdColor = (value: number, key: string) => {
    if (!thresholdRanges) return THRESHOLD_COLORS.Default;

    const thresholdRange = thresholdRanges[key as keyof typeof thresholdRanges];
    if (thresholdRange?.min && thresholdRange.min >= value) return THRESHOLD_COLORS.Min;
    if (thresholdRange?.max && thresholdRange.max <= value) return THRESHOLD_COLORS.Max;

    return THRESHOLD_COLORS.Default;
  };

  const columnHelper = createColumnHelper<ReportRow>();
  const filteredColumns = Object.keys(data[0]).filter(key => key !== "__typename");

  const columns = filteredColumns.map(key =>
    columnHelper.accessor(key, {
      header: () => <strong>{t(key.replace("user_", "")).message || key}</strong>,
      cell: info => {
        const color = getThresholdColor(info.getValue(), key);
        return formatTableCell(info.getValue(), color);
      }
    })
  );

  const updateThresholdCache = (key: string, value: string, type: "min" | "max") => {
    if (!thresholdRanges) return;
    setThresholdRanges({
      ...thresholdRanges,
      [key]: {
        ...thresholdRanges[key],
        [type]: Math.max(Number(value), 0)
      }
    });
  };

  const thresholdInputChange = (e: ChangeEvent<HTMLInputElement>, thresholdType: THRESHOLD_VALUES, key: string) => {
    const inputValue = String(Math.max(Number(e.target.value), 0));
    e.target.value = inputValue;
    updateThresholdCache(key, inputValue, thresholdType);
  };

  const thresholdInputs = filteredColumns.map((key, i) => {
    if (i === 0)
      return (
        <th key={key} className="threshold-placeholder">
          {t("v8_threshold").message || "Threshold"}
        </th>
      );

    if (thresholdRanges && key in thresholdRanges) {
      const range = thresholdRanges[key as keyof typeof thresholdRanges];

      return (
        <th key={key}>
          <div className="thresholdrange-wrapper">
            <input
              className="min-threshold-input"
              type="number"
              min={0}
              defaultValue={range.min || 0}
              onChange={e => thresholdInputChange(e, THRESHOLD_VALUES.Min, key)}
            />
            <input
              className="max-threshold-input"
              type="number"
              min={0}
              defaultValue={range.max || 0}
              onChange={e => thresholdInputChange(e, THRESHOLD_VALUES.Max, key)}
            />
          </div>
        </th>
      );
    }

    return <th key={key}></th>;
  });

  return (
    <div className="DealerUsersReportTable">
      <div style={{ maxHeight: "600px", overflow: "auto" }}>
        <ReactTable
          data={data}
          columns={columns}
          customHeader={props => (
            <>
              <DealerUserReportsCustomHeader {...props} /> {Boolean(thresholdsList.data?.length) && <tr>{thresholdInputs}</tr>}
            </>
          )}
        />{" "}
      </div>
    </div>
  );
};
