import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { QuestionResultData } from "components";
import { useDealersLocations } from "hooks";
import { LeadsNotSnoozed, SnoozeItem } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { useGetNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetTagsQuery, useLeadsNotSnoozedQuery } from "modules/LeadsDashboard/service";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useNotSnoozedLeads = () => {
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);
  const queryClient = useQueryClient();

  const tags = useGetTagsQuery();
  const { locations } = useDealersLocations();
  const { notSnoozedFilters } = useGetNotSnoozedFilters();
  const { updateFiltersWithCallback } = useGetFilterUpdaterFunctions<LeadsNotSnoozed>({ queryKey: LeadsQueryKeys.listNotSnoozed({ notSnoozedFilters }) });
  const { isLoading, data: notSnoozedLeads } = useLeadsNotSnoozedQuery({ notSnoozedFilters });

  const onSnoozeSave = (data: Partial<SnoozeItem>) => {
    const questionItem = notSnoozedLeads?.items?.find(item => item.question_result_id === data.question_result_id);
    if (!questionItem) return;

    const updatedItems =
      notSnoozedLeads?.items.map(item => {
        if (item.question_result_id !== data.question_result_id) return item;

        return {
          ...item,
          ...data,
          question_result: { ...item.question_result, snooze_history: [...(item.question_result?.snooze_history ?? []), { ...data }] }
        };
      }) || [];

    queryClient.setQueryData(LeadsQueryKeys.listNotSnoozed({ notSnoozedFilters }), { ...notSnoozedLeads, items: updatedItems, nb_items: updatedItems.length });
  };

  const onSnoozeDelete = (questionResultId: number) => {
    const updatedItems = notSnoozedLeads?.items?.filter(element => element.question_result_id !== questionResultId) || [];

    if (updatedItems.length !== notSnoozedLeads?.items?.length) {
      updateFiltersWithCallback(oldData => ({ ...oldData, items: updatedItems, nb_items: updatedItems.length }));
    }
  };

  return {
    tags,
    locations,
    modalData,
    isLoading,
    notSnoozedLeads,
    setModalData,
    onSnoozeSave,
    onSnoozeDelete
  };
};
