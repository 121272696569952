import { useTranslation } from "react-i18next";

import "components/Can/components/NoAccess/NoAccess.scss";
import { ITranslation } from "util/interfaces";

const NoAccess = () => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="NoAccess">
      <h1>{t("v8_no_access").message || "You don't have access to see this page"}</h1>
    </div>
  );
};

export default NoAccess;
