import { Loader } from "semantic-ui-react";

import SnoozeModal from "components/SnoozeModal";
import { SnoozeItem } from "models";
import { NotSnoozedLeadsTable } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { NotSnoozedLeadsFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedFilters";
import { useNotSnoozedLeads } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks";

export const NotSnoozedLeads = () => {
  const { isLoading, locations, modalData, notSnoozedLeads, tags, onSnoozeDelete, onSnoozeSave, setModalData } = useNotSnoozedLeads();

  return (
    <>
      <NotSnoozedLeadsFilters tagsOptions={tags} />
      {isLoading ? (
        <Loader active />
      ) : (
        <NotSnoozedLeadsTable
          locations={locations}
          setModalData={setModalData}
          notSnoozedData={notSnoozedLeads?.items || []}
          nbPages={notSnoozedLeads?.nb_pages || 1}
          nbItems={notSnoozedLeads?.nb_items || 0}
        />
      )}
      <SnoozeModal
        size="large"
        open={modalData !== null}
        onClose={() => setModalData(null)}
        closeOnDimmerClick
        data={modalData}
        onSave={(data: Partial<SnoozeItem>) => onSnoozeSave(data)}
        onDelete={(questionResultId: number) => onSnoozeDelete(questionResultId)}
      />
    </>
  );
};
