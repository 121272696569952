import React, { PropsWithChildren, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

import "components/DetailsCards/DetailsCards.scss";
import { Element } from "components/ReactScroll/index";
import { classNames } from "util/common";

interface ConditionalWrapInterface extends PropsWithChildren {
  condition: boolean;
  wrap: (children: React.ReactNode) => React.ReactNode;
}

type DetailsCardsProps = {
  icon?: string;
  title: string | React.ReactNode;
  isChecklistTitle?: boolean;
  id?: string;
  rightContent?: React.ReactNode;
  children?: React.ReactNode;
  isExpanded?: boolean;
  showExpandArrow?: boolean;
  button?: boolean;
  containerStyle?: string;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
};

export const ConditionalWrap = ({ condition, wrap, children }: ConditionalWrapInterface) => (condition ? wrap(children) : children);

export const DetailsCards: React.FC<DetailsCardsProps> = ({
  icon,
  title,
  id,
  rightContent,
  children,
  isExpanded = true,
  showExpandArrow = true,
  button = false,
  containerStyle = "",
  backgroundColor,
  borderColor,
  textColor,
  isChecklistTitle = false
}) => {
  const detailsCardClasses = classNames({ button });
  const [showCardDetails, setShowCardDetails] = useState(isExpanded);

  const toggleCardDetails = () => setShowCardDetails(previous => !previous);

  useEffect(() => {
    setShowCardDetails(isExpanded);
  }, [isExpanded]);

  return (
    <Element name={String(id)} className={`DetailsCards-container ${detailsCardClasses} ${containerStyle}`}>
      <div className="row">
        <ConditionalWrap
          condition={isChecklistTitle}
          wrap={children => (
            <div className="expand-bar" style={{ ...(borderColor && { border: `1px solid ${borderColor}` }), backgroundColor, color: textColor }}>
              {children}
            </div>
          )}
        >
          {icon && <Icon className={icon} style={{ ...(isChecklistTitle && { color: textColor }) }} />}
          <h3 className={isChecklistTitle ? "checklist-title" : ""}>{title}</h3>
          {showExpandArrow && <Icon className={`chevron ${showCardDetails ? "down" : "right"} pointer`} onClick={toggleCardDetails} />}
        </ConditionalWrap>
        {rightContent}
      </div>
      <div className={showCardDetails ? "show" : "hide"}>{children}</div>
    </Element>
  );
};
