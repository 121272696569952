import { faCircle, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { DATE_FORMATS_FNS } from "components";
import { CHANNELS } from "models";
import { AcsesCommunicationEvent } from "models/AcsesCommunication";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type AcsesCommunicationEventsProps = {
  events: AcsesCommunicationEvent[];
};

export const AcsesCommunicationEvents = ({ events }: AcsesCommunicationEventsProps) => {
  const t = useTranslation().t as ITranslation;
  return (
    <div className="acses-comm-section-container">
      <div className="acses-comm-section-header">{t("v8_acses_communication_events").message || "Acses communication events"}</div>
      <Grid>
        {events.map((event, index) => {
          return (
            <Grid.Row key={index}>
              <Grid.Column width={12}>{t("v8_dropped").message || "Dropped"}</Grid.Column>

              <Grid.Column width={4} className="event-timestamp">
                {formatDate(event.created_on, DATE_FORMATS_FNS.hoursMinutes)} - {formatDate(event.created_on, DATE_FORMATS_FNS.dateMonthYear)}
              </Grid.Column>

              <Grid.Row width={16} className="section-rows">
                {event.receivers?.map((receiver, i) => (
                  <React.Fragment key={i}>
                    <span>
                      <FontAwesomeIcon icon={receiver.communcation_channel_id === CHANNELS.Email ? faEnvelope : faPhone} className="i-green mr-5" />
                      {receiver.destination}
                    </span>
                    {receiver.reason && (
                      <span className="ml-10">
                        <FontAwesomeIcon key={i} icon={faCircle} color="red" className="mr-5" />
                        {receiver.reason}
                      </span>
                    )}
                  </React.Fragment>
                ))}
              </Grid.Row>
            </Grid.Row>
          );
        })}
      </Grid>
    </div>
  );
};
