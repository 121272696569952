import { useMemo, useState } from "react";

import { Appointment, ChecklistTemplate, QuestionResult, TYRE_POSITIONS } from "models";
import { ReplacementOfferModal, ReplacementOrderModal } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/components";
import DBBLabelsModal from "modules/AppointmentDetails/components/Tyre/DBBLabelsModal";
import "modules/AppointmentDetails/components/Tyre/Tyre.scss";
import TyreChecklist from "modules/AppointmentDetails/components/Tyre/TyreChecklist";

export enum TYRE_TEAM_ORDER_STATUS {
  Pending = 1,
  Fulfilled,
  Rejected
}

export const tyrePosition = {
  isOnCar: (position: TYRE_POSITIONS) => position > TYRE_POSITIONS.NotTyre && position < TYRE_POSITIONS.StorageFrontLeft,
  isInCar: (position: TYRE_POSITIONS) => position > TYRE_POSITIONS.CarRearRight && position < TYRE_POSITIONS.TyrePositionLast,
  isNotTruck: (position: TYRE_POSITIONS) => position > TYRE_POSITIONS.TyrePositionLast
};

type TyreProps = {
  data: QuestionResult[];
  template?: ChecklistTemplate | null;
  appointment: Appointment;
};

export type TyreLocationData = {
  onCar: QuestionResult[];
  inStorage: QuestionResult[];
  onTruck: QuestionResult[];
};

export const Tyre = ({ data, template, appointment }: TyreProps) => {
  const [isDBBModalOpen, setIsDBBModalOpen] = useState(false);
  const [isReplacementOfferModalOpen, setIsReplacementOfferModalOpen] = useState(false);
  const [isReplacementOrderModalOpen, setIsReplacementOrderModalOpen] = useState(false);
  const [DBBModalData, setModalDBBModalData] = useState<QuestionResult[] | null>(null);
  const [tyreReplacementOfferModalData, setTyreReplacementOfferModalData] = useState<QuestionResult | null>(null);
  const [tyreReplacementOrderModalData, setTyreReplacementOrderModalData] = useState<QuestionResult | null>(null);
  const tireData = useMemo(() => {
    const tyreLocationData: TyreLocationData = { onCar: [], inStorage: [], onTruck: [] };

    data?.forEach(item => {
      if (!item.tyre) return;

      if (tyrePosition.isOnCar(Number(item.tyre_position))) tyreLocationData.onCar.push(item);
      else if (tyrePosition.isInCar(Number(item.tyre_position))) tyreLocationData.inStorage.push(item);
      else tyreLocationData.onTruck.push(item);
    });

    return tyreLocationData;
  }, [data]);

  const handleModalClose = () => {
    setModalDBBModalData(null);
    setTyreReplacementOfferModalData(null);
    setTyreReplacementOrderModalData(null);
    setIsDBBModalOpen(false);
    setIsReplacementOfferModalOpen(false);
    setIsReplacementOrderModalOpen(false);
  };

  const handleDBBModal = (data: QuestionResult[]) => {
    setModalDBBModalData(data);
    setIsDBBModalOpen(true);
  };

  const handleTyreReplacementOfferModal = (data: QuestionResult) => {
    setTyreReplacementOfferModalData(data);
    setIsReplacementOfferModalOpen(true);
  };

  const handleTyreReplacementOrderModal = (data: QuestionResult) => {
    setTyreReplacementOrderModalData(data);
    setIsReplacementOrderModalOpen(true);
  };

  return (
    <div className="Tyre">
      <TyreChecklist
        onRequestDBBPreview={handleDBBModal}
        onRequestReplacement={handleTyreReplacementOfferModal}
        onRequestReplacementOrder={handleTyreReplacementOrderModal}
        data={data}
        template={template}
        appointment={appointment}
      />
      <DBBLabelsModal open={isDBBModalOpen} data={DBBModalData} onRequestClose={handleModalClose} appointment={appointment} />
      <ReplacementOfferModal
        tireData={tireData}
        appointment={appointment}
        open={isReplacementOfferModalOpen}
        data={tyreReplacementOfferModalData}
        onRequestClose={handleModalClose}
      />
      <ReplacementOrderModal
        tireData={tireData}
        appointment={appointment}
        open={isReplacementOrderModalOpen}
        data={tyreReplacementOrderModalData}
        onClose={handleModalClose}
      />
    </div>
  );
};
