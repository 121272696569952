import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessageListener } from "components";
import { Dealer, ExactCategory, ExactStandaloneLicense } from "models";
import { updateLicenseData } from "modules/DealerLicenseOverview/util";
import { Licenses } from "modules/LocationLicenseOverview/types";
import { updateCategoryArray, updateLicenseArray } from "modules/LocationLicenseOverview/utils";

type Props = {
  selectedDealer: Dealer | undefined;
};

export const useDealerLicenseOverviewListeners = ({ selectedDealer }: Props) => {
  const queryClient = useQueryClient();

  const listeners = useMemo((): WebSocketMessageListener[] => {
    if (!selectedDealer?.id) return [];

    return [
      {
        model: "ExactStandaloneLicense",
        action: "create",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];
              if (standaloneLicenses.some(lc => lc.id === license.id)) return licenses;

              return {
                ...licenses,
                monthlyStandalones: !license.invoice_on ? [...(licenses.monthlyStandalones ?? []), license] : licenses.monthlyStandalones,
                oneTimeStandalones: license.invoice_on ? [...(licenses.oneTimeStandalones ?? []), license] : licenses.oneTimeStandalones
              } as Licenses;
            },
            selectedDealer.id,
            queryClient
          );
        }
      },
      {
        model: "ExactStandaloneLicense",
        action: "update",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              return {
                ...licenses,
                monthlyStandalones: updateLicenseArray(licenses.monthlyStandalones, license, !license.invoice_on),
                oneTimeStandalones: updateLicenseArray(licenses.oneTimeStandalones, license, !!license.invoice_on)
              } as Licenses;
            },
            selectedDealer.id,
            queryClient
          );
        }
      },
      {
        model: "ExactStandaloneLicense",
        action: "delete",
        callback: message => {
          updateLicenseData(
            licenses => {
              const license = message.data as ExactStandaloneLicense;
              const standaloneLicenses = [...licenses.monthlyStandalones, ...licenses.oneTimeStandalones];

              if (!standaloneLicenses.some(lc => lc.id === license.id)) return licenses;
              return {
                ...licenses,
                monthlyStandalones: licenses.monthlyStandalones.filter(lc => lc.id !== license.id),
                oneTimeStandalones: licenses.oneTimeStandalones.filter(lc => lc.id !== license.id)
              } as Licenses;
            },
            selectedDealer.id,
            queryClient
          );
        }
      },
      {
        model: "ExactCategory",
        action: "update",
        callback: message => {
          updateLicenseData(
            licenses => {
              const category = message.data as ExactCategory;

              return {
                ...licenses,
                monthlyCategories: updateCategoryArray(licenses.monthlyCategories, category, !category.one_time),
                oneTimeCategories: updateCategoryArray(licenses.oneTimeCategories, category, category.one_time)
              } as Licenses;
            },
            selectedDealer.id,
            queryClient
          );
        }
      }
    ];
  }, [selectedDealer?.id]);

  return listeners;
};
