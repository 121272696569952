import { UseMutateFunction } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import { MANUFACTURER_REQUEST_STATUS, ManufacturerRequest, ManufacturerResponseModel } from "models/Manufacturer";
import "modules/ManufacturerDashboard/components/ManufacturerRequestModal/ManufacturerRequestModal.scss";
import { handleRequestMutationType } from "modules/ManufacturerDashboard/hooks/useHandleManufacturerRequest";
import { ITranslation } from "util/interfaces";

interface ManufacturerRequestModalPropTypes {
  onCloseModal: () => void;
  selectedRequest: ManufacturerRequest | null;
  handleManufacturerRequest: UseMutateFunction<null | undefined, Error, handleRequestMutationType, { manufacturerCache: ManufacturerResponseModel }>;
}
export const ManufacturerRequestModal = ({ onCloseModal, selectedRequest, handleManufacturerRequest }: ManufacturerRequestModalPropTypes) => {
  const [declineReason, setDeclineReason] = useState("");
  const t = useTranslation().t as ITranslation;

  const handlerForApproval = () => handleManufacturerRequest({ status: MANUFACTURER_REQUEST_STATUS.Approved }, { onSettled: () => setDeclineReason("") });
  const handlerForDecline = () => handleManufacturerRequest({ status: MANUFACTURER_REQUEST_STATUS.Declined, declineReason }, { onSettled: () => setDeclineReason("") });

  if (!selectedRequest) return null;

  return (
    <Modal open={Boolean(selectedRequest)} size="tiny">
      <Modal.Header className="Manufacturer__RequestModal-header">
        <span>{t("v8_confirmation_required").message || "Confirmation required"}</span>
      </Modal.Header>
      <Modal.Content className="Manufacturer__RequestModal-content">
        <span>{t("v8_approve_or_decline_manufacturer_request").message || "Once you approved or declined this request, you can't undo this action."}</span>
        <span>
          <b>{t("v8_manufacturer").message || "Manufacturer"}</b>: {selectedRequest.manufacturer?.first_name} {selectedRequest?.manufacturer?.last_name}
        </span>
        <span>
          <b>{t("v8_reason").message || "Reason"}</b>: {selectedRequest.request_reason}
        </span>
        <b>{t("v8_enter_a_reason_to_decline").message || "Please enter a reason if you want to decline this request"}</b>
        <textarea onChange={e => setDeclineReason(e.target.value)} value={declineReason} className="Manufacturer__RequestModal-content--textarea" />
      </Modal.Content>
      <Modal.Actions className="Manufacturer__RequestModal-actions">
        <Button onClick={onCloseModal} basic>
          {t("v8_cancel").message || "Cancel"}
        </Button>
        <Button onClick={handlerForDecline} basic disabled={!declineReason}>
          {t("v8_decline").message || "Decline"} <Icon className="x" />
        </Button>
        <Button onClick={handlerForApproval} className="Manufacturer__RequestModal-actions--approve">
          {t("v8_approve").message || "Approve"} <Icon className="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
