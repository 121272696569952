import { useQuery } from "@tanstack/react-query";

import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

type UseWarrantyExportModalProps = {
  isExportModalOpen: boolean;
};

export const useWarrantyExportModalQuery = () => {
  const {
    data: { isExportModalOpen }
  } = useQuery<UseWarrantyExportModalProps>({
    queryKey: WarrantyQueryKeys.warrantyExportModal,
    initialData: { isExportModalOpen: false },
    gcTime: Infinity
  });

  return { isExportModalOpen };
};

export const useGetWarrantyExportModalSetters = () => {
  const { updateFilters } = useGetFilterUpdaterFunctions<UseWarrantyExportModalProps>({ queryKey: WarrantyQueryKeys.warrantyExportModal });

  const handleCloseExportModal = () => updateFilters({ isExportModalOpen: false });
  const handleOpenExportModal = () => updateFilters({ isExportModalOpen: true });

  return { handleCloseExportModal, handleOpenExportModal };
};
