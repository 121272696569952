import { groupBy, sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { ChartPoint, TooltipProps, TyreMotionData, TyreMotionReportProps } from "modules/DealerReports/components/TyreMotionReport/types";
import "modules/DealerReports/components/TyreMotionReport/TyreMotionReport.scss";
import { ITranslation } from "util/interfaces";

interface CustomLabelProps {
  x?: string | number;
  y?: string | number;
  width?: string | number;
  value?: string | number;
}

const TYRE_MOTION_REPORT_COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#6edb8f", "#ffe366", "#ffc266", "#8784db"];

const transformData = (data: TyreMotionData[]): ChartPoint[] => {
  const groupedData = groupBy(data, item => `${item.year}-${String(item.month).padStart(2, "0")}`);

  const transformedData = Object.entries(groupedData).map(([dateKey, items]) => {
    const [year, month] = dateKey.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1, 1);
    const monthLabel = date.toLocaleDateString(undefined, { month: "short", year: "numeric" });

    const chartPoint: ChartPoint = {
      name: monthLabel,
      monthDate: date,
      rawData: items
    };

    items.forEach(item => {
      chartPoint[item.location] = item.usage;
      chartPoint[`${item.location}_limit`] = item.monthly_limit;
      chartPoint[`${item.location}_percent`] = Math.round((item.usage / item.monthly_limit) * 100);
      chartPoint[`${item.location}_label`] = `${item.usage}/${item.monthly_limit}`;
    });

    return chartPoint;
  });

  return sortBy(transformedData, point => point.monthDate.getTime());
};

const renderCustomLabel = (props: CustomLabelProps) => {
  const { x = 0, y = 0, width = 0, value } = props;
  return (
    <text x={Number(x) + Number(width) / 2} y={Number(y) - 6} className="TyreMotionReport__label-text" textAnchor="middle">
      {value}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
  const t = useTranslation().t as ITranslation;

  if (!active || !payload?.length) return null;

  const firstItem = payload[0]?.payload;

  return (
    <div className="TyreMotionReport__tooltip">
      <p className="TyreMotionReport__tooltip-label">{label}</p>
      {payload.map((entry, index) => {
        if (entry.dataKey.includes("_limit") || entry.dataKey.includes("_percent") || entry.dataKey.includes("_label")) return null;

        const location = entry.dataKey;
        const usage = firstItem[location];
        const limit = firstItem[`${location}_limit`];
        const percent = firstItem[`${location}_percent`];

        return (
          <div key={index} className="TyreMotionReport__tooltip-row">
            <p className="TyreMotionReport__tooltip-content">
              <span className="TyreMotionReport__tooltip-location" style={{ color: entry.color }}>
                {location}:
              </span>
              <span className="TyreMotionReport__tooltip-value">
                {t("v8_usage").message || "Usage"}: {usage} / {limit} ({percent}%)
              </span>
            </p>
          </div>
        );
      })}
    </div>
  );
};

export const TyreMotionReport = ({ data }: TyreMotionReportProps) => {
  const t = useTranslation().t as ITranslation;
  const transformedData = transformData(data);
  const uniqueLocations = Array.from(new Set(data.map(item => item.location)));

  return (
    <div className="TyreMotionReport__chart">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={transformedData} margin={{ top: 30, right: 30, left: 20, bottom: 20 }} barSize={35} barGap={5}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis label={{ value: t("v8_requests").message || "Requests", angle: -90, position: "insideLeft" }} allowDecimals={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          {uniqueLocations.map((location, index) => (
            <Bar key={location} dataKey={location} name={location} fill={TYRE_MOTION_REPORT_COLORS[index % TYRE_MOTION_REPORT_COLORS.length]}>
              <LabelList dataKey={`${location}_label`} position="top" content={renderCustomLabel} />
            </Bar>
          ))}

          {uniqueLocations.map((location, index) => {
            const locationData = data.find(item => item.location === location);
            if (!locationData) return null;

            const limit = locationData.monthly_limit;
            const color = TYRE_MOTION_REPORT_COLORS[index % TYRE_MOTION_REPORT_COLORS.length];

            return (
              <ReferenceLine
                key={`limit-${location}`}
                y={limit}
                stroke={color}
                strokeDasharray="3 3"
                label={{
                  value: `${location} limit: ${limit}`,
                  fill: color,
                  fontSize: 12,
                  position: "insideBottomRight"
                }}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
