import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

export type DeskCommService = {
  title: string;
  price: number;
  mechanic_notes?: string;
};

type ServicesProps = {
  title: string;
  services: DeskCommService[];
  vat?: number;
};

export const Services = ({ title, services, vat }: ServicesProps) => {
  if (!services?.length) return null;

  const t = useTranslation().t as ITranslation;
  return (
    <div className="desk-comm-section-container">
      <div className="desk-comm-section-header">{title}</div>

      <Grid>
        <Grid.Column width={16}>
          <div className="services-header">
            <span>{t("v8_title").message || "Title"}</span>
            <span>{vat ? t("v8_price_inc_vat").message || "Price (incl. VAT)" : t("v8_price_excl_vat").message || "Price (excl. VAT)"}</span>
          </div>
          {services.map((s, key) => (
            <div className="service" key={key}>
              <div className="service-title-remark-container">
                <span>{s.title}</span>
                <span>{s.mechanic_notes ?? ""}</span>
              </div>
              <div className="service-price-container">
                <span>{vat ? formatPrice(Number((s.price * (1 + vat / 100)).toFixed(2))) : formatPrice(s.price)} €</span>
              </div>
            </div>
          ))}
        </Grid.Column>
      </Grid>
    </div>
  );
};
