import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { ExportDataResponseStatus } from "components";
import ENV from "config/Env";
import { useRealTimeQuery } from "hooks";
import { Brand, STATUS_IDENTIFIER, Warranty } from "models";
import { ORDER_BY, useGetWarrantyDashboardFilters } from "modules/WarrantyDashboard/hooks";
import { useWarrantyDashboardListeners } from "modules/WarrantyDashboard/listeners";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyDashboardFiltersPayload, WarrantyWithHistory } from "modules/WarrantyDashboard/types";
import { normalizeData } from "modules/WarrantyDashboard/util";
import ApiInstance from "util/Api";
import { SelectOption } from "util/interfaces";
import { IBackendQueryKey } from "util/keyFactory";

const getBrands = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint } = queryKey[0];
  const { data } = await ApiInstance.get(endpoint, baseUrl);

  return data.brands.reduce((acc: SelectOption<string>[], brand: Brand): SelectOption<string>[] => {
    acc.push({ key: brand.vin_prefix, value: brand.vin_prefix, text: `${brand.name} - ${brand.vin_prefix}` });

    return acc;
  }, []);
};

const preparePayload = (filters: WarrantyDashboardFiltersPayload) => {
  const {
    claim_order_by,
    order_by,
    appointment_statuses,
    time_period: _timePeriod,
    pin_status_id,
    pin_status_refresh_timestamp: _pinStatusRefreshTimestamp,
    ...rest
  } = filters;
  const activeAppointmentStatuses = appointment_statuses.filter(({ isActive }) => isActive).map(({ id }) => id);
  const selectedAppointmentStatuses = [...activeAppointmentStatuses, STATUS_IDENTIFIER.ContactStatus, STATUS_IDENTIFIER.CarCheckStartedStatus];

  return {
    ...rest,
    order_by: claim_order_by || order_by,
    appointment_statuses: selectedAppointmentStatuses,
    pin_status_id: pin_status_id?.length ? pin_status_id : null
  };
};

const getWarrantiesData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>): Promise<WarrantyWithHistory | undefined> => {
  const { baseUrl, endpoint, params } = queryKey[0];
  if (!params) return;

  const payload = params.filters as WarrantyDashboardFiltersPayload;
  const response = await ApiInstance.post(endpoint, preparePayload(payload), baseUrl);
  const warrantyData = response.data as Warranty;
  const orderBy = (payload?.order_by || payload.claim_order_by) ?? ORDER_BY.AppointmentDateAscending;

  return { ...warrantyData, items: normalizeData(warrantyData?.items || [], orderBy) };
};

export const useBrandsQuery = () => {
  const { data: brandsOptions = [] } = useQuery({ queryKey: WarrantyQueryKeys.listBrands(), queryFn: getBrands });
  return brandsOptions;
};

export const useWarrantyQuery = () => {
  const { filters } = useGetWarrantyDashboardFilters();
  const listeners = useWarrantyDashboardListeners();

  const hasDealerLocationSelected = filters.location_ids?.length > 0 || filters.dealer_ids?.length > 0;
  const hasDateRangeSelected = filters.date_from && filters.date_to;
  const isEnabled = !!(hasDealerLocationSelected && hasDateRangeSelected);

  const { data, isLoading } = useRealTimeQuery({
    queryKey: WarrantyQueryKeys.listWarranties({ filters }),
    queryFn: getWarrantiesData,
    enabled: !!isEnabled,
    listeners
  });

  return { warrantyData: data?.items || [], nbItems: data?.nb_items || 0, nbPages: data?.nb_pages || 1, isLoading };
};

export const exportWarrantyDashboardData = async (params: WarrantyDashboardFiltersPayload): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/pins/export", preparePayload(params), ENV.appointmentBaseURL);
    window.open(response?.data?.url, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};
