import { useTranslation } from "react-i18next";

import "components/NavBarSearchAppointments/components/SearchResultsDivider/SearchResultsDivider.scss";
import { ITranslation } from "util/interfaces";

export const SearchResultsDivider = () => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="other-locations-container">
      <div className="other-locations-divider" />
      <div className="other-locations-text-container">
        <span>{t("v8_other_locations").message || "Other Locations"}</span>
      </div>
      <div className="other-locations-divider" />
    </div>
  );
};
