import { useTranslation } from "react-i18next";
import { Card, CardDescription, Icon, Popup } from "semantic-ui-react";

import "components/CustomerCard/CustomerCard.scss";
import { Customer } from "models";
import { ITranslation } from "util/interfaces";

type Props = {
  contractor: Customer | null;
  driver: Customer | null;
  owner: Customer | null;
};

type RenderPersonCardProps = {
  person: Customer | null;
  title: "contractor" | "driver" | "owner";
};

type LeaseCompanyTagProps = {
  isLeaseCompany: boolean;
};

const LeaseCompanyTag = ({ isLeaseCompany }: LeaseCompanyTagProps) => {
  const t = useTranslation().t as ITranslation;
  if (!isLeaseCompany) return null;

  return (
    <CardDescription
      content={
        <span>
          <Icon className="building" />
          {t("v8_lease_company").message || "Lease Company"}
        </span>
      }
    />
  );
};

const PersonCard = ({ person, title }: RenderPersonCardProps) => {
  const t = useTranslation().t as ITranslation;

  if (!person) return null;
  const { surname, is_lease_company, tel_private_nr, tel_business_nr, tel_mobile_private, tel_mobile_business, email_private, email_business } = person;

  return (
    <Card className="CustomerCard__customer-card-no-box-shadow">
      <Card.Content>
        <Card.Header content={surname} />
        <Card.Meta content={t(title).message || title} />
        <LeaseCompanyTag isLeaseCompany={is_lease_company} />
        {tel_private_nr && <Card.Description content={`${t("v8_private_phone").message || "Private phone"}: ${tel_private_nr}`} />}
        {tel_business_nr && <Card.Description content={`${t("v8_business_phone").message || "Business phone"}: ${tel_business_nr}`} />}
        {tel_mobile_private && <Card.Description content={`${t("v8_private_mobile").message || "Private mobile"}: ${tel_mobile_private}`} />}
        {tel_mobile_business && <Card.Description content={`${t("v8_business_mobile").message || "Business mobile"}: ${tel_mobile_business}`} />}
        {email_private && <Card.Description content={`${t("v8_private_email").message || "Private email"}: ${email_private}`} />}
        {email_business && <Card.Description content={`${t("v8_business_email").message || "Business email"}: ${email_business}`} />}
      </Card.Content>
    </Card>
  );
};

export const CustomerCard = ({ contractor, driver, owner }: Props) => {
  const t = useTranslation().t as ITranslation;

  const hasCustomer = contractor || driver || owner;

  return (
    <Popup
      on="hover"
      position="bottom left"
      content={
        hasCustomer ? (
          <>
            <PersonCard person={contractor} title="contractor" />
            <PersonCard person={driver} title="driver" />
            <PersonCard person={owner} title="owner" />
          </>
        ) : (
          <span>{t("v8_unknown_customer").message || "Unknown customer"}</span>
        )
      }
      trigger={
        <div className="CustomerCard__customer-card-container">
          <div className="CustomerCard__customer-card-user">
            <Icon className="user CustomerCard__customer-card-icon" />
          </div>
        </div>
      }
    />
  );
};
