import { DATE_FORMATS_FNS } from "components";
import { Check } from "models";
import "modules/AppointmentDetails/components/Interventions/components/InterventionChecklist/InterventionChecklist.scss";
import { formatDate } from "util/dateHelperFunctions";

interface InterventionChecklistPropTypes {
  checklist: Check[];
}

export const InterventionChecklist = ({ checklist }: InterventionChecklistPropTypes) => {
  return (
    <div className="connected-checklists-container">
      {checklist.map(checklist => (
        <div className="connected-checklist" key={checklist.id}>
          <b>{checklist.checklist?.name}</b>
          {checklist.mechanic && (
            <span>
              - {checklist.mechanic.first_name} {checklist.mechanic.last_name} -
            </span>
          )}
          <span>
            {formatDate(checklist.created_on, DATE_FORMATS_FNS.timeDateMonthYear)} {">"} {formatDate(checklist.updated_on, DATE_FORMATS_FNS.timeDateMonthYear)}{" "}
          </span>
        </div>
      ))}
    </div>
  );
};
