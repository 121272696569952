import { saveAs } from "file-saver";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import "components/FilePreview/FilePreview.scss";
import { checkIsFileImage, checkShouldRenderIFrame } from "components/FilePreview/utils";
import { AppointmentNoteAttachment, SnoozeAttachment } from "models";
import { getExtensionFromURL, getNameFromURL, getPreviewUrl } from "util/common";
import { ITranslation } from "util/interfaces";

enum KEY_CODES {
  LeftArrow = 37,
  RightArrow = 39
}

type FilePreviewProps = {
  attachments?: (AppointmentNoteAttachment | SnoozeAttachment)[];
  open: boolean;
  onClose?: () => void;
  previewIndex: number | null;
  handleSelectedAttachment: Dispatch<SetStateAction<number | null>>;
};

export const FilePreview = ({ attachments, open, onClose, previewIndex, handleSelectedAttachment }: FilePreviewProps) => {
  if (!attachments?.length) return null;

  const t = useTranslation().t as ITranslation;
  const filePreviewContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    filePreviewContentRef.current?.focus();
  }, [filePreviewContentRef.current]);

  if (previewIndex === null) return null;

  const selectedFile = attachments[previewIndex];
  const type = "type" in selectedFile ? selectedFile.type : getExtensionFromURL(selectedFile.url);
  const name = "name" in selectedFile ? selectedFile.name : getNameFromURL(selectedFile.url);

  const isFileImage = checkIsFileImage(type, name, selectedFile);
  const shouldRenderIFrame = checkShouldRenderIFrame(type);

  const handleClosePreviewModal = () => {
    onClose?.();
  };

  const handleArrowClick = (orientation: "right" | "left") => {
    handleSelectedAttachment(currentValue => {
      if (currentValue === null) return null;
      if (orientation === "right") return currentValue + 1;
      else return currentValue - 1;
    });
  };

  const handlePreviewUsingKeyboard = (evt: { stopPropagation: () => void; keyCode: number }) => {
    evt.stopPropagation();
    const keyCode = evt.keyCode;

    if (![KEY_CODES.LeftArrow, KEY_CODES.RightArrow].includes(keyCode)) return;

    const previewModalAttachmentIndex = attachments.findIndex(f => f.url === selectedFile.url);

    if (keyCode === KEY_CODES.LeftArrow && previewModalAttachmentIndex > 0) handleSelectedAttachment(previewModalAttachmentIndex - 1);
    else if (keyCode === KEY_CODES.RightArrow && previewModalAttachmentIndex < attachments.length - 1) handleSelectedAttachment(previewModalAttachmentIndex + 1);
  };

  return (
    <Modal open={open} closeOnDimmerClick={false} className="FilePreviewModal" centered tabIndex="0" onKeyDown={handlePreviewUsingKeyboard} openOnTriggerFocus>
      {attachments.length > 1 && (
        <>
          {previewIndex > 0 && <Icon className="chevron left left-arrow" onClick={() => handleArrowClick("left")} />}
          {previewIndex < attachments.length - 1 && <Icon className="chevron right right-arrow" onClick={() => handleArrowClick("right")} />}
        </>
      )}

      <Modal.Header>
        <div className="FilePreviewHeader">
          <div>
            <div className="FilePreviewTitle">{t("v8_file_preview").message || "File preview"}</div>
            <div className="FilePreviewName">{name}</div>
          </div>

          <div className="Actions">
            <Button color="teal" onClick={() => saveAs(selectedFile.url, name)}>
              {t("v8_download").message || "Download"}
            </Button>

            <Button color="green" onClick={handleClosePreviewModal}>
              {t("v8_close").message || "Close"}
            </Button>
          </div>
        </div>
      </Modal.Header>

      <Modal.Content>
        <div className="FilePreviewContent" tabIndex={0} ref={filePreviewContentRef}>
          {isFileImage ? (
            <img src={selectedFile.url} alt="preview" />
          ) : shouldRenderIFrame ? (
            <iframe src={getPreviewUrl(selectedFile.url)} title={selectedFile.url} width="100%" height="100%" />
          ) : (
            <h2>{t("v8_file_format_is_not_supported_for_preview").message || "File format is not supported for preview"}</h2>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};
