import { DealersLocationsDropdownValue } from "components";
import { useDefaultBackordersDashboardFilters } from "modules/BackordersDashboard/hooks";
import { BackordersQueryKeys } from "modules/BackordersDashboard/queryKeys";
import { BackordersDashboardFiltersPayload } from "modules/BackordersDashboard/types";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useBackordersDashboardFilterHandlers = () => {
  const { defaultBackordersFilters } = useDefaultBackordersDashboardFilters();
  const { updateFilters } = useGetFilterUpdaterFunctions<BackordersDashboardFiltersPayload>({ queryKey: BackordersQueryKeys.backordersFilters });

  const handleResetFilters = () => updateFilters({ ...defaultBackordersFilters });
  const handleSearchChange = (value: string) => updateFilters({ search_term: value });
  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => updateFilters({ dealer_ids: dealerIds, location_ids: locationIds });

  return { handleResetFilters, handleSearchChange, handleDealerLocationChange };
};
