import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { StatusData, statusData } from "components";
import { getPreference, setPreference } from "util/common";
import { queryKeys } from "util/keyFactory";

const PREFERENCE_KEY_SELECTED_IDENTIFIERS = "preferences-selected-status-identifiers";

export const useGetAppointmentsSelectedIdentifiers = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [queryKeys.appointments.selectedIdentifiers],
    queryFn: (): StatusData[] =>
      queryClient.getQueryData([queryKeys.appointments.selectedIdentifiers]) || getPreference(PREFERENCE_KEY_SELECTED_IDENTIFIERS, [...statusData]),
    gcTime: Infinity
  });

  return query.data || [];
};

export const useUpdateAppointmentsSelectedIdentifiers = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (newStatusIdentifiers: StatusData[]): Promise<StatusData[]> => Promise.resolve(newStatusIdentifiers),
    onMutate: (newStatusIdentifiers: StatusData[]) => {
      queryClient.setQueryData([queryKeys.appointments.selectedIdentifiers], [...newStatusIdentifiers]);
      setPreference(PREFERENCE_KEY_SELECTED_IDENTIFIERS, [...newStatusIdentifiers]);
    }
  });

  return mutation.mutate;
};

export const useResetAppointmentsSelectedIdentifiers = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.setQueryData([queryKeys.appointments.selectedIdentifiers], [...statusData]);
    setPreference(PREFERENCE_KEY_SELECTED_IDENTIFIERS, [...statusData]);
  };
};
