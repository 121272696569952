import { useTranslation } from "react-i18next";

import { PIN_STATUS, PIN_TYPE, PIN_WARRANTY, QUESTION_STATUSES } from "models";
import { ITranslation, SelectOption } from "util/interfaces";

export interface SelectOptionWithIcon<T> extends SelectOption<T> {
  icon: string | null;
  iconColor?: string;
}

interface SelectOptionWithFilterValue<T> extends SelectOption<T> {
  filterValue: boolean | null;
}

export enum REG_VIN_FILTER {
  Reg = 1,
  Vin
}

export enum ORDER_BY {
  AppointmentDateAscending = 1,
  AppointmentDateDescending,
  PinUpdatedOnAscending,
  PinUpdatedOnDescending,
  ClaimAscending,
  ClaimDescending
}

export enum WARRANTY_TIME_PERIODS {
  CustomTimePeriod = 0,
  LastMonth = 1,
  LastThreeMonths = 3,
  LastSixMonths = 6,
  LastNineMonths = 9,
  LastYear = 12,
  LastTwoYears = 24
}

interface QuestionStatusType extends SelectOptionWithIcon<string | number> {
  filterValue: QUESTION_STATUSES | null;
}

export const useRegVinNumberOptions = (): SelectOption<number>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: REG_VIN_FILTER.Reg, value: REG_VIN_FILTER.Reg, text: t("v8_reg_number").message || "Reg #" },
    { key: REG_VIN_FILTER.Vin, value: REG_VIN_FILTER.Vin, text: t("v8_vin_number").message || "Vin #" }
  ];
};

export const useGetMechanicFixedOptions = (): SelectOptionWithFilterValue<string | number>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: "mechanic-fixed-1", value: 1, text: t("v8_yes").message || "Yes", filterValue: true },
    { key: "mechanic-fixed-2", value: 2, text: t("v8_no").message || "No", filterValue: false }
  ];
};

export const useGetTimePeriodOptions = (): SelectOption<number | string>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.LastMonth}`,
      value: WARRANTY_TIME_PERIODS.LastMonth,
      text: t("v8_pinned_in_the_last_month").message || "Pinned in the last month"
    },
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.LastThreeMonths}`,
      value: WARRANTY_TIME_PERIODS.LastThreeMonths,
      text: t("v8_pinned_in_the_last_3_months").message || "Pinned in the last 3 months"
    },
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.LastSixMonths}`,
      value: WARRANTY_TIME_PERIODS.LastSixMonths,
      text: t("v8_pinned_in_the_last_6_months").message || "Pinned in the last 6 months"
    },
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.LastNineMonths}`,
      value: WARRANTY_TIME_PERIODS.LastNineMonths,
      text: t("v8_pinned_in_the_last_9_months").message || "Pinned in the last 9 months"
    },
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.LastYear}`,
      value: WARRANTY_TIME_PERIODS.LastYear,
      text: t("v8_pinned_in_the_last_12_months").message || "Pinned in the last 12 months"
    },
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.LastTwoYears}`,
      value: WARRANTY_TIME_PERIODS.LastTwoYears,
      text: t("v8_pinned_in_the_last_24_months").message || "Pinned in the last 24 months"
    },
    {
      key: `time-period-${WARRANTY_TIME_PERIODS.CustomTimePeriod}`,
      value: WARRANTY_TIME_PERIODS.CustomTimePeriod,
      text: t("v8_custom_pin_dates").message || "Custom pin dates"
    }
  ];
};

export const useAppointmentOrderingOptions = (): SelectOptionWithIcon<number | string>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    {
      key: `appointment-ordering-${ORDER_BY.AppointmentDateAscending}`,
      value: ORDER_BY.AppointmentDateAscending,
      text: t("v8_appointment_date").message || "Appointment date",
      icon: "arrow down a z"
    },
    {
      key: `appointment-ordering-${ORDER_BY.AppointmentDateDescending}`,
      value: ORDER_BY.AppointmentDateDescending,
      text: t("v8_appointment_date").message || "Appointment date",
      icon: "arrow up a z"
    },
    {
      key: `appointment-ordering-${ORDER_BY.PinUpdatedOnAscending}`,
      value: ORDER_BY.PinUpdatedOnAscending,
      text: t("v8_last_updated").message || "Last updated",
      icon: "arrow down a z"
    },
    {
      key: `appointment-ordering-${ORDER_BY.PinUpdatedOnDescending}`,
      value: ORDER_BY.PinUpdatedOnDescending,
      text: t("v8_last_updated").message || "Last updated",
      icon: "arrow up a z"
    }
  ];
};

export const useGetPinStatusOptions = (): SelectOption<number | string>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: `pin-status-${PIN_STATUS.NotSet}`, value: PIN_STATUS.NotSet, text: t("v8_not_set").message || "Not set" },
    { key: `pin-status-${PIN_STATUS.Support}`, value: PIN_STATUS.Support, text: t("v8_support").message || "Support" },
    { key: `pin-status-${PIN_STATUS.ApprovalPending}`, value: PIN_STATUS.ApprovalPending, text: t("v8_approval_pending").message || "Approval pending" },
    { key: `pin-status-${PIN_STATUS.MoreInfo}`, value: PIN_STATUS.MoreInfo, text: t("v8_more_information").message || "More Info" },
    { key: `pin-status-${PIN_STATUS.Approved}`, value: PIN_STATUS.Approved, text: t("v8_approved").message || "Approved" },
    { key: `pin-status-${PIN_STATUS.Declined}`, value: PIN_STATUS.Declined, text: t("v8_declined").message || "Declined" },
    { key: `pin-status-${PIN_STATUS.ToClaim}`, value: PIN_STATUS.ToClaim, text: t("v8_to_claim").message || "To claim" },
    { key: `pin-status-${PIN_STATUS.Claimed}`, value: PIN_STATUS.Claimed, text: t("v8_claimed").message || "Claimed" },
    { key: `pin-status-${PIN_STATUS.Billed}`, value: PIN_STATUS.Billed, text: t("v8_billed").message || "Billed" },
    { key: `pin-status-${PIN_STATUS.Request}`, value: PIN_STATUS.Request, text: t("v8_request").message || "Request" },
    { key: `pin-status-${PIN_STATUS.TechnicalInfo}`, value: PIN_STATUS.TechnicalInfo, text: t("v8_technical_info").message || "Technical info" },
    { key: `pin-status-${PIN_STATUS.Deleted}`, value: PIN_STATUS.Deleted, text: t("v8_deleted").message || "Deleted" }
  ];
};

export const useGetPinWarrantyTypeOptions = (): SelectOption<number | string>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: `pin-warranty-all`, value: "all", text: t("v8_all").message || "All" },
    { key: `pin-warranty-${PIN_WARRANTY.NotSet}`, value: PIN_WARRANTY.NotSet, text: t("v8_not_set").message || "Not Set" },
    { key: `pin-warranty-${PIN_WARRANTY.Factory}`, value: PIN_WARRANTY.Factory, text: t("v8_factory").message || "Factory" },
    { key: `pin-warranty-${PIN_WARRANTY.Leniency}`, value: PIN_WARRANTY.Leniency, text: t("v8_leniency").message || "Leniency" },
    { key: `pin-warranty-${PIN_WARRANTY.BodyPaint}`, value: PIN_WARRANTY.BodyPaint, text: t("v8_body_paint").message || "Body Paint" },
    { key: `pin-warranty-${PIN_WARRANTY.OnRepair}`, value: PIN_WARRANTY.OnRepair, text: t("v8_on_repair").message || "On Repair" },
    { key: `pin-warranty-${PIN_WARRANTY.Occasion}`, value: PIN_WARRANTY.Occasion, text: t("v8_occasion").message || "Occasion" },
    { key: `pin-warranty-${PIN_WARRANTY.Service}`, value: PIN_WARRANTY.Service, text: t("v8_service").message || "Service" }
  ];
};

export const useGetPinTypeOptions = (): SelectOption<number | string>[] => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: `pin-type-all`, value: "all", text: t("v8_all").message || "All" },
    { key: `pin-type-${PIN_TYPE.Remarks}`, value: PIN_TYPE.Remarks, text: t("v8_remarks").message || "Remarks" },
    { key: `pin-type-${PIN_TYPE.Warranty}`, value: PIN_TYPE.Warranty, text: t("v8_warranty").message || "Warranty" },
    { key: `pin-type-${PIN_TYPE.Recall}`, value: PIN_TYPE.Recall, text: t("v8_recall").message || "Recall" }
  ];
};

export const useGetQuestionStatusOptions = (): QuestionStatusType[] => {
  const t = useTranslation().t as ITranslation;

  return [
    { key: "all", value: "all", text: t("v8_all").message || "All", icon: null, filterValue: null, iconColor: "" },
    {
      key: QUESTION_STATUSES.OK,
      value: QUESTION_STATUSES.OK,
      text: t("v8_Ok").message || "Ok",
      icon: "file",
      iconColor: "#21BA45",
      filterValue: QUESTION_STATUSES.OK
    },
    {
      key: QUESTION_STATUSES.Advice,
      value: QUESTION_STATUSES.Advice,
      text: t("v8_advice").message || "Advice",
      icon: "triangle exclamation",
      iconColor: "#FFBA39",
      filterValue: QUESTION_STATUSES.Advice
    },
    {
      key: QUESTION_STATUSES.Necessary,
      value: QUESTION_STATUSES.Necessary,
      text: t("v8_necessary").message || "Necessary",
      icon: "circle exclamation",
      iconColor: "#FF0202",
      filterValue: QUESTION_STATUSES.Necessary
    }
  ];
};
