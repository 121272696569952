import { ReactElement } from "react";
import { Icon, Label, Popup } from "semantic-ui-react";

import { Pin } from "components";
import { Appointment } from "models";
import "modules/Dayplanner/components/AppointmentCard/components/InterventionQuickView/InterventionQuickView.scss";
import { formatDurationFromMinutes } from "util/dateHelperFunctions";

type InterventionQuickViewProps = {
  appointment: Appointment;
  popupTrigger: ReactElement;
};

type ReadyStatusProps = {
  isFixed: boolean;
};

const ReadyStatus = ({ isFixed }: ReadyStatusProps) => {
  return (
    <div className="mechanic-fixed-status-container">
      {isFixed ? (
        <>
          <Icon className="wrench green pointer" size="small" />
          <Icon className="check circle green wrench-check-icon" />
        </>
      ) : (
        <Icon className="wrench regular grey rotate-270" size="small" />
      )}
    </div>
  );
};

export const InterventionQuickView = ({ appointment, popupTrigger }: InterventionQuickViewProps) => {
  if (!appointment.interventions) return popupTrigger;
  const content = appointment.interventions.map(i => {
    const latestPinHistory = i.pin_history?.at(0);

    return (
      <div className="InterventionQuickView-interventions-container" key={i.id}>
        <div className="title-description-container">
          <div className="title">{i.title}</div>
        </div>

        <div className="special-indicators-container">
          {i.labor_minutes ? (
            <span className="labor-minutes">
              <Icon className="clock" />
              {formatDurationFromMinutes(i.labor_minutes)}
            </span>
          ) : null}
          <span className="AnswerReadyStatus">
            {latestPinHistory && <Pin pin={latestPinHistory} />}
            <Label>
              <Icon className={`${i.customer_ok ? "check circle green" : "remove circle red"}`} />
            </Label>
            <ReadyStatus isFixed={i.mechanic_fixed} />
          </span>
        </div>
      </div>
    );
  });
  return <Popup trigger={popupTrigger} content={<div className="InterventionQuickView-content">{content}</div>} hoverable style={{ padding: "0" }} />;
};
