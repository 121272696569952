import { Appointment, Intervention, QuestionResult, User } from "models";
import ApiLoadable from "models/ApiLoadable";
export enum PIN_TYPE {
  NotSet = 0,
  Remarks,
  Warranty,
  Recall
}

export enum PIN_STATUS {
  NotSet = 0,
  Deleted,
  Support,
  ApprovalPending,
  MoreInfo,
  Approved,
  Declined,
  ToClaim,
  Claimed,
  Billed,
  Request,
  TechnicalInfo
}

export enum PIN_ORDER {
  SameDay = 1,
  ToOrder,
  NextDay,
  BackOrder,
  Arrived
}

export enum PIN_WARRANTY {
  NotSet = 0,
  Factory,
  Leniency,
  BodyPaint,
  OnRepair,
  Occasion,
  Service
}

export class PinModel extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  question_result_id: number | null = null;
  intervention_id: number | null = null;
  pin_type_id: number = 0;
  warranty_type_id: number | null = null;
  pin_status_id: PIN_STATUS | null = null;
  claim_nr: number | string = "";
  ref_nr: number | string = "";
  support_nr: number | string = "";
  order_status: number | null = null;
  note: string = "";
  visible_important_items: boolean = false;
  visible_mechanic: boolean = false;
  keep_parts: boolean = false;
  info: boolean = false;
  mechanic_fixed: boolean = false;
  is_dms: boolean = false;
  user_id: number = 0;
  appointment_id: number | null | undefined = 0;
  dealer_location_id: number = 0;
  car_id: number = 0;
  manufacturer_id: number | null = null;

  user?: User | null = null;
  appointment?: Appointment | null = null;
  intervention?: Intervention | null = null;
  question_result?: QuestionResult | null = null;
  manufacturer?: User | null = null;
  reg_number?: string | null = null;
  dealer_id?: number | null = null;
  dealer_name?: string | null = null;
  location_name?: string | null = null;

  private _note_attachments?: string[] | null = null;

  get note_attachments(): string[] {
    return this._note_attachments ? this._note_attachments : [];
  }

  set note_attachments(note_attachments: string[] | null | undefined) {
    this._note_attachments = note_attachments;
  }
}

export class PinDropdownOptions extends ApiLoadable {
  private _pin_order_statuses?: PinOptions[] | null = null;

  get pin_order_statuses(): PinOptions[] {
    return this._pin_order_statuses ? this._pin_order_statuses : [];
  }

  set pin_order_statuses(pin_order_statuses: PinOptions[] | null | undefined) {
    this._pin_order_statuses = pin_order_statuses;
  }

  private _pin_statuses?: PinOptions[] | null = null;

  get pin_statuses(): PinOptions[] {
    return this._pin_statuses ? this._pin_statuses : [];
  }

  set pin_statuses(pin_statuses: PinOptions[] | null | undefined) {
    this._pin_statuses = pin_statuses;
  }

  private _pin_types?: PinOptions[] | null = null;

  get pin_types(): PinOptions[] {
    return this._pin_types ? this._pin_types : [];
  }

  set pin_types(pin_types: PinOptions[] | null | undefined) {
    this._pin_types = pin_types;
  }

  private _warranty_types?: PinOptions[] | null = null;

  get warranty_types(): PinOptions[] {
    return this._warranty_types ? this._warranty_types : [];
  }

  set warranty_types(warranty_types: PinOptions[] | null | undefined) {
    this._warranty_types = warranty_types;
  }
}

export class PinOptions extends ApiLoadable {
  id: number = 0;
  name: string = "";
}
