import { useQuery } from "@tanstack/react-query";

import { BackordersQueryKeys } from "modules/BackordersDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

type UseBackordersExportModalProps = {
  isExportModalOpen: boolean;
};

export const useBackordersExportModalQuery = () => {
  const {
    data: { isExportModalOpen }
  } = useQuery<UseBackordersExportModalProps>({
    queryKey: BackordersQueryKeys.backordersExportModal,
    initialData: { isExportModalOpen: false },
    gcTime: Infinity
  });
  return { isExportModalOpen };
};

export const useGetBackordersExportModalSetters = () => {
  const { updateFilters } = useGetFilterUpdaterFunctions<UseBackordersExportModalProps>({ queryKey: BackordersQueryKeys.backordersExportModal });
  const handleCloseExportModal = () => updateFilters({ isExportModalOpen: false });
  const handleOpenExportModal = () => updateFilters({ isExportModalOpen: true });
  return { handleCloseExportModal, handleOpenExportModal };
};
