/* eslint-disable */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
 * Tell the browser that the event listener won't prevent a scroll.
 * Allowing the browser to continue scrolling without having to
 * to wait for the listener to return.
 */
exports.addPassiveEventListener = function addPassiveEventListener(target, eventName, listener) {
  var supportsPassiveOption = (function () {
    var supportsPassiveOption = false;
    try {
      var opts = Object.defineProperty({}, "passive", {
        get: function get() {
          supportsPassiveOption = true;
          return null;
        }
      });
      window.addEventListener("test", null, opts);
    } catch (e) {}
    return supportsPassiveOption;
  })();
  target.addEventListener(eventName, listener, supportsPassiveOption ? { passive: true } : false);
};

exports.removePassiveEventListener = function removePassiveEventListener(target, eventName, listener) {
  target.removeEventListener(eventName, listener);
};
