import { useQuery } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { StatusData, statusData } from "components/StatusFilters";
import { PIN_STATUS, PIN_TYPE } from "models";
import { MANUFACTURER_REQUEST_STATUS, MANUFACTURER_STATUS, WARRANTY_TYPE } from "models";
import { MANUFACTURER_PAGE_SIZE } from "modules/ManufacturerDashboard/components";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import { FAKE_STATUSES } from "util/appointmentUtils";
import { useGetFilterUpdaterFunctions } from "util/common";

export interface ManufacturerFilterType {
  isMechanicFixed: boolean | null;
  statusIdentifiers: StatusData[];
  page: number;
  pinStatusIds: PIN_STATUS[] | null;
  pinTypeIds: PIN_TYPE[] | null;
  requestStatus: MANUFACTURER_REQUEST_STATUS | null;
  searchTerm: string | null;
  manufacturerStatus: MANUFACTURER_STATUS | null;
  warrantyTypeId: WARRANTY_TYPE | null;
  dealerIds: number[];
  locationIds: number[];
}

const defaultManufacturerFilters: ManufacturerFilterType = {
  isMechanicFixed: null,
  page: 1,
  pinStatusIds: null,
  pinTypeIds: [],
  requestStatus: null,
  searchTerm: null,
  manufacturerStatus: null,
  warrantyTypeId: null,
  dealerIds: [],
  locationIds: [],
  statusIdentifiers: statusData.filter(status => !FAKE_STATUSES.includes(status.id))
};

export const useGetManufacturerDashboardFilters = () => {
  const { data: manufacturerDashboardFilters } = useQuery<ManufacturerFilterType>({
    queryKey: ManufacturerQueryKeys.manufacturerFilters,
    queryFn: () => Promise.resolve(defaultManufacturerFilters),
    initialData: defaultManufacturerFilters,
    gcTime: Infinity
  });

  return { manufacturerDashboardFilters };
};

export const useUpdateManufacturerDashboardFilters = () => {
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();
  const { updateFilters } = useGetFilterUpdaterFunctions<ManufacturerFilterType>({ queryKey: ManufacturerQueryKeys.manufacturerFilters });

  const updatePinDropdownOptions = (selectedValues: string[]) => {
    if (selectedValues.at(0) === "all") selectedValues.shift();
    const selectedValueFormatted = selectedValues.includes("all") ? [] : selectedValues.map(value => Number(value));

    updateFilters({ pinTypeIds: selectedValueFormatted });
  };

  const onPageChange = (data: { activePage: number }) => updateFilters({ page: data.activePage }, false);

  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const isUpdaterFunction = typeof updater === "function";
    const currentPage = manufacturerDashboardFilters.page;
    const newState = isUpdaterFunction ? updater({ pageIndex: currentPage - 1, pageSize: MANUFACTURER_PAGE_SIZE }) : updater;
    onPageChange({ activePage: newState.pageIndex + 1 });
  };

  const handleResetFilters = () => updateFilters(defaultManufacturerFilters);

  return { updatePinDropdownOptions, handlePaginationSelect, handleResetFilters };
};
