import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import "components/PonRecall/components/PonRecallModal/styles.scss";
import { PonRecallResponseModel } from "components/PonRecall/types";
import { capitalizeFirstLetter } from "util/common";
import { ITranslation } from "util/interfaces";

type PonRecallModalPropTypes = {
  showModal: boolean;
  closeModal: () => void;
  data: PonRecallResponseModel | null | undefined;
  error: Error | null | undefined;
};

export const PonRecallModal = ({ showModal, closeModal, data, error }: PonRecallModalPropTypes) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Modal open={showModal}>
      <Modal.Header className="Pon__modal-header">
        <span>{t("v8_pon_recall").message || "Pon Recall"}</span>
        <Button className="-modal-x-button" size="small" onClick={closeModal}>
          <Icon className="xmark grey" size="big" />
        </Button>
      </Modal.Header>

      <Modal.Content scrolling className="Pon__modal-content">
        {error && <span className="error-msg">{capitalizeFirstLetter(error.message)}</span>}
        {data && (
          <>
            <div className="Pon__modal-vin">
              <b>Vin:</b>
              <span>{data.vehicleRef.vin.value}</span>
            </div>
            <div className="Pon__modal-content--gridContainer">
              <span className="Pon__title">{t("v8_service_campaigns").message || "Service campaigns"}</span>
              {data.serviceCampaigns?.serviceCampaign?.map(campaign => {
                const {
                  serviceCampaignIdentifier: { serviceCampaignUID },
                  campaignDescription,
                  campaignType,
                  startDate
                } = campaign;

                return (
                  <div key={serviceCampaignUID.value} className="Pon__modal-content--row">
                    <div className="Pon__modal--itemContainer">
                      <span>{t("v8_service_campaign_id").message || "Service Campaign ID"} :</span>
                      <span>{serviceCampaignUID.value}</span>
                    </div>

                    <div className="Pon__modal--itemContainer">
                      <span>{t("v8_desc").message || "Description"}:</span>
                      <span>{campaignDescription.value}</span>
                    </div>

                    <div className="Pon__modal--itemContainer">
                      <span>{t("v8_type").message || "Type"}:</span>
                      <span>{campaignType.name}</span>
                    </div>

                    <div className="Pon__modal--itemContainer">
                      <span>{t("v8_start_date").message || "Start date"}:</span>
                      <span>{startDate}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};
