import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { ActionModalSelector, type ActionModalSelectorData, AnswerStatusSelector, QuestionResultMedia, TextareaEditable, useCan } from "components";
import "components/CheckList/CheckList.scss";
import { Appointment, ChecklistTemplate, QuestionResult } from "models";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { parseQuestionResultAttachments } from "modules/AppointmentDetails/utils";
import { isCarReadyOrQualityCheckDoneOrCanceled } from "util/appointmentUtils";
import { ITranslation } from "util/interfaces";

type CheckListItemProps = {
  questionResult: QuestionResult;
  template?: ChecklistTemplate | null;
  onSelect: (data: ActionModalSelectorData) => void;
  showDropdownActions?: boolean;
  appointment: Appointment;
};

const CheckListItem = ({ questionResult, template, onSelect, showDropdownActions = true, appointment }: CheckListItemProps) => {
  const t = useTranslation().t as ITranslation;

  const [showMedia, setShowMedia] = useState(false);
  const { updateQuestionItemRemark } = useChecklist(questionResult.appointment_id);
  const canUpdateCheckListItems = useCan("update_question_results_remark", "car_checks");
  const isConvertToInterventionButtonDisabled = useMemo(() => {
    return appointment.interventions?.some(i => i.question_result_id === questionResult.id);
  }, [appointment.interventions, questionResult.id]);
  const hideConvertToIntervention = questionResult.question_options?.some(option => option.name === "hide_customer_ok_and_convert_to_intervention" && option.value);

  const attachments = parseQuestionResultAttachments(questionResult);

  const toggleShowMedia = () => setShowMedia(prev => !prev);
  const handleUpdateNote = (raw: string) => updateQuestionItemRemark.mutate({ id: questionResult.id, remark: raw });

  return (
    <div className="CheckListItem">
      <div className="title-container">
        <p>{questionResult.title}</p>
        <div className="title-row">
          <p>
            <strong>{t("v8_remarks").message || "Remarks"}:</strong>
          </p>
          <TextareaEditable
            value={questionResult.raw}
            disabled={questionResult.is_quality || !canUpdateCheckListItems || isCarReadyOrQualityCheckDoneOrCanceled(appointment.appointment_status_identifier)}
            onSubmit={handleUpdateNote}
          />
        </div>

        <div className="status-container">
          <AnswerStatusSelector data={questionResult} />
          {!!attachments?.length && (
            <div onClick={toggleShowMedia}>
              <Icon className="images grey pointer" size="small" />
              <p>
                <small>{attachments.length}</small>
              </p>
            </div>
          )}
          {showDropdownActions && (
            <ActionModalSelector
              appointment={appointment}
              template={template}
              attachments={attachments}
              data={questionResult}
              onSelect={onSelect}
              isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
              hideConvertToIntervention={hideConvertToIntervention}
            />
          )}
        </div>
      </div>

      {showMedia && <QuestionResultMedia questionResult={questionResult} canUpdate={canUpdateCheckListItems} appointment={appointment} />}
    </div>
  );
};

export default CheckListItem;
