import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { ReactTable } from "components";
import { LicenseData, LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { combinedLicenseData } from "modules/LocationLicenseOverview/utils";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

type MonthlyTableProps = {
  licensesData: Licenses | undefined;
  isLoadingLicenses: boolean;
  items: LicenseItem[];
};

export const MonthlyTable = ({ licensesData, isLoadingLicenses, items }: MonthlyTableProps) => {
  const t = useTranslation().t as ITranslation;

  const monthlyData = useMemo(() => combinedLicenseData(licensesData, items, t, true), [licensesData, items]);

  const columnHelper = createColumnHelper<LicenseData>();
  const columns = [
    columnHelper.accessor(row => row.code, {
      id: "code",
      header: t("v8_code").message || "Code",
      cell: info =>
        info.renderValue() ? (
          <div className="license-code">{info.renderValue()}</div>
        ) : (
          <div className="no-license-code">
            <Icon className="link slash solid red" />
          </div>
        ),
      size: 50
    }),
    columnHelper.accessor(row => row, {
      id: "name",
      header: t("v8_name").message || "Name",
      cell: ({ getValue }) => {
        const { name, description } = getValue();
        return <span>{description || name || ""}</span>;
      }
    }),
    columnHelper.accessor(row => row.category, {
      id: "category",
      header: t("v8_role_license_service").message || "Role / License / Service",
      cell: info => info.renderValue(),
      size: 300
    }),
    columnHelper.accessor(row => row.dealer_location_name, {
      id: "dealerLocationName",
      header: t("v8_location").message || "Location",
      cell: info => info.renderValue() || "",
      size: 100
    }),
    columnHelper.accessor(row => row.quantity, {
      id: "quantity",
      header: t("v8_amount").message || "Amount",
      cell: info => info.renderValue(),
      size: 100
    }),
    columnHelper.accessor(row => row.totalPrice, {
      id: "totalPrice",
      header: t("v8_total_price").message || "Total Price",
      cell: info => `€ ${formatPrice(info.renderValue())}`,
      size: 100
    })
  ];

  if (isLoadingLicenses) return <Loader active inline="centered" size="small" />;

  return (
    <div className="MonthlyTable mb-25">
      <ReactTable
        columns={columns}
        data={monthlyData}
        renderEmptyRow={!monthlyData?.length}
        emptyRowMessage={t("v8_no_monthly_licenses").message || "No monthly licenses"}
      />
    </div>
  );
};
