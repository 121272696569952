import React from "react";
import DatePicker, { DatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";

import "components/Calendar/Calendar.scss";
import { DATE_FORMATS_FNS } from "components/DatePicker";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

export const Calendar: React.FC<DatePickerProps> = props => {
  const t = useTranslation().t as ITranslation;

  return (
    <DatePicker
      className="CalendarInput"
      calendarClassName="CalendarDatepicker"
      todayButton={<span>{t("v8_today").message || "Today"}</span>}
      calendarStartDay={1}
      showPopperArrow={false}
      value={`${props.startDate ? formatDate(props.startDate, DATE_FORMATS_FNS.dateMonthYear) : ""} ${props.endDate ? `- ${formatDate(props.endDate, DATE_FORMATS_FNS.dateMonthYear)}` : ""}`}
      {...props}
    />
  );
};
