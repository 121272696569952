import { createColumnHelper } from "@tanstack/react-table";
import { endOfDay, isAfter, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

import { Can, DATE_FORMATS_FNS, ReactTable } from "components";
import { useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import "modules/DealerDocumentations/components/DealerDocumentationTable/DealerDocumentationTable.scss";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { useDealerDocumentationTable, useGetEntityNames } from "modules/DealerDocumentations/hooks";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

export const DealersDocumentationTable = () => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();

  const { dealersDocumentation } = useDealerDocumentationTable();
  const { handleSetShowViewModalDocumentation, handleSetShowAddEditDealerDocumentationModal } = useDealerDocumentationContext();
  const { matchDealersAndLocations } = useGetEntityNames();

  const checkPublishDate = (publish_date: string | Date | undefined) => {
    if (!publish_date) return false;

    const dateToCheck = typeof publish_date === "string" ? parseISO(publish_date) : publish_date;

    return isAfter(dateToCheck, endOfDay(new Date()));
  };

  const isOwnerOrHasHigherPrivilege = (data: DealerDocumentation): boolean => {
    if (!user || !data) return false;

    if (data.publish_date === null || checkPublishDate(data.publish_date)) {
      return user.id === data.created_by?.id;
    } else {
      if (!data.created_by?.role_id) return false;
      return user.role_id <= data.created_by.role_id;
    }
  };
  const columnHelper = createColumnHelper<DealerDocumentation>();
  const columns = [
    columnHelper.accessor(row => row, {
      id: "title",
      header: t("v8_title").message || "Title",
      cell: ({ cell }) => {
        const { getValue } = cell;
        const { title, is_pinned } = getValue();
        return (
          <>
            <Icon className={is_pinned ? "pin" : "circle regular"} /> {title}
          </>
        );
      },
      size: 200
    }),
    columnHelper.accessor(row => row, {
      id: "dealer_locations",
      header: t("v8_dealers_&_locations").message || "Dealers/Locations",
      cell: ({ cell: { getValue } }) => {
        const { dealer_ids, dealer_location_ids } = getValue();
        return matchDealersAndLocations(dealer_ids, dealer_location_ids);
      },
      size: 150
    }),

    columnHelper.accessor(row => row, {
      id: "publish_date",
      header: t("v8_publish_date").message || "Publish Date",
      cell: ({ getValue }) => {
        const { publish_date } = getValue();
        return <span> {publish_date ? formatDate(publish_date, DATE_FORMATS_FNS.dateMonthYear) : ""}</span>;
      },
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "category",
      header: t("v8_category").message || "Category",
      cell: ({ getValue }) => {
        const { category_id } = getValue();
        return <Label className="doc-table-category"> {DOCUMENTATION_CATEGORIES[category_id]} </Label>;
      },
      size: 80
    }),
    columnHelper.accessor(row => row, {
      id: "published_by",
      header: t("v8_published_by").message || "Published By",
      cell: ({ getValue }) => {
        const { updated_by, created_by } = getValue();
        const publishedBy = updated_by ? `${updated_by.first_name} ${updated_by.last_name}` : `${created_by?.first_name} ${created_by?.last_name}`;
        return <span> {publishedBy} </span>;
      },
      size: 100
    }),

    columnHelper.accessor(row => row, {
      id: "edit",
      header: "",
      cell: ({ getValue }) => {
        const data = getValue();
        if (!isOwnerOrHasHigherPrivilege(data)) return null;

        return (
          <Can I="update" the="dealer-documentation">
            <div className="edit-button-container">
              <div
                className="edit-button"
                onClick={e => {
                  e.stopPropagation();
                  handleSetShowAddEditDealerDocumentationModal(data);
                }}
              >
                <Icon className="pen to square edit-icon" />
              </div>
            </div>
          </Can>
        );
      },
      size: 50
    })
  ];

  return dealersDocumentation?.length ? (
    <div className="DealerDocumentationTable">
      <ReactTable columns={columns} data={dealersDocumentation} onRowClick={handleSetShowViewModalDocumentation} />
    </div>
  ) : (
    <div className="SubitemsTable">
      <div className="no-data">
        <p>{t("v8_no_data_to_show").message || "No data to show"}</p>
      </div>
    </div>
  );
};
