import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Icon, Input } from "semantic-ui-react";

import "components/UploadProfile/UploadProfile.scss";
import { useUploadAttachment } from "hooks";
import { User } from "models";
import { ITranslation } from "util/interfaces";

interface Props {
  path: string;
  baseURL: string;
  locationPath: string;
  onSuccess?: (url: string) => void;
  formId: string;
  user?: User;
}

export const UploadProfile = ({ path, baseURL, locationPath, onSuccess, formId, user }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [profilePicture, setProfilePicture] = useState<string | null | undefined>(user?.profile_picture);

  const t = useTranslation().t as ITranslation;
  const { handleUploadFileAttachment } = useUploadAttachment(path, baseURL);

  useEffect(() => {
    setProfilePicture(user?.profile_picture);
  }, [user]);

  const handleChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("path", locationPath);
    setFile(event.target.files[0]);

    try {
      const attachmentUrl = await handleUploadFileAttachment({ formData });

      if (attachmentUrl) onSuccess?.(attachmentUrl);
      else throw new Error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
    } catch (e) {
      const errMessage = e instanceof Error ? e.message : t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.";
      toast.error(errMessage);
    }
  };

  const handleUpload = () => {
    (document as Document).getElementById(formId)?.click();
  };

  const handleDelete = () => {
    const fd = new FormData();
    fd.delete("file");
    setFile(null);
    setProfilePicture(null);
    if (onSuccess) onSuccess("");
  };

  return (
    <div className="upload__profile">
      <div className="upload__profile-button">
        <div className="button">
          {file ? (
            <img alt="preview image" src={URL.createObjectURL(file)} />
          ) : profilePicture ? (
            <img title={`${user?.first_name || ""} ${user?.last_name || ""}`} className="preview image" src={profilePicture} />
          ) : (
            <Icon className="user no-avatar" size="big" />
          )}
          <div className="add-avatar" onClick={handleUpload}>
            {file || user?.profile_picture ? <Icon name="camera" size="big" /> : <Icon name="add circle" size="big" />}
          </div>
        </div>
      </div>
      {(file || user?.profile_picture) && (
        <div className="avatar-action-container">
          <div onClick={handleUpload} className="action upload-section">
            <Icon name="upload" />
            <span className="upload">{t("v8_upload_new").message || "Upload New"}</span>
          </div>
          <div onClick={handleDelete} className="action delete-section">
            <Icon className="trash" color="red" />
            <span className="remove">{t("v8_remove").message || "Remove"}</span>
          </div>
        </div>
      )}
      <Input id={formId} accept="image/png, image/gif, image/jpeg" type="file" onChange={handleChangeFile} />
    </div>
  );
};
