import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessageListener } from "components";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";

export const useManufacturerDashboardListeners = () => {
  const queryClient = useQueryClient();
  const listeners = useMemo((): WebSocketMessageListener[] => {
    return [
      {
        model: "ManufacturerRequest",
        action: "create",
        callback: () => {
          const currentManufacturerCount = queryClient.getQueryData<number>(ManufacturerQueryKeys.ManufacturerRequestCountKey);
          if (!currentManufacturerCount) return;

          queryClient.setQueryData(ManufacturerQueryKeys.ManufacturerRequestCountKey, () => currentManufacturerCount + 1);
        }
      },
      {
        model: "ManufacturerRequest",
        action: "update",
        callback: () => {
          const currentManufacturerCount = queryClient.getQueryData<number>(ManufacturerQueryKeys.ManufacturerRequestCountKey);
          if (!currentManufacturerCount) return;

          queryClient.setQueryData(ManufacturerQueryKeys.ManufacturerRequestCountKey, () => currentManufacturerCount - 1);
        }
      }
    ];
  }, []);

  return listeners;
};
