import { useGetSelectedLeadType } from "modules/LeadsDashboard/hooks";
import { CarsLeads, NotSnoozedLeads, SnoozedLeads, TiresLeads } from "modules/LeadsDashboard/Leads";
import "modules/LeadsDashboard/LeadsDashboard.scss";

export enum LEAD_TYPES {
  Snoozed = 1,
  NotSnoozed,
  Cars,
  Tires
}

const renderLeads = (type: LEAD_TYPES) => {
  switch (type) {
    case LEAD_TYPES.Snoozed:
      return <SnoozedLeads />;

    case LEAD_TYPES.NotSnoozed:
      return <NotSnoozedLeads />;

    case LEAD_TYPES.Tires:
      return <TiresLeads />;

    default:
      return <CarsLeads />;
  }
};

const LeadsDashboard = () => {
  const { selectedLeadType } = useGetSelectedLeadType();

  return <div className="LeadsDashboard">{renderLeads(selectedLeadType)}</div>;
};

export default LeadsDashboard;
