import { forwardRef } from "react";
import { Icon } from "semantic-ui-react";

import "components/TableFilters/TableDatePickerInput/TableDatePicker.scss";

type CustomDatePickerInputProps = {
  value?: string;
  onClick?: () => void;
  inputClassName: string;
};

export const CustomDatePickerInput = forwardRef<HTMLButtonElement, CustomDatePickerInputProps>((props, ref) => (
  <div className="TableDatePickerInput">
    <button className={`filter-button ${props.inputClassName}`} onClick={props.onClick} ref={ref}>
      <Icon className={`${props.value !== "" ? "filters" : "filter"} filters-icon`} size="small" />
    </button>
  </div>
));

CustomDatePickerInput.displayName = "CustomDatePickerInput";
