import { useTranslation } from "react-i18next";
import { Button, Icon, Input, Loader, Modal } from "semantic-ui-react";

import { PinModel } from "models";
import { ManufacturerClaimsTable, ManufacturerConfirmRequestModal } from "modules/ManufacturerDashboard/components";
import "modules/ManufacturerDashboard/components/CreateManufacturerRequestModal/CreateManufacturerRequestModal.scss";
import { useConfirmManufacturerRequest } from "modules/ManufacturerDashboard/hooks";
import { ITranslation } from "util/interfaces";

interface CreateManufacturerRequestModalTypes {
  onCloseModal: () => void;
  onSearchClaim: (searchTerm: string) => void;
  claimResults: PinModel[];
  isLoading: boolean;
}

export const CreateManufacturerRequestModal = ({ onCloseModal, onSearchClaim, claimResults, isLoading }: CreateManufacturerRequestModalTypes) => {
  const t = useTranslation().t as ITranslation;

  const { createManufacturerRequest, handleRequestReason, closeConfirmModal, selectRequest, isConfirmButtonDisabled, selectedRequest } = useConfirmManufacturerRequest();

  const closeConfirmAndRequestModals = () => {
    closeConfirmModal();
    onCloseModal();
  };

  return (
    <>
      <Modal open size="large">
        <Modal.Header className="Manufacturer__requestModal-header">
          <h3>{t("v8_request_access").message || "Request Access"}</h3>
          <Button basic size="big" className="Manufacturer__requestModal-header--closeBtn" onClick={onCloseModal}>
            <Icon className="x" />
          </Button>
        </Modal.Header>

        <Modal.Content scrolling>
          <h3>{t("v8_search_by_claim_number").message || "Search by claim number"}</h3>
          <Input
            onChange={e => onSearchClaim(e.target.value)}
            className="Manufacturer__requestModal-content--input"
            disabled={isLoading}
            icon={"magnifying glass"}
            iconPosition="left"
            placeholder={t("v8_claim_number").message || "Claim Number"}
          />
          {isLoading && <Loader size="small" />}
          {!isLoading && claimResults.length ? <ManufacturerClaimsTable claimsResults={claimResults} onSelectRow={selectRequest} /> : null}
        </Modal.Content>
      </Modal>

      <ManufacturerConfirmRequestModal
        onCloseModal={closeConfirmModal}
        showConfirmModal={Boolean(selectedRequest)}
        onHandleRequestReason={handleRequestReason}
        onCreateRequest={createManufacturerRequest}
        isConfirmButtonDisabled={isConfirmButtonDisabled}
        onSuccessfulRequest={closeConfirmAndRequestModals}
      />
    </>
  );
};
