import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Dropdown, Form, Icon, Input, TextArea } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm, PinTypeIcon } from "components";
import { Appointment, Intervention, PIN_TYPE } from "models";
import { INTERVENTION_TABS, PinType } from "modules/AppointmentDetails/components";
import "modules/AppointmentDetails/components/Interventions/components/GeneralInfo/GeneralInfo.scss";
import { useGeneralInfo } from "modules/AppointmentDetails/components/Interventions/components/GeneralInfo/useGeneralInfo";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

type GeneralInfoProps = {
  data: Intervention;
  appointment: Appointment | null;
  onPinTypeSet: (pinTypeID: PIN_TYPE) => void;
  pinTypeData: PinType[];
  typeID: number;
  handleTabSelection: (tab: INTERVENTION_TABS) => void;
  onSetHasChanges?: (change: boolean) => void;
};

export const GeneralInfo = ({ data, appointment, onPinTypeSet, typeID, pinTypeData, onSetHasChanges, handleTabSelection }: GeneralInfoProps) => {
  const t = useTranslation().t as ITranslation;
  const {
    disabled,
    pinTypeID,
    followDMS,
    isDisabled,
    customerOk,
    openDropdown,
    showConfirmModal,
    deleteIntervention,
    canUpdateInterventions,
    cannotToggleCustomerOK,
    showDeleteInterventionModal,
    handleConfirm,
    toggleDropdown,
    handlePinUpdate,
    toggleCustomerOk,
    setDeletePinReason,
    onRequestCloseModal,
    handleFormValueChange,
    handleButtonGroupClick,
    handleUpdateIntervention,
    handleDeleteLocalIntervention,
    handleCancelDeleteLocalIntervention,
    handleDeleteLocalInterventionConfirm
  } = useGeneralInfo({ data, typeID, appointment, onPinTypeSet, onSetHasChanges, handleTabSelection });

  return (
    <div className="GeneralInfo">
      <Form onSubmit={handleUpdateIntervention} onChange={handleFormValueChange}>
        <Form.Group className="input-row-equal-spacing">
          <Form.Field width={16} required>
            <label>{t("v8_title").message || "Title"}</label>
            <Input defaultValue={data.title} placeholder="Title" fluid name="title" disabled={isDisabled || followDMS} />
          </Form.Field>

          <Form.Field width={2} className="mt-28">
            <Input name="price" labelPosition="right" defaultValue={formatPrice(data.price)} label={{ basic: true, content: "€" }} disabled={isDisabled || followDMS} />
          </Form.Field>

          <Form.Field className="mt-28 label-dropdown-container">
            <ButtonGroup className="withdropdown" onClick={handleButtonGroupClick}>
              <Button className={`withdropdownButton ${pinTypeID === 0 ? "grey" : "black"}`} disabled={isDisabled}>
                <PinTypeIcon pin_type_id={pinTypeID || 0} />
              </Button>
              <Dropdown className="button icon" floating open={openDropdown} onOpen={toggleDropdown} onClose={toggleDropdown} disabled={isDisabled}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handlePinUpdate(0)} className="no-label" disabled={isDisabled}>
                    <Icon name="pin" />
                    {t("v8_no_label").message || "No Label"}
                  </Dropdown.Item>
                  {pinTypeData?.map((pinType: PinType) => (
                    <Dropdown.Item
                      key={pinType.key}
                      onClick={() => handlePinUpdate(pinType.value as PIN_TYPE)}
                      disabled={isDisabled}
                      icon={pinType.icon}
                      text={pinType.text}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Form.Field>

          <Form.Field width={1} className="mt-28 user-approved-icon">
            <Button disabled={isDisabled || cannotToggleCustomerOK} onClick={toggleCustomerOk} className="wdLightGrey centerIcon greyBorder" type="button">
              <Icon className={`${customerOk ? "check circle green" : "remove circle red"}`} />
            </Button>
          </Form.Field>
        </Form.Group>

        <Form.Field width={16} className="mt-14">
          <label>{t("v8_description").message || "Description"}</label>
          <TextArea disabled={isDisabled || followDMS} defaultValue={data.description} placeholder="Write description" fluid name="description" />
        </Form.Field>

        {data.internal_note && (
          <Form.Field width={16} className="mt-14">
            <label>{t("v8_dms_internal_note").message || "DMS Internal note"}</label>
            <div>{data.internal_note}</div>
          </Form.Field>
        )}

        <div className={data.is_local ? "flex-space-between" : "flex-end"}>
          {data.is_local && (
            <Can I="delete" the="interventions">
              <Button color="red" type="button" onClick={handleDeleteLocalIntervention}>
                {t("v8_delete").message || "Delete"}
                <Icon className="trash" />
              </Button>
            </Can>
          )}

          <Can I="update" the="interventions">
            <Button color="green" type="submit" disabled={disabled || !canUpdateInterventions}>
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </Can>
        </div>
      </Form>

      <CustomConfirm
        isLoading={false}
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isOpen={showConfirmModal}
        handleConfirm={handleConfirm}
        handleCancel={onRequestCloseModal}
        confirmMandatoryNote={t("v8_reason_for_delete_pin").message || "Reason for deleting pin item"}
        onChangeNote={e => setDeletePinReason(e.target.value)}
        confirmMsg={t("v8_unsaved_changes_confirm_message").message || "You have some unsaved changes. If you proceed, these changes will be lost."}
      />

      <CustomConfirm
        isLoading={deleteIntervention.isPending}
        isOpen={showDeleteInterventionModal}
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleConfirm={handleDeleteLocalInterventionConfirm}
        handleCancel={handleCancelDeleteLocalIntervention}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
      />
    </div>
  );
};
