import { useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { NavBarSearchKeys } from "components/NavBarSearch/queryKeys";
import { useSearchTerm } from "components/NavBarSearch/useSearchTerm";
import { SearchDropdown } from "components/NavBarSearchAppointments/components";
import { useAppointmentSearchResults } from "components/NavBarSearchAppointments/hooks/useAppointmentSearchResults";
import { useDealersLocations } from "hooks";
import { COMMUNICATION_STATUS, DealerLocation, STATUS_IDENTIFIER } from "models";

const MINIMUM_SEARCH_LENGTH = 3;

export enum SEARCH_OPTION_TYPE {
  Claire = 1,
  Keyloop,
  Nextlane,
  Wincar
}

export interface AppointmentSearchOption {
  id: string;
  car_make: string;
  car_model: string;
  due_in: string;
  wo_nr: string;
  reg_number: string;
  appointment_status_identifier: STATUS_IDENTIFIER;
  dealer_location?: DealerLocation | null;
  dealer_location_id?: number;
  customcom_status?: COMMUNICATION_STATUS | null;
  check_paused_at?: string | null;
  car_out_of_shop?: string | null;
  type: SEARCH_OPTION_TYPE;
}

export const NavBarSearchAppointments = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const searchTerm = useSearchTerm();
  const canSearch = Number(searchTerm?.length) > MINIMUM_SEARCH_LENGTH;

  const { isKeyLoopLocation, isNextLaneLocation, selectedLocation, isWincarLocation } = useDealersLocations();
  const results = useAppointmentSearchResults(searchTerm, !!isKeyLoopLocation, !!isNextLaneLocation, !!isWincarLocation, selectedLocation);

  if (!selectedLocation || !results) return null;

  const { data, refetch, reset } = results;

  const clearSearch = () => {
    reset();
    queryClient.setQueryData(NavBarSearchKeys.searchTerm, "");
  };

  const handleSelect = (id: string) => {
    history.push(`/appointments/${id}`);
  };

  if (!data) {
    if (!canSearch) return null;

    return <Icon className="ellipsis grey list-view" onClick={() => selectedLocation && refetch()} />;
  }

  return (
    <>
      <Icon className="xmark grey list-view" onClick={clearSearch} />
      <SearchDropdown data={data} onSelect={handleSelect} onClose={clearSearch} />
    </>
  );
};
