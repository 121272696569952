import { Icon } from "semantic-ui-react";

import { getShopIconColor } from "modules/AppointmentDetails/components/Interventions/components";
import "modules/WarrantyDashboard/components/WarrantyDashboardTable/components/OrderStatus/OrderStatus.scss";

type OrderStatusProps = {
  orderStatus: number;
};

export const OrderStatus = ({ orderStatus }: OrderStatusProps) => (
  <div className="WarrantyOrderStatus__order-status-container">
    <div className="WarrantyOrderStatus__order-status-icon-container" style={{ backgroundColor: getShopIconColor(orderStatus) }}>
      <Icon className="shop" />
    </div>
  </div>
);
