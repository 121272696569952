import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Label, Loader, Popup } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";

import {
  AppointmentStatusCell,
  CurrentStatus,
  DATE_FORMATS_FNS,
  PinModelWithHistory,
  ReactTable,
  TableDropdownSelector,
  TableQuestionStatusButton,
  useCan
} from "components";
import { useDealersLocations } from "hooks";
import { MANUFACTURER_REQUEST_STATUS, ManufacturerRequest, PIN_TYPE, PinModel, WARRANTY_TYPE } from "models";
import { ManufacturerStatus } from "modules/ManufacturerDashboard/components";
import "modules/ManufacturerDashboard/components/ManufacturerTable/ManufacturerTable.scss";
import {
  ManufacturerFilterType,
  useGetManufacturerDashboardFilters,
  useGetManufacturerDropdownOptions,
  useUpdateManufacturerDashboardFilters
} from "modules/ManufacturerDashboard/hooks";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import { useManufacturerQuery } from "modules/ManufacturerDashboard/service";
import {
  ManufacturerRequestWithLatestHistoryItem,
  attachLatestHistoryItem,
  handleCopyToClipboard,
  retrieveLatestHistoryItem,
  validateManufacturerDropdownInput
} from "modules/ManufacturerDashboard/utils";
import { LocationCard, PinTypeIcon } from "modules/WarrantyDashboard/components/WarrantyDashboardTable/components";
import { REG_VIN_FILTER } from "modules/WarrantyDashboard/hooks";
import { PATHS } from "router/paths";
import { useGetFilterUpdaterFunctions } from "util/common";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

export const MANUFACTURER_PAGE_SIZE = 100;
interface ManufacturerTablePropTypes {
  openRequestModal: (request: ManufacturerRequest) => void;
  onSelectPinItem: (pin: PinModel[]) => void;
}

export const ManufacturerTable = ({ openRequestModal, onSelectPinItem }: ManufacturerTablePropTypes) => {
  const t = useTranslation().t as ITranslation;
  const { updateFilters } = useGetFilterUpdaterFunctions<ManufacturerFilterType>({ queryKey: ManufacturerQueryKeys.manufacturerFilters });
  const { manufacturerData, isLoadingManufacturerData } = useManufacturerQuery();
  const { dealers, locations } = useDealersLocations();
  const canUpdateRequest = useCan("update-request", "manufacturer");
  const { pinStatusDropdownOptions } = useGetManufacturerDropdownOptions();
  const { warrantyDropdownOptions, regHeaderOptions, questionStatusDropdownOptions, manufacturerRequestStatusDropdownOptions, pinTypeDropdownOptions } =
    useGetManufacturerDropdownOptions();
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();
  const { updatePinDropdownOptions, handlePaginationSelect } = useUpdateManufacturerDashboardFilters();

  const [activeRegFilter, setActiveRegFilter] = useState(REG_VIN_FILTER.Reg);

  const manufacturerRequestsWithLatestPinHistory = useMemo(() => attachLatestHistoryItem(manufacturerData?.manufacturer_requests), [manufacturerData]);

  const columnHelper = createColumnHelper<ManufacturerRequestWithLatestHistoryItem>();
  const columns = [
    columnHelper.accessor(row => row.latestPinHistoryItem?.appointment, {
      id: "appointment_date",
      header: () => <span>{t("v8_appointment").message || "Appointment"}</span>,
      cell: ({ getValue }) => {
        const date = getValue()?.created_on;
        return date && <span>{formatDate(date, DATE_FORMATS_FNS.dateMonthYear)}</span>;
      },
      size: 80
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem?.appointment?.appointment_status_identifier, {
      id: "appointment_status",
      header: () => <span>{t("v8_status").message || "Status"}</span>,
      cell: ({ row: { original } }) => {
        const lastestPinHistory = retrieveLatestHistoryItem(original.pin_history);
        if (!lastestPinHistory?.appointment) return null;

        return <AppointmentStatusCell value={{ id: lastestPinHistory.appointment.appointment_status_identifier }} />;
      },
      size: 30
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "wo_nr",
      header: "WO #",
      size: 10,
      cell: ({ getValue, row: { original } }) => {
        const appointment = getValue()?.appointment;
        const appointmentId = appointment?.id;

        const canUpdateOrStatusApproved = canUpdateRequest || original.status === MANUFACTURER_REQUEST_STATUS.Approved;
        return (
          <Popup
            basic
            content={appointment?.wo_nr}
            trigger={
              <div className="Manufacturer__tableColumn-openLink">
                {canUpdateOrStatusApproved ? (
                  <Link target="_blank" to={`${PATHS.APPOINTMENTS}/${appointmentId}`}>
                    <Icon className="external alternate" />
                    <p className="ellipsis">{appointment?.wo_nr}</p>
                  </Link>
                ) : (
                  <p className="ellipsis">{appointment?.wo_nr}</p>
                )}
              </div>
            }
          />
        );
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem?.appointment, {
      id: "reg_nr",
      size: 10,
      header: () => (
        <div className="Manufacturer__tableColumn-filters">
          <span>{activeRegFilter === REG_VIN_FILTER.Reg ? `${t("v8_reg").message || "Reg"}` : `${t("v8_vin").message || "Vin"} `} #</span>
          <TableDropdownSelector
            options={regHeaderOptions}
            selected={[String(activeRegFilter)]}
            handleOnSelect={(selectedItem: string[]) => setActiveRegFilter(Number(selectedItem.at(0)))}
          />
        </div>
      ),
      cell: ({ getValue, row: { original } }) => {
        const latestPinHistoryItem = getValue();

        if (!latestPinHistoryItem) return;

        const { reg_number, vin_nr, car_id } = latestPinHistoryItem;
        const shownValue = activeRegFilter === REG_VIN_FILTER.Reg ? reg_number : vin_nr;

        if (!shownValue) return;

        const handleCopyVinNr = () =>
          activeRegFilter === REG_VIN_FILTER.Vin ? handleCopyToClipboard(shownValue.toString(), t("v8_vin_nr_copied").message || "Vin # copied to clipboard.") : null;

        const showOpenwOIcon = (canUpdateRequest || original.status === MANUFACTURER_REQUEST_STATUS.Approved) && activeRegFilter === REG_VIN_FILTER.Reg;
        return (
          <div className="Manufacturer__tableColumn-openLink">
            {showOpenwOIcon ? (
              <Link target="_blank" to={`${PATHS.CAR_DETAIL}/${car_id}`}>
                <Icon className="external alternate" />
                <Popup basic content={shownValue} trigger={<p className="ellipsis">{shownValue}</p>} />
              </Link>
            ) : (
              <div className="Manufacturer__copyWrapper Manufacturer__copyWrapper--shortColumn" onClick={handleCopyVinNr}>
                {activeRegFilter === REG_VIN_FILTER.Vin && <Icon className="copy outline" />}
                <Popup basic content={shownValue} trigger={<p className="ellipsis">{shownValue}</p>} />
              </div>
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor(row => row.dealer_id, {
      id: "dealer_id",
      header: t("v8_dealer").message || "Dealer",
      size: 30,
      cell: ({ getValue }) => {
        const dealer = dealers.find(dealer => dealer.id === getValue())?.name;
        return <Popup basic content={dealer} trigger={<p className="ellipsis">{dealer}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.dealer_location_id, {
      id: "location_id",
      size: 30,
      header: t("v8_location").message || "Location",
      cell: ({ getValue }) => <span>{locations.find(location => location.id === getValue())?.name}</span>
    }),
    columnHelper.accessor(row => row.created_on, {
      id: "pin_date",
      size: 50,
      header: t("v8_pin_date").message || "Pin Date",
      cell: ({ getValue }) => {
        const date = getValue() as Date;
        const value = formatDate(date, DATE_FORMATS_FNS.dateMonthYear);
        return date && <Popup content={value} trigger={<p className="ellipsis">{value}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "pin_type",
      size: 50,
      header: t("v8_pin_type").message || "Pin Type",
      cell: ({ getValue }) => {
        const pinTypeId = getValue()?.pin_type_id;
        return pinTypeId ? <span>{PIN_TYPE[pinTypeId]}</span> : null;
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "warranty_type",
      size: 100,
      header: () => {
        const selectedWarrantyDropdownOptions = manufacturerDashboardFilters.warrantyTypeId != null ? [String(manufacturerDashboardFilters.warrantyTypeId)] : ["all"];
        const popupText = t("v8_warranty").message || "Warranty";
        return (
          <div className="Manufacturer__tableColumn-filters">
            <Popup trigger={<p className="ellipsis">{popupText}</p>} value={popupText} />
            <TableDropdownSelector
              options={warrantyDropdownOptions}
              selected={selectedWarrantyDropdownOptions}
              handleOnSelect={(selectedItem: string[]) => updateFilters({ warrantyTypeId: validateManufacturerDropdownInput(selectedItem) })}
            />
          </div>
        );
      },

      cell: info => {
        const warrantyTypeId = info.getValue()?.warranty_type_id;
        if (manufacturerDashboardFilters.warrantyTypeId === null && warrantyTypeId === WARRANTY_TYPE.NotSet) return "";
        const matchingWarrantyStatus = warrantyDropdownOptions.find(option => option.value === warrantyTypeId)?.text ?? "";
        return <span>{matchingWarrantyStatus}</span>;
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "pin_status",
      size: 30,
      header: t("v8_pin_status").message || "Pin Status",
      cell: info => {
        const pinStatusId = info.getValue()?.pin_status_id;
        const matchingPinStatus = pinStatusDropdownOptions.find(option => option.value === pinStatusId)?.text ?? "";

        return <Popup basic content={matchingPinStatus} trigger={<p className="ellipsis">{matchingPinStatus}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "support_nr",
      size: 5,
      header: `${t("v8_support").message || "Support"} #`,
      cell: info => {
        const supportNum = info.getValue()?.support_nr;
        if (!supportNum) return;

        return (
          <div
            className="Manufacturer__copyWrapper"
            onClick={() => handleCopyToClipboard(supportNum.toString(), t("v8_support_nr_copied").message || "Support # copied to clipboard")}
          >
            <Icon className="copy outline" />
            <Popup basic content={supportNum} trigger={<p className="ellipsis Manufacturer__short-column">{supportNum}</p>} />
          </div>
        );
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "claim_nr",
      size: 5,
      header: `${t("v8_claim").message || "Claim"} #`,
      cell: info => {
        const claimNum = info.getValue()?.claim_nr;

        if (!claimNum) return;
        return (
          <div
            className="Manufacturer__copyWrapper"
            onClick={() => handleCopyToClipboard(claimNum.toString(), t("v8_claim_nr_copied").message || "Claim # copied to clipboard")}
          >
            <Icon className="copy outline" />
            <Popup basic content={claimNum} trigger={<p className="ellipsis  Manufacturer__short-column">{claimNum}</p>} />
          </div>
        );
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "ref_nr",
      size: 5,
      header: `${t("v8_ref").message || "Ref "} #`,
      cell: info => {
        const refNum = info.getValue()?.ref_nr;
        if (!refNum) return;

        return (
          <div
            className="Manufacturer__copyWrapper"
            onClick={() => handleCopyToClipboard(refNum.toString(), t("v8_claim_nr_copied").message || "Ref # copied to clipboard")}
          >
            <Icon className="copy outline" />
            <Popup basic content={refNum} trigger={<p className="ellipsis  Manufacturer__short-column">{refNum}</p>} />
          </div>
        );
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "brand",
      size: 40,
      header: t("v8_brand").message || "Brand",
      cell: info => {
        const brand = info.getValue()?.appointment?.car?.make;
        return <Popup basic content={brand} trigger={<p className="ellipsis  Manufacturer__short-column">{brand}</p>} />;
      }
    }),
    columnHelper.accessor(row => row.latestPinHistoryItem, {
      id: "intervention_question",
      size: 100,
      header: () => {
        const value = t("v8_intervention_question").message || "Intervention/Question";
        return <Popup content={value} trigger={<p className="ellipsis">{value}</p>} />;
      },
      cell: info => {
        const title = info.getValue()?.intervention?.title || info.getValue()?.question_result?.title;

        return <Popup basic content={title} trigger={<p className="ellipsis">{title}</p>} />;
      }
    }),

    columnHelper.accessor(row => row, {
      id: "actions",
      size: 40,
      header: () => {
        const selectedPinClaimsOptions = manufacturerDashboardFilters.pinTypeIds?.length ? manufacturerDashboardFilters.pinTypeIds.map(id => String(id)) : ["all"];
        const selectedManufacturerStatusOptions = manufacturerDashboardFilters.manufacturerStatus ? [String(manufacturerDashboardFilters.manufacturerStatus)] : ["all"];
        const selectedManufacturerRequestStatusOptions = manufacturerDashboardFilters.requestStatus ? [String(manufacturerDashboardFilters.requestStatus)] : ["all"];

        return (
          <div className="Manufacturer__tableColumn-actions">
            <div></div>
            <TableDropdownSelector
              selected={selectedPinClaimsOptions}
              multiple
              options={pinTypeDropdownOptions}
              handleOnSelect={selectedValue => updatePinDropdownOptions(selectedValue)}
              trigger={<TableQuestionStatusButton selectedQuestionStatuses={selectedPinClaimsOptions} />}
              scrolling={false}
            />
            <TableDropdownSelector
              selected={selectedManufacturerStatusOptions}
              options={questionStatusDropdownOptions}
              handleOnSelect={(selectedValue: string[]) => updateFilters({ manufacturerStatus: validateManufacturerDropdownInput(selectedValue) })}
              trigger={<TableQuestionStatusButton selectedQuestionStatuses={selectedManufacturerStatusOptions} />}
              scrolling={false}
              className="Manufacturer__tableColumn-actions--statusId"
            />

            <TableDropdownSelector
              selected={selectedManufacturerRequestStatusOptions}
              options={manufacturerRequestStatusDropdownOptions}
              handleOnSelect={(selectedValue: string[]) => updateFilters({ requestStatus: validateManufacturerDropdownInput(selectedValue) })}
              trigger={<TableQuestionStatusButton selectedQuestionStatuses={selectedManufacturerRequestStatusOptions} />}
              scrolling={false}
              className="Manufacturer__tableColumn-actions--requestStatus"
            />
          </div>
        );
      },
      cell: info => {
        const latestPinHistory = info.row.original.latestPinHistoryItem as PinModelWithHistory;
        const currentRequestStatus = info.row.original.latestPinHistoryItem?.question_result?.status || info.row.original.latestPinHistoryItem?.intervention?.status;

        return (
          <div className="Manufacturer__tableColumn-actions Manufacturer__tableColumn-actions--cell">
            <LocationCard dealerLocationId={info.row.original.dealer_location_id} dealers={dealers} locations={locations} />
            {latestPinHistory && <PinTypeIcon pinType={latestPinHistory.pin_type_id} onClick={() => onSelectPinItem(info.row.original.pin_history)} />}
            {currentRequestStatus ? (
              <Label basic className="Manufacturer__tableColumn-iconContainer">
                <CurrentStatus status={currentRequestStatus} />
              </Label>
            ) : (
              <div></div>
            )}
            <ManufacturerStatus request={info.renderValue()} openRequestModal={openRequestModal} />
          </div>
        );
      }
    })
  ];

  if (isLoadingManufacturerData) return <Loader active />;
  if (!manufacturerData?.manufacturer_requests) return toast.error(t("v8_error_loading_data").message || "Something went wrong loading the data. Please try again.");

  const totalItemsNum = manufacturerData?.manufacturer_requests.length;
  const pageCount = manufacturerData?.nb_pages || 0;
  const currentPage = manufacturerDashboardFilters.page;

  return (
    <ReactTable
      pageCount={pageCount}
      emptyRowMessage={t("v8_no_results").message || "No results."}
      data={manufacturerRequestsWithLatestPinHistory}
      columns={columns}
      totalItems={totalItemsNum}
      pageSize={MANUFACTURER_PAGE_SIZE}
      pagination={manufacturerData?.nb_pages > 1 ? { pageIndex: currentPage - 1, pageSize: MANUFACTURER_PAGE_SIZE } : undefined}
      onPaginationChange={handlePaginationSelect}
    />
  );
};
