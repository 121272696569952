import { PinModel } from "models";
import { ORDER_BY } from "modules/WarrantyDashboard/hooks";

export interface PinModelWithHistory extends Omit<PinModel, "note_attachments"> {
  history: PinModel[];
  note_attachments?: string[];
}

export const groupInterventionsAndQuestions = (items: PinModel[]) => {
  const interventions = new Map<number, PinModelWithHistory>();
  const questions = new Map<number, PinModelWithHistory>();

  const addOrUpdatePinHistory = (map: Map<number, PinModelWithHistory>, id: number, snoozeItem: PinModel) => {
    if (map.has(id)) {
      const existing = map.get(id);

      if (existing && snoozeItem.created_on > existing.created_on) {
        map.set(id, { ...snoozeItem, history: existing.history.concat(snoozeItem) });
      } else if (existing) {
        existing.history.push(snoozeItem);
        map.set(id, existing);
      }
    } else map.set(id, { ...snoozeItem, history: [snoozeItem] });
  };

  items.forEach(snoozeItem => {
    if (snoozeItem.intervention_id) {
      addOrUpdatePinHistory(interventions, snoozeItem.intervention_id, snoozeItem);
    } else if (snoozeItem.question_result_id) {
      addOrUpdatePinHistory(questions, snoozeItem.question_result_id, snoozeItem);
    }
  });

  return [...Array.from(interventions.values()), ...Array.from(questions.values())];
};

const sortData = (items: PinModelWithHistory[], orderBy: ORDER_BY) =>
  [...items].sort((a, b) => {
    switch (orderBy) {
      case ORDER_BY.ClaimAscending:
      case ORDER_BY.ClaimDescending: {
        const claimNrA = a.claim_nr ? String(a?.claim_nr) : "";
        const claimNrB = b.claim_nr ? String(b?.claim_nr) : "";
        return orderBy === ORDER_BY.ClaimAscending ? claimNrA.localeCompare(claimNrB) : claimNrB.localeCompare(claimNrA);
      }

      case ORDER_BY.PinUpdatedOnAscending:
      case ORDER_BY.PinUpdatedOnDescending: {
        const updatedOnA = a.updated_on ? String(a?.updated_on) : "";
        const updatedOnB = b.updated_on ? String(b?.updated_on) : "";
        return orderBy === ORDER_BY.PinUpdatedOnAscending ? updatedOnA.localeCompare(updatedOnB) : updatedOnB.localeCompare(updatedOnA);
      }

      case ORDER_BY.AppointmentDateAscending:
      case ORDER_BY.AppointmentDateDescending: {
        const appDateA = a.appointment?.time_car_app ? String(a?.appointment?.time_car_app) : "";
        const appDateB = b.appointment?.time_car_app ? String(b?.appointment?.time_car_app) : "";
        return orderBy === ORDER_BY.AppointmentDateAscending ? appDateA.localeCompare(appDateB) : appDateB.localeCompare(appDateA);
      }

      default:
        return 0;
    }
  });

export const normalizeData = (items: PinModel[], orderBy: ORDER_BY) => sortData(groupInterventionsAndQuestions(items), orderBy);
