import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { DATE_FORMATS_FNS } from "components/DatePicker";
import "components/Notes/DMSNotes/DMSNotes.scss";
import { CarNote } from "models";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type DMSNote = {
  note: string;
};

type DMSNotesProps = {
  dmsCarNotes?: CarNote[];
  dmsNotes?: DMSNote[];
};

export const DMSNotes = ({ dmsCarNotes, dmsNotes }: DMSNotesProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="DMSNotes">
      {dmsCarNotes?.map(({ note, created_on }: CarNote, idx) => (
        <div className="dms-note-row" key={idx}>
          <p className="dms-note-type">
            <span>
              <Icon className="desktop" />
            </span>
            {note}
          </p>
          {created_on && (
            <p className="dms-note-info">
              {t("v8_dms_car_note").message || "DMS Car note"} {t("v8_on").message || "on"} {formatDate(created_on, DATE_FORMATS_FNS.dateMonthYearTime)}
            </p>
          )}
        </div>
      ))}
      {dmsNotes?.map(({ note }: DMSNote, idx) => (
        <div className="dms-note-row" key={idx}>
          <p className="dms-note-type">
            <span>
              <Icon className="desktop" />
            </span>
            {note}
          </p>
          <p className="dms-note-info">{t("v8_dms_note").message || "DMS note"}</p>
        </div>
      ))}
    </div>
  );
};
