import { saveAs } from "file-saver";
import { Icon } from "semantic-ui-react";

import { DATE_FORMATS_FNS, UploadVideo } from "components";
import "components/Videos/Videos.scss";
import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment } from "models/";
import { formatDate } from "util/dateHelperFunctions";

type GenericData = {
  id: number;
  url: string;
  visible: boolean;
};

type GenericListProps<T extends GenericData> = {
  data: T[] | undefined;
  appointment?: Appointment;
  isEditable?: boolean;
  handleDeleteVideo?: (data: T, index: number) => void;
  handleVideoUpload?: (data: T, index: number, url: string) => void;
  handleVideoVisibility?: (data: T, index: number) => void;
};

export const Videos = <T extends GenericData>({ data, appointment, isEditable, handleDeleteVideo, handleVideoUpload, handleVideoVisibility }: GenericListProps<T>) => {
  const { selectedLocation } = useDealersLocations();
  const date = appointment ? formatDate(appointment?.created_on, DATE_FORMATS_FNS.yearMonthDateSlash) : null;
  const locationPath = `${selectedLocation?.id}_${selectedLocation?.name}/${date}/${appointment?.id}_${appointment?.wo_nr}`;

  const handleSaveVideo = (item: T, index: number) => {
    const videoName = `Car_${appointment?.reg_number_escaped}_WO_${appointment?.wo_nr}_${formatDate(new Date(), DATE_FORMATS_FNS.yearMonthDate)}_${index + 1}.mp4`;
    saveAs(item.url, videoName);
  };

  if (data?.length) {
    return (
      <div className="Snooze-Videos">
        {data.map((item, index) => {
          return (
            <div key={item.id} className="video-view">
              <video controls key={item.url}>
                <source src={item.url} type="video/mp4" />
                <source src={item.url} type="video/quicktime" />
              </video>
              <div className="video-controls">
                {isEditable && handleVideoVisibility && (
                  <div className="video-action-button" onClick={() => handleVideoVisibility(item, index)}>
                    <Icon className={`eye ${item.visible ? "green" : "slash red"}`} />
                  </div>
                )}
                <div className="video-actions">
                  {selectedLocation?.is_editing_question_videos_enabled && handleVideoUpload && (
                    <div className="video-action-button">
                      <UploadVideo
                        locationPath={locationPath}
                        path="/checks/upload"
                        baseURL={ENV.appointmentBaseURL}
                        formId={`upload-video-${item.id}-${index}`}
                        onSuccess={url => handleVideoUpload(item, index, url)}
                      />
                    </div>
                  )}
                  <div className="video-action-button" onClick={() => handleSaveVideo(item, index)}>
                    <Icon className="download blue" />
                  </div>
                  {isEditable && handleDeleteVideo && (
                    <div className="video-action-button" onClick={() => handleDeleteVideo(item, index)}>
                      <Icon className="trash grey" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
