import { BubbleController, Chart, Filler, LineController, LineElement, LinearScale, PointElement, TimeScale, Tooltip } from "chart.js";
import "chartjs-adapter-date-fns";
import { useTranslation } from "react-i18next";

import { BubbleData } from "modules/CarDetails/components";
import { useMileageChart } from "modules/CarDetails/components/MileageChart/useMileageChart";
import { ITranslation } from "util/interfaces";

export type MileageChartProps = {
  lineData: BubbleData[];
  bubbleData: BubbleData[];
  colors: {
    backgroundColors: string[];
  };
};

Chart.register(LinearScale, LineElement, PointElement, Filler, Tooltip, BubbleController, LineController, TimeScale);

export const MileageChart = (mileageChartProps: MileageChartProps) => {
  const t = useTranslation().t as ITranslation;
  const { chartRef } = useMileageChart(mileageChartProps);
  return (
    <div className="MileageChart">
      <div className="MileageChart__Legend">
        <span className="MileageChart__Legend__Text thirtyKM">
          &#60; 30 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp; &nbsp;
        </span>

        <span className="MileageChart__Legend__Text thirtyFiftyKM">
          30-50 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp; &nbsp;
        </span>

        <span className="MileageChart__Legend__Text fiftySeventyKM">
          50-70 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp;
        </span>

        <span className="MileageChart__Legend__Text seventyHunderdKM">
          70-100 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp;
        </span>

        <span className="MileageChart__Legend__Text hunderdKM">
          &#62; 100 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp;
        </span>
      </div>
      <div id="MileageChartArea" ref={chartRef}></div>
    </div>
  );
};
