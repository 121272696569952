export const formatPrice = (price: number | null | undefined): string => {
  const numericPrice = price ?? 0;
  return numericPrice.toLocaleString("de-DE", { minimumFractionDigits: 2 });
};

export const parsePriceFromInput = (input: string): number => {
  let price = input;

  if (input.match(/^(\d{1,3}\.)*\d{3},\d{1,2}$/)) price = input.replace(".", "");
  if (price.match(/,\d{1,2}$/)) price = price.replace(",", ".");
  return Number(price.replace(",", ""));
};
