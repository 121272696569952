import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { PAGE_SIZE } from "modules/BackordersDashboard/components/BackordersDashboardTable";
import { useGetBackordersDashboardFilters } from "modules/BackordersDashboard/hooks";
import { BackordersQueryKeys } from "modules/BackordersDashboard/queryKeys";
import { BackordersDashboardFiltersPayload } from "modules/BackordersDashboard/types";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useBackordersDashboardTableFilterHandlers = () => {
  const {
    filters: { page: currentPage }
  } = useGetBackordersDashboardFilters();
  const { updateFilters } = useGetFilterUpdaterFunctions<BackordersDashboardFiltersPayload>({ queryKey: BackordersQueryKeys.backordersFilters });

  const onPageChange = (data: { activePage: number }) => updateFilters({ page: data.activePage }, false);
  const handlePaginationChange: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize: PAGE_SIZE }) : updater;
    onPageChange({ activePage: newState.pageIndex + 1 });
  };

  return { handlePaginationChange };
};
