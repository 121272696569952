import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { ExportDataResponseStatus } from "components";
import ENV from "config/Env";
import { useGetBackordersDashboardFilters } from "modules/BackordersDashboard/hooks";
import { BackordersQueryKeys } from "modules/BackordersDashboard/queryKeys";
import { BackordersDashboardFiltersPayload, BackordersResponse } from "modules/BackordersDashboard/types";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

const getBackordersData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>): Promise<BackordersResponse | undefined> => {
  const { baseUrl, endpoint, params } = queryKey[0];
  if (!params) return;

  const response = await ApiInstance.post(endpoint, params.filters, baseUrl);
  return response.data as BackordersResponse;
};

export const useBackordersQuery = () => {
  const { filters } = useGetBackordersDashboardFilters();

  const { data, isLoading } = useQuery({
    queryKey: BackordersQueryKeys.listBackorders({ filters }),
    queryFn: getBackordersData,
    enabled: !(filters.dealer_ids.length === 0 && filters.location_ids.length === 0)
  });

  return { backordersData: data?.items || [], nbItems: data?.nb_items || 0, nbPages: data?.nb_pages || 1, isLoading };
};

export const exportBackordersDashboardData = async (backordersFilters: BackordersDashboardFiltersPayload): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/backorder/export", backordersFilters, ENV.appointmentBaseURL);
    window.open(response?.data?.url, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};
