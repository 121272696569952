import { useTranslation } from "react-i18next";
import { Image } from "semantic-ui-react";

import { DATE_FORMATS_FNS } from "components";
import "modules/DealerDocumentations/components/DealerDocumentationReadingModal/DealerDocumentationReadingModal.scss";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { getUserNameInitials } from "util/common";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

export const PublisherInformation = () => {
  const t = useTranslation().t as ITranslation;
  const { showViewModalDocumentation: documentation } = useDealerDocumentationContext();

  const publisherProfilePicture = documentation?.created_by?.profile_picture;
  const publishDate = documentation?.publish_date ? formatDate(documentation.publish_date, DATE_FORMATS_FNS.dateMonthYear) : "";
  const updateUser = `${documentation?.updated_by?.first_name} ${documentation?.updated_by?.last_name}`;
  const publisherFullName = `${documentation?.created_by?.first_name} ${documentation?.created_by?.last_name} `;
  const updatedOn = documentation?.updated_by && documentation?.updated_on ? formatDate(documentation.updated_on, DATE_FORMATS_FNS.dateMonthYearTime) : "";
  const publisherInitials = getUserNameInitials(`${documentation?.created_by?.first_name || ""} ${documentation?.created_by?.last_name || ""}`.trim());

  return (
    <div className="modal-first-row">
      <div className="img-name-wrapper">
        {publisherProfilePicture ? (
          <Image src={publisherProfilePicture} size="tiny" className="profilePic" />
        ) : (
          <div className="publisher-initials">{publisherInitials}</div>
        )}
        {publisherFullName && <b>{publisherFullName}</b>}
      </div>
      {updatedOn && (
        <b>
          ({t("v8_updated_on").message || "Updated on"} {updatedOn} {t("v8_by").message || "by"} {updateUser})
        </b>
      )}
      <span>{publishDate}</span>
    </div>
  );
};
