import { toast } from "react-toastify";

import { ManufacturerRequest, PinModel, STATUS_IDENTIFIER } from "models";
import { ManufacturerFilterType } from "modules/ManufacturerDashboard/hooks";
import { copyToClipboard } from "util/common";
import { toDate } from "util/dateHelperFunctions";

export interface ManufacturerRequestWithLatestHistoryItem extends ManufacturerRequest {
  latestPinHistoryItem: PinModel | undefined;
}

export const constructManufacturerRequestPayload = (payload: ManufacturerFilterType) => {
  const activeStatusIdentifers = payload.statusIdentifiers.filter(e => e.isActive).map(e => e.value) as STATUS_IDENTIFIER[];

  return {
    appointment_statuses: activeStatusIdentifers,
    dealer_ids: payload.dealerIds,
    location_ids: payload.locationIds,
    mechanic_fixed: payload.isMechanicFixed,
    page: payload.page,
    pin_status_id: payload.pinStatusIds,
    pin_type_id: payload.pinTypeIds,
    request_status: payload.requestStatus,
    search_term: payload.searchTerm,
    status: payload.manufacturerStatus,
    warranty_type_id: payload.warrantyTypeId
  };
};

export const retrieveLatestHistoryItem = (pin_history: PinModel[]) => {
  return pin_history
    .sort((a, b) => toDate(b.appointment?.created_on ?? new Date()).getTime() - toDate(a.appointment?.created_on ?? new Date()).getTime())
    .at(pin_history.length - 1);
};

export const attachLatestHistoryItem = (manufacturerRequest?: ManufacturerRequest[]): ManufacturerRequestWithLatestHistoryItem[] => {
  return [...(manufacturerRequest || [])].map(request => ({ ...request, latestPinHistoryItem: retrieveLatestHistoryItem(request.pin_history) }));
};

export const validateManufacturerDropdownInput = (input: string[]) => {
  if (input.includes("all")) return null;
  else return Number(input.at(0));
};

export const handleCopyToClipboard = (content: string, message: string) => copyToClipboard(content).then(() => toast.success(message));
