import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";

import { FormCheckbox } from "components";
import { Element } from "components/ReactScroll/index";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface KioskProps {
  tabId: string;
}

export const Kiosk = ({ tabId }: KioskProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_kiosk").message || "Kiosk"}</h3>
      <div>
        <Form.Group className="mt-22 file">
          <Form.Field width={8} className="pr-12">
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="is_customer_name_in_kiosk_checkin_enabled" />
              </span>
              {t("v8_customer_name_in_kiosk_check_in").message || "Customer name in kiosk checkin"}
            </p>

            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="is_customer_signature_in_kiosk_checkin_enabled" />
              </span>
              {t("v8_customer_signature_in_kiosk_check_in").message || "Customer signature in kiosk checkin"}
            </p>
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};
