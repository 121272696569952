import { Icon } from "semantic-ui-react";

import { PIN_TYPE } from "models";
import "modules/WarrantyDashboard/components/WarrantyDashboardTable/components/PinTypeIcon/PinTypeIcon.scss";

type PinTypeIconProps = {
  pinType: number;
  onClick?: () => void;
};

const getCorrectPinTypeIcon = (pinType: number) => {
  switch (pinType) {
    case PIN_TYPE.Warranty:
      return <Icon className="shield halved" />;
    case PIN_TYPE.Recall:
      return <Icon className="registered" />;
    case PIN_TYPE.Remarks:
      return <Icon className="thumb tack" />;

    default:
      return null;
  }
};

export const PinTypeIcon = ({ pinType, onClick }: PinTypeIconProps) => (
  <div className="WarrantyPinType__pin-type-container" onClick={onClick}>
    <div className="WarrantyPinType__pin-type-icon-container" style={{ background: pinType === PIN_TYPE.Warranty ? "#C83627" : "#2084CE" }}>
      {getCorrectPinTypeIcon(pinType)}
    </div>
  </div>
);
