import { OnChangeFn, PaginationState, createColumnHelper } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

import { ActionModalSelectorData, AttachmentPreview, DATE_FORMATS_FNS, ReactTable } from "components";
import { Appointment, QuestionResult } from "models";
import AdvisedCriticalItemsHistoryActions from "modules/AppointmentDetails/components/AdvisedCriticalItemsHistory/components/AdvisedCriticalItemsHistoryActions";
import "modules/AppointmentDetails/components/AdvisedCriticalItemsHistory/components/AdvisedCriticalItemsHistoryTable/AdvisedCriticalItemsHistoryTable.scss";
import { parseQuestionResultAttachments } from "modules/AppointmentDetails/utils";
import { getTyreSeasonIcon } from "util/common";
import { formatDate, isDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

interface AdvisedCriticalItemsHistoryTableProps {
  appointment: Appointment;
  questionResults: QuestionResult[];
  currentPage: number;
  totalPages: number;
  totalItems: number;
  onPageChange: (data: { currentPage: number }) => void;
  onSelect: (data: ActionModalSelectorData) => void;
  onUpdateHiddenItems: () => void;
  showHiddenItems: boolean;
  setShowHiddenItems: Dispatch<SetStateAction<boolean>>;
}

const PAGE_SIZE = 10;

export const AdvisedCriticalItemsHistoryTable = ({
  appointment,
  questionResults,
  currentPage,
  totalPages,
  totalItems,
  onPageChange,
  onSelect,
  showHiddenItems,
  setShowHiddenItems,
  onUpdateHiddenItems
}: AdvisedCriticalItemsHistoryTableProps) => {
  const t = useTranslation().t as ITranslation;
  const columnHelper = createColumnHelper<QuestionResult>();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: currentPage - 1,
    pageSize: totalPages
  });

  const handlePaginationChange: OnChangeFn<PaginationState> = updater => {
    setPagination(old => {
      const newState = typeof updater === "function" ? updater(old) : updater;
      onPageChange({ currentPage: newState.pageIndex + 1 });
      return newState;
    });
  };

  const columns = [
    columnHelper.accessor(row => row.time_car_app, {
      id: "date",
      header: t("v8_appointment_date").message || "Appointment Date",
      cell: info => {
        const appointmentDate = info.renderValue();
        return (
          <span>
            {appointmentDate && (isDate(appointmentDate) || !appointmentDate.startsWith("0001-01-01T00:00:00"))
              ? formatDate(appointmentDate, DATE_FORMATS_FNS.dateMonthYear)
              : ""}
          </span>
        );
      },
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "wo",
      header: "WO #",
      cell: info => {
        const questionResult = info.renderValue();
        if (!questionResult) return null;
        return (
          <span>
            <Link to={`/appointments/${questionResult.appointment_id}`} target="_blank">
              <Icon className="arrow up right from square" />
              {questionResult.wo_nr}
            </Link>
          </span>
        );
      },
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "title",
      header: t("v8_title").message || "Title",
      cell: info => {
        const questionResult = info.renderValue();
        if (!questionResult) return "";
        return (
          <span>
            {questionResult.tyre_id && questionResult.tyre && getTyreSeasonIcon(questionResult.tyre.season)} {questionResult.title}
          </span>
        );
      },
      size: 200
    }),
    columnHelper.accessor(row => row.raw, {
      id: "remarks",
      header: t("v8_remarks").message || "Remarks",
      cell: info => <span>{info.renderValue() || ""}</span>,
      size: 200
    }),
    columnHelper.accessor(row => row, {
      id: "action",
      header: () => (
        <div className="header-action-buttons">
          <Popup
            wide
            basic
            content={t("v8_show_hidden_items").message || "Show hidden items"}
            trigger={
              <div className="hidden-items-button" onClick={() => setShowHiddenItems(prev => !prev)}>
                <Icon className={`clock rotate left ${showHiddenItems ? "green" : "grey"}`} />
              </div>
            }
          />
        </div>
      ),
      cell: ({ row }) => {
        const questionResult = row.original;
        if (!questionResult) return null;
        return (
          <div className="action-container">
            <AdvisedCriticalItemsHistoryActions
              questionResult={questionResult}
              appointment={appointment}
              onSelect={onSelect}
              toggleShowMedia={() => row.toggleExpanded()}
              onUpdateHiddenItems={onUpdateHiddenItems}
            />
          </div>
        );
      },
      size: 100
    })
  ];

  return (
    <div className="AppointmentDetails-AdvisedCriticalItemsHistory-AdvisedCriticalItemsTable mt-15">
      <ReactTable
        columns={columns}
        data={questionResults}
        renderEmptyRow={!questionResults.length}
        emptyRowMessage={t("v8_no_data").message || "No data"}
        pageCount={totalPages}
        pagination={totalPages > 1 ? pagination : undefined}
        onPaginationChange={handlePaginationChange}
        totalItems={totalItems}
        pageSize={PAGE_SIZE}
        renderExpandedComponent={row => {
          const questionResult = row.original as QuestionResult;
          const attachments = parseQuestionResultAttachments(questionResult);
          return <AttachmentPreview attachments={attachments} />;
        }}
      />
    </div>
  );
};
