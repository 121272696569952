import ENV from "config/Env";
import { WarrantyDashboardFiltersPayload } from "modules/WarrantyDashboard/types";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

const component = "WarrantyDashboard";

type Params = {
  filters: WarrantyDashboardFiltersPayload;
};

export const WarrantyQueryKeys = {
  listWarranties: ({ filters }: Params) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/pins/list",
      params: { filters }
    })
  ],
  listBrands: () => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.carBaseUrl,
      endpoint: "/brands/list"
    })
  ],
  warrantyFilters: [localQueryKey({ component, data: "warrantyFilters" })] as const,
  warrantyExportModal: [localQueryKey({ component, data: "warrantyExportModal" })] as const
};
