import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { useDealersLocations } from "hooks";
import { LocationLicenseOverviewKeys } from "modules/LocationLicenseOverview/queryKeys";
import { LicenseItem } from "modules/LocationLicenseOverview/types";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export const useItems = () => {
  const { selectedLocation } = useDealersLocations();

  const getItems = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);

    return res.data.items as LicenseItem[];
  };

  const { data, isLoading } = useQuery({
    queryKey: LocationLicenseOverviewKeys.items({ dealer_location_id: selectedLocation?.id }),
    queryFn: getItems,
    retry: false,
    enabled: !!selectedLocation?.id
  });

  return {
    items: data || [],
    isLoadingItems: isLoading
  };
};
