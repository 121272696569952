import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Message } from "components";
import "modules/DealerLicenseOverview/components/DealerInvoiceDisabledMessage/DealerInvoiceDisabledMessage.scss";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

export const DealerInvoiceDisabledMessage = () => {
  const t = useTranslation().t as ITranslation;

  return (
    <Message
      centered={true}
      content={
        <>
          <span>
            {t("v8_dealer_level_invoicing_is_not_enabled").message || "Dealer level invoicing is not enabled"}.{" "}
            {t("v8_view_your_locations_licenses_in_the").message || "View your locations licenses in the"}{" "}
          </span>
          <Link className="dealer-invoice-disabled-message-link-text" to={PATHS.LOCATION_LICENSE_OVERVIEW}>
            <span>{t("v8_location_licenses_overview").message?.toLowerCase() || "location licenses overview"}.</span>
          </Link>
        </>
      }
    />
  );
};
