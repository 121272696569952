import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DealerReportThreshold } from "models";
import { DealerReportQueryKeys } from "modules/DealerReports/queryKeys";
import ApiInstance from "util/Api";
import { ITranslation } from "util/interfaces";
import { IBackendQueryKey } from "util/keyFactory";

export const useDealerThresholds = (dealer_id: number) => {
  const t = useTranslation().t as ITranslation;

  const getDealerThresholdsList = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params, endpoint } = queryKey[0];
    try {
      const response = await ApiInstance.post(endpoint, params, baseUrl);
      return response?.data?.dealer_report_thresholds as DealerReportThreshold[];
    } catch (err) {
      toast.error(t("failed_to_load_thresholds").message || "Failed to load thresholds");
      throw err;
    }
  };

  return useQuery({
    queryKey: DealerReportQueryKeys.list_thresholds(dealer_id),
    queryFn: getDealerThresholdsList,
    enabled: !!dealer_id
  });
};
