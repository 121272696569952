import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Icon, Popup } from "semantic-ui-react";

import { FormCheckbox, FormColorPicker, FormDropdown, FormInput, FormUploadImage } from "components";
import { Element } from "components/ReactScroll/index";
import ENV from "config/Env";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/components/General/General.scss";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface GeneralProps {
  tabId: string;
}

enum HEADING_POSITION {
  Header = 1,
  Footer
}

export const General = ({ tabId }: GeneralProps) => {
  const t = useTranslation().t as ITranslation;

  const layoutOptions = useMemo(
    () => [
      { key: 1, value: 1, text: t("v8_default_layout").message || "Default layout" },
      { key: 2, value: 2, text: t("v8_default_layout_menu_left").message || "Default layout with menu on the left" }
    ],
    []
  );

  const { setValue, watch } = useFormContext();
  const headingPosition = watch("heading_position");

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_general").message || "GENERAL"}</h3>
      <div>
        <Form.Group className="mt-18">
          <Form.Field width={8}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <FormInput<CommunicationSettings> name="email" inputProps={{ fluid: true, placeholder: t("v8_email").message || "E-mail" }} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <FormInput<CommunicationSettings> name="phone_number" inputProps={{ fluid: true, placeholder: t("v8_phone").message || "Phone", type: "Phone" }} />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_website").message || "Website"}</label>
            <FormInput<CommunicationSettings> name="website" inputProps={{ fluid: true, placeholder: t("v8_website").message || "Website" }} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_layout").message || "Layout"}</label>
            <FormDropdown<CommunicationSettings>
              name="layout"
              dropdownProps={{ options: layoutOptions, placeholder: t("v8_select_layout").message || "Select Layout", selection: true }}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_external_CSS").message || "External CSS"}</label>
            <FormInput<CommunicationSettings> name="external_css" inputProps={{ fluid: true, placeholder: t("v8_external_CSS").message || "External CSS" }} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_external_JS").message || "External JS"}</label>
            <FormInput<CommunicationSettings> name="external_js" inputProps={{ fluid: true, placeholder: t("v8_external_JS").message || "External JS" }} />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8} className="pr-12">
            <label>{t("v8_google_analytics_ID").message || "Google Analytics ID"}</label>
            <FormInput<CommunicationSettings>
              name="google_analytics_id"
              inputProps={{ fluid: true, placeholder: t("v8_google_analytics_ID").message || "Google Analytics ID" }}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-16">
          <Form.Field width={8} className="row items-center">
            <p>{t("v8_pick_a_colour_for_customer_communication").message || "Pick a colour for customer communication"}</p>
            <div className="color-picker">
              <FormColorPicker<CommunicationSettings> name="color" />
            </div>
          </Form.Field>
          <Form.Field width={8}>
            <label className="big">{t("v8_confirmation_emails").message || "Confirmation Emails"}</label>

            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="send_confirmation_emails" />
              </span>
              {t("v8_enable_sending_confirmation_emails").message || "Enable sending confirmation emails"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-18">
          <Form.Field width={8}>
            <p className="title">{t("v8_heading_position").message || "Heading position"}</p>
            <p className="items-center">
              <span>
                <Checkbox toggle checked={headingPosition === HEADING_POSITION.Header} onClick={() => setValue("heading_position", 1)} />
              </span>
              {t("v8_logo_and_info_in_header").message || "Logo and info in Header"}
            </p>
            <p className="items-center">
              <span>
                <Checkbox toggle checked={headingPosition === HEADING_POSITION.Footer} onClick={() => setValue("heading_position", 2)} />
              </span>
              {t("v8_logo_and_info_in_footer").message || "Logo and info in Footer"}
            </p>
          </Form.Field>

          <Form.Field width={8}>
            <p className="title">{t("v8_price").message || "Price"}</p>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="hide_lease_intervention_price" />
              </span>
              {t("v8_hide_price_of_interventions_for_lease_appointments").message || "Hide price of interventions for Lease appointments"}
            </p>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="hide_internal_intervention_price" />
              </span>
              {t("v8_hide_internal_intervention_price").message || "Hide price of internal interventions"}
            </p>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="hide_warranty_intervention_price" />
              </span>
              {t("v8_hide_warranty_intervention_price").message || "Hide price of warranty interventions"}
            </p>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="hide_recall_intervention_price" />
              </span>
              {t("v8_hide_recall_intervention_price").message || "Hide price of recall interventions"}
            </p>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="hide_prices_in_desk_communication" />
              </span>
              {t("v8_hide_prices_in_desk_communication").message || "Hide price for the desk check-in/out"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-18">
          <Form.Field width={8}>
            <p className="title">{t("v8_scheduled_services").message || "Scheduled services"}</p>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="display_intervention_elements" />
              </span>
              {t("v8_display_intervention_elements").message || "Display Intervention Elements"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-25 file">
          <Form.Field width={8}>
            <label className="small">
              <span>{t("v8_upload_logo").message || "Upload Logo"} (.jpg, .jpeg, .png)</span>
              <Popup content={t("v8_min_size_of_logo").message || "Min size of Logo 100x100 px"} position="right center" trigger={<Icon className="circle info" />} />
            </label>
            <FormUploadImage<CommunicationSettings> path="/communication/logo/upload" baseUrl={ENV.dealerBaseUrl} name="logo" fileFieldName="file" />
          </Form.Field>

          <Form.Field width={8}>
            <label className="small">
              <span>{t("v8_upload_welcome_image").message || "Upload welcome image"} (.jpg, .jpeg, .png) </span>
              <Popup
                content={t("v8_min_size_of_welcome_pic").message || "Min size of welcome picture"}
                position="right center"
                trigger={<Icon className="circle info" />}
              />
            </label>
            <FormUploadImage<CommunicationSettings> path="/communication/welcome_pic/upload" baseUrl={ENV.dealerBaseUrl} name="welcome_picture" fileFieldName="file" />
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};
