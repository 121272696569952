import ENV from "config/Env";
import { ORIGIN, UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "DealerLicenseOverview";

type ListInvoices = {
  dealer_id: number | undefined;
  pagination_url?: string;
  page_index?: number;
};

type LicenseParams = {
  dealerId: number | undefined;
};

export const DealerLicenseQueryKeys = {
  licenseItems: ({ dealerId }: LicenseParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/licenses/items/list",
      params: { dealer_id: dealerId }
    })
  ],
  licenses: ({ dealerId }: LicenseParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/licenses/list",
      params: { dealer_id: dealerId }
    })
  ],
  invoices: ({ dealer_id, pagination_url, page_index }: ListInvoices) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/invoices/list",
      params: { dealer_id, pagination_url, page_index }
    })
  ],
  paginatedInvoices: [{ origin: ORIGIN.Local, component, data: "paginatedInvoices" }]
};
