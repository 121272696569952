import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";

import { ActionModalSelectorData, DetailsCards, ExtendedInterventionData, MODAL_TYPE, TabData } from "components";
import InterventionModal from "components/InterventionModal";
import { Appointment } from "models";
import { AdvisedCriticalItemsHistoryTable } from "modules/AppointmentDetails/components/AdvisedCriticalItemsHistory/components/AdvisedCriticalItemsHistoryTable";
import { useAdvisedCriticalItemsHistory } from "modules/AppointmentDetails/hooks/useAdvisedCriticalItemsHistory";
import { ITranslation } from "util/interfaces";

type AdvisedCriticalItemsHistoryProps = {
  isExpanded: boolean;
  tabData: TabData;
  appointment: Appointment;
};

export const AdvisedCriticalItemsHistory = ({ isExpanded, tabData, appointment }: AdvisedCriticalItemsHistoryProps) => {
  const t = useTranslation().t as ITranslation;
  const { advisedCriticalItemsHistory, loading, setCurrentPage, currentPage, showHiddenItems, setShowHiddenItems, refetchAdvisedCriticalItemsHistory } =
    useAdvisedCriticalItemsHistory(appointment.id);

  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [modalData, setModalData] = useState<ActionModalSelectorData | null>(null);
  const totalItems = advisedCriticalItemsHistory?.nb_count || 0;
  const totalPages = advisedCriticalItemsHistory?.nb_pages || 1;

  const handlePageChange = (data: { currentPage: number }) => setCurrentPage(data.currentPage);

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType !== MODAL_TYPE.Intervention) return;
    setModalData(data as ExtendedInterventionData);
    setShowInterventionModal(true);
  };

  const onRequestCloseModal = () => {
    setShowInterventionModal(false);
    setModalData(null);
  };

  return (
    <DetailsCards
      id={tabData.id}
      title={`${t("v8_advised_critical_items_history").message || "Advised and critical items history"} ${totalItems > 0 ? `(${totalItems})` : ""}`}
      icon="clock rotate left"
      isExpanded={isExpanded}
      containerStyle={"AppointmentDetails-AdvisedCriticalItemsHistory"}
    >
      {loading && <Loader active />}
      {!loading && (
        <AdvisedCriticalItemsHistoryTable
          appointment={appointment}
          questionResults={advisedCriticalItemsHistory?.question_results || []}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalItems={totalItems}
          onSelect={handleSelect}
          showHiddenItems={showHiddenItems}
          setShowHiddenItems={setShowHiddenItems}
          onUpdateHiddenItems={refetchAdvisedCriticalItemsHistory}
        />
      )}
      <InterventionModal
        size="large"
        open={showInterventionModal}
        onClose={onRequestCloseModal}
        closeOnDimmerClick
        data={modalData}
        onSuccess={refetchAdvisedCriticalItemsHistory}
      />
    </DetailsCards>
  );
};
