import { useCallback } from "react";

import { NavPortal, SearchFilter } from "components";
import { useGetManufacturerDashboardFilters } from "modules/ManufacturerDashboard/hooks";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

export const ManufacturerSearch = () => {
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();
  const { updateFilters } = useGetFilterUpdaterFunctions({ queryKey: ManufacturerQueryKeys.manufacturerFilters });

  const handleSearchChange = useCallback((input: string) => {
    if (!input) return updateFilters({ searchTerm: null });
    updateFilters({ searchTerm: input });
  }, []);

  return (
    <NavPortal>
      <SearchFilter value={manufacturerDashboardFilters.searchTerm || ""} handleSearchChange={handleSearchChange} />
    </NavPortal>
  );
};
