import { PinModal } from "components";
import {
  CreateManufacturerRequestModal,
  ManufacturerDashboardFilter,
  ManufacturerRequestModal,
  ManufacturerSearch,
  ManufacturerTable
} from "modules/ManufacturerDashboard/components";
import { useCreateManufacturerRequestModal, useManufacturerPinModal, usehandleManufacturerRequest } from "modules/ManufacturerDashboard/hooks";
import "modules/ManufacturerDashboard/ManufacturerDashboard.scss";
import { retrieveLatestHistoryItem } from "modules/ManufacturerDashboard/utils";

const ManufacturerDashboard = () => {
  const { selectedRequestToHandle, closeRequestModal, openRequestModal, handleManufacturerRequest } = usehandleManufacturerRequest();
  const { closePinModal, selectPinItem, selectedPinItem } = useManufacturerPinModal();
  const { showCreateRequestModal, closeCreateRequestModal, openCreateRequestModal, searchManufacturerClaim, pinClaims, isSearchingPin } =
    useCreateManufacturerRequestModal();

  const latestPinHistory = selectedPinItem && retrieveLatestHistoryItem(selectedPinItem);

  return (
    <div className="Manufacturer__container">
      <ManufacturerSearch />
      <ManufacturerDashboardFilter onOpenRequestModal={openCreateRequestModal} />
      <ManufacturerTable openRequestModal={openRequestModal} onSelectPinItem={selectPinItem} />
      <ManufacturerRequestModal onCloseModal={closeRequestModal} selectedRequest={selectedRequestToHandle} handleManufacturerRequest={handleManufacturerRequest} />
      {latestPinHistory && <PinModal pin={latestPinHistory} externalPinHistory={selectedPinItem} isOpen={Boolean(selectedPinItem)} onClose={closePinModal} />}
      {showCreateRequestModal && (
        <CreateManufacturerRequestModal
          onCloseModal={closeCreateRequestModal}
          onSearchClaim={searchManufacturerClaim}
          claimResults={pinClaims}
          isLoading={isSearchingPin}
        />
      )}
    </div>
  );
};

export default ManufacturerDashboard;
