import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { DATE_FORMATS_FNS, WebSocketMessageListener } from "components";
import { Appointment, Intervention, PinModel } from "models";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyDashboardFiltersPayload, WarrantyWithHistory } from "modules/WarrantyDashboard/types";
import { PinModelWithHistory } from "modules/WarrantyDashboard/util";
import { formatCurrentUTCDate } from "util/dateHelperFunctions";

export const useWarrantyDashboardListeners = () => {
  const queryClient = useQueryClient();

  const listeners = useMemo((): WebSocketMessageListener[] => {
    return [
      {
        model: "Intervention",
        action: "update",
        callback: message => {
          const filters = queryClient.getQueryData<WarrantyDashboardFiltersPayload>(WarrantyQueryKeys.warrantyFilters) as WarrantyDashboardFiltersPayload;
          const queryKey = WarrantyQueryKeys.listWarranties({ filters });
          const warrantyData = queryClient.getQueryData<WarrantyWithHistory>(queryKey);

          if (!warrantyData?.items?.length) return;

          const interventionData = message.data as Intervention;
          if (!interventionData) return;

          const interventionIdx = warrantyData.items.findIndex(warranty => warranty.intervention_id === interventionData.id);
          if (interventionIdx < 0) return;

          const pin = warrantyData.items[interventionIdx];
          queryClient.setQueryData(queryKey, {
            ...warrantyData,
            items: warrantyData.items.with(interventionIdx, { ...pin, intervention: { ...pin.intervention, ...interventionData } } as PinModelWithHistory)
          });
        }
      },
      {
        model: "Appointment",
        action: "update",
        callback: message => {
          const filters = queryClient.getQueryData<WarrantyDashboardFiltersPayload>(WarrantyQueryKeys.warrantyFilters) as WarrantyDashboardFiltersPayload;
          const queryKey = WarrantyQueryKeys.listWarranties({ filters });
          const warrantyData = queryClient.getQueryData<WarrantyWithHistory>(queryKey);

          if (!warrantyData?.items?.length) return;

          const appointmentData = message.data as Appointment;
          if (!appointmentData) return;

          const appointmentIdx = warrantyData.items.findIndex(warranty => warranty.appointment_id === appointmentData.id);
          if (appointmentIdx < 0) return;

          const pin = warrantyData.items[appointmentIdx] as PinModel;
          queryClient.setQueryData(queryKey, {
            ...warrantyData,
            items: warrantyData.items.with(appointmentIdx, { ...pin, appointment: { ...pin.appointment, ...appointmentData } } as PinModelWithHistory)
          });
        }
      },
      {
        model: "Pin",
        action: "append",
        callback: message => {
          const pinData = message.data as PinModel;
          if (!pinData) return;

          const filters = queryClient.getQueryData<WarrantyDashboardFiltersPayload>(WarrantyQueryKeys.warrantyFilters) as WarrantyDashboardFiltersPayload;
          const warrantyQueryKey = WarrantyQueryKeys.listWarranties({ filters });
          const warrantyData = queryClient.getQueryData<WarrantyWithHistory>(warrantyQueryKey);

          if (!warrantyData) return;

          const pinIdx = warrantyData.items.findIndex(
            pin =>
              (pin.question_result_id && pin.question_result_id === pinData.question_result_id) ||
              (pin.intervention_id && pin.intervention_id === pinData.intervention_id)
          );

          if (pinIdx === -1) {
            const currentPinStatus = filters.pin_status_id;
            const incomingPinStatus = pinData.pin_status_id;

            const isPinStatusFilterSelected = currentPinStatus && currentPinStatus?.length > 0;
            const isPinStatusFilterSelectedAndPinStatusMatches = isPinStatusFilterSelected && incomingPinStatus !== null && currentPinStatus.includes(incomingPinStatus);

            if (!isPinStatusFilterSelected || isPinStatusFilterSelectedAndPinStatusMatches) {
              const newPins = warrantyData.items.concat({ ...pinData, history: [{ ...pinData } as PinModel] });
              queryClient.setQueryData(warrantyQueryKey, { ...warrantyData, items: newPins, nb_items: warrantyData.nb_items + 1 });
              return;
            }
          }

          const pin = warrantyData.items[pinIdx];
          if (!pin) return;

          const historyIdx = pin.history?.findIndex(historyPin => historyPin.id === pinData.id);

          if (historyIdx > -1) {
            const pinHistory = pin.history[historyIdx];
            const updatedPinsWithHistory = warrantyData.items.with(pinIdx, {
              ...pin,
              history: pin.history.with(historyIdx, { ...pinHistory, ...pinData, updated_on: formatCurrentUTCDate(DATE_FORMATS_FNS.ISOFormat) } as PinModel)
            });

            queryClient.setQueryData(warrantyQueryKey, { ...warrantyData, items: updatedPinsWithHistory });
          } else {
            const updatedPins = warrantyData.items.with(pinIdx, { ...pin, ...pinData, history: [...pin.history, pinData] });
            queryClient.setQueryData(warrantyQueryKey, { ...warrantyData, items: updatedPins });
          }
        }
      },
      {
        model: "Pin",
        action: "delete",
        callback: message => {
          const filters = queryClient.getQueryData<WarrantyDashboardFiltersPayload>(WarrantyQueryKeys.warrantyFilters) as WarrantyDashboardFiltersPayload;
          const queryKey = WarrantyQueryKeys.listWarranties({ filters });
          const warrantyData = queryClient.getQueryData<WarrantyWithHistory>(queryKey);

          if (!warrantyData?.items || warrantyData?.items?.length <= 0) return;

          const pinData = message.data as PinModel;
          if (!pinData) return;

          const updatedPinItems = warrantyData.items.filter(warranty => warranty.id !== pinData.id);
          queryClient.setQueryData(queryKey, { ...warrantyData, items: updatedPinItems, nb_items: warrantyData.nb_items - 1 });
        }
      }
    ];
  }, []);

  return listeners;
};
