import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useUser } from "hooks";
import { DealerDocumentation } from "models";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { DealerDocumentationsQueryKeys } from "modules/DealerDocumentations/queryKeys";
import ApiInstance from "util/Api";

const uploadDocumentationFile = async (formData: FormData) => {
  const { baseUrl, endpoint } = DealerDocumentationsQueryKeys.upload;
  const result = await ApiInstance.post(endpoint, formData, baseUrl);
  return result.data?.url;
};

export async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  const result = await uploadDocumentationFile(formData);
  return result;
}

export const useCreateDocumentationMtn = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const queryKey = DealerDocumentationsQueryKeys.list;
  const { endpoint, baseUrl } = DealerDocumentationsQueryKeys.create;

  const createDocumentation = useMutation({
    mutationFn: async (documentation: DealerDocumentation) => {
      const response = await ApiInstance.post(endpoint, documentation, baseUrl);
      return response.data?.dealer_documentation_id;
    },
    onMutate: (documentation: DealerDocumentation) => {
      const previousQueryData = queryClient.getQueryData([queryKey]);
      const newQueryData = (old: DealerDocumentation[]) => [documentation, ...old];
      queryClient.setQueryData([queryKey], newQueryData);
      return { previousQueryData };
    },
    onError: (error, __, context) => {
      toast.error(error.message);
      queryClient.setQueryData([queryKey], () => context?.previousQueryData);
    },
    onSuccess: res => {
      const newQueryData = (old: DealerDocumentation[]) => old.map(item => (item.id === 0 ? { ...item, id: res, created_by: user, created_by_id: user?.id } : item));
      queryClient.setQueryData([queryKey], newQueryData);
    }
  });

  return createDocumentation;
};

export const useUpdateDocumentationMtn = () => {
  const queryClient = useQueryClient();
  const queryKey = DealerDocumentationsQueryKeys.list;
  const { endpoint, baseUrl } = DealerDocumentationsQueryKeys.update;

  const updateDocumentation = useMutation({
    mutationFn: async (documentation: DealerDocumentation) => {
      await ApiInstance.post(endpoint, documentation, baseUrl);
    },
    onMutate: (documentation: DealerDocumentation) => {
      const previousQueryData = queryClient.getQueryData([queryKey]);
      const newQueryData = (old: DealerDocumentation[]) => old.map(item => (item.id === documentation.id ? { ...item, ...documentation } : item));
      queryClient.setQueryData([queryKey], newQueryData);
      return { previousQueryData };
    },
    onError: (error, __, context) => {
      toast.error(error.message);
      queryClient.setQueryData([queryKey], () => context?.previousQueryData);
    }
  });

  return updateDocumentation;
};

export const useDeleteDocumentationMtn = () => {
  const queryClient = useQueryClient();
  const queryKey = DealerDocumentationsQueryKeys.list;
  const { endpoint, baseUrl } = DealerDocumentationsQueryKeys.delete;

  const deleteDocumentation = useMutation({
    mutationFn: async (id: number) => {
      const formData = new FormData();
      formData.append("id", id.toString());
      await ApiInstance.post(endpoint, { id }, baseUrl);
    },
    onMutate: (id: number) => {
      const previousQueryData = queryClient.getQueryData([queryKey]);
      const newQueryData = (old: DealerDocumentation[]) => old.filter(item => item.id !== id);
      queryClient.setQueryData([queryKey], newQueryData);
      return { previousQueryData };
    },
    onError: (error, __, context) => {
      toast.error(error.message);
      queryClient.setQueryData([queryKey], () => context?.previousQueryData);
    }
  });

  return deleteDocumentation;
};

export const useGetDealersDocumentations = () => {
  const queryKey = DealerDocumentationsQueryKeys.list;

  const {
    visibleToMechanic,
    selectedDealersLocations: { dealerIds, locationIds }
  } = useDealerDocumentationContext();

  const getDocumentationsList = async () => {
    const response = await ApiInstance.post(
      queryKey.endpoint,
      { dealer_ids: dealerIds, dealer_location_ids: locationIds, visible_to_mechanic: visibleToMechanic },
      queryKey.baseUrl
    );
    return response?.data?.documentations as DealerDocumentation[];
  };

  useQuery({
    queryKey: [queryKey],
    queryFn: getDocumentationsList,
    refetchOnMount: "always",
    enabled: dealerIds.length > 0 || locationIds.length > 0
  });
};
