import { isAfter, isBefore } from "date-fns";

import { Appointment, COMMUNICATION_STATUS, STATUS_IDENTIFIER } from "models";

export const FAKE_STATUSES = [
  STATUS_IDENTIFIER.CanceledStatus,
  STATUS_IDENTIFIER.CheckInCar,
  STATUS_IDENTIFIER.CheckInDone,
  STATUS_IDENTIFIER.CarInShop,
  STATUS_IDENTIFIER.CarOkPlusRepairOverview,
  STATUS_IDENTIFIER.QualityCheckPlusRepairOverview,
  STATUS_IDENTIFIER.CarOutOfShop
];

export const updateAppointmentStatusIdentifier = (appointment: Appointment): Appointment => {
  let currentStatus = null;

  const isStatusIn = (statuses: STATUS_IDENTIFIER[]) => statuses.includes(appointment.appointment_status_identifier);
  const isCurrentTimeBefore = (time: string) => isBefore(new Date(time), new Date());
  const isCurrentTimeAfter = (time: string) => isAfter(new Date(time), new Date());

  if (appointment.check_in_com_to_handle && isStatusIn([STATUS_IDENTIFIER.NewCarStatus])) {
    currentStatus = STATUS_IDENTIFIER.CheckInCar;
  } else if (isStatusIn([STATUS_IDENTIFIER.CarInShop]) && appointment.car_out_of_shop) {
    currentStatus = STATUS_IDENTIFIER.NewCarStatus;
  } else if (
    isStatusIn([STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CheckInDone]) &&
    appointment.car_in_shop &&
    isCurrentTimeBefore(appointment.car_in_shop) &&
    (!appointment.car_out_of_shop || isCurrentTimeAfter(appointment.car_out_of_shop))
  ) {
    currentStatus = STATUS_IDENTIFIER.CarInShop;
  } else if (isStatusIn([STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CheckInCar]) && appointment.check_in_com_answered && !appointment.check_in_com_to_handle) {
    currentStatus = STATUS_IDENTIFIER.CheckInDone;
  } else if (
    isStatusIn([STATUS_IDENTIFIER.CarReadyStatus, STATUS_IDENTIFIER.QualityCheckStatus]) &&
    (appointment.customer_communication?.status === COMMUNICATION_STATUS.RepairOverviewSent ||
      appointment.customer_communication?.status === COMMUNICATION_STATUS.RepairOverviewOpened)
  ) {
    currentStatus =
      appointment.appointment_status_identifier === STATUS_IDENTIFIER.CarReadyStatus
        ? STATUS_IDENTIFIER.CarOkPlusRepairOverview
        : STATUS_IDENTIFIER.QualityCheckPlusRepairOverview;
  }

  return currentStatus ? ({ ...appointment, appointment_status_identifier: currentStatus } as Appointment) : appointment;
};

export const isCarReady = (status: STATUS_IDENTIFIER | undefined) =>
  Boolean(status && [STATUS_IDENTIFIER.CarReadyStatus, STATUS_IDENTIFIER.CarOkPlusRepairOverview].includes(status));
export const isQualityCheck = (status: STATUS_IDENTIFIER | undefined) =>
  Boolean(status && [STATUS_IDENTIFIER.QualityCheckStatus, STATUS_IDENTIFIER.QualityCheckPlusRepairOverview].includes(status));
export const isWorkNotStarted = (status: STATUS_IDENTIFIER | undefined) =>
  Boolean(status && [STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CheckInCar, STATUS_IDENTIFIER.CheckInDone, STATUS_IDENTIFIER.CarInShop].includes(status));
export const isWorkStarted = (status: STATUS_IDENTIFIER | undefined) => Boolean(status && !isWorkNotStarted(status));
export const isCarReadyOrQualityCheck = (status: STATUS_IDENTIFIER | undefined) => Boolean(status && (isCarReady(status) || isQualityCheck(status)));
export const isCarReadyOrQualityCheckDoneOrCanceled = (status: STATUS_IDENTIFIER | undefined) =>
  Boolean(status && (isCarReadyOrQualityCheck(status) || status === STATUS_IDENTIFIER.CanceledStatus));
