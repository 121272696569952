import { useState } from "react";

import { PinModel } from "models";

export const useManufacturerPinModal = () => {
  const [selectedPinItem, setSelectedPinItem] = useState<PinModel[] | null>(null);

  const closePinModal = () => setSelectedPinItem(null);
  const selectPinItem = (pinItem: PinModel[]) => setSelectedPinItem(pinItem);

  return { selectedPinItem, closePinModal, selectPinItem };
};
