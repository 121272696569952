import { useQuery } from "@tanstack/react-query";

import { useDealersLocations } from "hooks";
import { BackordersQueryKeys } from "modules/BackordersDashboard/queryKeys";
import { BackordersDashboardFiltersPayload } from "modules/BackordersDashboard/types";

export const useDefaultBackordersDashboardFilters = () => {
  const { selectedLocation } = useDealersLocations();

  const defaultBackordersFilters: BackordersDashboardFiltersPayload = {
    dealer_ids: [],
    location_ids: selectedLocation ? [selectedLocation.id] : [],
    page: 1,
    search_term: ""
  };

  return { defaultBackordersFilters };
};

export const useGetBackordersDashboardFilters = () => {
  const { selectedLocation } = useDealersLocations();
  const { defaultBackordersFilters } = useDefaultBackordersDashboardFilters();

  const { data, isLoading } = useQuery<BackordersDashboardFiltersPayload>({
    queryKey: BackordersQueryKeys.backordersFilters,
    placeholderData: defaultBackordersFilters,
    queryFn: () => ({ ...defaultBackordersFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] }),
    enabled: !!selectedLocation,
    gcTime: Infinity
  });

  return { filters: data || defaultBackordersFilters, isLoading };
};
