export const toggleZohoWidget = () => {
  const ZohohcAsapWebButton = document.getElementById("zohohc-asap-web-button");
  if (!ZohohcAsapWebButton) return;

  if (ZohohcAsapWebButton.style.left === "auto") {
    ZohohcAsapWebButton.click();
    ZohohcAsapWebButton.style.left = "-100px";
  } else {
    ZohohcAsapWebButton.click();
    ZohohcAsapWebButton.style.left = "auto";
    ZohohcAsapWebButton.addEventListener("click", () => (ZohohcAsapWebButton.style.left = "-100px"), { once: true });
  }
};
