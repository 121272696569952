import { Button, Icon } from "semantic-ui-react";

import FaceIcon from "assets/svg/face.svg";
import { getAppointmentStatus } from "components";
import "components/StatusFilters/StatusFilters.scss";
import { useDealersLocations } from "hooks";
import { STATUS_IDENTIFIER } from "models";

interface StatusFiltersProps {
  onChange: (e: StatusData[]) => void;
  selectedStatusIdentifiersFilters?: StatusData[];
}

export type StatusData = {
  icon: string;
  value: STATUS_IDENTIFIER;
  isActive: boolean;
  id: STATUS_IDENTIFIER;
};

export const statusData: StatusData[] = [
  { icon: "calendar check", isActive: true, value: STATUS_IDENTIFIER.CheckInCar, id: STATUS_IDENTIFIER.CheckInCar },
  { icon: "wrench", isActive: true, value: STATUS_IDENTIFIER.CarCheckStatus, id: STATUS_IDENTIFIER.CarCheckStatus },
  { icon: "square a", isActive: true, value: STATUS_IDENTIFIER.AannameOKStatus, id: STATUS_IDENTIFIER.AannameOKStatus },
  { icon: "dollar", isActive: true, value: STATUS_IDENTIFIER.CalculatingStatus, id: STATUS_IDENTIFIER.CalculatingStatus },
  { icon: "tag", isActive: true, value: STATUS_IDENTIFIER.PricingOKStatus, id: STATUS_IDENTIFIER.PricingOKStatus },
  { icon: "triangle exclamation", isActive: true, value: STATUS_IDENTIFIER.DiagnoseStatus, id: STATUS_IDENTIFIER.DiagnoseStatus },
  { icon: "user check", isActive: true, value: STATUS_IDENTIFIER.CustomerAnsweredStatus, id: STATUS_IDENTIFIER.CustomerAnsweredStatus },
  { icon: "face", isActive: true, value: STATUS_IDENTIFIER.CustomerOKStatus, id: STATUS_IDENTIFIER.CustomerOKStatus },
  { icon: "check double", isActive: true, value: STATUS_IDENTIFIER.CarReadyStatus, id: STATUS_IDENTIFIER.CarReadyStatus },
  { icon: "shield check", isActive: true, value: STATUS_IDENTIFIER.QualityCheckStatus, id: STATUS_IDENTIFIER.QualityCheckStatus },
  { icon: "right from bracket outOfShop", isActive: true, value: STATUS_IDENTIFIER.CarOutOfShop, id: STATUS_IDENTIFIER.CarOutOfShop },
  { icon: "car garage", isActive: true, value: STATUS_IDENTIFIER.CarInShop, id: STATUS_IDENTIFIER.CarInShop },
  { icon: "car mirrors", isActive: true, value: STATUS_IDENTIFIER.NewCarStatus, id: STATUS_IDENTIFIER.NewCarStatus },
  { icon: "bo", isActive: true, value: STATUS_IDENTIFIER.BackOrderStatus, id: STATUS_IDENTIFIER.BackOrderStatus },
  { icon: "circle xmark", isActive: true, value: STATUS_IDENTIFIER.CanceledStatus, id: STATUS_IDENTIFIER.CanceledStatus }
];

export const StatusFilters = ({ onChange, selectedStatusIdentifiersFilters = statusData }: StatusFiltersProps) => {
  const { selectedLocation } = useDealersLocations();

  const handleClick = (index: number) => {
    const matchingIdentifier = selectedStatusIdentifiersFilters[index];

    const updatedSelectedStatusIdentifiersFilters = selectedStatusIdentifiersFilters.with(index, { ...matchingIdentifier, isActive: !matchingIdentifier.isActive });
    onChange(updatedSelectedStatusIdentifiersFilters);
  };

  if (!selectedLocation?.statuses) return null;

  return (
    <div className="status-filters-wrapper">
      {selectedStatusIdentifiersFilters.map((item: StatusData, index: number) => {
        return (
          <div key={`filter-status-${index}`} className="StatusFilters-buttons" onClick={() => handleClick(index)}>
            <Button
              color="orange"
              className="af"
              style={{ backgroundColor: getAppointmentStatus(item.value, selectedLocation?.statuses)?.color }}
              disabled={!item.isActive}
            >
              {item.icon === "bo" ? (
                "BO"
              ) : item.icon === "face" ? (
                <div className="filter-status-face-icon">
                  <img src={FaceIcon} />
                </div>
              ) : (
                <Icon className={item.icon} />
              )}
            </Button>
          </div>
        );
      })}
    </div>
  );
};
