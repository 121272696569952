import { useTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

import { DATE_FORMATS_FNS, PinUserLabel } from "components";
import { PinModel } from "models";
import "modules/AppointmentDetails/components/Interventions/components/ImportantNotes/ImportantNotes.scss";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type Props = {
  data: PinModel[] | undefined;
};

export const ImportantNotes = ({ data }: Props) => {
  const t = useTranslation().t as ITranslation;
  if (!data) return null;

  const onlyImportantOnes = data.filter(element => element.visible_important_items);

  return (
    <div className="ImportantNotesContainer">
      {onlyImportantOnes.map(currentPin => (
        <div key={currentPin.id} className="container">
          <PinUserLabel currentPin={currentPin} />
          {currentPin.updated_on && (
            <Label basic>
              <span className="label-text">{formatDate(currentPin.updated_on, DATE_FORMATS_FNS.dateMonthYearTime)}</span>
            </Label>
          )}
          {currentPin.visible_important_items && currentPin.keep_parts && (
            <Label basic>
              <Icon className="gear i-green keep-parts-icon" />
              <span className="label-text"> {t("v8_keep_parts").message || "Keep Parts"} </span>
            </Label>
          )}
          <span className="note-content">{currentPin.note}</span>
        </div>
      ))}
    </div>
  );
};
