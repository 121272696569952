import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps, Grid, Icon } from "semantic-ui-react";

import "components/CustomerModal/components/CustomerDetail/CustomerDetail.scss";
import { CHANNELS, Customer } from "models";
import { ITranslation } from "util/interfaces";

type CustomerDetailProps = {
  customer: Customer;
  loading: boolean;
  isUpdatingCustomer: boolean;
  onChangePreferredCommunicationChannel: (channel: CHANNELS) => void;
  onChangeCheckbox: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
};

const CustomerDetail = ({ customer, loading, isUpdatingCustomer, onChangeCheckbox, onChangePreferredCommunicationChannel }: CustomerDetailProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="CustomerDetail">
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <strong>{t("v8_company_name").message || "Company name"}</strong>
            <span>{customer.company}</span>
          </Grid.Column>
          {customer.is_lease_company && (
            <Grid.Column>
              <span className="lease-company-tag">
                <Icon name="building" />
                {t("v8_lease_company").message || "Lease company"}
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <strong>{t("v8_location").message || "Location"}</strong>
            <span>{customer.place}</span>
          </Grid.Column>
          <Grid.Column>
            <strong>{t("v8_street").message || "Street"}</strong>
            <span>
              {customer.street} {customer.house_nr}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <strong>{t("v8_postcode").message || "Postcode"}</strong>
            <span>{customer.postcode}</span>
          </Grid.Column>
          <Grid.Column>
            <strong>{t("v8_business_email").message || "Business email"}</strong>
            <span>{customer.email_business}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <strong>{t("v8_private_email").message || "Private email"}</strong>
            <span>{customer.email_private}</span>
          </Grid.Column>
          <Grid.Column>
            <strong>{t("v8_business_phone").message || "Business phone"}</strong>
            <span>{customer.tel_business_nr}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <strong>{t("v8_private_phone").message || "Private phone"}</strong>
            <span>{customer.tel_private_nr}</span>
          </Grid.Column>
          <Grid.Column>
            <strong>{t("v8_business_mobile_phone").message || "Business mobile phone"}</strong>
            <span>{customer.tel_mobile_business}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <strong>{t("v8_private_mobile_phone").message || "Private mobile phone"}</strong>
            <span>{customer.tel_mobile_private}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              name="is_internal"
              toggle
              checked={customer.is_internal}
              onChange={onChangeCheckbox}
              disabled={loading || isUpdatingCustomer}
              label={t("v8_is_internal").message || "Is internal"}
            />
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              name="block_communication"
              toggle
              checked={customer.block_communication}
              onChange={onChangeCheckbox}
              disabled={loading || isUpdatingCustomer}
              label={t("v8_block_communication").message || "Block communication"}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ alignItems: "center" }}>
          <Grid.Column width={3}>
            <strong>{t("v8_preferred_channel").message || "Preferred Channel"}</strong>
          </Grid.Column>

          <Grid.Column>
            <button
              className={`preferred-channel-button ${customer.preferred_communication_channel === CHANNELS.Email ? "active-preferred-channel" : ""}`}
              disabled={loading || isUpdatingCustomer}
              onClick={() => onChangePreferredCommunicationChannel(CHANNELS.Email)}
            >
              <Icon name="mail outline" />
              <span>{t("v8_email").message || "Email"}</span>
            </button>
            <button
              className={`preferred-channel-button ${customer.preferred_communication_channel === CHANNELS.Sms ? "active-preferred-channel" : ""}`}
              disabled={loading || isUpdatingCustomer}
              onClick={() => onChangePreferredCommunicationChannel(CHANNELS.Sms)}
            >
              <Icon name="mobile alternate" />
              <span>{t("v8_sms").message || "SMS"}</span>
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CustomerDetail;
