import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";

import ENV from "config/Env";
import { User } from "models";
import ApiInstance from "util/Api";

export const useResetPasswordUser = (hash: string | null) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await ApiInstance.post("/password/user", { hash }, ENV.authBaseUrl);
      return response;
    }
  });

  useEffect(() => {
    if (hash) mutation.mutate();
  }, [hash]);

  return { userData: mutation.data?.data } as { userData: User | undefined };
};
