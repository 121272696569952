import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { AdvisedCriticalItemHistoryResult, QuestionResult } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export type AdvisedCriticalItemsParams = {
  appointment_id: number;
  page?: number;
  hidden_items?: boolean;
};

const getAdvisedCriticalItemsHistory = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint, params } = queryKey[0];
  const res = await ApiInstance.post(endpoint, params, baseUrl);
  return {
    ...res.data,
    question_results: res.data.question_results?.sort((a: QuestionResult, b: QuestionResult) => (b.time_car_app > a.time_car_app ? 1 : -1)) ?? []
  } as AdvisedCriticalItemHistoryResult;
};

export const useAdvisedCriticalItemsHistory = (appointment_id: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showHiddenItems, setShowHiddenItems] = useState(false);

  const query = useQuery({
    queryKey: AppointmentDetailsKeys.advisedCriticalItemsHistory({ appointment_id, page: currentPage, hidden_items: showHiddenItems }),
    queryFn: getAdvisedCriticalItemsHistory
  });

  useEffect(() => {
    query.refetch();
  }, [showHiddenItems, currentPage, query.refetch]);

  return {
    currentPage,
    setCurrentPage,
    showHiddenItems,
    setShowHiddenItems,
    advisedCriticalItemsHistory: query.data,
    loading: query.isFetching,
    error: query.error,
    refetchAdvisedCriticalItemsHistory: query.refetch
  };
};
