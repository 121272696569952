import { useQueryClient } from "@tanstack/react-query";

import { DealersLocationsDropdownValue } from "components";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { useDefaultNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks/useGetNotSnoozedFilters";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useNotSnoozedFilterHandlers = () => {
  const queryClient = useQueryClient();
  const { defaultNotSnoozedFilters } = useDefaultNotSnoozedFilters();
  const notSnoozedFilters = queryClient.getQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters);
  const { updateFilters, updateFiltersWithCallback } = useGetFilterUpdaterFunctions<LeadsNotSnoozedFilters>({ queryKey: LeadsQueryKeys.notSnoozedFilters });

  const handleTagsChange = (value: number[]) => updateFilters({ tags: value });
  const handleSearchChange = (value: string) => updateFilters({ search_term: value });
  const handleResetFilters = () => updateFiltersWithCallback(oldData => ({ ...oldData, ...defaultNotSnoozedFilters }));
  const handleInterventionsChange = () => updateFiltersWithCallback(({ interventions }) => ({ interventions: !interventions }));
  const handleCustomerApprovedChange = () => updateFiltersWithCallback(({ customer_approved }) => ({ customer_approved: !customer_approved }));
  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) =>
    updateFilters({ dealer_ids: dealerIds, location_ids: locationIds, questions: null });

  return {
    notSnoozedFilters,
    handleTagsChange,
    handleResetFilters,
    handleSearchChange,
    handleInterventionsChange,
    handleDealerLocationChange,
    handleCustomerApprovedChange
  };
};
