import { Icon } from "semantic-ui-react";

import { HeaderType } from "components";
import "modules/DealerReports/components/DealerUsersReport/components/DealerUserReportsCustomHeader.scss";

const DealerUserReportsCustomHeader = ({ headers }: { headers: HeaderType[] }) => {
  const customHeaderOptions = headers.map(({ column, sortingState, toggleSorting, renderHeader }) => (
    <th key={column.id} className="DealerUserReportsCustomHeader">
      <div className="title-sorting-icons-header">
        <div className="title">
          <strong>{renderHeader()}</strong>
          <Icon className={sortingState?.desc ? "caret down" : "caret up"} onClick={toggleSorting} />
        </div>
      </div>
    </th>
  ));

  return <tr>{customHeaderOptions}</tr>;
};

export default DealerUserReportsCustomHeader;
