import { useTranslation } from "react-i18next";

import { ITranslation } from "util/interfaces";

interface RepairOverviewInterventionPriceProps {
  totalFixedSelectedPriceInterventions: string;
}

export const RepairOverviewInterventionPrice = ({ totalFixedSelectedPriceInterventions }: RepairOverviewInterventionPriceProps) => {
  const t = useTranslation().t as ITranslation;
  return (
    <div className="total-amount-container">
      <div className="float-right">
        <p>
          {t("v8_fixed_and_selected").message || "Fixed & Selected"}: <strong>€ {totalFixedSelectedPriceInterventions}</strong>
        </p>
      </div>
    </div>
  );
};
