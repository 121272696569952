import { Loader } from "semantic-ui-react";

import { BackordersDashboardFilters, BackordersDashboardTable, BackordersExportModal } from "modules/BackordersDashboard/components";
import { useGetBackordersDashboardFilters } from "modules/BackordersDashboard/hooks";

const BackordersDashboard = () => {
  const { isLoading } = useGetBackordersDashboardFilters();

  if (isLoading) return <Loader active />;

  return (
    <>
      <BackordersDashboardFilters />
      <BackordersDashboardTable />
      <BackordersExportModal />
    </>
  );
};

export default BackordersDashboard;
