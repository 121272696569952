import { Loader } from "semantic-ui-react";

import { WarrantyDashboardFilters, WarrantyDashboardTable, WarrantyExportModal } from "modules/WarrantyDashboard/components";
import { useGetWarrantyDashboardFilters } from "modules/WarrantyDashboard/hooks";

const WarrantyDashboard = () => {
  const { isLoading } = useGetWarrantyDashboardFilters();

  if (isLoading) return <Loader active />;

  return (
    <>
      <WarrantyDashboardFilters />
      <WarrantyDashboardTable />
      <WarrantyExportModal />
    </>
  );
};

export default WarrantyDashboard;
