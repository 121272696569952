import { useTranslation } from "react-i18next";
import { Grid, GridColumn } from "semantic-ui-react";

import { DatePicker, DealersAndLocationsDropdown, ExportDataButton, ExportDataModal, NavPortal, ResetFiltersButton, SearchFilter } from "components";
import { useUser } from "hooks";
import { LeadTypeFilter } from "modules/LeadsDashboard/components";
import { useExportModal } from "modules/LeadsDashboard/hooks";
import { CustomDateRangeInput } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import "modules/LeadsDashboard/Leads/CarsLeads/components/CarsLeadsFilters/CarsLeadsFilters.scss";
import { useCarsFilterHandlers } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { exportLeadsCars } from "modules/LeadsDashboard/service";
import { ITranslation } from "util/interfaces";

export type LeadsCarsFilter = {
  page: number;
  date_from: Date;
  date_to: Date;
  dealer_ids: number[];
  location_ids: number[];
  is_lease_company: boolean | null;
  search_term: string;
};

export const CarsLeadsFilters = () => {
  const user = useUser();
  const t = useTranslation().t as ITranslation;

  const { handleCloseExportModal, handleOpenExportModal, showExportModal } = useExportModal();
  const { handleDateRangeChange, handleDealerLocationChange, handleResetFilters, handleSearchChange, carsFilters } = useCarsFilterHandlers();

  if (!carsFilters) return;
  const { search_term, dealer_ids, location_ids, date_from, date_to } = carsFilters;
  const exportButtonWarning = location_ids?.length > 1 || dealer_ids?.length > 1;

  return (
    <div className="CarsLeadsFilters">
      <NavPortal>
        <SearchFilter value={search_term} handleSearchChange={handleSearchChange} />
      </NavPortal>

      <div className="toolbar-container">
        <Grid columns={16} className="toolbar-grid">
          <GridColumn width={4} className="no-left-gutter right-gutter">
            <LeadTypeFilter />
          </GridColumn>
          <GridColumn width={4} className="left-gutter right-gutter">
            <DatePicker fluid selectsRange onDateChange={handleDateRangeChange} startDate={date_from} endDate={date_to} customInput={<CustomDateRangeInput />} />
          </GridColumn>
          <GridColumn width={8} className="left-gutter no-right-gutter">
            <DealersAndLocationsDropdown
              values={{ dealerIds: dealer_ids, locationIds: location_ids }}
              handleOnChange={({ dealerIds, locationIds }) => handleDealerLocationChange({ dealerIds, locationIds })}
              allowSelectAllDealers={false}
            />
          </GridColumn>
        </Grid>

        <div className="toolbar-reset-export-buttons-container">
          <ResetFiltersButton handleReset={handleResetFilters} />
          {user?.csv_download && (
            <ExportDataButton
              contentMessage={`${t("v8_you_cannot_export_leads_for_more_than_one_location").message || "You cannot export leads for more than one location"}.`}
              handleOnClick={handleOpenExportModal}
              hasWarning={exportButtonWarning}
            />
          )}
        </div>
      </div>

      <ExportDataModal open={showExportModal} onClose={handleCloseExportModal} onExportData={() => exportLeadsCars(carsFilters)} />
    </div>
  );
};
