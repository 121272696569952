import { useTranslation } from "react-i18next";

import { ITranslation } from "util/interfaces";

interface CustomerCommunicationPriceProps {
  totalAmountOfSelectedInterventions: number | string;
  totalCustomerOkPrice: number | string;
  showCustomerOk?: boolean;
}

export const CustomerCommunicationPrice = ({ totalAmountOfSelectedInterventions, totalCustomerOkPrice, showCustomerOk }: CustomerCommunicationPriceProps) => {
  const t = useTranslation().t as ITranslation;
  return (
    <div className="total-amount-container">
      <div className="float-right">
        <p>
          {t("v8_total_amount_of_selected_items").message || "Total amount of selected items"}: <strong>€ {totalAmountOfSelectedInterventions}</strong>
        </p>
      </div>
      {showCustomerOk && (
        <div className="float-right">
          <p>
            {t("v8_customer_approved_total").message || "Customer approved total"}: <strong>€ {totalCustomerOkPrice}</strong>
          </p>
        </div>
      )}
    </div>
  );
};
