import { useTranslation } from "react-i18next";

import { TableView } from "components";
import { Element } from "components/ReactScroll/index";
import { ITranslation } from "util/interfaces";

interface VariablesProps {
  tabId: string;
}

export const Variables = ({ tabId }: VariablesProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_variables").message || "VARIABLES"}</h3>
      <div className="mt-25">
        <TableView
          cells={[
            "Appointment.VIN 1",
            "Customer.Title",
            "Appointment.WONr",
            "Customer.FirstName",
            "Appointment.CarMake",
            "Customer.Surname",
            "Appointment.CarModel",
            "Link",
            "Appointment.RegNumber",
            "Receptionist",
            "Date"
          ]}
          nrOfCells={2}
          enableCopy
        />
      </div>
    </Element>
  );
};
