import { createColumnHelper } from "@tanstack/react-table";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Loader, Popup } from "semantic-ui-react";

import { AppointmentStatusCell, CustomerCard, ReactTable } from "components";
import { useDealersLocations } from "hooks";
import { APPOINTMENT_NOTE_TYPES, Appointment } from "models";
import "modules/BackordersDashboard/components/BackordersDashboardTable/BackordersDashboardTable.scss";
import { useBackordersDashboardTableFilterHandlers } from "modules/BackordersDashboard/components/BackordersDashboardTable/hooks";
import { useGetBackordersDashboardFilters } from "modules/BackordersDashboard/hooks";
import { useBackordersQuery } from "modules/BackordersDashboard/service";
import { getFormattedDate } from "modules/LeadsDashboard/util";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

export const PAGE_SIZE = 100;

export const BackordersDashboardTable = () => {
  const t = useTranslation().t as ITranslation;
  const { dealers, locations } = useDealersLocations();
  const {
    filters: { page: currentPage }
  } = useGetBackordersDashboardFilters();
  const { backordersData, nbItems, nbPages, isLoading } = useBackordersQuery();
  const { handlePaginationChange } = useBackordersDashboardTableFilterHandlers();
  const tablePagination = nbPages > 1 ? { pageIndex: currentPage - 1, pageSize: PAGE_SIZE } : undefined;

  const columnHelper = createColumnHelper<Appointment>();
  const columns = [
    columnHelper.accessor((row: Appointment) => row.time_car_app, {
      id: "backorders_appointment_date",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">{t("v8_appointment").message || "Appointment"}</span>,
      size: 20,
      cell: ({ renderValue }) => <span className="no-wrap-text"> {getFormattedDate(renderValue())} </span>
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_status",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">{t("v8_status").message || "Status"}</span>,
      size: 30,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return null;
        const status = {
          id: appointment.appointment_status_identifier,
          customcom_status: appointment?.customer_communication?.status,
          check_paused_at: appointment?.check_paused_at,
          car_out_of_shop: appointment?.car_out_of_shop,
          car_in_shop: appointment?.car_in_shop
        };

        return <AppointmentStatusCell shadow={false} value={status} />;
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_location",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">{t("v8_location").message || "Location"}</span>,
      size: 50,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return "";
        const location = locations.find(location => location.id === appointment.dealer_location_id);
        const dealer = dealers.find(dealer => dealer.id === location?.dealer_id);

        return (
          <span className="no-wrap-text">
            {dealer?.name}, {location?.name}
          </span>
        );
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_bo_date",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">{t("v8_bo_date").message || "BO Date"}</span>,
      size: 50,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return "";

        const boNote = appointment?.notes?.findLast(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BackOrder);
        return <span className="no-wrap-text"> {getFormattedDate(boNote?.backorder_date)} </span>;
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_wo",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">WO # {backordersData.length}</span>,
      size: 50,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return "";

        const { wo_nr, id } = appointment;
        return (
          <Popup
            hoverable
            content={wo_nr}
            trigger={
              <p className="ellipsis">
                <Link to={`${PATHS.APPOINTMENTS}/${id}`}>
                  <Icon className="arrow up right from square" />
                  {wo_nr}
                </Link>
              </p>
            }
          />
        );
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_reg_number",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">{t("v8_reg_number").message || "Reg #"}</span>,
      size: 50,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return "";
        const { car_id, is_lease_company, reg_number } = appointment;

        return (
          <Popup
            hoverable
            content={reg_number}
            trigger={
              <p className="ellipsis">
                <Link to={`${PATHS.CAR_DETAIL}/${car_id}`}>
                  <Icon className="arrow up right from square" />
                  {reg_number}
                  {is_lease_company && <Icon name="building" />}
                </Link>
              </p>
            }
          />
        );
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_bo_note",
      header: () => <span className="no-wrap-text BackordersDashboardTable__table-header-text">{t("v8_bo_note").message || "BO Note"}</span>,
      maxSize: 120,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return "";
        const boNote = appointment?.notes?.findLast(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BackOrder);
        if (boNote?.note === "") return "";

        return (
          <Popup
            hoverable
            content={boNote?.note}
            trigger={
              <div className="BackordersDashboardTable__bo-note-container">
                <p className="BackordersDashboardTable__table-ellipsis">{boNote?.note}</p>
              </div>
            }
          />
        );
      }
    }),

    columnHelper.accessor((row: Appointment) => row, {
      id: "backorders_customer",
      header: () => <div />,
      size: 20,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (!appointment) return null;

        return (
          <div className="BackordersDashboardTable__table-action-buttons-container">
            <CustomerCard contractor={appointment?.customer_contract || null} owner={appointment?.customer_owner || null} driver={appointment?.customer_driver || null} />
          </div>
        );
      }
    })
  ];

  if (isLoading) return <Loader active />;

  return (
    <div className="BackordersDashboardTable">
      <ReactTable
        columns={columns}
        data={backordersData}
        pageCount={nbPages}
        pageSize={PAGE_SIZE}
        totalItems={nbItems}
        pagination={tablePagination}
        onPaginationChange={handlePaginationChange}
      />
    </div>
  );
};
