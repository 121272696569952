import { Grid, GridColumn, Icon } from "semantic-ui-react";

import { ANSWER_STATUS_SELECTOR_TYPE, AnswerStatusSelector, Can } from "components";
import { useDealersLocations } from "hooks";
import { Intervention } from "models";
import { getPriceInVAT } from "util/common";
import { formatPrice } from "util/formatPrice";

type RepairOverviewInterventionItemProps = {
  intervention: Intervention;
  toggleVisibleInPDF: (item: Intervention) => void;
  showVat: boolean;
};

export const RepairOverviewInterventionItem = ({ intervention, toggleVisibleInPDF, showVat }: RepairOverviewInterventionItemProps) => {
  const { selectedLocation: location } = useDealersLocations();

  return (
    <div className="Intervention" key={intervention.id}>
      <Grid>
        <GridColumn width={10}>
          <div className="title-container">
            {intervention.question_result_status ? (
              <div className="question-status-container">
                <AnswerStatusSelector
                  data={{ ...intervention, status: intervention.question_result_status as number | null } as Intervention}
                  type={ANSWER_STATUS_SELECTOR_TYPE.intervention}
                  disabled
                />
              </div>
            ) : null}
            <div className="title-container-button">
              {!intervention.is_local && <Icon className="desktop" size="tiny" />}
              <p>{intervention.title}</p>
            </div>
          </div>
        </GridColumn>
        <GridColumn width={3}>
          {location?.dms_price_enabled && (
            <div className="column-container float-right">
              <p>{`€ ${showVat ? formatPrice(getPriceInVAT(intervention.price, location)) : formatPrice(intervention.price)}`}</p>
            </div>
          )}
        </GridColumn>
        <Can I="update" the="interventions">
          <GridColumn width={3}>
            <div className="column-container float-right">
              <div className="status-container">
                <div>
                  <Icon
                    className={`eye ${intervention.visible_to_customer ? "green" : "slash red"} pointer`}
                    size="small"
                    onClick={() => toggleVisibleInPDF(intervention)}
                  />
                </div>

                <div>
                  <Icon className={`${intervention.customer_ok ? "check circle green" : "remove circle red"}`} size="large" disabled={true} />
                </div>
              </div>
            </div>
          </GridColumn>
        </Can>
      </Grid>
    </div>
  );
};
