import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Icon, Loader } from "semantic-ui-react";

import { AppointmentPin, CUSTOM_CONFIRM_TYPES, CUSTOM_REASON, Can, CustomConfirm, DATE_FORMATS_FNS, TabData, Tabs } from "components";
import { useScrollPageTop } from "hooks";
import { Check, STATUS_IDENTIFIER } from "models";
import "modules/AppointmentDetails/AppointmentDetails.scss";
import {
  ActivityLog,
  AdvisedCriticalItemsHistory,
  AppointmentDate,
  AppointmentNotes,
  AppointmentStatus,
  CarDetails,
  CopyContent,
  CustomerActions,
  ExtraParts,
  IndicatorsAction,
  Interventions,
  SnoozedItems,
  StandardList
} from "modules/AppointmentDetails/components";
import { useAppointmentStatusMutation } from "modules/AppointmentDetails/components/AppointmentStatus/useAppointmentStatusMutation";
import { useAppointment, useAppointmentCancellationReasons, useCancelAppointmentMutation, useChecklist } from "modules/AppointmentDetails/hooks";
import useTabData from "modules/AppointmentDetails/hooks/useTabData";
import { filterAndSortStandardChecklist, filterAndSortTyreChecklist, getHighestId } from "modules/AppointmentDetails/utils";
import ApiInstance from "util/Api";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

export interface OrderedChecklist extends Check {
  order: number;
}

const AppointmentDetails = () => {
  useScrollPageTop();
  const params = useParams<{ id: string }>();
  const id = Number(params.id);
  const router = useHistory();
  const { tabsData } = useTabData();
  const t = useTranslation().t as ITranslation;

  const [expanded, setExpanded] = useState(true);
  const [showVat, setShowVat] = useState(true);
  const [showCancelModel, setShowCancelModel] = useState(false);
  const [cancelReason, setCancelReason] = useState<number | undefined>(undefined);
  const [otherCustomReason, setOtherCustomReason] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [checklistData, setChecklistData] = useState<OrderedChecklist[]>([]);
  const [isBackPressed, setIsBackPressed] = useState(false);

  const { appointment, loading, error } = useAppointment(id);
  const updateAppointmentStatusMutation = useAppointmentStatusMutation(id);
  const appointmentCancellationReasons = useAppointmentCancellationReasons();
  const cancelAppointmentMutation = useCancelAppointmentMutation(id);
  const [isExpandedComponent, setIsExpandedComponent] = useState(false);

  useEffect(() => {
    if (!error) return;
    if (!ApiInstance.loggedInWithQrCode) router.goBack();
    toast.error(t("v8_error_loading_appointment").message || "Something went wrong loading the appointment. Please try again");
  }, [error]);

  const {
    checklistQuery: { data: checklist }
  } = useChecklist(Number(id), true);

  const latestChecklist = getHighestId(checklist);

  const handleCancelConfirm = () => {
    if (typeof cancelReason !== "undefined") {
      cancelAppointmentMutation.mutate(cancelReason == CUSTOM_REASON ? { custom_reason: otherCustomReason } : { reason_id: cancelReason });
      setShowCancelModel(false);
    }
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    setHasChanges(false);
    router.goBack();
  };

  const toggleExpand = () => {
    setExpanded(prev => !prev);
    setIsExpandedComponent(!expanded);
  };
  const toggleShowVat = () => setShowVat(prev => !prev);
  const onRequestCloseModal = () => setShowCancelModel(false);
  const handleCancelAppointment = () => setShowCancelModel(true);
  const onRequestCloseHasChangeModal = () => setShowConfirmModal(false);
  const navigateBack = () => {
    if (hasChanges) {
      setShowConfirmModal(true);
      return;
    }

    setIsBackPressed(true);
    router.goBack();
  };

  useEffect(() => {
    if (checklist && Array.isArray(checklist)) {
      const sorted: OrderedChecklist[] = [...checklist]
        .sort((a: Check, b: Check) => a.id - b.id)
        .map((check: Check, index: number) => ({ ...check, question_items: check.question_items, order: index + 1 }));

      setChecklistData(sorted);
    }
  }, [checklist]);

  useEffect(() => {
    return () => {
      setIsBackPressed(false);
    };
  }, []);

  if (loading)
    return (
      <div className="AppointmentDetails-loader">
        <Loader active inline />
      </div>
    );

  if (appointment)
    return (
      <div className={`AppointmentDetails ${appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus ? "CanceledAppointment" : ""}`}>
        <div className="AppointmentDetails-container">
          <div className="AppointmentDetails-NavigationHeader">
            <div className="AppointmentDetails-NavigationHeader-container">
              <div className="row">
                <Can I={["new-status", "restore"]} the="appointments">
                  <AppointmentStatus appointment={appointment} onClick={updateAppointmentStatusMutation.mutate} isUpdating={updateAppointmentStatusMutation.isPending} />
                </Can>
                {appointment.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && (
                  <IndicatorsAction data={appointment} showVat={showVat} onSwitchShowVat={toggleShowVat} />
                )}
              </div>
              <div className="row">
                <Tabs data={tabsData} offset={-280} hideBorder />
                <div className="sub-row">
                  <div className="Appointment-time">
                    <p>
                      <strong>{t("v8_appointment").message || "Appointment"}</strong>
                      <AppointmentDate appointment={appointment} />
                    </p>

                    <p>
                      <strong>{t("v8_in").message || "In"}</strong>
                      {appointment?.due_in && formatDate(appointment.due_in, DATE_FORMATS_FNS.dateMonthYearTime)}
                    </p>

                    <p>
                      <strong>{t("v8_out").message || "Out"}</strong>
                      {appointment?.car_return_time && formatDate(appointment.car_return_time, DATE_FORMATS_FNS.dateMonthYearTime)}
                    </p>
                  </div>

                  <div className="Appointment-time">
                    <CopyContent content={appointment?.wo_nr ?? ""}>
                      <p>
                        <strong>{t("v8_wo").message || "WO"} #</strong>
                        <span className={appointment?.internal ? "info-wo" : ""}>{appointment?.wo_nr}</span>
                        <span className="appointment-pin ml-5">
                          <AppointmentPin appointment={appointment} />
                        </span>
                      </p>
                    </CopyContent>
                  </div>
                  <Button className="-appointment-status icon" size="small" onClick={toggleExpand}>
                    <Icon className={`arrows ${expanded ? "minimize" : "maximize"}`} color="green" />
                  </Button>
                  {!ApiInstance.loggedInWithQrCode && (
                    <Button className="-appointment-status" size="small" active loading={isBackPressed} disabled={isBackPressed} onClick={navigateBack}>
                      <Icon className="angle left" />
                      {t("v8_back").message || "Back"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="AppointmentDetails-cards mt-20">
            <CarDetails
              tabData={tabsData.get("details") as TabData}
              title={t("v8_car_customer_details").message || "Car & Customer details"}
              data={appointment}
              isExpanded={expanded}
              handleCancelAppointment={handleCancelAppointment}
            />
          </div>

          <div className="AppointmentDetails-cards mt-25">
            {tabsData.get("customerActions")?.data && (
              <CustomerActions tabData={tabsData.get("customerActions") as TabData} isExpanded={expanded} appointment={appointment} />
            )}

            {tabsData.get("snoozedItems")?.data && <SnoozedItems tabData={tabsData.get("snoozedItems") as TabData} isExpanded={expanded} appointment={appointment} />}
            {tabsData.get("advisedCriticalItemsHistory")?.data && (
              <AdvisedCriticalItemsHistory tabData={tabsData.get("advisedCriticalItemsHistory") as TabData} isExpanded={isExpandedComponent} appointment={appointment} />
            )}
            {tabsData.get("notes")?.data && <AppointmentNotes tabData={tabsData.get("notes") as TabData} appointment={appointment} isExpanded={expanded} />}
            {tabsData.get("interventions")?.data && (
              <Interventions
                tabData={tabsData.get("interventions") as TabData}
                isExpanded={expanded}
                appointment={appointment}
                showVat={showVat}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
              />
            )}
            {tabsData.get("extraParts")?.data && (
              <ExtraParts
                latestChecklist={latestChecklist}
                tabData={tabsData.get("extraParts") as TabData}
                isExpanded={expanded}
                checks={checklistData}
                appointment={appointment}
              />
            )}
            {tabsData.get("standardList")?.data &&
              filterAndSortStandardChecklist(checklistData).map((item, index) => {
                if (!item.question_items) return null;

                return (
                  <StandardList
                    id={index === 0 ? tabsData.get("standardList")?.id : undefined}
                    key={item.id}
                    latestChecklist={latestChecklist}
                    isExpanded={expanded}
                    data={item}
                    appointment={appointment}
                  />
                );
              })}

            {tabsData.get("tireElement")?.data &&
              filterAndSortTyreChecklist(checklistData).map((item, index) => {
                if (!item.question_items) return null;

                return (
                  <StandardList
                    icon="tire"
                    latestChecklist={latestChecklist}
                    id={index === 0 ? tabsData.get("tireElement")?.id : undefined}
                    key={item.id}
                    isExpanded={expanded}
                    data={item}
                    appointment={appointment}
                  />
                );
              })}
          </div>

          {tabsData.get("activityLog")?.data && (
            <div className="AppointmentDetails-cards mt-25">
              <ActivityLog tabData={tabsData.get("activityLog") as TabData} isExpanded={isExpandedComponent} appointment={appointment} />
            </div>
          )}

          <CustomConfirm
            type={CUSTOM_CONFIRM_TYPES.Danger}
            confirmReasons={appointmentCancellationReasons.data}
            allowCustomReason
            isOpen={showCancelModel}
            customClass={"CancelAppointmentModal"}
            handleCancel={onRequestCloseModal}
            handleConfirm={handleCancelConfirm}
            handleReasonChange={value => setCancelReason(value)}
            handleChangeCustomReason={e => setOtherCustomReason(e.target.value)}
            isLoading={appointmentCancellationReasons.isLoading}
            confirmMsg={t("v8_confirm_cancel_appointment_message").message || "Are you sure that you want to cancel this appointment?"}
            error={appointmentCancellationReasons.error?.message ?? ""}
            cancelButtonText={t("v8_close").message || "Close"}
            confirmButtonText={t("v8_cancel").message || "Cancel"}
            placeholderText={t("v8_cancellation_reason").message || "Cancellation reason"}
          />
          <CustomConfirm
            isLoading={false}
            type={CUSTOM_CONFIRM_TYPES.Warning}
            isOpen={showConfirmModal}
            handleConfirm={handleConfirm}
            handleCancel={onRequestCloseHasChangeModal}
            confirmMsg={t("v8_unsaved_changes_confirm_message").message || "You have some unsaved changes. If you proceed, these changes will be lost."}
          />
        </div>
      </div>
    );
};

export default AppointmentDetails;
