import { useTranslation } from "react-i18next";
import { Card, Icon, Popup } from "semantic-ui-react";

import { Dealer, DealerLocation } from "models";
import "modules/WarrantyDashboard/components/WarrantyDashboardTable/components/LocationInfo/LocationInfo.scss";
import { ITranslation } from "util/interfaces";

type LocationCardProps = {
  dealerLocationId: number;
  locations: DealerLocation[];
  dealers: Dealer[];
};

const DealerLocationCard = ({ dealerLocationId, locations, dealers }: LocationCardProps) => {
  const location = locations.find(dealerLocation => dealerLocation.id === dealerLocationId);
  const dealer = dealers.find(element => element.id === location?.dealer_id);

  const t = useTranslation().t as ITranslation;

  return (
    <Card className="WarrantyLocationInfo__location-info-card-no-box-shadow">
      <Card.Content>
        <Card.Meta content={t("v8_dealer_location").message || "Dealer/Location"} />
        {location?.name && <Card.Description content={location.name} />}
        {dealer?.name && <Card.Description content={dealer.name} />}
      </Card.Content>
    </Card>
  );
};

export const LocationCard = ({ dealerLocationId, locations, dealers }: LocationCardProps) => (
  <Popup
    on="hover"
    content={<DealerLocationCard dealerLocationId={dealerLocationId} locations={locations} dealers={dealers} />}
    trigger={
      <div className="WarrantyLocationInfo__location-info-card-container">
        <div className="WarrantyLocationInfo__location-info-card-user">
          <Icon className="house chimney" />
        </div>
      </div>
    }
  />
);
