import { useQueryClient } from "@tanstack/react-query";
import _startCase from "lodash/startCase";

import { LeadsCars } from "models";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components/CarsLeadsFilters";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const useGetCarsLeadData = () => {
  const queryClient = useQueryClient();
  const carsFilters = queryClient.getQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters);
  const carsLeads = queryClient.getQueryData<LeadsCars>(LeadsQueryKeys.listCars({ carsFilters }));

  const carsLeadsData = carsLeads?.items || [];
  const nbPages = carsLeads?.nb_pages || 1;
  const nbItems = carsLeads?.nb_items || 0;

  return { carsLeadsData, nbPages, nbItems };
};
