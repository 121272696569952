import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUploadAttachment } from "hooks";
import { ITranslation } from "util/interfaces";

type UploadImageProps = {
  path: string;
  baseUrl: string;
};

export const useUploadImage = <T extends FieldValues>({ baseUrl, path }: UploadImageProps) => {
  const t = useTranslation().t as ITranslation;
  const { handleUploadFileAttachment } = useUploadAttachment(path, baseUrl);

  const handleChangeFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
    fileFieldName: string,
    field: ControllerRenderProps<T, Path<T>>,
    onSuccess?: (res: string) => void,
    onError?: (res: string) => void
  ) => {
    if (!event.target.files) return;
    const uploadedFile = event.target.files[0];
    const formData = new FormData();
    formData.append(fileFieldName, uploadedFile);

    try {
      const attachmentUrl = await handleUploadFileAttachment({ formData });
      if (attachmentUrl) {
        field.onChange(attachmentUrl);
        onSuccess?.(attachmentUrl);
      } else throw new Error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
    } catch (e) {
      let errMessage = "";

      if (e instanceof Error) errMessage = e.message;
      else errMessage = t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.";

      onError?.(errMessage);
    }
  };

  const handleUpload = (name: string) => {
    document.getElementById(name)?.click();
  };

  const handleDelete = (field: ControllerRenderProps<T, Path<T>>) => {
    field.onChange(null);
  };

  return { handleDelete, handleUpload, handleChangeFile };
};
