import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Icon } from "semantic-ui-react";

import { DetailsCards, useCan } from "components";
import { useDealersLocations } from "hooks/useDealersLocations";
import { Appointment, Intervention, STATUS_IDENTIFIER } from "models";
import "modules/AppointmentDetails/components/CustomerCommunicationModal/components/CustomerCommunicationInterventions/CustomerCommunicationInterventions.scss";
import {
  RepairOverviewInterventionItem,
  RepairOverviewInterventionPrice
} from "modules/AppointmentDetails/components/CustomerCommunicationModal/components/RepairOverviewInterventions/components/index";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

type RepairOverviewInterventionsProps = {
  appointment: Appointment;
  invalidInterventions?: boolean;
};

export const RepairOverviewInterventions = ({ appointment, invalidInterventions }: RepairOverviewInterventionsProps) => {
  const [showVat, setShowVat] = useState(true);
  const { selectedLocation: location } = useDealersLocations();
  const canUpdateInterventions = useCan("update", "appointments");
  const { updateIntervention } = useIntervention(appointment.id);
  const t = useTranslation().t as ITranslation;

  const fixedInterventions = appointment?.interventions?.filter(intervention => intervention.mechanic_fixed);
  const notFixedInterventions = appointment?.interventions?.filter(intervention => !intervention.mechanic_fixed);

  const totalFixedSelectedPriceInterventions = useMemo(() => {
    const eligibleInterventions = appointment.interventions?.filter(intervention => intervention.mechanic_fixed && intervention.visible_to_customer) || [];
    const sum = eligibleInterventions?.reduce((sum, intervention) => sum + intervention.price, 0) || 0;

    return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
  }, [appointment, location, showVat]);

  const toggleVisibleInPDF = (item: Intervention) => {
    if (!canUpdateInterventions) return;

    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      updateIntervention.mutate({
        ...item,
        visible_to_customer: !item.visible_to_customer
      });
    }
  };

  return (
    <>
      <DetailsCards
        title={`${t("interventions").message || "Interventions"} ${appointment.interventions?.length ? `(${appointment.interventions.length})` : ""}`}
        icon="screwdriver wrench"
        isExpanded={true}
        showExpandArrow={false}
        containerStyle={invalidInterventions ? "error" : ""}
        rightContent={
          <div className="full end">
            <p>{t("v8_show_prices_with_vat").message || "Show prices with VAT"}</p>
            <Checkbox toggle checked={showVat} onChange={() => setShowVat(prev => !prev)} />
          </div>
        }
      >
        <div className="CustomerCommunicationInterventions">
          <div className="CustomerCommunicationInterventions-container">
            {!!notFixedInterventions?.length && (
              <div className="CustomerCommunicationInterventions-section">
                <div className="CustomerCommunicationInterventions-section-group">
                  <div className="CustomerCommunicationInterventions-section-group_header">
                    <Icon className="wrench regular grey rotate-270" />
                    <p>{t("v8_not_fixed").message || "Not Fixed"}</p>
                  </div>
                  {notFixedInterventions?.map((item: Intervention) => {
                    return <RepairOverviewInterventionItem key={item.id} intervention={item} toggleVisibleInPDF={toggleVisibleInPDF} showVat={showVat} />;
                  })}
                </div>
              </div>
            )}

            {!!fixedInterventions?.length && (
              <div className="CustomerCommunicationInterventions-section">
                <div className="CustomerCommunicationInterventions-section-group">
                  <div className="CustomerCommunicationInterventions-section-group_header">
                    <div className="mechanic-fixed-status-container">
                      <Icon className="wrench green pointer" />
                      <Icon className="check circle green wrench-check-icon" />
                    </div>

                    <p>{t("v8_fixed").message || "Fixed"}</p>
                  </div>
                  {fixedInterventions?.map((item: Intervention) => {
                    return <RepairOverviewInterventionItem key={item.id} intervention={item} toggleVisibleInPDF={toggleVisibleInPDF} showVat={showVat} />;
                  })}
                </div>
              </div>
            )}
          </div>

          <RepairOverviewInterventionPrice totalFixedSelectedPriceInterventions={totalFixedSelectedPriceInterventions} />
        </div>
      </DetailsCards>
    </>
  );
};
