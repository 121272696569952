import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, DropdownProps, Icon, Modal } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import printJS from "lib/print-js";
import { Appointment, KEY_LABEL_TYPES } from "models";
import { PRINT_SIZES } from "util/common";
import { ITranslation } from "util/interfaces";

interface KeyLabelModalProps {
  handleCloseCarInShopPrintModal: () => void;
  carInShopPrintVisible: boolean;
  keyLabelPrintSize: PRINT_SIZES;
  handleChangeKeyLabelPrintSize: (data: PRINT_SIZES) => void;
  appointment: Appointment;
  customerName: string;
}

const KeyLabelModal = ({
  carInShopPrintVisible,
  handleCloseCarInShopPrintModal,
  keyLabelPrintSize,
  handleChangeKeyLabelPrintSize,
  appointment,
  customerName
}: KeyLabelModalProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();

  const labelTypeOptions = [
    { text: "A4", value: PRINT_SIZES.A4, key: PRINT_SIZES.A4 },
    { text: t("v8_label").message || "Label", value: PRINT_SIZES.Label, key: PRINT_SIZES.Label }
  ];

  const handleKeyLabelDropdownChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => handleChangeKeyLabelPrintSize(data.value as PRINT_SIZES);
  const handlePrint = () =>
    printJS({
      printable: keyLabelPrintSize === PRINT_SIZES.A4 ? "car_in_shop_a4_content" : "car_in_shop_label_content",
      type: "html",
      copyStyles: true
    });

  return (
    <Modal
      className="IndicatorsActionModal"
      size="small"
      closeOnEscape
      dimmer="blurring"
      closeOnDimmerClick={false}
      open={carInShopPrintVisible}
      onClose={handleCloseCarInShopPrintModal}
    >
      <Modal.Actions>
        <Dropdown selection options={labelTypeOptions} value={keyLabelPrintSize} onChange={handleKeyLabelDropdownChange} />
        <Button color="grey" onClick={handlePrint}>
          <Icon className="print" />
          {t("v8_print").message || "Print"}
        </Button>

        <Button color="light" onClick={handleCloseCarInShopPrintModal}>
          <Icon className="xmark" size="big" />
        </Button>
      </Modal.Actions>
      <Modal.Content scrolling id={keyLabelPrintSize === PRINT_SIZES.A4 ? "car_in_shop_a4_content" : "car_in_shop_label_content"}>
        {selectedLocation?.key_label_type_id === KEY_LABEL_TYPES.Standard && (
          <>
            <div>{appointment.car_make}</div>
            <div>{appointment.car_model}</div>
          </>
        )}
        <div className="car_in_shop_print_large">{appointment.reg_number}</div>
        <div className="car_in_shop_print_large">{appointment.vin_nr}</div>
        <div className="car_in_shop_print_large car_in_shop_print_bold">{appointment.wo_nr}</div>
        {customerName && <div>{customerName}</div>}
      </Modal.Content>
    </Modal>
  );
};

export default KeyLabelModal;
