import { CHECKLIST_TYPE, Check, QuestionResult, SnoozeAttachment, SnoozeItem } from "models";
import { OrderedChecklist } from "modules/AppointmentDetails";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";
import { getExtensionFromURL, getNameFromURL } from "util/common";

const isTyreChecklist = (questionItems: QuestionResult[]) => questionItems.some(obj => obj.tyre);

export const parseQuestionResultAttachments = (questionResult: QuestionResult): GenericAttachmentData[] => {
  const images = questionResult?.images?.filter(item => item.active)?.map(item => ({ ...item, type: `image/${getExtensionFromURL(item.url)}` })) || [];
  const videos = questionResult?.videos?.filter(item => item.active)?.map(item => ({ ...item, type: `video/${getExtensionFromURL(item.url)}` })) || [];
  const attachments = [...images, ...videos].map(attachment => {
    return {
      id: attachment.id,
      created_on: attachment.created_on,
      updated_on: attachment.updated_on,
      url: attachment.url,
      name: getNameFromURL(attachment.url),
      type: attachment.type
    };
  });
  return attachments;
};

export const getHighestId = (checklistItems: Check[] | null) => {
  if (!checklistItems) return 0;

  const sortedChecklist = [...checklistItems].sort((a, b) => a.id - b.id);
  const lastItem = sortedChecklist[sortedChecklist.length - 1];
  return lastItem?.id;
};

export const filterAndSortTyreChecklist = (checklist: OrderedChecklist[] = []): OrderedChecklist[] =>
  checklist.filter(check => check.question_items && isTyreChecklist(check.question_items)).sort((a, b) => a.created_on.localeCompare(b.created_on));

export const filterAndSortStandardChecklist = (checklist: OrderedChecklist[] = []) => {
  const sortedStandardChecklist: OrderedChecklist[] = [...checklist].sort((a: Check, b: Check) =>
    a.checklist.display_order === b.checklist.display_order ? a.checklist.id - b.checklist.id : a.checklist.display_order - b.checklist.display_order
  );

  return sortedStandardChecklist.filter(
    check => check.checklist.checklist_type !== CHECKLIST_TYPE.ExtraParts && check.question_items && !isTyreChecklist(check.question_items)
  );
};

export const getAllSnoozeAttachments = (statusHistory: SnoozeItem[]) => {
  if (!statusHistory.length) return [];

  return statusHistory.flatMap(({ note_attachments }) =>
    note_attachments ? note_attachments.map(url => ({ url, name: getNameFromURL(url), type: getExtensionFromURL(url) }) as SnoozeAttachment) : []
  );
};
