import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";

import ENV from "config/Env";
import { Appointment, DESK_COMMUNICATION_EVENT_TYPE } from "models";
import "modules/AppointmentDetails/components/DeskCommunication/components/CounterTabletModal/CounterTabletModal.scss";
import { useDeskCheckin } from "modules/AppointmentDetails/components/DeskCommunication/hooks/useDeskCheckin";
import { useUpdateAppointment } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type DeskTabletModalProps = {
  appointment: Appointment;
  onClose: () => void;
};

export const CounterTabletModal = ({ appointment, onClose }: DeskTabletModalProps) => {
  const [statusName, setStatusName] = useState("loading");
  const [receptionistKey, setReceptionistKey] = useState<string | null>(appointment.receptionist_key || null);
  const [deskCommId, setDeskCommId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const [showRetryButton, setShowRetryButton] = useState<boolean>(false);
  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);

  const { createDeskCheckinMutation, cancelDeskCheckinMutation, isCanceling, isCreating } = useDeskCheckin(appointment.id);
  const { appointmentCarInShopMutation } = useUpdateAppointment(appointment.id);

  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    if (!appointment.desk_communications?.length) return;

    const deskComm = [...appointment.desk_communications].sort((dc1, dc2) => dc2.id - dc1.id)[0];
    if (!deskComm.events?.length) return;

    const event = [...deskComm.events].sort((e1, e2) => e2.id - e1.id)[0];

    setIsCheckingOut(deskComm.is_checking_out);
    setLoading(true);

    switch (event.type) {
      case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInSent:
        setStatusName(deskComm.is_checking_out ? "desk_check_out_sending" : "desk_checkin_sending");
        break;
      case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInReceived:
        setStatusName(deskComm.is_checking_out ? "desk_check_out_opening" : "desk_checkin_opening");
        break;
      case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInFailedToOpen:
        setStatusName(deskComm.is_checking_out ? "desk_check_out_failed_to_open" : "desk_checkin_failed_to_open");
        setLoading(false);
        setShowRetryButton(true);
        break;
      case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInOpened:
        setStatusName("waiting_for_answer");
        break;
      case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInAnswered:
        setStatusName(deskComm.is_checking_out ? "desk_check_out_answered" : "desk_checkin_answered");
        setLoading(false);
        break;
      case DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInCanceled:
        setStatusName(deskComm.is_checking_out ? "desk_check_out_canceled" : "desk_checkin_canceled");
        setLoading(false);
        break;
      default:
        setStatusName("unknown_error_occured");
        setShowRetryButton(true);
        setLoading(false);
        break;
    }
  }, [appointment.desk_communications]);

  const createDeskComm = async () => {
    setStatusName("loading");
    setShowRetryButton(false);

    try {
      const response = await createDeskCheckinMutation.mutateAsync();
      setDeskCommId(response.id);
      setReceptionistKey(response.receptionist_key);
    } catch {
      setStatusName("unknown_error_occured");
      setShowRetryButton(true);
    }
  };

  useEffect(() => {
    createDeskComm();
  }, []);

  const handleCancel = async () => {
    try {
      await cancelDeskCheckinMutation.mutateAsync(deskCommId);
      setDeskCommId(null);
      onClose();
    } catch {
      setStatusName("unknown_error_occured");
      onClose();
    }
  };

  const handleClose = async () => {
    if (!isCheckingOut && (!appointment.car_in_shop || appointment.car_out_of_shop) && statusName === "desk_checkin_answered") {
      await appointmentCarInShopMutation.mutateAsync({ car_in_shop: new Date().toISOString() });
    }
    onClose();
  };

  const handleOpenDeskCheckin = () => {
    if (!receptionistKey) return;

    window.open(`${ENV.ccBaseURL}/deskcheckin/#${receptionistKey}`, "_blank");
  };

  const isLoading = isCreating || isCanceling || loading;

  return (
    <Modal className="CounterTabletModal" open closeOnDimmerClick={false} onClose={onClose} size="mini">
      <Modal.Header className="CounterTabletModal__header">
        {isCheckingOut ? t("v8_desk_check_out").message || "Desk check-out" : t("v8_desk_checkin").message || "Desk checkin"}
        {receptionistKey && (
          <Button icon basic size="large" onClick={handleOpenDeskCheckin}>
            <Icon className="external link" />
          </Button>
        )}
      </Modal.Header>

      <Modal.Content className="CounterTabletModal__content">
        {isLoading && <Loader inline="centered" active />}
        <h3>{t(`v8_${statusName}`).message || statusName}</h3>
      </Modal.Content>

      <Modal.Actions className="CounterTabletModal__actions">
        {showRetryButton && (
          <Button color="green" onClick={createDeskComm}>
            {t("v8_retry").message || "Retry"}
            <Icon className="redo" />
          </Button>
        )}
        <Button color="red" onClick={isLoading ? handleCancel : handleClose}>
          {isLoading ? t("v8_cancel").message || "Cancel" : t("v8_close").message || "Close"}
          <Icon className="xmark" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
