import { useTranslation } from "react-i18next";
import { Icon, Label, Popup } from "semantic-ui-react";

import "components/AppointmentNotes/AppointmentNotes.scss";
import { DATE_FORMATS_FNS } from "components/DatePicker";
import { APPOINTMENT_NOTE_TYPES, Appointment } from "models";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type AppointmentNotesProps = {
  appointment: Appointment;
};

export const AppointmentNotes = ({ appointment }: AppointmentNotesProps) => {
  const t = useTranslation().t as ITranslation;
  const mainNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.Main);
  const callCustomerNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CallCustomer);

  return (
    <div className="AppointmentNotesIcons-wrapper">
      {mainNote && (
        <Popup
          wide
          basic
          content={
            <div className="AppointmentNotePopup">
              <div className="AppointmentNoteTitle">
                <Icon className="note sticky red" color="#DB2828" />
                <span>{t("v8_main_note").message || "Main Note"}</span>
              </div>
              <div className="AppointmentNoteDescription">
                {mainNote.updated_by
                  ? `${mainNote.updated_by.first_name} ${mainNote.updated_by.last_name} ${t("v8_updated_main_note").message || "updated Main Note"} `
                  : `${mainNote.user?.first_name} ${mainNote.user?.last_name} ${t("v8_added_main_note").message || "added Main Note"} `}
                {`${formatDate(mainNote.updated_on, DATE_FORMATS_FNS.dateMonthYearTime)}`}
              </div>
              <div className="AppointmentNoteContent">{mainNote.note}</div>
            </div>
          }
          trigger={
            <Label circular color="red">
              <Icon className="note sticky red" />
            </Label>
          }
        />
      )}

      {callCustomerNote && (
        <Popup
          wide
          basic
          content={
            <div className="AppointmentNotePopup">
              <div className="AppointmentNoteTitle">
                <Icon className="phone flip green" />
                <span>{t("v8_call_customer_note").message || "Call Customer Note"}</span>
              </div>
              <div className="AppointmentNoteDescription">
                {callCustomerNote.updated_by
                  ? `${callCustomerNote.updated_by.first_name} ${callCustomerNote.updated_by.last_name} ${
                      t("v8_updated_call_customer_note").message || "updated Call Customer Note"
                    } `
                  : `${callCustomerNote.user?.first_name} ${callCustomerNote.user?.last_name} ${t("v8_added_call_customer_note").message || "added Call Customer Note"} `}
                {`${formatDate(callCustomerNote.updated_on, DATE_FORMATS_FNS.dateMonthYearTime)}`}
              </div>

              <div className="AppointmentNoteContent">
                <Label className="CallCustomerPhoneNr">{callCustomerNote.phone_nr}</Label>
                <div>{callCustomerNote.note}</div>
              </div>
            </div>
          }
          trigger={
            <Label circular color="green">
              <Icon className="phone flip green" />
            </Label>
          }
        />
      )}
    </div>
  );
};
