import { QueryClient } from "@tanstack/react-query";
import _join from "lodash/join";
import _split from "lodash/split";

import { DealerLicenseQueryKeys } from "modules/DealerLicenseOverview/queryKeys";
import { Licenses } from "modules/LocationLicenseOverview/types";

export const updateLicenseData = (updateFn: (licenses: Licenses) => Licenses, dealerId: number, queryClient: QueryClient) => {
  const queryKey = DealerLicenseQueryKeys.licenses({ dealerId });
  const licenses = queryClient.getQueryData<Licenses>(queryKey);

  if (!licenses) return;

  queryClient.setQueryData(queryKey, updateFn(licenses));
  return licenses;
};
