import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { SnoozeItem } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

// TODO: cleanup like for other hooks
export const useSnooze = (appointment_id: number) => {
  const queryClient = useQueryClient();

  const addSnoozeFn = async (data: Partial<SnoozeItem>) => {
    const res = await ApiInstance.post("/snoozes/append", data, ENV.appointmentBaseURL);
    return res.data;
  };

  const addSnooze = useMutation({
    mutationFn: addSnoozeFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AppointmentDetailsKeys.view(appointment_id)
      });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  const handleDeleteSnooze = async ({ question_result_id, note }: { question_result_id: number; note: string }) => {
    const res = await ApiInstance.post("/snoozes/delete", { question_result_id, note }, ENV.appointmentBaseURL);
    return res.data;
  };

  const deleteSnooze = useMutation({
    mutationFn: handleDeleteSnooze,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AppointmentDetailsKeys.checks(appointment_id)
      });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  return { addSnooze, deleteSnooze };
};
