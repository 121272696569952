import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { TabData } from "..";

export const useOverflowTabs = (data: Map<string, TabData>) => {
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const [overflowTabs, setOverflowTabs] = useState<[string, TabData][]>([]);
  const [visibleTabs, setVisibleTabs] = useState<[string, TabData][]>([]);
  const [activeTab, setActiveTab] = useState<string>("");
  const [canSpy, setCanSpy] = useState(true);
  const [isTabClicked, setIsTabClicked] = useState(false);
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const tabClickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const calculateVisibleTabs = () => {
    if (!tabsContainerRef.current) return;

    const containerWidth = tabsContainerRef.current.offsetWidth;
    const tabsArray = Array.from(data.entries());

    if (containerWidth === 0 || tabsArray.length === 0) return;

    const visible: [string, TabData][] = [];
    const overflow: [string, TabData][] = [];

    const OVERFLOW_BUTTON_WIDTH = 110;
    const PADDING_PER_ITEM = 35;
    const MARGIN_SAFETY = 45;

    let availableWidth = containerWidth - OVERFLOW_BUTTON_WIDTH - MARGIN_SAFETY;

    tabsArray.forEach(([_key, item]) => {
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      tempSpan.textContent = item.title || "";
      document.body.appendChild(tempSpan);

      const itemWidth = Math.min(tempSpan.offsetWidth + PADDING_PER_ITEM, 300);
      document.body.removeChild(tempSpan);

      if (availableWidth - itemWidth >= 0) {
        availableWidth -= itemWidth;
        visible.push([_key, item]);
      } else overflow.push([_key, item]);
    });

    setVisibleTabs(visible);
    setOverflowTabs(overflow);
  };

  const toggleTabClickActivity = () => {
    setIsTabClicked(true);

    if (tabClickTimeoutRef.current) clearTimeout(tabClickTimeoutRef.current);

    tabClickTimeoutRef.current = setTimeout(() => {
      setIsTabClicked(false);
      tabClickTimeoutRef.current = null;
    }, 500);
  };

  const handleTabClick = (id: string) => {
    if (id === activeTab) return;
    toggleTabClickActivity();
    const overflowIndex = overflowTabs.findIndex(([_, item]) => item.id === id);

    if (overflowIndex < 0) {
      calculateVisibleTabs();
      setActiveTab(id);
      setCanSpy(false);
      return;
    }

    const lastVisibleTab = visibleTabs[visibleTabs.length - 1];

    setVisibleTabs(prev => {
      const newVisibleTabs = [...prev];
      newVisibleTabs[newVisibleTabs.length - 1] = overflowTabs[overflowIndex];
      return newVisibleTabs;
    });

    setOverflowTabs(prev => {
      const newOverflowTabs = [...prev];
      newOverflowTabs[overflowIndex] = lastVisibleTab;
      return newOverflowTabs;
    });
    setActiveTab(id);
    setCanSpy(false);
  };

  useEffect(() => {
    const contentContainer = document.querySelector<HTMLDivElement>(".content-container");
    const handleScroll = () => {
      if (isTabClicked) return setCanSpy(false);
      setCanSpy(true);

      const isAtBottomOfThePage = contentContainer!.scrollTop + contentContainer!.clientHeight >= contentContainer!.scrollHeight - 10;

      if (isAtBottomOfThePage && overflowTabs.length > 0) setActiveTab(overflowTabs[overflowTabs.length - 1][0]);
      else if (isAtBottomOfThePage && visibleTabs.length > 0) setActiveTab(visibleTabs[visibleTabs.length - 1][0]);
    };

    contentContainer?.addEventListener("scroll", handleScroll);
    return () => contentContainer?.removeEventListener("scroll", handleScroll);
  }, [isTabClicked, visibleTabs]);

  useLayoutEffect(() => {
    if (!tabsContainerRef.current) return;

    resizeObserver.current = new ResizeObserver(calculateVisibleTabs);
    resizeObserver.current.observe(tabsContainerRef.current);

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [data]);

  useEffect(() => {
    calculateVisibleTabs();
  }, [data]);

  return { canSpy, setActiveTab, activeTab, handleTabClick, tabsContainerRef, visibleTabs, overflowTabs };
};
