import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { AppointmentStatusCell, CircleIcon, DATE_FORMATS_FNS } from "components";
import { useDealersLocations } from "hooks";
import { AppointmentHistory as AppointmentHistoryModel } from "modules/CarDetails/hooks";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type AppointmentHistoryHeaderProps = {
  propertyName: string;
  appointment: AppointmentHistoryModel;
};

export const AppointmentHistoryHeader = ({ propertyName, appointment }: AppointmentHistoryHeaderProps) => {
  const { locations } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const data = String(appointment[propertyName as keyof AppointmentHistoryModel]);
  switch (propertyName) {
    case "date":
      return (
        <div>
          <p className="date-wo">
            <div>
              <span className="weak">{t("v8_date").message || "Date"}:</span> {formatDate(data, DATE_FORMATS_FNS.dateMonthYear)}
            </div>
            <div>
              <span className="weak">{t("v8_wo").message || "WO"}#:</span> {appointment.wo_nr}
            </div>
          </p>
          <p>{t("v8_name").message || "Name"}</p>
        </div>
      );
    case "location_name":
      return (
        <div>
          <p>
            {" "}
            <span className="weak">{t("v8_location").message || "Location"}:</span>{" "}
            {locations?.find(location => location.id === appointment.dealer_location_id)?.name || ""}
          </p>
          <p>{t("v8_photo").message || "Photo"}</p>
        </div>
      );
    case "next_date":
      return (
        <div>
          <p>
            {" "}
            <span className="weak">{t("v8_next_date").message || "Next date"}:</span>{" "}
            {appointment.next_date ? formatDate(appointment.next_date, DATE_FORMATS_FNS.dateMonthYear) : ""}
          </p>
          <p>{t("v8_critical").message || "Critical"}</p>
        </div>
      );
    case "current_km":
      return (
        <div>
          <p>
            {" "}
            <span className="weak">{t("v8_current_km").message || "Current km"}:</span> {appointment.current_km}
          </p>
          <p>{t("v8_critical_approved").message || "Critical Approved"}</p>
        </div>
      );
    case "next_km":
      return (
        <div>
          <p>
            {" "}
            <span className="weak">{t("v8_next_km").message || "Next km"}:</span> {appointment.next_km}
          </p>
          <p>{t("v8_advisable").message || "Advisable"}</p>
        </div>
      );
    case "reg_number":
      return (
        <div>
          <p>
            {" "}
            <span className="weak">{t("v8_registration").message || "Registration"}:</span> {data}
          </p>
          <p>{t("v8_advisable_approved").message || "Advisable Approved"}</p>
        </div>
      );
    case "id":
      return (
        <>
          <div className="item-buttons">
            {appointment?.is_money && (
              <div className="button-icon">
                <CircleIcon icon="is_money" />
              </div>
            )}
            <div className="small-status">
              <AppointmentStatusCell value={{ id: appointment.appointment_status_identifier }} />
            </div>
            <div className="button-icon">
              <Link to={`/appointments/${data}`} className="workorder">
                <Button className="-appointment-status" size="small">
                  <Icon className="arrow up right from square" color="green" />
                </Button>
              </Link>
            </div>
          </div>
          <div className="empty" />
        </>
      );
    default:
      return null;
  }
};
