import { format, subMinutes } from "date-fns";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Dropdown, Form, Icon, Modal, ModalProps, TextArea } from "semantic-ui-react";

import {
  CUSTOM_CONFIRM_TYPES,
  Can,
  CustomConfirm,
  DATE_FORMATS_FNS,
  DatePicker,
  QuestionResultData,
  SNOOZE_DEPARTMENT,
  SNOOZE_STATUS,
  SNOOZE_TYPE,
  UploadFilesZone,
  UploadedFilesPreview,
  useGetSnoozeDepartmentData,
  useGetSnoozeStatusData,
  useGetSnoozeTypeData
} from "components";
import { LogHistory } from "components/SnoozeModal/components";
import "components/SnoozeModal/SnoozeModal.scss";
import ENV from "config/Env";
import { useDealersLocations, useSnooze, useUploadAttachment, useUser } from "hooks";
import { SnoozeAttachment, SnoozeItem } from "models";
import { useAppointment } from "modules/AppointmentDetails/hooks";
import { formatCurrentUTCDate, formatDate, toDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

interface SnoozeModalProps extends ModalProps {
  data?: QuestionResultData;
  onSave?: (data: Partial<SnoozeItem>) => void;
  onDelete?: (questionResultId: number) => void;
}

const SnoozeModal = ({ open, onClose, data, onSave, onDelete, ...rest }: SnoozeModalProps) => {
  if (!data) return null;

  const [snoozeType, setSnoozeType] = useState<SNOOZE_TYPE | null>();
  const [snoozeItem, setSnoozeItem] = useState<SnoozeItem | null>(null);
  const [snoozeStatus, setSnoozeStatus] = useState<SNOOZE_STATUS | null>();
  const [snoozeDepartment, setSnoozeDepartment] = useState<SNOOZE_DEPARTMENT | null>();
  const [attachments, setAttachments] = useState<SnoozeAttachment[]>([]);
  const [snoozeDate, setSnoozeDate] = useState<Date | null>(null);
  const [hasValidationError, setHasValidationError] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [deleteConfirmationNote, setDeleteConfirmationNote] = useState<string>("");

  const { dealerSnoozeTypeData } = useGetSnoozeTypeData();
  const snoozeStatusData = useGetSnoozeStatusData();
  const snoozeDepartmentData = useGetSnoozeDepartmentData();

  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const { selectedDealer, selectedLocation } = useDealersLocations();
  const { handleUploadFileAttachment } = useUploadAttachment("/snoozes/note_attachment/upload", ENV.appointmentBaseURL);

  const { addSnooze, deleteSnooze } = useSnooze(Number(data.appointment_id));
  const { appointment } = useAppointment(Number(data.appointment_id));

  useEffect(() => {
    const sortedSnoozeHistory = data?.snooze_history?.sort((history1, history2) => history2.created_on.localeCompare(history1.created_on)) ?? [];
    const latestSnoozeItem = sortedSnoozeHistory.length > 0 && sortedSnoozeHistory[0].snooze_status_id !== SNOOZE_STATUS.Deleted ? sortedSnoozeHistory[0] : null;
    setSnoozeItem(latestSnoozeItem);

    if (latestSnoozeItem?.snooze_type_id) setSnoozeType(latestSnoozeItem.snooze_type_id);
    if (latestSnoozeItem?.snooze_status_id) setSnoozeStatus(latestSnoozeItem.snooze_status_id);
    if (latestSnoozeItem?.snooze_department_id) setSnoozeDepartment(latestSnoozeItem.snooze_department_id);
    if (latestSnoozeItem?.snooze_date) setSnoozeDate(toDate(latestSnoozeItem.snooze_date));
    if (data?.attachments?.length) setAttachments(data.attachments);
  }, [data]);

  const validateRequest = () => {
    if (!snoozeType || (selectedDealer?.is_snooze_status_enabled && !snoozeStatus)) {
      setHasValidationError(true);
      return false;
    }
    setHasValidationError(false);
    return true;
  };

  const handleClose = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    setSnoozeType(null);
    setSnoozeStatus(null);
    setSnoozeDepartment(null);
    setSnoozeDate(null);
    setHasValidationError(false);
    onClose && onClose(e, rest);
  };

  const handleNewSnooze = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!data.appointment_id || !validateRequest()) return;
    const formData: any = Object.fromEntries(new FormData((e as any).target));
    const snoozeData: Partial<SnoozeItem> = {
      ...formData,
      appointment_id: data.appointment_id,
      snooze_type_id: snoozeType || null,
      snooze_status_id: snoozeStatus || null,
      snooze_department_id: snoozeDepartment || null,
      snooze_date: snoozeDate ? format(subMinutes(snoozeDate, snoozeDate.getTimezoneOffset()), DATE_FORMATS_FNS.ISOFormat) : null,
      created_on: formatCurrentUTCDate(DATE_FORMATS_FNS.ISOFormat),
      question_result_id: data.question_result_id,
      user_id: user?.id || 0,
      user: user
    };
    if (attachments?.length) snoozeData.note_attachments = attachments.map(attachment => attachment.url);
    await addSnooze.mutateAsync(snoozeData);
    onSave?.(snoozeData);
    handleClose(e as unknown as MouseEvent<HTMLElement, globalThis.MouseEvent>);
  };

  const handleUploadAttachment = async (file: File) => {
    if (!file) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    const date = appointment?.created_on && formatDate(appointment?.created_on, DATE_FORMATS_FNS.yearMonthDateSlash);
    const path = `${selectedLocation?.id}_${selectedLocation?.name}/${date}/${appointment?.id}_${appointment?.wo_nr}`;

    formData.append("file", uploadFile);
    formData.append("path", path);

    const attachmentUrl = await handleUploadFileAttachment({ formData });

    if (attachmentUrl) {
      const newFile = {
        url: attachmentUrl,
        created_on: formatCurrentUTCDate(DATE_FORMATS_FNS.ISOFormat),
        updated_on: formatCurrentUTCDate(DATE_FORMATS_FNS.ISOFormat),
        user_id: user?.id || 0
      };
      setAttachments(attach => (attach ? [...attach, newFile] : [newFile]));
    }
  };

  const handleDeleteAttachment = (url: string) => {
    setAttachments(attach => attach.filter(attachment => attachment.url !== url));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => {
    let files;

    if (e.type === "change") {
      files = (e as React.ChangeEvent<HTMLInputElement>).target.files;
    }

    if (e.type === "drop") {
      files = (e as React.DragEvent).dataTransfer.files;
      e.preventDefault();
    }

    if (!files) return;

    [...files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const handleFileDragOver = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();
  };

  const handleDeleteSnooze = async (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    const snoozeItem = {
      question_result_id: data.question_result_id,
      note: deleteConfirmationNote
    };
    await deleteSnooze.mutateAsync(snoozeItem);
    setShowDeleteConfirmation(false);
    handleClose(e as unknown as MouseEvent<HTMLElement, globalThis.MouseEvent>);
    onDelete?.(data.question_result_id);
  };

  const handleSnoozeTypeSelection = (value: SNOOZE_TYPE) => {
    setSnoozeType(value);
    if ([SNOOZE_TYPE.NextVisitCustomer, SNOOZE_TYPE.MakeNewAppointment].includes(value)) setSnoozeDate(null);
  };

  return (
    <>
      <Modal size="large" open={open} onClose={onClose} closeOnDimmerClick className="InterventionModal SnoozeModal">
        <Modal.Header>
          <div className="notes-modal-header-icon">
            <Icon className="screwdriver wrench" />
          </div>
          <p className="snooze-item-title">{t("v8_snooze_item").message || "Snooze Item"}</p>
        </Modal.Header>
        <Modal.Content>
          <div>
            <Form id="new-intervention" onSubmit={handleNewSnooze}>
              <Form.Group>
                <Form.Field width={8} required error={hasValidationError && !snoozeType}>
                  <label>{t("v8_snooze_type").message || "Snooze type"}</label>
                  <Dropdown
                    fluid
                    selection
                    value={snoozeType as SNOOZE_TYPE}
                    options={dealerSnoozeTypeData}
                    onChange={(_e, { value }) => handleSnoozeTypeSelection(value as SNOOZE_TYPE)}
                    placeholder={t("v8_select_snooze_type").message || "Select snooze type"}
                    required
                  />
                </Form.Field>

                <Form.Field width={8}>
                  <label>{t("v8_snooze_date").message || "Snooze date"}</label>
                  <DatePicker
                    fluid
                    date={snoozeDate}
                    onDateChange={setSnoozeDate}
                    disabled={snoozeType === SNOOZE_TYPE.NextVisitCustomer || snoozeType === SNOOZE_TYPE.MakeNewAppointment}
                    showLabel
                  />
                </Form.Field>
              </Form.Group>

              {(selectedDealer?.is_snooze_status_enabled || selectedDealer?.is_snooze_department_enabled) && (
                <Form.Group>
                  {selectedDealer?.is_snooze_status_enabled && (
                    <>
                      <Form.Field width={8} required error={hasValidationError && !snoozeStatus}>
                        <label>{t("v8_snooze_status").message || "Snooze status"}</label>
                        <Dropdown
                          fluid
                          selection
                          placeholder={t("v8_select_snooze_status").message || "Select snooze status"}
                          value={snoozeStatus as SNOOZE_STATUS}
                          options={snoozeStatusData}
                          onChange={(_e, { value }) => setSnoozeStatus(value as SNOOZE_STATUS)}
                          required
                        />
                      </Form.Field>
                      {!selectedDealer?.is_snooze_department_enabled && <Form.Field width={8} />}
                    </>
                  )}

                  {selectedDealer?.is_snooze_department_enabled && (
                    <>
                      <Form.Field width={8}>
                        <label>{t("v8_department").message || "Department"}</label>
                        <Dropdown
                          fluid
                          selection
                          clearable
                          placeholder={t("v8_select_department").message || "Select department"}
                          value={snoozeDepartment as SNOOZE_DEPARTMENT}
                          options={snoozeDepartmentData}
                          onChange={(_e, { value }) => setSnoozeDepartment(value as SNOOZE_DEPARTMENT)}
                        />
                      </Form.Field>
                      {!selectedDealer?.is_snooze_status_enabled && <Form.Field width={8} />}
                    </>
                  )}
                </Form.Group>
              )}

              <Form.Group>
                <Form.Field width={16}>
                  <label>{t("v8_remarks").message || "Remarks"}</label>
                  <TextArea defaultValue={snoozeItem?.note} placeholder={t("v8_type_note_here").message || "Type note here"} rows={2} name="note" />
                </Form.Field>
              </Form.Group>

              <LogHistory
                data={data.snooze_history}
                snoozeTypeData={dealerSnoozeTypeData}
                snoozeStatusData={snoozeStatusData}
                snoozeDepartmentData={snoozeDepartmentData}
              />
            </Form>
            <UploadFilesZone display={true} onFileInputChange={handleFileChange} onFileDragOver={handleFileDragOver} onFileDrop={handleFileChange} hasError={false} />
            <UploadedFilesPreview attachments={[...(data?.images ?? []), ...attachments]} onDeleteAttachment={handleDeleteAttachment} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className={`${snoozeItem?.snooze_type_id ? "update-snooze-modal-actions" : "add-snooze-modal-actions"}`}>
            <div>
              {snoozeItem?.snooze_type_id && (
                <Can I="delete" the="snooze">
                  <Button onClick={() => setShowDeleteConfirmation(true)} color="red">
                    {t("v8_delete").message || "Delete"}
                    <Icon className="trash" />
                  </Button>
                </Can>
              )}
            </div>
            <div>
              <Button color="light" onClick={handleClose}>
                {t("v8_cancel").message || "Cancel"}
                <Icon className="xmark" />
              </Button>
              <Button color="green" form="new-intervention" type="submit">
                {t("v8_save").message || "Save"}
                <Icon className="check" />
              </Button>
            </div>
          </div>
        </Modal.Actions>
      </Modal>

      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isOpen={showDeleteConfirmation}
        customClass={"CancelAppointmentModal"}
        handleCancel={() => setShowDeleteConfirmation(false)}
        note={deleteConfirmationNote}
        customTitle={t("v8_are_you_sure").message || "Are you sure?"}
        onChangeNote={e => setDeleteConfirmationNote(e.target.value)}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        handleConfirm={handleDeleteSnooze}
        isLoading={deleteSnooze.isPending}
        confirmMandatoryNote={t("v8_reason_for_deleting_snoozed_item").message || "Reason for deleting snoozed item."}
      />
    </>
  );
};

export default withTranslation()(SnoozeModal);
