import { useQueryClient } from "@tanstack/react-query";

import { DealersLocationsDropdownValue } from "components";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { useDefaultSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks/useGetSnoozedFilters";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";

export const useSnoozedFilterHandlers = () => {
  const queryClient = useQueryClient();
  const { defaultSnoozedFilters } = useDefaultSnoozedFilters();
  const snoozedFilters = queryClient.getQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters);
  const { updateFilters, updateFiltersWithCallback } = useGetFilterUpdaterFunctions<LeadsSnoozedFilters>({ queryKey: LeadsQueryKeys.snoozedFilters });

  const handleTagsChange = (value: number[]) => updateFilters({ tags: value });
  const handleSearchChange = (value: string) => updateFilters({ search_term: value });
  const handleDepartmentChange = (value: number) => updateFilters({ snooze_department_id: value ? value : null });
  const handleResetFilters = () => updateFiltersWithCallback(oldData => ({ ...oldData, ...defaultSnoozedFilters }));
  const handleInterventionsChange = () => updateFiltersWithCallback(({ interventions }) => ({ interventions: !interventions }));
  const handleCustomerApprovedChange = () => updateFiltersWithCallback(({ customer_approved }) => ({ customer_approved: !customer_approved }));
  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) =>
    updateFilters({ dealer_ids: dealerIds, location_ids: locationIds, questions: null });

  return {
    snoozedFilters,
    handleTagsChange,
    handleResetFilters,
    handleSearchChange,
    handleDepartmentChange,
    handleInterventionsChange,
    handleDealerLocationChange,
    handleCustomerApprovedChange
  };
};
