import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm, PinModelWithHistory } from "components";
import { PinModalForm } from "components/PinModal/components";
import { useDeletePin, useGetPinOptionsData } from "components/PinModal/hooks";
import { PinModel } from "models";
import { PinOverview } from "modules/AppointmentDetails/components/Interventions/components";
import { ITranslation } from "util/interfaces";

type PinProps = {
  pin: Partial<PinModelWithHistory>;
  isOpen: boolean;
  onClose: () => void;
  externalPinHistory?: PinModel[];
};

export const PinModal = ({ pin, isOpen, onClose, externalPinHistory }: PinProps) => {
  const t = useTranslation().t as ITranslation;
  const { deletePinItem } = useDeletePin();
  const { latestPinData, pinOrderData, pinStatusData, pinTypeData, pinWarrantyData } = useGetPinOptionsData({ pin });

  const [showPrintOverview, setShowPrintOverview] = useState(false);
  const [deleteConfirmationNote, setDeleteConfirmationNote] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const openPrintOverviewModal = () => setShowPrintOverview(true);
  const closePrintOverviewModal = () => setShowPrintOverview(false);
  const openDeleteConfirmationModal = () => setShowDeleteConfirmation(true);

  const handleDeletePin = async () => {
    if (pin.intervention_id || pin.question_result_id) {
      await deletePinItem.mutateAsync({ intervention_id: pin?.intervention_id, question_result_id: pin?.question_result_id, note: deleteConfirmationNote });
      onClose();
    }
  };

  return (
    <div className="PinModal">
      <PinModalForm
        pin={pin}
        isOpen={isOpen}
        onClose={onClose}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        openPrintOverviewModal={openPrintOverviewModal}
        externalPinHistory={externalPinHistory}
        isDeleteButtonDisabled={deletePinItem.isPending}
      />
      <CustomConfirm
        note={deleteConfirmationNote}
        isOpen={showDeleteConfirmation}
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isLoading={deletePinItem.isPending}
        isDisabled={!deleteConfirmationNote}
        handleConfirm={handleDeletePin}
        handleCancel={() => setShowDeleteConfirmation(false)}
        customClass="CancelAppointmentModal"
        customTitle={t("v8_are_you_sure").message || "Are you sure?"}
        onChangeNote={e => setDeleteConfirmationNote(e.target.value)}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        confirmMandatoryNote={t("v8_reason_for_deleting_pinned_item").message || "Reason for deleting pinned item."}
      />
      <PinOverview
        intervention={pin.intervention ?? null}
        pin={latestPinData}
        onClose={closePrintOverviewModal}
        pinLog={pin.history}
        appointment={pin.appointment ?? null}
        pinTypeData={pinTypeData}
        pinOrderData={pinOrderData}
        pinStatusOptions={pinStatusData}
        pinWarrantyData={pinWarrantyData}
        showPrintOverview={showPrintOverview}
      />
    </div>
  );
};
