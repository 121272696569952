import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { Button, Icon, Modal } from "semantic-ui-react";

import "components/Logs/Logs.scss";
import { copyToClipboard } from "util/common";
import { ITranslation } from "util/interfaces";

type AttachmentsListProps = {
  data: string[] | null;
  showAttachments: boolean;
  onRequestClose: () => void;
};

const AttachmentsList = ({ data, showAttachments, onRequestClose }: AttachmentsListProps) => {
  const t = useTranslation().t as ITranslation;

  const copyAllAttachmentUrls = () => {
    if (data) {
      copyToClipboard(data.join("\n")).then(() => {
        toast.success(t("v8_all_attachments_copied").message || "All attachments copied", { containerId: "modal-toast" });
      });
    }
  };

  return (
    <Modal size="large" open={showAttachments} onClose={onRequestClose} closeOnDimmerClick className="Logs">
      <Modal.Header className="Logs-title">
        <p>{t("v8_all_pictures").message || "All pictures"}</p>
        <Button className="-appointment-status" size="small" onClick={onRequestClose}>
          <Icon className="xmark grey" size="big" />
        </Button>
      </Modal.Header>
      <Modal.Content>
        <div className="attachments-list">
          {data?.map((item: string, index: number) => {
            return (
              <a href={item} target="_blank" rel="noreferrer" key={index}>
                {item}
              </a>
            );
          })}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button active className="-appointment-status" size="small" onClick={copyAllAttachmentUrls}>
          <Icon name="copy" />
          {t("v8_copy_all_links").message || "Copy all links"}
        </Button>
      </Modal.Actions>
      <ToastContainer containerId="modal-toast" position="top-right" autoClose={1500} closeOnClick draggable theme="light" />
    </Modal>
  );
};

export default AttachmentsList;
