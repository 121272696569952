import { ReactElement } from "react";
import { MessageHeader, Message as SemanticMessage } from "semantic-ui-react";

import "components/Message/Message.scss";

type Props = {
  content: ReactElement | string;
  title?: string;
  centered?: boolean;
};

export const Message = ({ content, title, centered = true }: Props) => (
  <div className="mt-20 Message">
    <SemanticMessage className={centered ? "centered" : ""}>
      {title && <MessageHeader>{title}</MessageHeader>}
      {content}
    </SemanticMessage>
  </div>
);
