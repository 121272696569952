import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

type UploadFnPayload = {
  formData: FormData;
};

type UploadAttachmentProps = {
  isUploadingAttachment: boolean;
  attachmentUrl: string;
  handleUploadFileAttachment: ({ formData }: UploadFnPayload) => Promise<string>;
};

type UploadAttachmentResponse = {
  data: { url: string };
};

export const useUploadAttachment = (url: string, baseUrl: string): UploadAttachmentProps => {
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const mutationFn = async ({ formData }: UploadFnPayload) => {
    const response: UploadAttachmentResponse = await ApiInstance.post(url, formData, baseUrl);
    return response?.data?.url;
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: (response: string) => setAttachmentUrl(response),
    onError: e => toast.error(e.message)
  });

  return { attachmentUrl, isUploadingAttachment: mutation.isPending, handleUploadFileAttachment: mutation.mutateAsync };
};
