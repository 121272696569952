import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { ActionModalSelectorData, ExtendedInterventionData, MODAL_TYPE, QuestionResultData } from "components";
import InterventionModal from "components/InterventionModal";
import SnoozeModal from "components/SnoozeModal";
import { Appointment, ChecklistTemplate, QuestionResult, TYRE_SEASONS } from "models";
import { TyreLocationData, tyrePosition } from "modules/AppointmentDetails/components/Tyre";
import TyreTable from "modules/AppointmentDetails/components/Tyre/TyreTable";
import { InterventionData } from "modules/AppointmentDetails/hooks/useIntervention";
import { ITranslation } from "util/interfaces";

type TyreChecklistProps = {
  data: QuestionResult[];
  template?: ChecklistTemplate | null;
  onRequestDBBPreview?: (data: QuestionResult[]) => void;
  onRequestReplacement?: (data: QuestionResult) => void;
  onRequestReplacementOrder?: (data: QuestionResult) => void;
  appointment: Appointment;
};

const TyreChecklist = ({ data, template, onRequestDBBPreview, onRequestReplacement, onRequestReplacementOrder, appointment }: TyreChecklistProps) => {
  const t = useTranslation().t as ITranslation;
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  const tireData = useMemo(() => {
    const tyreLocationData: TyreLocationData = { onCar: [], inStorage: [], onTruck: [] };

    data?.forEach(item => {
      if (item.tyre) {
        if (tyrePosition.isOnCar(Number(item.tyre_position))) {
          tyreLocationData.onCar.push(item);
        } else if (tyrePosition.isInCar(Number(item.tyre_position))) {
          tyreLocationData.inStorage.push(item);
        } else {
          tyreLocationData.onTruck.push(item);
        }
      }
    });

    return tyreLocationData;
  }, [data]);

  const onRequestCloseModal = () => {
    setShowInterventionModal(false);
    setShowSnoozeModal(false);
    setModalData(null);
  };

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType === MODAL_TYPE.Intervention) {
      setModalData(data as ExtendedInterventionData);
      setShowInterventionModal(true);
    } else {
      setModalData(data as QuestionResultData);
      setShowSnoozeModal(true);
    }
  };

  return (
    <div className="TyreCheckList">
      <TyreTable
        icon={<Icon className="car mirrors" />}
        title={(t("v8_on_car").message || "On Car").toUpperCase()}
        data={tireData.onCar}
        template={template}
        onRequestDBBPreview={onRequestDBBPreview}
        onRequestReplacement={onRequestReplacement}
        onRequestReplacementOrder={onRequestReplacementOrder}
        appointment={appointment}
        onSelectAction={handleSelect}
      />
      <TyreTable
        icon={<Icon className="garage" />}
        title={(t("v8_in_storage").message || "In Storage").toUpperCase()}
        data={tireData.inStorage}
        template={template}
        onRequestDBBPreview={onRequestDBBPreview}
        onRequestReplacement={onRequestReplacement}
        onRequestReplacementOrder={onRequestReplacementOrder}
        appointment={appointment}
        onSelectAction={handleSelect}
      />
      <TyreTable
        icon={<Icon className="truck" />}
        title={
          tireData.onTruck?.[0]?.tyre?.season === TYRE_SEASONS.NotAvailable
            ? (t("v8_on_truck").message || "On Truck").toUpperCase()
            : (t("v8_on_vehicle").message || "On Vehicle").toUpperCase()
        }
        data={tireData.onTruck}
        template={template}
        onRequestDBBPreview={onRequestDBBPreview}
        onRequestReplacement={onRequestReplacement}
        onRequestReplacementOrder={onRequestReplacementOrder}
        appointment={appointment}
        onSelectAction={handleSelect}
        showAxle
      />
      <InterventionModal size="large" open={showInterventionModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
      <SnoozeModal size="large" open={showSnoozeModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
    </div>
  );
};

export default TyreChecklist;
