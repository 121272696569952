import { useState } from "react";
import { Button } from "semantic-ui-react";

import RDWDetails from "components/RDW/components/RDWDetails";
import useRdw from "components/RDW/hooks/useRdw";
import "components/RDW/RDW.scss";
import { isDateAfter } from "util/dateHelperFunctions";

type RDWProps = {
  regNumber?: string;
  country?: string | null;
};

const RDWContent = ({ regNumber }: { regNumber?: string }) => {
  const [isRdwDetailsDisplayed, setIsRdwDetailsDisplayed] = useState(false);

  const { data, fetchRDWData, loading } = useRdw(regNumber);

  const handleGetRdwDate = () => {
    fetchRDWData();
  };

  const handleToggleIsRdwDetailsOpen = () => {
    setIsRdwDetailsDisplayed(isRdwDetailsDisplayed => {
      if (!isRdwDetailsDisplayed) {
        handleGetRdwDate();
      }
      return !isRdwDetailsDisplayed;
    });
  };

  const expiredDate =
    (data?.vervaldatum_tachograaf && isDateAfter(new Date(), new Date(data?.vervaldatum_tachograaf))) || isDateAfter(new Date(), new Date(data?.vervaldatum_apk));

  return (
    <div className="RDW">
      <Button
        className={`-appointment-status ${expiredDate ? "expired" : ""}`}
        size="small"
        loading={loading && !isRdwDetailsDisplayed}
        onClick={handleToggleIsRdwDetailsOpen}
      >
        RDW
      </Button>
      <RDWDetails data={data} isOpen={isRdwDetailsDisplayed} loading={loading} onClose={handleToggleIsRdwDetailsOpen} onRefresh={handleGetRdwDate} />
    </div>
  );
};

export const RDW = ({ regNumber, country }: RDWProps) => {
  if (country !== "NL") return null;

  return <RDWContent regNumber={regNumber} />;
};
