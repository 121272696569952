import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { useDealersLocations } from "hooks";
import { DealerDocumentation } from "models";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { DealerDocumentationsQueryKeys } from "modules/DealerDocumentations/queryKeys";
import { ITranslation } from "util/interfaces";

export const useGetEntityNames = () => {
  const t = useTranslation().t as ITranslation;
  const { dealers } = useDealersLocations();

  const matchDealersAndLocations = (dealerIds: number[] | null, locationIds: number[] | null) => {
    const matchingDealers = dealers.filter(dealer => dealerIds?.includes(dealer.id) || dealer.locations?.some(location => locationIds?.includes(location.id)));

    const computedValue = matchingDealers.map(dealer => {
      if (dealerIds?.includes(dealer.id)) return `${dealer.name}: ${t("v8_all_locations").message || "All locations"}; `;

      const matchingLocations = dealer.locations?.filter(location => locationIds?.includes(location.id)).map(loc => loc.name);

      if (!matchingLocations?.length) return "";
      if (matchingLocations.length === 1 && matchingDealers.length > 1) return `${dealer.name}: ${matchingLocations.at(0)};  `;

      return `${dealer.name}: ${matchingLocations.join("; ")}`;
    });

    if (computedValue.length > 0) computedValue[computedValue.length - 1] = computedValue[computedValue.length - 1].trimEnd().replace(/;$/, "");

    return computedValue;
  };

  return { matchDealersAndLocations };
};

export const useDealerDocumentationTable = () => {
  const queryClient = useQueryClient();
  const queryKey = DealerDocumentationsQueryKeys.list;
  const { categoryId } = useDealerDocumentationContext();

  const dealersDocumentations: DealerDocumentation[] | undefined = queryClient.getQueryData([queryKey]);

  const filterDealerDocumentationByCategory = () =>
    !categoryId ? dealersDocumentations : dealersDocumentations?.filter(category => category.category_id === categoryId);

  return { dealersDocumentation: filterDealerDocumentationByCategory() };
};
