import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "semantic-ui-react";

import { DealersAndLocationsDropdown, ResetFiltersButton, StatusFilters, useCan } from "components";
import { PIN_STATUS } from "models";
import "modules/ManufacturerDashboard/components/ManufacturerDashboardFilters/ManufacturerDashboardFilters.scss";
import {
  ManufacturerFilterType,
  useGetManufacturerDashboardFilters,
  useGetManufacturerDropdownOptions,
  useUpdateManufacturerDashboardFilters
} from "modules/ManufacturerDashboard/hooks";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import { useGetFilterUpdaterFunctions } from "util/common";
import { ITranslation } from "util/interfaces";

interface ManufacturerDashboardFilters {
  onOpenRequestModal: () => void;
}

export const ManufacturerDashboardFilter = ({ onOpenRequestModal }: ManufacturerDashboardFilters) => {
  const t = useTranslation().t as ITranslation;
  const canCreateRequest = useCan("create-request", "manufacturer");

  const {
    manufacturerDashboardFilters: { locationIds, dealerIds, pinStatusIds, statusIdentifiers }
  } = useGetManufacturerDashboardFilters();
  const { updateFilters } = useGetFilterUpdaterFunctions<ManufacturerFilterType>({ queryKey: ManufacturerQueryKeys.manufacturerFilters });
  const { handleResetFilters } = useUpdateManufacturerDashboardFilters();
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();
  const { pinStatusDropdownOptions, mechanicFixedDropdownOptions } = useGetManufacturerDropdownOptions();

  const numOfColumns = canCreateRequest ? 5 : 4;

  return (
    <div className={`Manufacturer__filters-container Manufacturer__filters-container-${numOfColumns}`}>
      {canCreateRequest && (
        <div onClick={onOpenRequestModal} className="Manufacturer__filters-container--addButton">
          <Icon className="plus" />
          <span>{t("v8_add").message || "Add"}</span>
        </div>
      )}
      <DealersAndLocationsDropdown
        values={{ dealerIds, locationIds }}
        handleOnChange={({ dealerIds, locationIds }) => updateFilters({ dealerIds, locationIds })}
        allowSelectAllDealers={false}
      />
      <Dropdown
        options={pinStatusDropdownOptions}
        placeholder={t("v8_pin_status").message || "Pin status"}
        multiple
        fluid
        clearable
        selection
        selectOnBlur={false}
        defaultValue={pinStatusIds || []}
        value={pinStatusIds || []}
        onChange={(_, { value }) => updateFilters({ pinStatusIds: value as PIN_STATUS[] })}
      />
      <Dropdown
        options={mechanicFixedDropdownOptions}
        value={manufacturerDashboardFilters.isMechanicFixed as boolean}
        fluid
        clearable
        selection
        selectOnBlur={false}
        placeholder={t("v8_mechanic_fixed").message || "Mechanic Fixed"}
        onChange={(_, { value }) => {
          const updatedvalue = typeof value === "string" ? null : (value as boolean);
          updateFilters({ isMechanicFixed: updatedvalue });
        }}
      />
      <StatusFilters onChange={newStatusIdentifiers => updateFilters({ statusIdentifiers: newStatusIdentifiers })} selectedStatusIdentifiersFilters={statusIdentifiers} />
      <ResetFiltersButton handleReset={handleResetFilters} />
    </div>
  );
};
