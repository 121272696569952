import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";

import { DATE_FORMATS_FNS } from "components";
import { WARRANTY_TIME_PERIODS, useGetTimePeriodOptions, useGetWarrantyDashboardFilters } from "modules/WarrantyDashboard/hooks";
import { WarrantyQueryKeys } from "modules/WarrantyDashboard/queryKeys";
import { WarrantyDashboardFiltersPayload } from "modules/WarrantyDashboard/types";
import { useGetFilterUpdaterFunctions } from "util/common";
import { formatDate } from "util/dateHelperFunctions";

type UseTimePeriodDropdownProps = {
  handleDateChange: (dates: [Date | null, Date | null]) => void;
  selectedWarrantyTimePeriod: WARRANTY_TIME_PERIODS;
};

export const getFormattedDateRange = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) return "";
  return `${formatDate(startDate, DATE_FORMATS_FNS.dateMonthYear)} - ${formatDate(endDate, DATE_FORMATS_FNS.dateMonthYear)}`;
};

export const useTimePeriodDropdown = ({ handleDateChange, selectedWarrantyTimePeriod }: UseTimePeriodDropdownProps) => {
  const [show, setShow] = useState(false);
  const datePickerRef = useRef<ReactDatePicker | null>(null);
  const timePeriodOptions = useGetTimePeriodOptions();
  const {
    filters: { date_from, date_to }
  } = useGetWarrantyDashboardFilters();
  const { updateFilters } = useGetFilterUpdaterFunctions<WarrantyDashboardFiltersPayload>({ queryKey: WarrantyQueryKeys.warrantyFilters });
  const startDate = date_from ? new Date(date_from) : null;
  const endDate = date_to ? new Date(date_to) : null;

  const updatedTimePeriodOptions = timePeriodOptions.map(tPeriod => {
    if (tPeriod.value === WARRANTY_TIME_PERIODS.CustomTimePeriod && selectedWarrantyTimePeriod === WARRANTY_TIME_PERIODS.CustomTimePeriod) {
      return { ...tPeriod, text: `${tPeriod.text} ${getFormattedDateRange(startDate, endDate)}` };
    }
    return tPeriod;
  });
  const currentTimePeriodLabel = updatedTimePeriodOptions.find(element => element.value === selectedWarrantyTimePeriod)?.text;

  const handleOpenDatePicker = () => {
    if (datePickerRef.current instanceof ReactDatePicker) {
      datePickerRef.current.setOpen(true);
      setShow(true);
      updateFilters({ date_from: null, date_to: null });
    }
  };

  const handleCloseDatePicker = () => {
    if (datePickerRef.current instanceof ReactDatePicker) {
      datePickerRef.current.setOpen(false);
      setShow(false);
    }
  };

  const onDateChange = ([start, end]: [Date | null, Date | null]) => {
    handleDateChange([start, end]);
    if (start && end) handleCloseDatePicker();
  };

  return {
    show,
    endDate,
    startDate,
    datePickerRef,
    currentTimePeriodLabel,
    updatedTimePeriodOptions,
    onDateChange,
    handleOpenDatePicker,
    handleCloseDatePicker
  };
};
