import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Icon, Input, Modal } from "semantic-ui-react";
import zxcvbn from "zxcvbn";

import "components/ChangePasswordModal/ChangePasswordModal.scss";
import { useAuth } from "hooks";
import { User } from "models";
import { ITranslation } from "util/interfaces";

type ChangePasswordModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  user: User | undefined;
};

export const ChangePasswordModal = ({ isOpen, handleCancel, user }: ChangePasswordModalProps) => {
  const t = useTranslation().t as ITranslation;

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newConfirmPassword, setNewConfirmPassword] = useState<string>("");
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<number | null>(null);

  const { changePasswordMutation } = useAuth();
  const { isPending: isLoading, error: changePasswordError } = changePasswordMutation;

  useEffect(() => {
    if (changePasswordError) {
      setErrorMsg((changePasswordError as AxiosError<any>).response?.data.errors.join(" "));
    }
  }, [changePasswordError]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setSuccessMsg("");
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
      const score = zxcvbn(value, ["claire", "auto", user?.first_name || "", user?.last_name || "", user?.email || "", user?.username || ""]).score;
      setPasswordStrength(score);
    } else if (name === "oldPassword") {
      setOldPassword(value);
    } else {
      setNewConfirmPassword(value);
    }
  };

  const resetPasswordForm = () => {
    setErrorMsg("");
    setSuccessMsg("");
    setOldPassword("");
    setNewPassword("");
    setNewConfirmPassword("");
    setPasswordStrength(null);
  };

  const handleHideChangePassword = () => {
    resetPasswordForm();
    handleCancel();
  };

  const handleChangePasswordSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSuccessMsg("");
    if (!(oldPassword && newPassword && newConfirmPassword)) {
      setErrorMsg(t("v8_please_check_your_input_fill_in_all_the_required_fields").message || "Please check your input. Fill in all the required fields.");
      return;
    }

    if (newPassword !== newConfirmPassword) {
      setErrorMsg(t("v8_new_password_and_new_password_confirmation_must_be_the_same").message || "New password and new password confirmation must be the same.");
      return;
    }

    if (oldPassword === newConfirmPassword) {
      setErrorMsg(t("v8_new_password_must_be_different_from_your_previous_password").message || "New password must be different from your previous password");
      return;
    }

    if (passwordStrength && passwordStrength < 4) {
      setErrorMsg(t("v8_password_not_secure_enough").message || "New Password is not secure enough.");
      return;
    }

    await changePasswordMutation.mutateAsync(
      { new_password: newPassword, old_password: oldPassword, new_password_confirm: newConfirmPassword },
      {
        onSuccess() {
          setSuccessMsg(t("v8_your_password_successfully_reset").message || "Your password was successfully reset!");
          handleHideChangePassword();
        }
      }
    );
  };

  const getPasswordCheckMarkIcon = (type: "new" | "confirm") => {
    let icon = null;
    if (type === "new" && newPassword && newPassword.length > 0) {
      if (passwordStrength && passwordStrength > 3) {
        icon = <Icon name="check circle outline" className="checkIcon" />;
      } else {
        icon = <Icon className="crossIcon circle xmark outline" />;
      }
    }

    if (type === "confirm" && newConfirmPassword && newConfirmPassword.length > 0) {
      if (passwordStrength && passwordStrength > 3 && newPassword === newConfirmPassword) {
        icon = <Icon name="check circle outline" className="checkIcon" />;
      } else {
        icon = <Icon className="crossIcon circle xmark" />;
      }
    }

    return icon;
  };

  return (
    <Modal size="tiny" open={isOpen} className={`ChangePasswordModal `} onClose={handleCancel}>
      <Modal.Header>{t("v8_change_password").message || "Change Password"}</Modal.Header>
      <Modal.Content scrolling>
        <p className="ChangePasswordModal-description">
          {t("v8_change_password_description").message ||
            "Your new password must be secure. A secure password cannot contain your first name, last name, email, ‘claire’ or common words like ‘password’. You don’t need to use any special characters. A secure password contains two or more uncommon words."}
        </p>
        <Form loading={isLoading}>
          <Form.Group widths="equal">
            <Form.Field className="password" required>
              <label>{t("v8_old_password").message || "Old password"}</label>
              <Input
                name="oldPassword"
                id="oldPassword"
                value={oldPassword}
                onChange={handlePasswordChange}
                required
                type={showOldPassword ? "text" : "password"}
                placeholder={t("v8_old_password").message || "Old password"}
              />
              <Icon
                className={showOldPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
                onMouseDown={() => setShowOldPassword(true)}
                onMouseUp={() => setShowOldPassword(false)}
              />
            </Form.Field>
          </Form.Group>
          {passwordStrength !== null && (
            <div className="strength-meter">
              <div className="strength-meter-fill" data-strength={passwordStrength}></div>
            </div>
          )}
          <Form.Group widths="equal" className="password-group">
            <Form.Field className="password" required>
              <label>{t("v8_new_password").message || "New password"}</label>
              <Input
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={handlePasswordChange}
                required
                type={showNewPassword ? "text" : "password"}
                placeholder={t("v8_new_password").message || "New password"}
              />
              <Icon
                className={showNewPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
                onMouseDown={() => setShowNewPassword(true)}
                onMouseUp={() => setShowNewPassword(false)}
              />
            </Form.Field>
            {getPasswordCheckMarkIcon("new")}
          </Form.Group>

          <Form.Group widths="equal" className="password-group">
            <Form.Field className="password" required>
              <label>{t("v8_confirm_new_password").message || "Confirm new password"}</label>
              <Input
                name="newPasswordConfirmation"
                id="newPasswordConfirmation"
                value={newConfirmPassword}
                onChange={handlePasswordChange}
                required
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("v8_confirm_new_password").message || "Confirm new password"}
              />
              <Icon
                className={showConfirmPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
                onMouseDown={() => setShowConfirmPassword(true)}
                onMouseUp={() => setShowConfirmPassword(false)}
              />
            </Form.Field>
            {getPasswordCheckMarkIcon("confirm")}
          </Form.Group>

          {errorMsg && (
            <Form>
              <div className="mt-15">
                <Form.Field error>
                  <Input value={errorMsg} readOnly />
                </Form.Field>
              </div>
            </Form>
          )}

          {successMsg && <div className="ChangePasswordModal-success">{successMsg}</div>}
        </Form>
      </Modal.Content>

      <Modal.Actions className="ChangePasswordModal-action">
        <div className="inner">
          <Button size="medium" basic className="cancel-btn" onClick={handleHideChangePassword}>
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>

          <Button loading={isLoading} color="green" className="save-btn" onClick={handleChangePasswordSubmit}>
            {t("v8_save").message || "Save"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
