import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { SNOOZE_STATUS, SNOOZE_TYPE } from "components";
import {
  useGetCustomerOkUserOptions,
  useGetLeaseCompanyOptions,
  useGetQuestionResultStatusOptions,
  useGetSnoozeStatusOptions,
  useGetSnoozeTypeOptions
} from "modules/LeadsDashboard/hooks";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { QUESTION_STATUSES_DEFAULT_STATUSES } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetChecklistsQuestionsQuery } from "modules/LeadsDashboard/service";
import { useGetFilterUpdaterFunctions } from "util/common";

type Props = {
  pageSize: number;
};

export const useSnoozedLeadsTableFilterHandlers = ({ pageSize }: Props) => {
  const queryClient = useQueryClient();
  const { snoozeStatusOptions } = useGetSnoozeStatusOptions();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const { customerOkUserOptions } = useGetCustomerOkUserOptions();
  const { questionResultStatusOptions } = useGetQuestionResultStatusOptions();
  const { dealerSnoozeTypeOptions, allSnoozeTypeOptions } = useGetSnoozeTypeOptions();

  const snoozedFilters = queryClient.getQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters);
  const { updateFilters, updateFiltersWithCallback } = useGetFilterUpdaterFunctions<LeadsSnoozedFilters>({ queryKey: LeadsQueryKeys.snoozedFilters });

  const interventionQuestionOptions =
    useGetChecklistsQuestionsQuery({ dealer_ids: snoozedFilters?.dealer_ids || [], location_ids: snoozedFilters?.location_ids || [] }) || [];
  const areAllDateFiltersReset =
    !snoozedFilters?.appointment_date_from && !snoozedFilters?.appointment_date_to && !snoozedFilters?.snooze_date_from && !snoozedFilters?.snooze_date_to;

  const currentPage = snoozedFilters?.page ?? 1;
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === snoozedFilters?.is_lease_company)?.value || "";
  const selectedTaskOption = dealerSnoozeTypeOptions.find(option => option.value === snoozedFilters?.snooze_type_id)?.value.toString() || "all";
  const selectedSnoozeStatusOption = snoozeStatusOptions.find(option => option.value === snoozedFilters?.snooze_status_id)?.value.toString() || "all";
  const selectedCustomerOkUserOption = customerOkUserOptions.find(option => option.filterValue === snoozedFilters?.last_customer_ok_is_me)?.value.toString() || "";
  const selectedQuestions = snoozedFilters?.questions
    ? snoozedFilters.questions.reduce((acc, questionId) => {
        const question = interventionQuestionOptions.find(element => element.filterValue === questionId);
        if (question) acc.push(question.key.toString());

        return acc;
      }, [] as string[])
    : ["all"];

  const getSelectedQuestionStatuses = () => {
    if (snoozedFilters?.statuses?.length === QUESTION_STATUSES_DEFAULT_STATUSES.length) return ["all"];
    return snoozedFilters?.statuses?.map(String) ?? ["all"];
  };

  const onPageChange = (data: { activePage: number }) => updateFilters({ page: data.activePage }, false);
  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange({ activePage: newState.pageIndex + 1 });
  };

  const handleQuestionResultStatusSelect = (questionStatuses: string[]) => {
    if (!questionStatuses.length) return;

    updateFiltersWithCallback(({ statuses }) => {
      const isAllOptionSelected = questionStatuses.includes("all");
      const areAllStatusesNotSelected = statuses?.length !== QUESTION_STATUSES_DEFAULT_STATUSES.length;
      const newStatuses = isAllOptionSelected && areAllStatusesNotSelected ? QUESTION_STATUSES_DEFAULT_STATUSES : questionStatuses.map(Number).filter(Boolean);
      return { statuses: newStatuses };
    });
  };

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    const leaseCompanyOption = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    updateFilters({ is_lease_company: leaseCompanyOption ? leaseCompanyOption?.filterValue : leaseCompanyOption });
  };

  const handleOnTaskSelect = (task: string[]) => {
    updateFiltersWithCallback(({ snooze_type_id }) => {
      const isResetNotSelected = task.includes("all") && snooze_type_id !== null;
      const tasks = isResetNotSelected ? null : dealerSnoozeTypeOptions.find(option => option.value === Number(task[0]));
      const taskOption = tasks ? (tasks?.value as SNOOZE_TYPE) : null;
      return { snooze_type_id: taskOption };
    });
  };

  const handleOnSnoozeStatusSelect = (snoozeStatus: string[]) => {
    updateFiltersWithCallback(oldData => {
      const isResetNotSelected = snoozeStatus.includes("all") && oldData.statuses !== null;
      const statuses = isResetNotSelected ? null : snoozeStatusOptions.find(option => option.value === Number(snoozeStatus[0]));
      const snoozeStatusOption = statuses ? (statuses?.value as SNOOZE_STATUS) : null;
      return { snooze_status_id: snoozeStatusOption };
    });
  };

  const handleOnCustomerOkUserSelect = (customerOkUser: string[]) => {
    updateFiltersWithCallback(oldData => {
      const isResetSelected = customerOkUser.includes("all") && oldData.statuses !== null;
      return { last_customer_ok_is_me: !isResetSelected };
    });
  };

  const handleOnQuestionSelect = (questions: string[]) => {
    updateFiltersWithCallback(oldData => {
      const isResetNotSelected = questions.includes("all") && oldData.questions !== null;
      const newQuestions = isResetNotSelected ? null : questions.filter(el => el !== "all").map(el => Number(el.split("-")[1]));
      return { questions: newQuestions };
    });
  };

  const handleAppointmentDateRangeChange = ([start, end]: [Date | null, Date | null]) => {
    updateFilters({ appointment_date_from: start, appointment_date_to: end });
  };
  const handleSnoozedDateRangeChange = ([start, end]: [Date | null, Date | null]) => {
    updateFilters({ snooze_date_from: start, snooze_date_to: end });
  };

  return {
    handleOnTaskSelect,
    handleOnLeaseSelect,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleOnSnoozeStatusSelect,
    handleSnoozedDateRangeChange,
    handleOnCustomerOkUserSelect,
    handleAppointmentDateRangeChange,
    handleQuestionResultStatusSelect,
    currentPage,
    snoozedFilters,
    selectedQuestions,
    dealerSnoozeTypeOptions,
    allSnoozeTypeOptions,
    selectedTaskOption,
    leaseCompanyOptions,
    snoozeStatusOptions,
    customerOkUserOptions,
    areAllDateFiltersReset,
    selectedQuestionStatuses: getSelectedQuestionStatuses(),
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions,
    selectedSnoozeStatusOption,
    selectedCustomerOkUserOption
  };
};
