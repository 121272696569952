import { add, isBefore } from "date-fns";

import { APPOINTMENT_NOTE_TYPES, Appointment, AppointmentStatus, STATUS_IDENTIFIER } from "models";
import { DATE_ORDER_BY } from "modules/Appointments";
import { getPreference } from "util/common";
import { toISOString } from "util/dateHelperFunctions";

type DateColumnAccessor = "time_car_app" | "car_return_time";

enum SORTING_ORDER {
  ascending = "ascending",
  descending = "descending"
}

export const ACCESSOR_KEYS = {
  DateColumn: "date-column-accessor-value",
  CustomerColumn: "customer-column-accessor-value",
  MakeColumn: "make-column-accessor-value",
  LastUserColumn: "last-user-column-accessor-value"
};

export const sortAppointmentsByID = (app1: Appointment, app2: Appointment, order: SORTING_ORDER) => {
  if (app1.id < app2.id) return order === SORTING_ORDER.ascending ? -1 : 1;
  if (app1.id > app2.id) return order === SORTING_ORDER.ascending ? 1 : -1;
  return 0;
};

export const sortAppointmentsByStatus = (appointments: Appointment[], statuses: AppointmentStatus[] | undefined): Appointment[] => {
  if (!appointments) return [];
  const orderMap = new Map<STATUS_IDENTIFIER, number>();
  statuses?.forEach(s => orderMap.set(s.identifier, s.order));

  return appointments.sort((prevApp, nextApp) => {
    if (statuses) {
      const aStatusOrder = orderMap.get(prevApp.appointment_status_identifier) || 0;
      const bStatusOrder = orderMap.get(nextApp.appointment_status_identifier) || 0;
      if (aStatusOrder < bStatusOrder) return -1;
      if (aStatusOrder > bStatusOrder) return 1;
    }

    const dateColumnPreferenceValue = getPreference(ACCESSOR_KEYS.DateColumn, DATE_ORDER_BY.APPOINTMENT_DATE_ASCENDING);
    const dateColumnAccessor: DateColumnAccessor = dateColumnPreferenceValue.replace("_asc", "").replace("_desc", "");
    const dateStringA = prevApp[dateColumnAccessor] || "";
    const dateStringB = nextApp[dateColumnAccessor] || "";

    if ([DATE_ORDER_BY.APPOINTMENT_DATE_ASCENDING, DATE_ORDER_BY.SCHEDULED_OUT_DATE_ASCENDING].includes(dateColumnPreferenceValue)) {
      const dateComparison = dateStringA.localeCompare(dateStringB);
      if (dateComparison !== 0) return dateComparison;
      return sortAppointmentsByID(prevApp, nextApp, SORTING_ORDER.ascending);
    }

    const dateComparison = dateStringB.localeCompare(dateStringA);
    if (dateComparison !== 0) return dateComparison;
    return sortAppointmentsByID(prevApp, nextApp, SORTING_ORDER.descending);
  });
};

export const sortAppointments = (appointments: Appointment[], statuses: AppointmentStatus[] | undefined): Appointment[] => {
  const panics: Appointment[] = [];
  const lockers: Appointment[] = [];
  const others: Appointment[] = [];

  appointments.forEach(appointment => {
    if (appointment.has_panic) {
      panics.push(appointment);
    } else if (
      (appointment.key_dropped_at && !appointment.key_picked_up_at) ||
      (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
      (appointment.acses_key_dropped_at && !appointment.acses_key_picked_up_at) ||
      (appointment.kiosk_checked_in_at && !appointment.car_in_shop) ||
      (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
      (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at) ||
      (appointment.acses_key_dropped_back_at && !appointment.acses_key_picked_up_back_at)
    ) {
      lockers.push(appointment);
    } else {
      others.push(appointment);
    }
  });

  const sortedPanics = sortAppointmentsByStatus(panics, statuses);
  const sortedLockers = sortAppointmentsByStatus(lockers, statuses);
  const sortedOthers = sortAppointmentsByStatus(others, statuses);

  return [...sortedPanics, ...sortedLockers, ...sortedOthers];
};

export const formatDateColumnAccessorValue = (dateColumnAccessor: string) => {
  if (!dateColumnAccessor.endsWith("_asc") && !dateColumnAccessor.endsWith("_desc")) return dateColumnAccessor + "_asc";
  return dateColumnAccessor;
};

export const filterAppointmentsBySearch = (appointments: Appointment[], searchTerm: string = "") => {
  const filteredAppointments = [];
  for (const appointment of appointments) {
    const matchFound = Object.values(appointment).some(value => {
      if (typeof value === "object" && value !== null) {
        return Object.values(value).some(nestedValue => String(nestedValue).toLowerCase().includes(searchTerm.toLowerCase()));
      }
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });

    if (matchFound) {
      filteredAppointments.push(appointment);
    }
  }
  return filteredAppointments;
};

export const filterSpecialIndicators = (appointments: Appointment[], specialIndicators: unknown | Record<string, string>) => {
  const specialIndicatorsKeys = Object.keys(specialIndicators || {});
  if (appointments.length > 0 && specialIndicatorsKeys.length) {
    return appointments.filter((appointment: Appointment) => {
      const onlyWithoutMoney = specialIndicatorsKeys.length === 1 && (specialIndicators as unknown as Appointment).is_money;
      let visible = onlyWithoutMoney;
      for (const icon of specialIndicatorsKeys) {
        switch (icon) {
          case "is_money":
            if (appointment.is_money) return false;
            break;

          case "pin_count":
            if (appointment.recall_pin_count > 0 || appointment.remark_pin_count > 0 || appointment.warranty_pin_count > 0) visible = true;
            break;

          case "is_shop_green":
            if (appointment.is_shop_color === "green") visible = true;
            break;

          case "is_shop_orange":
            if (appointment.is_shop_color.startsWith("#")) visible = true;
            break;

          case "is_shop_blue":
            if (appointment.is_shop_color === "blue") visible = true;
            break;

          case "is_shop_red":
            if (appointment.is_shop_color === "red") visible = true;
            break;

          case "is_shop_yellow":
            if (appointment.is_shop_color === "yellow") visible = true;
            break;

          case "is_recurring":
            if (appointment.is_recurring || appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RecurringCar)) visible = true;
            break;

          default:
            if (appointment[icon as keyof typeof appointment]) visible = true;
            break;
        }
      }

      return visible;
    });
  }
  return appointments;
};

export const determineAPKColor = (date: string): string => {
  const expirationDate = toISOString(date);

  if (isBefore(expirationDate, new Date())) return "expired-apk";

  if (isBefore(expirationDate, add(new Date(), { months: 2 }))) return "apk-expire-soon";

  return "";
};
