import { useTranslation } from "react-i18next";

import "components/PinNotification/PinNotification.scss";
import { PinModel } from "models";
import { PinTypeIcon } from "modules/WarrantyDashboard/components/WarrantyDashboardTable/components";
import { ITranslation } from "util/interfaces";

type PinNotificationProps = {
  pin: PinModel;
  location: string;
  statusMessage?: string;
  dealer?: string;
  car?: string;
  wo?: string;
  icon?: React.ReactNode;
};

export const PinNotification = ({ pin, location, statusMessage, dealer, car, wo }: PinNotificationProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="NotificationWrapper">
      <PinTypeIcon pinType={pin.pin_type_id} />
      <div className="content-column">
        <div>
          <span className="title">{t("v8_wo").message || "WO"}: </span>
          <span className="value">{wo}</span>
        </div>
        <div>
          <span className="title">{t("v8_name").message || "Name"}: </span>
          <span className="value">{car}</span>
        </div>
        <div>
          <span className="title">{t("v8_status").message || "Status"}: </span>
          <span className="value">{statusMessage}</span>
        </div>
        <div>
          <span className="title">{t("v8_dealer").message || "Dealer"}: </span>
          <span className="value">{dealer}</span>
        </div>
        <div>
          <span className="title">{t("v8_location").message || "Location"}:</span>
          <span className="value">{location}</span>
        </div>
      </div>
    </div>
  );
};
