import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import ApiInstance from "util/Api";

const useRdw = (regNumber: string | undefined) => {
  const mutation = useMutation({
    mutationFn: () => ApiInstance.post("/cars/rdw/details", { reg_number: regNumber }, ENV.carBaseUrl),
    onError: e => toast.error(e.message)
  });

  return { data: mutation.data?.data, fetchRDWData: mutation.mutate, loading: mutation.isPending };
};

export default useRdw;
