import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { useCan } from "components";
import { Appointment, InterventionAttachment, NOTE_TYPES, PIN_TYPE, STATUS_IDENTIFIER } from "models";
import { PinType } from "modules/AppointmentDetails/components";
import { PinCategoryFormType } from "modules/AppointmentDetails/components/Interventions/components/PinCategory/components/PinCategoryForm";
import { useGetNotes } from "modules/AppointmentDetails/hooks";

type PinCategoryFormProps = {
  pinType: PinType;
  hasChanges: boolean;
  appointment: Appointment | null;
  attachments: InterventionAttachment[];
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  setAttachments: Dispatch<SetStateAction<InterventionAttachment[]>>;
};

export const PinToNoteTypeMapping = {
  [PIN_TYPE.Warranty]: NOTE_TYPES.WarrantyPin,
  [PIN_TYPE.Recall]: NOTE_TYPES.RecallPin,
  [PIN_TYPE.Remarks]: NOTE_TYPES.RemarkPin
};

export const usePinCategoryForm = ({ appointment, pinType, attachments, hasChanges, setHasChanges, setAttachments }: PinCategoryFormProps) => {
  const canUpdateInterventions = useCan("update", "appointments");
  const { setValue, watch, formState, reset } = useFormContext<PinCategoryFormType>();
  const { isDirty, isSubmitSuccessful } = formState;
  const note = watch("note");
  const important = watch("important");
  const orderStatus = watch("orderStatus");

  const isNoteRequired = important && !note;
  const hasAttachments = useMemo(() => attachments.length > 0, [attachments]);
  const isButtonDisabled = isNoteRequired || !hasChanges || !canUpdateInterventions || appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus;

  const noteTypeId = PinToNoteTypeMapping[pinType.value as keyof typeof PinToNoteTypeMapping];
  const noteSuggestionOptions = useGetNotes(noteTypeId);

  const handleNoteSuggestionDropdownChange = (value: number | undefined) => {
    const noteSuggestion = noteSuggestionOptions.find(item => item.value === value)?.text || "";
    setValue("note", noteSuggestion);
  };

  const handleKeepPartsCheckboxChange = (value: boolean) => value && setValue("important", true);

  useEffect(() => {
    setHasChanges(isDirty || hasAttachments);
    if (isSubmitSuccessful) {
      reset();
      setAttachments([]);
    }
  }, [isDirty, isSubmitSuccessful, hasAttachments]);

  return { orderStatus, isButtonDisabled, noteSuggestionOptions, isNoteRequired, handleKeepPartsCheckboxChange, handleNoteSuggestionDropdownChange };
};
