import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm } from "components";
import useAppointmentPin from "components/AppointmentPin/hooks/useAppointmentPin";
import { useDealersLocations } from "hooks";
import { Appointment } from "models";
import { ITranslation } from "util/interfaces";

interface AppointmentPinProps {
  appointment: Appointment;
}

export const AppointmentPin = ({ appointment }: AppointmentPinProps) => {
  const t = useTranslation().t as ITranslation;
  const handleChangePin = useAppointmentPin();
  const { selectedLocation } = useDealersLocations();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const isAppointmentPinDisabled =
    appointment.is_pinned && !appointment.is_pinned_manually
      ? selectedLocation?.is_automatically_pinned_appointments_readonly
      : !selectedLocation?.is_manually_pinning_appointments_enabled;

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleChangePin({ appointment_id: appointment.id, is_pinned: false, is_pinned_manually: false });
    setShowConfirmModal(false);
  };

  const onRequestCloseModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setShowConfirmModal(false);
  };

  const handlePinToggle = () => {
    if (appointment?.id && !appointment.is_pinned) {
      handleChangePin({ appointment_id: appointment.id, is_pinned: true, is_pinned_manually: true });
    } else {
      setShowConfirmModal(true);
    }
  };

  const handlePinUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handlePinToggle();
  };

  return (
    <span onClick={e => e.stopPropagation()}>
      <Icon
        color={appointment.is_pinned_manually ? "red" : undefined}
        className={`${!appointment.is_pinned ? "circle regular" : "pin"} appointment-pin ${isAppointmentPinDisabled ? "appointment-pin-disabled" : ""}`}
        onClick={handlePinUpdate}
      />
      <CustomConfirm
        isLoading={false}
        isOpen={showConfirmModal}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        handleConfirm={handleConfirm}
        handleCancel={onRequestCloseModal}
        confirmMsg={t("v8_unpin_appointment_confirm_message").message || "Do you want to unpin the selected appointment? This action will affect other users as well."}
      />
    </span>
  );
};
