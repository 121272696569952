import _omit from "lodash/omit";

import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

const component = "LocationLicenseOverview";

type ListInvoices = {
  dealer_location_id?: number;
  pagination_url?: string;
  page_index?: number;
};

type LicenseParams = {
  dealer_location_id?: number;
};

export const LocationLicenseOverviewKeys = {
  items: ({ dealer_location_id }: LicenseParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/licenses/items/list",
      params: { dealer_location_id }
    })
  ],
  licenses: ({ dealer_location_id }: LicenseParams) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/licenses/list",
      params: { dealer_location_id }
    })
  ],
  invoices: ({ dealer_location_id, page_index, pagination_url }: ListInvoices) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/invoices/list",
      params: { dealer_location_id, page_index, pagination_url }
    })
  ],
  paginatedInvoices: [localQueryKey({ component, data: "paginatedInvoices" })]
};
