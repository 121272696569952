import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";

import "components/FileDragDrop/FileDragDrop.scss";
import { ITranslation } from "util/interfaces";

type DragEvent = React.DragEvent<HTMLDivElement>;

type FileDragDropProps = {
  id: string;
  onFileUpload: (files: FileList | null) => void;
  required?: boolean;
};

export const FileDragDrop = ({ onFileUpload, id, required = true }: FileDragDropProps) => {
  const [draggedOver, setDraggedOver] = useState(false);
  const t = useTranslation().t as ITranslation;

  const handleDrop = (evt: DragEvent) => {
    evt.preventDefault();
    setDraggedOver(false);

    const files = evt.dataTransfer?.files;

    if (files && files.length > 0) {
      onFileUpload(files);
    }
  };

  const handleAddFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files) {
      onFileUpload(evt.target.files);
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleDragLeave = () => {
    setDraggedOver(false);
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
  };

  const triggerOnChangeOnFileInput = () => {
    document.getElementById(id)?.click();
  };

  return (
    <div
      className={`FileDragDrop ${draggedOver ? "active" : ""}`}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <div>
        <span>
          {t("v8_drag_files_here").message || "Drag files here"} {t("v8_or").message || "or"}
        </span>{" "}
        <span className="browse-text" onClick={triggerOnChangeOnFileInput}>
          {t("v8_browse").message || "Browse"}
        </span>
      </div>
      <div className="max-file-size">{t("v8_maximum_file_size").message || "Maximum file size"} 32MB</div>
      <Input type="file" id={id} onChange={handleAddFile} placeholder="Browse" multiple required={required} />
    </div>
  );
};
