import { differenceInDays, startOfDay } from "date-fns";

import { DATE_FORMATS_FNS } from "components";
import { DATE_ORDER_BY } from "modules/Appointments";
import "modules/Appointments/components/AppointmentTable/components/AppointmentTableDateColumnCell/AppointmentTableDateColumnCell.scss";
import { formatDate, isDateAfter } from "util/dateHelperFunctions";

type AppointmentTableDateColumnCellProps = {
  dateColumnAccessor: string;
  value: { date: string | null; isPinned: boolean };
  isTodaysList: boolean;
};

export const AppointmentTableDateColumnCell = ({ dateColumnAccessor, value, isTodaysList }: AppointmentTableDateColumnCellProps) => {
  const dateDiff = differenceInDays(startOfDay(new Date()), startOfDay(value.date ? new Date(value.date) : new Date()));

  const renderDateDiff = () => (value.isPinned && dateDiff && isTodaysList ? (dateDiff > 0 ? `+${dateDiff}` : dateDiff) : null);

  switch (dateColumnAccessor) {
    case DATE_ORDER_BY.APPOINTMENT_DATE_ASCENDING:
    case DATE_ORDER_BY.APPOINTMENT_DATE_DESCENDING:
      return (
        <div className="AppointmentTableDateColumnCellTimeCarApp">
          {value.date && formatDate(value.date, DATE_FORMATS_FNS.dateMonthYearShort)}
          <span className="date-diff-value">{renderDateDiff()}</span>
        </div>
      );
    case DATE_ORDER_BY.SCHEDULED_OUT_DATE_ASCENDING:
    case DATE_ORDER_BY.SCHEDULED_OUT_DATE_DESCENDING:
      return (
        <div className={isDateAfter(new Date(), value.date) ? "past-car-return-time" : ""}>
          {value.date ? (
            <span>
              <span>{formatDate(value.date, DATE_FORMATS_FNS.dateMonthYear)}</span>
              <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;{formatDate(value.date, DATE_FORMATS_FNS.hoursMinutes)}</span>
            </span>
          ) : (
            ""
          )}
        </div>
      );
    default:
      return null;
  }
};
