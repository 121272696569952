import { useTranslation } from "react-i18next";
import { Checkbox, Icon, Popup } from "semantic-ui-react";

import { SearchTyreReplacement } from "models";
import { getTyreSeasonIcon, roundTwoDecimals } from "util/common";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

interface TyreTeamListProps {
  segmentedResults: SearchTyreReplacement[];
  selection: Record<string, any> | null;
  handleSelect: (index: number, checked: boolean) => void;
  getDelivery: (delivery: string | null) => { content: string; trigger: string; long: string } | null;
  getDeliveryChannel: (channel: number) => string;
}

export const TyreTeamList = ({ segmentedResults, selection, handleSelect, getDeliveryChannel, getDelivery }: TyreTeamListProps) => {
  const t = useTranslation().t as ITranslation;

  const renderDeliveryPopup = (delivery: string) => {
    const type = getDelivery(delivery);
    return type ? <Popup content={<span>{type.content}</span>} trigger={<span>{type.trigger}</span>} /> : null;
  };

  const getGrossPrice = (item: SearchTyreReplacement) => {
    if (item.stock && item.gross_price) {
      return (
        <>
          {formatPrice(item.gross_price)} {item.discount && `- ${item.discount}%`}
        </>
      );
    }

    return "0.0";
  };

  const getNetPrice = (item: SearchTyreReplacement) => {
    if (item.stock && item.net_price) {
      return (
        <>
          {formatPrice(item.net_price)} {item.net_percent_discount && ` + ${item.net_percent_discount}%`} {item.price_reduction && ` + ${item.price_reduction}€`}
        </>
      );
    }

    return "0.0";
  };

  return (
    <div className="tyre-team-list">
      <div className="tyre-row header">
        <div className="tyre-col season"></div>
        <div className="tyre-col description">{t("v8_description").message || "Description"}</div>
        <div className="tyre-col brand">{t("v8_brand").message || "Brand"}</div>
        <div className="tyre-col artean">{"Art / EAN"} #</div>
        <div className="tyre-col gross">{t("v8_gross").message || "Gross"} €</div>
        <div className="tyre-col neto">{t("v8_neto").message || "Net"} €</div>
        <div className="tyre-col customer">{t("v8_customer").message || "Customer"} €</div>
        <div className="tyre-col load">{t("v8_load").message || "Load"}</div>
        <div className="tyre-col speed">
          <Popup trigger={<span>{t("v8_speed").message || "Speed"}</span>} content={t("v8_speed").message || "Speed"} />
        </div>
        <div className="tyre-col flat-tire">{t("v8_runflat").message || "Runflat"}</div>
        <div className="tyre-col channel">{t("v8_channel").message || "Channel"}</div>
        <div className="tyre-col stock">
          <Popup trigger={<span> {t("v8_stock").message || "Stock"}</span>} content={t("v8_stock").message || "Stock"} />
        </div>
        <div className="tyre-col delivery">{t("v8_delivery").message || "Delivery"}</div>
        <div className="tyre-col add">
          <Popup trigger={<span>{t("v8_add").message || "Add"}</span>} content={t("v8_add").message || "Add"} />
        </div>
      </div>

      {segmentedResults.map((item, index) => (
        <div className="tyre-row" key={index}>
          <div className="tyre-col season">
            <Icon className={getTyreSeasonIcon(Number(item.season))} />
            {item["3pmsf"] === 2 && (
              <div className="three-pmsf-icon">
                <img src="/images/3pmsf_outline_white.svg" alt="3pmsf" />
              </div>
            )}
          </div>
          <div className="tyre-col description">{item.description}</div>
          <div className="tyre-col brand">{item.manufacturer}</div>
          <div className="tyre-col artean">
            {item.system_number}
            {item.system_number && " / "}
            {item.ean && <Popup hoverable trigger={<span className="replacement-tyre-ean">{item.ean}</span>} content={item.ean} />}
          </div>
          <div className="tyre-col gross">{getGrossPrice(item)}</div>
          <div className={`tyre-col neto ${item.customerPrice && item.customerPrice < item.net_price ? "lower-customer-price" : ""}`}>{getNetPrice(item)}</div>
          <div className="tyre-col customer">{item.customerPrice ? formatPrice(roundTwoDecimals(item.customerPrice)) : null}</div>
          <div className="tyre-col load">{item.carry_capacity}</div>
          <div className="tyre-col speed">{item.speed}</div>
          <div className="tyre-col flat-tire">{item.run_flat_tire ? <Icon name="check circle" color="green" /> : ""}</div>
          <div className="tyre-col channel">{getDeliveryChannel(item.channel)}</div>
          <div className="tyre-col stock">{item.stock ? item.stock : 0}</div>
          <div className="tyre-col delivery">{renderDeliveryPopup(item.delivery)}</div>
          <div className="tyre-col add">
            <Checkbox
              className="check"
              checked={Boolean(selection?.[`${item.ean}-${item.delivery}-${item.channel}`])}
              onChange={(_e, data) => handleSelect(index, Boolean(data.checked))}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
