import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

export const CommunicationSettingsQueryKeys = {
  settings: (dealer_location_id: number | undefined) => [
    backendQueryKey({
      baseUrl: ENV.dealerBaseUrl,
      endpoint: "/communication/settings/read",
      updateType: UPDATE_TYPE.Static,
      component: "CommunicationSettings",
      ...(dealer_location_id ? { params: { dealer_location_id } } : {})
    })
  ],
  pdf_templates: [
    backendQueryKey({ baseUrl: ENV.dealerBaseUrl, endpoint: "/report_templates/comm/list", component: "CommunicationSettings", updateType: UPDATE_TYPE.Static })
  ]
};
