import { REPORT_CONFIGS } from "modules/DealerReports/util/config";
import { calculatePercentage, formatDuration, getColor, isDurationField } from "modules/DealerReports/util/helper";
import { FieldCalculation, FormattedTableData, ParsedDealerReport, ParsedReportData, ReportConfig } from "modules/DealerReports/util/types";

export const COLOR_THRESHOLDS = {
  geclaired: [
    { min: 0, max: 25, color: "red" },
    { min: 25, max: 50, color: "orange" },
    { min: 50, max: 80, color: "green" },
    { min: 80, max: Infinity, color: "darkgreen" }
  ],
  qualityCheck: [
    { min: 0, max: 20, color: "red" },
    { min: 20, max: Infinity, color: "darkgreen" }
  ],
  avgPhotos: [
    { min: 0, max: 5, color: "red" },
    { min: 5, max: Infinity, color: "darkgreen" }
  ],
  avgSold: [
    { min: 0, max: 40, color: "red" },
    { min: 40, max: 65, color: "orange" },
    { min: 65, max: Infinity, color: "darkgreen" }
  ]
} as const;

export const processField = (fieldValue: any, fieldKey: string, calculation?: FieldCalculation, row?: ParsedReportData): any => {
  let value = fieldValue;

  if (value === null || value === undefined) return 0;

  if (typeof value === "string" && !isNaN(Number(value))) value = Number(value);

  if (calculation?.totalField) {
    const total = Number(row?.[calculation.totalField] || 0);
    const percent = calculatePercentage(value, total);

    return {
      value,
      percent,
      ...(calculation.colorType && { color: getColor(percent, calculation.colorType) })
    };
  }

  if (isDurationField(fieldKey)) return formatDuration(value);
  if (fieldKey.startsWith("avg_")) return Number(value).toFixed(2);

  return value;
};

const calculateTotals = (originalReportData: Record<string, string>[], config: ReportConfig): ParsedReportData => {
  const getValue = (value: any): number => {
    if (value === null || value === undefined) return 0;
    return typeof value === "object" ? Number(value.value) || 0 : Number(value) || 0;
  };

  const totals = originalReportData.reduce<ParsedReportData>(
    (acc, row) => {
      Object.keys(row).forEach(key => {
        if (key === "location") {
          acc[key] = "Total";
          return;
        }

        acc[key] = getValue(acc[key]) + getValue(row[key]);
      });
      return acc;
    },
    { location: "Total" }
  );

  if (config.calculations) {
    Object.entries(config.calculations).forEach(([key, calculation]) => {
      try {
        if (calculation.totalCalculation) {
          const parsedTotals = Object.fromEntries(Object.entries(totals).map(([key, value]) => [key, getValue(value)]));
          const result = calculation.totalCalculation(parsedTotals as Record<string, number>);
          totals[key] = result ? Number(result.toFixed(2)) : 0;
        } else if (calculation.totalField) {
          const value = getValue(totals[key]);
          const totalValue = getValue(totals[calculation.totalField]);
          const percent = calculatePercentage(value, totalValue);

          totals[key] = {
            value,
            percent: Number.isFinite(percent) ? Number(percent.toFixed(2)) : 0,
            ...(calculation.colorType && {
              color: getColor(percent, calculation.colorType)
            })
          };
        }
      } catch (error) {
        console.error(`Error calculating ${key}:`, error);
      }
    });
  }

  return totals;
};

export const parseDealerReportData = (report: ParsedDealerReport): FormattedTableData => {
  if (!Array.isArray(report.report_data) || !report.report_data.length) return { report_data: [] };

  try {
    const config = REPORT_CONFIGS[report.id];
    if (!config) return report;

    const parsedData = report.report_data.map(row => {
      let result: ParsedReportData = {};

      config.fields.forEach(fieldKey => {
        result[fieldKey] = processField(row[fieldKey], fieldKey, config.calculations?.[fieldKey], row);
      });

      if (config.dynamicFields) {
        Object.keys(row)
          .filter(key => key.startsWith(config.dynamicFields!.pattern))
          .forEach(key => {
            const calculation = config.dynamicFields?.totalField ? { totalField: config.dynamicFields.totalField } : undefined;
            result[key] = processField(row[key], key, calculation, row);
          });

        if (config.dynamicFields.customProcessor) result = config.dynamicFields.customProcessor(result, row, config);
      }

      return result;
    });

    const finalData = config.showTotals ? [...parsedData, calculateTotals(report.report_data, config)] : parsedData;

    return {
      report_data: finalData,
      fields: config.fields
    };
  } catch (error) {
    console.error("Error processing dealer report data:", error);
    return { report_data: [] };
  }
};
