/* eslint-disable */

Object.defineProperty(exports, "__esModule", {
  value: true
});
// var updateHash = function updateHash(hash, historyUpdate) {
//   var hashVal = hash.indexOf("#") === 0 ? hash.substring(1) : hash;
//   var hashToUpdate = hashVal ? "#" + hashVal : "";
//   var curLoc = window && window.location;
//   var urlToPush = hashToUpdate ? curLoc.pathname + curLoc.search + hashToUpdate : curLoc.pathname + curLoc.search;
//   historyUpdate ? history.pushState(null, "", urlToPush) : history.replaceState(null, "", urlToPush);
// };

var getHash = function getHash() {
  return window.location.hash.replace(/^#/, "");
};

var filterElementInContainer = function filterElementInContainer(container) {
  return function (element) {
    return container.contains ? container !== element && container.contains(element) : !!(container.compareDocumentPosition(element) & 16);
  };
};

var scrollOffset = function scrollOffset(c, t, horizontal) {
  if (horizontal) {
    return c === document
      ? t.getBoundingClientRect().left + (window.scrollX || window.pageXOffset)
      : getComputedStyle(c).position !== "static"
        ? t.offsetLeft
        : t.offsetLeft - c.offsetLeft;
  } else {
    return c === document
      ? t.getBoundingClientRect().top + (window.scrollY || window.pageYOffset)
      : getComputedStyle(c).position !== "static"
        ? t.offsetTop
        : t.offsetTop - c.offsetTop;
  }
};
exports.default = {
  // updateHash: updateHash,
  getHash: getHash,
  filterElementInContainer: filterElementInContainer,
  scrollOffset: scrollOffset
};
