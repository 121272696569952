import { faCalendarAlt, faCar, faEnvelope, faInfoCircle, faMap, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

import { DATE_FORMATS_FNS } from "components";
import { Appointment } from "models";
import "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/HeaderSection/HeaderSection.scss";
import useHeaderSection from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/HeaderSection/useHeaderSection";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type Props = {
  showCustomerInformation: boolean;
  appointment: Appointment | null;
};

export const HeaderSection = ({ showCustomerInformation, appointment }: Props) => {
  const { getCustomerEmail, getCustomerPhone, customerFirstName, customerSurnameName, selectedLocation } = useHeaderSection();
  const customerPhone = getCustomerPhone(appointment);
  const customerEmail = getCustomerEmail(appointment);
  const t = useTranslation().t as ITranslation;

  return (
    <div className="HeaderSectionContent">
      <div className="header-section">
        <Grid columns="equal" padded="horizontally" className="grid-header-container">
          <GridRow verticalAlign="middle">
            <GridColumn>
              <span className="grid-title-text"> {t("v8_appointment_information").message || "Appointment information"} </span>
            </GridColumn>
            {showCustomerInformation && (
              <GridColumn>
                <span className="grid-title-text"> {t("v8_customer_information").message || "Customer information"} </span>
              </GridColumn>
            )}
            <GridColumn>
              <span className="grid-title-text"> {t("v8_car_information").message || "Car information"} </span>
            </GridColumn>
          </GridRow>
        </Grid>
        <Grid columns="equal" padded="horizontally" className="appointment-section">
          <GridRow>
            <GridColumn>
              <div className="text-icon-container">
                <div className="icon-container">
                  <FontAwesomeIcon icon={faCalendarAlt} className="i-green" />
                </div>
                <span>
                  {" "}
                  {appointment?.due_in
                    ? formatDate(appointment.due_in, DATE_FORMATS_FNS.dateMonthYearTime)
                    : formatDate(appointment?.time_car_app ?? new Date(), DATE_FORMATS_FNS.dateMonthYear)}{" "}
                </span>
              </div>
              <div className="text-icon-container">
                <div className="icon-container">
                  <FontAwesomeIcon icon={faInfoCircle} className="i-green" />
                </div>
                <span> {appointment?.wo_nr} </span>
              </div>
              <div className="text-icon-container">
                <div className="icon-container">
                  <FontAwesomeIcon icon={faMap} className="i-green" />
                </div>
                <span>
                  {selectedLocation?.name || ""} {selectedLocation?.city || ""}
                </span>
              </div>
            </GridColumn>
            {showCustomerInformation && (
              <GridColumn>
                <div className="text-icon-container">
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faUser} className="i-green" />
                  </div>
                  <span>{customerFirstName(appointment)}</span> <span>{customerSurnameName(appointment)}</span>
                </div>
                {customerEmail && (
                  <div className="text-icon-container">
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faEnvelope} className="i-green" />
                    </div>
                    <span>{customerEmail}</span>
                  </div>
                )}
                {customerPhone && (
                  <div className="text-icon-container">
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faPhone} className="i-green" />
                    </div>
                    <span>{customerPhone}</span>
                  </div>
                )}
              </GridColumn>
            )}

            <GridColumn>
              <div className={`reg-container ${selectedLocation?.country || ""}`}>
                <div className="reg-country">
                  <div>{selectedLocation?.country || ""}</div>
                </div>
                <div className="reg-nr">{appointment?.reg_number.split("-").join(" - ")}</div>
              </div>
              {appointment?.vin_nr && (
                <div className="text-icon-container">
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faInfoCircle} className="i-green" />
                  </div>
                  <span> {appointment?.car?.vin_nr} </span>
                </div>
              )}
              <div className="text-icon-container">
                <div className="icon-container">
                  <FontAwesomeIcon icon={faCar} className="i-green" />
                </div>
                <span>
                  {appointment?.car?.make} {appointment?.car?.model}
                </span>
              </div>
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
};
