import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, Input } from "semantic-ui-react";

import "components/UploadVideo/UploadVideo.scss";
import { useUploadAttachment } from "hooks";
import { ITranslation } from "util/interfaces";

interface Props {
  path: string;
  baseURL: string;
  locationPath: string;
  onSuccess?: (url: string) => void;
  onError?: (reason: string) => void;
  formId: string;
}

export const UploadVideo = ({ path, baseURL, locationPath, onSuccess, onError, formId }: Props) => {
  const t = useTranslation().t as ITranslation;
  const { handleUploadFileAttachment } = useUploadAttachment(path, baseURL);

  const handleChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const uploadFile = event.target.files[0];

    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("path", locationPath);

    try {
      const attachmentUrl = await handleUploadFileAttachment({ formData });

      if (attachmentUrl) onSuccess?.(attachmentUrl);
      else throw new Error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
    } catch (e: Error | unknown) {
      const errMessage = e instanceof Error ? e.message : t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.";
      if (onError) onError(errMessage);
    }
  };

  const handleUpload = () => {
    (document as Document).getElementById(formId)?.click();
  };

  return (
    <div className="upload-container">
      <Icon className="upload blue" onClick={handleUpload} />
      <Input id={formId} accept="video/mp4, video/quicktime" type="file" onChange={handleChangeFile} />
    </div>
  );
};
