import { useQuery, useQueryClient } from "@tanstack/react-query";
import { addSeconds, differenceInSeconds } from "date-fns";
import { useEffect } from "react";

import { WebSocketComponent, WebSocketMessage, WebSocketMessageListener } from "components";
import { useWebSocketStatus } from "components/WebSocket/useWebSocketStatus";
import { useUser } from "hooks";
import { User } from "models";
import { staticLocalKey } from "util/keyFactory";

interface UserWithTimeOut extends User {
  timeOut: Date;
}

const liveUsersQueryKey = staticLocalKey("liveUsers");

export const useLiveUsers = (appointmentId: number) => {
  const isConnected = useWebSocketStatus();
  const { data: users } = useQuery({ queryKey: liveUsersQueryKey });
  const user = useUser();
  const queryClient = useQueryClient();
  const listeners: WebSocketMessageListener[] = [
    {
      model: "_ActiveUsersOnAppointmentDetailsPage",
      action: "ping",
      id: appointmentId,
      callback: (message: WebSocketMessage) => {
        if (message) {
          const userData = message.data as Partial<User>;
          if (userData?.id !== user?.id) {
            const usersSnapshot: Partial<UserWithTimeOut[]> | undefined = queryClient.getQueryData(liveUsersQueryKey);
            let updatedUsers = [];
            let foundUser = false;
            updatedUsers =
              usersSnapshot?.map(prevUser => {
                if (prevUser?.id === userData.id) {
                  foundUser = true;
                  return {
                    ...prevUser,
                    timeOut: addSeconds(new Date(), 3)
                  };
                }
                return prevUser;
              }) || [];

            if (!foundUser) {
              updatedUsers = usersSnapshot?.concat({ ...userData, timeOut: addSeconds(new Date(), 3) } as UserWithTimeOut) || [];
            }
            queryClient.setQueryData(liveUsersQueryKey, updatedUsers);
          }
        }
      }
    }
  ];

  useEffect(() => {
    if (user && isConnected) {
      const unsubscribeFunctions: (() => void)[] = [];
      WebSocketComponent.subscribeToAppointmentQueue(appointmentId);

      const interval = setInterval(() => {
        WebSocketComponent.sendAppointmentActiveUserPing(appointmentId, user);
        const usersSnapshot: Partial<UserWithTimeOut[]> | undefined = queryClient.getQueryData(liveUsersQueryKey);
        if (usersSnapshot?.length) {
          const updatedUsers = usersSnapshot.filter(prevUser => prevUser?.timeOut && differenceInSeconds(new Date(), prevUser.timeOut) <= 3);
          queryClient.setQueryData(liveUsersQueryKey, updatedUsers);
        }
      }, 1000);

      listeners.forEach(listener => {
        const unsubscribe = WebSocketComponent.addMessageListener(listener);
        unsubscribeFunctions.push(() => unsubscribe());
      });

      return () => {
        clearInterval(interval);
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        WebSocketComponent.unsubscribeFromAppointmentQueue(appointmentId);
      };
    }
  }, [isConnected, user]);

  return { users: (users || []) as Partial<User[]> };
};
