import { useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { Appointment } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

interface CheckInResultMutationData {
  checkin_result_id: number;
  handled: boolean;
}

export const useCheckInResultMutation = (appointment_id: number) => {
  const queryClient = useQueryClient();

  const appointmentQueryKey = AppointmentDetailsKeys.view(appointment_id);

  const updateCheckInResultData = (variables: CheckInResultMutationData) => {
    const appointment = queryClient.getQueryData<Appointment>(appointmentQueryKey);
    if (!appointment?.customer_communication?.check_in_results?.length) return;

    const checkInResultIdx = appointment.customer_communication.check_in_results.findIndex(result => result.id === variables.checkin_result_id);
    if (checkInResultIdx < 0) return;

    const customer_communication = {
      ...appointment.customer_communication,
      check_in_results: appointment.customer_communication.check_in_results.with(checkInResultIdx, {
        ...appointment.customer_communication.check_in_results[checkInResultIdx],
        receptionist_handled: variables.handled
      })
    };

    queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication });

    return appointment;
  };

  const checkInResultMutation = useMutation({
    mutationFn: async (data: CheckInResultMutationData) => {
      await ApiInstance.post(`/check_in/result/handle`, data, ENV.appointmentBaseURL);
    },
    onMutate: (variables: CheckInResultMutationData) => updateCheckInResultData(variables),
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  return { checkInResultMutation, updateCheckInResultData };
};
