import { differenceInHours } from "date-fns";
import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import useMCC from "components/MCC/hooks/useMCC";
import "components/MCC/MCC.scss";
import { Car } from "models";

type MCCProps = {
  carDetails: Car | null;
};

export const MCC = ({ carDetails }: MCCProps) => {
  if (!carDetails) return null;

  const [mccStatus, setMccStatus] = useState({
    status: carDetails.mcc_status,
    redirectUrl: ""
  });

  const { mccMutation } = useMCC(carDetails.id);

  useEffect(() => {
    const isMccStatusTimeOver24Hours = carDetails.mcc_status_time && differenceInHours(new Date(), new Date(carDetails.mcc_status_time)) > 24;

    if (carDetails.mcc_status === 0 || isMccStatusTimeOver24Hours) {
      mccMutation.mutateAsync().then(data => {
        setMccStatus({
          status: data.status,
          redirectUrl: data.redirect_url
        });
      });
    }
  }, []);

  const onClickMcc = () => {
    mccMutation.mutateAsync().then(data => {
      setMccStatus({
        status: data.status,
        redirectUrl: data.redirect_url
      });
      if (data.redirect_url) {
        window.open(data.redirect_url, "_blank");
      }
    });
  };

  return (
    <Button className={`-appointment-status mcc-status-${mccStatus?.status !== undefined ? mccStatus.status : "default"}`} size="small" onClick={onClickMcc}>
      MCC
      <Icon className="arrow up right from square" color="green" />
    </Button>
  );
};
