import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import ENV from "config/Env";
import { PinModel } from "models";
import { useGetManufacturerDashboardFilters } from "modules/ManufacturerDashboard/hooks";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import ApiInstance from "util/Api";

interface CreateRequestPayload {
  id: number | null;
  reason: string;
  isQuestionResult?: boolean;
}

const manufacturerRequestHandler = async ({ id, reason, isQuestionResult = false }: CreateRequestPayload) => {
  if (!id) return null;

  const payload = isQuestionResult ? { question_result_id: id, reason } : { reason, intervention_id: id };
  const response = await ApiInstance.post("/manufacturers/requests/access", payload, ENV.appointmentBaseURL);

  return response as { data: { id: number } };
};

export const useConfirmManufacturerRequest = () => {
  const queryClient = useQueryClient();
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();

  const [selectedRequest, setSelectedRequest] = useState<PinModel | null>(null);
  const [requestReason, setRequestReason] = useState("");

  const selectRequest = (row: PinModel) => setSelectedRequest(row);
  const handleRequestReason = (input: string) => setRequestReason(input);

  const closeConfirmModal = () => {
    setRequestReason("");
    setSelectedRequest(null);
  };

  const { mutate: createManufacturerRequest } = useMutation({
    mutationFn: async () => {
      if (!selectedRequest) return;

      const manufacturerRequestPayload = {
        id: selectedRequest.intervention_id || selectedRequest.question_result_id,
        reason: requestReason,
        isQuestionResult: !!selectedRequest.question_result_id
      };
      const response = await manufacturerRequestHandler(manufacturerRequestPayload);

      return response as { data: { id: number } };
    },

    onSuccess: res => {
      if (res?.data) queryClient.invalidateQueries({ queryKey: ManufacturerQueryKeys.manufacturerListRequests({ manufacturerFilters: manufacturerDashboardFilters }) });
    }
  });

  return {
    createManufacturerRequest,
    handleRequestReason,
    closeConfirmModal,
    selectRequest,
    isConfirmButtonDisabled: !requestReason,
    selectedRequest
  };
};
