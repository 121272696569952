import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm, DATE_FORMATS_FNS, FileDragDrop } from "components";
import { InterventionAttachment } from "models";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components/Attachments";
import "modules/AppointmentDetails/components/Interventions/components/NoteAttachments/NoteAttachments.scss";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { getNameFromURL } from "util/common";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

type AttachmentsProps<T extends GenericAttachmentData> = {
  appointmentId: number;
  interventionId: number;
  attachments?: T[];
  setAttachments: Dispatch<SetStateAction<InterventionAttachment[]>>;
};

const handleOpenAttachment = (url: string) => {
  window.open(url, "_blank");
};

export const NoteAttachments = <T extends GenericAttachmentData>({ appointmentId, interventionId, attachments, setAttachments }: AttachmentsProps<T>) => {
  const t = useTranslation().t as ITranslation;
  const [selectedDeleteAttachmentURL, setSelectedDeleteAttachmentURL] = useState("");
  const { uploadAttachment, deleteInterventionAttachmentMutation } = useIntervention(appointmentId);

  const handleUploadAttachment = async (file: File) => {
    if (!file) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });

    const attachmentUrl = await uploadAttachment(appointmentId, uploadFile);
    const attachment: InterventionAttachment = {
      url: attachmentUrl!,
      intervention_id: interventionId,
      type: file.type,
      name: getNameFromURL(attachmentUrl!)
    };

    setAttachments(currentAttachments => [...currentAttachments, attachment]);
  };

  const handleFileUpload = (files: FileList | null) => {
    if (!files) return;

    [...files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const onCancelDeleteAttachment = () => {
    setSelectedDeleteAttachmentURL("");
  };

  const onDeleteAttachment = (url: string) => {
    setSelectedDeleteAttachmentURL(url);
  };

  const handleConfirmDeleteAttachment = () => {
    if (selectedDeleteAttachmentURL) setAttachments(prev => prev.filter(attachment => attachment.url !== selectedDeleteAttachmentURL));
    setSelectedDeleteAttachmentURL("");
  };

  const renderListView = () => {
    return (
      !!attachments?.length && (
        <div className="attachment-container">
          {attachments.map((item: GenericAttachmentData) => {
            return (
              <div key={item.id} className="attachment-view">
                <div className="attachment-name" onClick={() => handleOpenAttachment(item.url)}>
                  <Icon className="image" />
                  <p className="ellipsis">{item.name}</p>
                </div>
                <div className="attachment-delete">
                  {item.created_on && <p>{`${t("v8_uploaded_on").message || "Uploaded on"} ${formatDate(item.created_on, DATE_FORMATS_FNS.dateMonthYearTime)}`}</p>}
                  {<Icon className="trash grey" onClick={() => onDeleteAttachment(item.url)} />}
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  };

  return (
    <div className="NoteAttachments">
      <FileDragDrop id="intervention-image" onFileUpload={handleFileUpload} required={false} />
      {renderListView()}
      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleCancel={onCancelDeleteAttachment}
        isOpen={!!selectedDeleteAttachmentURL}
        isLoading={deleteInterventionAttachmentMutation.isPending}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        handleConfirm={handleConfirmDeleteAttachment}
      />
    </div>
  );
};
