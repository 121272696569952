import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { DATE_FORMATS_FNS } from "components";
import { AppointmentsKeys } from "modules/Appointments/queryKeys";
import { formatDate } from "util/dateHelperFunctions";

interface DateMutationContext {
  previousDate?: string;
}

export const useSelectedDate = () => {
  return useQueryClient().getQueryData<string>(AppointmentsKeys.selectedDate) || formatDate(new Date(), DATE_FORMATS_FNS.timeCarAppDate);
};

export const useSelectedDateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Date, Error, Date, DateMutationContext>({
    mutationFn: async newDate => Promise.resolve(newDate),
    onMutate: newDate => {
      const previousDate = queryClient.getQueryData<string>(AppointmentsKeys.selectedDate) || formatDate(new Date(), DATE_FORMATS_FNS.timeCarAppDate);
      queryClient.setQueryData(AppointmentsKeys.selectedDate, formatDate(newDate, DATE_FORMATS_FNS.timeCarAppDate));

      return { previousDate };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(AppointmentsKeys.selectedDate, context?.previousDate);
      toast.error(error.message);
    }
  });

  return mutation.mutate;
};
