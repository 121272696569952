import { useTranslation } from "react-i18next";
import { Icon, Label, Popup } from "semantic-ui-react";

import { DATE_FORMATS_FNS } from "components";
import { AppointmentNote } from "models";
import { formatDate } from "util/dateHelperFunctions";
import { ITranslation } from "util/interfaces";

interface RecurringCarNoteProps {
  note: AppointmentNote | undefined;
}

export const RecurringCarNote = ({ note: recurringCarNote }: RecurringCarNoteProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Popup
      wide
      basic
      disabled={!recurringCarNote}
      content={
        recurringCarNote && (
          <div className="AppointmentNotePopup">
            <div className="AppointmentNoteTitle">
              <Icon className="warning sign red" color="red" />

              <span>{t("v8_recurring_car").message || "Recurring car"}</span>
            </div>
            <div className="AppointmentNoteDescription">
              {recurringCarNote.updated_by
                ? `${t("v8_updated_by").message || "Updated by"} ${recurringCarNote?.updated_by.first_name} ${recurringCarNote?.updated_by.last_name} ${t("v8_at").message || "at"} `
                : `${t("v8_added_by").message || "Added by"}  ${recurringCarNote.user?.first_name} ${recurringCarNote.user?.last_name} ${t("v8_at").message || "at"} `}
              {`${formatDate(recurringCarNote.updated_on, DATE_FORMATS_FNS.dateMonthYearTime)}`}
            </div>
            <div className="AppointmentNoteContent">{recurringCarNote.note}</div>
          </div>
        )
      }
      trigger={
        <Label circular color="red">
          <Icon name="warning sign" />
        </Label>
      }
    />
  );
};
