import { useTranslation } from "react-i18next";
import { Checkbox, Dropdown, Grid, GridColumn } from "semantic-ui-react";

import { DealersAndLocationsDropdown, ExportDataButton, NavPortal, ResetFiltersButton, SearchFilter, StatusFilters } from "components";
import { useUser } from "hooks";
import { PIN_STATUS } from "models";
import { TimePeriodDropdown } from "modules/WarrantyDashboard/components/WarrantyDashboardFilters/components";
import { useWarrantyDashboardFilterHandlers } from "modules/WarrantyDashboard/components/WarrantyDashboardFilters/hooks";
import "modules/WarrantyDashboard/components/WarrantyDashboardFilters/WarrantyDashboardFilters.scss";
import { useGetPinStatusOptions, useGetWarrantyDashboardFilters, useGetWarrantyExportModalSetters } from "modules/WarrantyDashboard/hooks";
import { useBrandsQuery } from "modules/WarrantyDashboard/service";
import { ITranslation } from "util/interfaces";

export const WarrantyDashboardFilters = () => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const brandsOptions = useBrandsQuery();
  const pinStatusOptions = useGetPinStatusOptions();
  const { filters } = useGetWarrantyDashboardFilters();
  const { handleOpenExportModal } = useGetWarrantyExportModalSetters();
  const {
    mechanicFixedOptions,
    selectedMechanicFixed,
    isOnlyRemarksSelected,
    handleDateChange,
    handleResetFilters,
    handleSearchChange,
    handleBrandsChange,
    handleClaimedChange,
    handleDeletedChange,
    handlePinStatusChange,
    handleTimePeriodChange,
    handleMechanicFixedChange,
    handleDealerLocationChange,
    handleAppointmentStatusUpdate
  } = useWarrantyDashboardFilterHandlers();

  const availablePinStatusOptions = pinStatusOptions.filter(status => status.value !== PIN_STATUS.Deleted && status.value !== PIN_STATUS.Claimed);
  const { dealer_ids, location_ids, search_term, claimed, pin_status_id, brands, time_period, appointment_statuses, deleted } = filters;

  return (
    <>
      <NavPortal>
        <SearchFilter value={search_term} handleSearchChange={handleSearchChange} />
      </NavPortal>

      <div className="WarrantyDashboardFilters__first-toolbar-container">
        <Grid columns="equal" className="WarrantyDashboardFilters__toolbar-grid">
          <GridColumn width={5} className="WarrantyDashboardFilters__no-left-gutter WarrantyDashboardFilters__right-gutter">
            <TimePeriodDropdown value={time_period} handleTimePeriodChange={handleTimePeriodChange} handleDateChange={handleDateChange} />
          </GridColumn>

          <GridColumn className="WarrantyDashboardFilters__left-gutter WarrantyDashboardFilters__right-gutter">
            <Dropdown
              fluid
              selection
              name="pinStatus"
              clearable
              multiple
              value={pin_status_id || []}
              selectOnBlur={false}
              options={availablePinStatusOptions}
              placeholder={t("v8_pin_status").message || "Pin status"}
              onChange={(_, { value }) => handlePinStatusChange(value as number[])}
              disabled={deleted || isOnlyRemarksSelected}
            />
          </GridColumn>

          <GridColumn
            width={5}
            className="WarrantyDashboardFilters__no-left-gutter WarrantyDashboardFilters__no-right-gutter WarrantyDashboardFilters__flex-align-justify-center"
          >
            <div className="WarrantyDashboardFilters__filters-checkboxes-container">
              <div className="WarrantyDashboardFilters__filters-checkbox-container">
                <Checkbox
                  toggle
                  name="claimed"
                  onChange={(_, { checked }) => handleClaimedChange(!!checked)}
                  checked={claimed}
                  disabled={deleted || isOnlyRemarksSelected}
                />
                <span className="no-wrap-text"> {t("v8_claimed").message || "Claimed"}</span>
              </div>
              <div className="WarrantyDashboardFilters__filters-checkbox-container">
                <Checkbox toggle name="deleted" onChange={(_, { checked }) => handleDeletedChange(!!checked)} checked={deleted} />
                <span className="no-wrap-text"> {t("v8_deleted").message || "Deleted"}</span>
              </div>
            </div>
          </GridColumn>
        </Grid>

        <div className="WarrantyDashboardFilters__toolbar-reset-export-buttons-container">
          <StatusFilters onChange={data => handleAppointmentStatusUpdate(data)} selectedStatusIdentifiersFilters={appointment_statuses} />
          <ResetFiltersButton handleReset={handleResetFilters} />
          {user?.csv_download && (
            <ExportDataButton
              contentMessage={`${t("v8_export_disabled_as_no_dealer_location_selected").message || "Export disabled as no dealer or location have been selected"}.`}
              handleOnClick={handleOpenExportModal}
              hasWarning={dealer_ids.length === 0 && location_ids.length === 0}
            />
          )}
        </div>
      </div>

      <div className="WarrantyDashboardFilters__second-toolbar-container">
        <Grid columns={16} className="WarrantyDashboardFilters__toolbar-grid">
          <GridColumn
            width={4}
            className="WarrantyDashboardFilters__no-left-gutter WarrantyDashboardFilters__right-gutter WarrantyDashboardFilters__flex-align-justify-center"
          >
            <Dropdown
              fluid
              clearable
              selection
              value={selectedMechanicFixed}
              name="mechanicFixed"
              selectOnBlur={false}
              options={mechanicFixedOptions}
              placeholder={t("v8_mechanic_fixed").message || "Mechanic fixed"}
              onChange={(_, { value }) => {
                const mechanicFixed = typeof value === "string" && !value ? null : value;
                handleMechanicFixedChange(mechanicFixed as number | null);
              }}
            />
          </GridColumn>

          <GridColumn
            width={6}
            className="WarrantyDashboardFilters__left-gutter WarrantyDashboardFilters__right-gutter WarrantyDashboardFilters__flex-align-justify-center"
          >
            <Dropdown
              fluid
              clearable
              selection
              multiple
              name="brands"
              value={brands || []}
              selectOnBlur={false}
              options={brandsOptions}
              placeholder={t("v8_brands").message || "Brands"}
              onChange={(_, { value }) => handleBrandsChange(value as string[])}
            />
          </GridColumn>

          <GridColumn width={6} className="WarrantyDashboardFilters__left-gutter WarrantyDashboardFilters__right-gutter">
            <DealersAndLocationsDropdown
              values={{ dealerIds: dealer_ids || [], locationIds: location_ids || [] }}
              handleOnChange={({ dealerIds, locationIds }) => handleDealerLocationChange({ dealerIds, locationIds })}
              allowSelectAllDealers={false}
            />
          </GridColumn>
        </Grid>
      </div>
    </>
  );
};
