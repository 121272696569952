import { useTranslation } from "react-i18next";

import { SEARCH_OPTION_TYPE } from "components/NavBarSearchAppointments";
import { SearchResultRow, SearchResultsDivider } from "components/NavBarSearchAppointments/components";
import "components/NavBarSearchAppointments/components/SearchDropdown/SearchDropdown.scss";
import { SearchResultsData } from "components/NavBarSearchAppointments/hooks/useAppointmentSearchResults";
import { ITranslation } from "util/interfaces";

type SearchResultLocationsProps = {
  data: SearchResultsData;
  isPending: boolean;
  selectedId: string | null;
  handleOnClick: (id: string, type: SEARCH_OPTION_TYPE) => void;
};

export const SearchResultLocations = ({ data, handleOnClick, isPending, selectedId }: SearchResultLocationsProps) => {
  const t = useTranslation().t as ITranslation;

  if (!data?.currentLocation.length && !data?.otherLocations.length)
    return (
      <div className="no-result">
        <p>{t("v8_no_results_found").message || "No results found"}</p>
      </div>
    );

  return (
    <>
      {data.currentLocation.map(item => (
        <SearchResultRow handleOnClick={handleOnClick} isPending={isPending} selectedId={selectedId} key={item.id} item={item} />
      ))}
      {data.otherLocations.length > 0 && (
        <>
          <SearchResultsDivider />
          {data.otherLocations.map(item => (
            <SearchResultRow handleOnClick={handleOnClick} isPending={isPending} selectedId={selectedId} key={item.id} item={item} />
          ))}
        </>
      )}
    </>
  );
};
