import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { useCan } from "components";
import { useDealersLocations, useRealTimeQuery } from "hooks";
import { ManufacturerResponseModel } from "models";
import { ManufacturerFilterType, useGetManufacturerDashboardFilters } from "modules/ManufacturerDashboard/hooks";
import { useManufacturerDashboardListeners } from "modules/ManufacturerDashboard/listeners";
import { ManufacturerQueryKeys } from "modules/ManufacturerDashboard/queryKeys";
import { constructManufacturerRequestPayload } from "modules/ManufacturerDashboard/utils";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

const getManufacturerData = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint, params } = queryKey[0];
  if (!params) return;

  const payload = params.filters as ManufacturerFilterType;
  const manufacturerRequestPayload = constructManufacturerRequestPayload(payload);
  const response = await ApiInstance.post(endpoint, manufacturerRequestPayload, baseUrl);
  return response.data as ManufacturerResponseModel;
};

export const useManufacturerQuery = () => {
  const { manufacturerDashboardFilters } = useGetManufacturerDashboardFilters();

  const { data: manufacturerData, isLoading: isLoadingManufacturerData } = useQuery({
    queryKey: ManufacturerQueryKeys.manufacturerListRequests({ manufacturerFilters: manufacturerDashboardFilters }),
    queryFn: getManufacturerData,
    refetchOnMount: true
  });

  return { manufacturerData, isLoadingManufacturerData };
};

const getManufacturerNoficationCount = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>, dealerLocationId: number | undefined) => {
  const { baseUrl, endpoint } = queryKey[0];
  const response = await ApiInstance.post(endpoint, { dealer_location_id: dealerLocationId }, baseUrl);
  return response.data as number;
};

export const useGetManufacturerNotificationCount = () => {
  const listeners = useManufacturerDashboardListeners();
  const { selectedLocation } = useDealersLocations();
  const canUpdateManufacturerRequests = useCan("update-request", "manufacturer");

  const { data: manufacturerNotificationCount, isLoading: isLoadingNotificationCount } = useRealTimeQuery({
    queryKey: ManufacturerQueryKeys.ManufacturerRequestCountKey,
    queryFn: queryObject => getManufacturerNoficationCount(queryObject, selectedLocation?.id),
    listeners,
    enabled: !!(selectedLocation && canUpdateManufacturerRequests)
  });

  return { manufacturerNotificationCount, isLoadingNotificationCount };
};
