import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Grid, Icon, Input, Loader, Modal, ModalProps } from "semantic-ui-react";

import { Pagination, useCan } from "components";
import { Appointment, QuestionResult, SearchTyreReplacement, TYRE_SEASONS } from "models";
import { TyreDiscount } from "models/TyreDiscount";
import { TyreLocationData } from "modules/AppointmentDetails/components/Tyre";
import { TyreList, TyreTeamList } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/components";
import "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/components/ReplacementModal.scss";
import { useTyreReplacementOffer } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/hooks";
import { parseNumberOrDefault } from "util/common";
import { ITranslation } from "util/interfaces";

interface TyreReplacementModalProps extends ModalProps {
  data: QuestionResult | null;
  onRequestClose?: () => void;
  appointment: Appointment;
  tireData: TyreLocationData;
}

export type Delivery = {
  order_before: string;
  delivery_before: string;
  delivery_from: string;
  id: string;
};

export type TyreData = {
  price: number;
  question_result_id: number;
  tyre_team: SearchTyreReplacement;
  tyre_id?: number;
  preselected?: boolean;
};

const MAX_RESULTS_PER_PAGE = 100;

export const ReplacementOfferModal = ({ data, open, onRequestClose, appointment, tireData }: TyreReplacementModalProps) => {
  const t = useTranslation().t as ITranslation;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    size,
    width,
    height,
    season,
    selection,
    brandsQuery,
    searchResult,
    manufacturer,
    setSize,
    setWidth,
    setHeight,
    setSeason,
    handleSelect,
    handleSearch,
    getBrandsData,
    handleAddTyre,
    handlePriceUpdate,
    setManufacturer,
    deliveryQuery,
    getDeliveriesData,
    getDelivery,
    getDeliveryChannel,
    isTyreTeamEnabled,
    inStock,
    setInStock,
    delivery,
    setDelivery,
    handleSelectDiscount,
    discountsQuery,
    getDiscountsData,
    selectedDiscount,
    tyreSearchMutation,
    isAddingTyre
  } = useTyreReplacementOffer({ data, onRequestClose, appointment, tireData });
  const canUpdateAppointments = useCan("update", "appointments");
  const seasonOptions = [
    { key: TYRE_SEASONS.AllSeason, value: TYRE_SEASONS.AllSeason, text: t("v8_all_season").message || "All season" },
    { key: TYRE_SEASONS.Summer, value: TYRE_SEASONS.Summer, text: t("v8_summer").message || "Summer" },
    { key: TYRE_SEASONS.Winter, value: TYRE_SEASONS.Winter, text: t("v8_winter").message || "Winter" }
  ];

  const startIndex = (currentPage - 1) * MAX_RESULTS_PER_PAGE;
  const endIndex = currentPage * MAX_RESULTS_PER_PAGE;
  const segmentedResults = searchResult.length > MAX_RESULTS_PER_PAGE ? searchResult.slice(startIndex, endIndex) : searchResult;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <Modal className="TyreReplacementModal" closeOnEscape size="fullscreen" open={open} onClose={onRequestClose} closeOnDimmerClick={true}>
      <Modal.Header>{t("v8_add_replacement").message || "Add Replacement"}</Modal.Header>
      <Modal.Content scrolling className="modal-content-container">
        <div className="TyreReplacementModal">
          <Form onSubmit={handleSubmit}>
            {isTyreTeamEnabled && (
              <Form.Field width={6} className="TyreReplacementModal__discount-dropdown">
                <label>{t("v8_discount_name").message || "Discount Name"}</label>
                <Dropdown
                  clearable
                  options={getDiscountsData()}
                  value={selectedDiscount?.id}
                  onChange={(_, data) => {
                    const selectedDiscount = discountsQuery.data?.find((discount: TyreDiscount) => discount.id === data.value);
                    handleSelectDiscount(selectedDiscount || null);
                  }}
                  placeholder={t("v8_select_discount").message || "Select discount"}
                  fluid
                  selection
                />
              </Form.Field>
            )}
            <Form.Group>
              <Form.Field width={2} required>
                <label>{t("v8_width").message || "Width"}</label>
                <Input
                  value={width || ""}
                  placeholder={t("v8_width").message || "Width"}
                  fluid
                  name="width"
                  required
                  onChange={e => setWidth(Number(parseNumberOrDefault(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={2}>
                <label>{t("v8_height").message || "Height"}</label>
                <Input
                  value={height || ""}
                  placeholder={t("v8_height").message || "Height"}
                  fluid
                  name="height"
                  onChange={e => {
                    const value = Number(parseNumberOrDefault(e.target.value));
                    if (!value) setSize(null);
                    setHeight(value);
                  }}
                />
              </Form.Field>

              <Form.Field width={2} disabled={!height || height <= 0}>
                <label>{t("v8_size").message || "Size"}</label>
                <Input
                  value={size || ""}
                  placeholder={t("v8_size").message || "Size"}
                  fluid
                  name="size"
                  onChange={e => setSize(Number(parseNumberOrDefault(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={4}>
                <label>{t("v8_brand").message || "Brand"}</label>
                <Dropdown
                  selection
                  selectOnBlur={false}
                  loading={brandsQuery.isLoading}
                  options={getBrandsData()}
                  clearable
                  className="DeliveryDropdown"
                  search
                  value={manufacturer}
                  onChange={(_evt, { value }) => setManufacturer(String(value))}
                  name="manufacturer"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("v8_season").message || "Season"}</label>
                <Dropdown
                  selection
                  className="DeliveryDropdown"
                  selectOnBlur={false}
                  clearable
                  options={seasonOptions}
                  value={season !== null ? season : ""}
                  onChange={(_evt, { value }) => {
                    setSeason(value === "" ? null : Number(value));
                  }}
                  name="season"
                />
              </Form.Field>

              {isTyreTeamEnabled && (
                <>
                  <Form.Field width={6} required>
                    <label>{t("v8_delivery").message || "Delivery"}</label>
                    <Dropdown
                      selection
                      selectOnBlur={false}
                      clearable
                      className="DeliveryDropdown"
                      value={delivery}
                      options={getDeliveriesData()}
                      placeholder={t("v8_select_deliveries").message || "Select deliveries"}
                      loading={deliveryQuery.isLoading}
                      onChange={(_evt, { value }) => {
                        setDelivery(value as string[]);
                      }}
                      name="delivery"
                      multiple
                    />
                  </Form.Field>

                  <Form.Field width={1} className="in-stock-form-field">
                    <label>{t("v8_in_stock").message || "In Stock"}</label>
                    <Checkbox toggle className="in-stock-checkbox" defaultChecked={inStock} name="in_stock" onChange={(_e, data) => setInStock(Boolean(data.checked))} />
                  </Form.Field>
                </>
              )}

              <Form.Field className="no-padding-right">
                <label className="search-form-label"> {t("v8_search").message || "Search"}</label>
                <Button
                  type="submit"
                  className="search-button"
                  disabled={(isTyreTeamEnabled && !delivery.length) || tyreSearchMutation.isPending || brandsQuery.isLoading || !width || width <= 0}
                  loading={brandsQuery.isLoading || tyreSearchMutation.isPending}
                >
                  {t("v8_search").message || "Search"}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>

          {tyreSearchMutation.isPending && (
            <Grid>
              <div className="tyre-team-list-loading">
                <Loader active inline size="large" className="Loader">
                  {t("v8_loading_tyres").message || "Loading tyres"}...
                </Loader>
              </div>
            </Grid>
          )}

          {!tyreSearchMutation.isPending && searchResult.length === 0 && (
            <Grid>
              <div className="no-results-container">
                <p> {t("v8_no_tyres_found").message || "No tyres found"} </p>
              </div>
            </Grid>
          )}

          {!tyreSearchMutation.isPending && searchResult.length > 0 && (
            <Grid>
              {isTyreTeamEnabled ? (
                <TyreTeamList
                  segmentedResults={segmentedResults}
                  selection={selection}
                  handleSelect={(localIndex, checked) => {
                    const absoluteIndex = startIndex + localIndex;
                    handleSelect(absoluteIndex, checked);
                  }}
                  getDeliveryChannel={getDeliveryChannel}
                  getDelivery={getDelivery}
                />
              ) : (
                <TyreList
                  segmentedResults={segmentedResults}
                  selection={selection}
                  handleSelect={(localIndex, checked) => {
                    const absoluteIndex = startIndex + localIndex;
                    handleSelect(absoluteIndex, checked);
                  }}
                  handlePriceUpdate={handlePriceUpdate}
                  canUpdateAppointments={canUpdateAppointments}
                  appointment={appointment}
                />
              )}
            </Grid>
          )}

          {searchResult.length > MAX_RESULTS_PER_PAGE && (
            <div className="pagination-container">
              <Pagination
                currentPage={currentPage}
                pageSize={MAX_RESULTS_PER_PAGE}
                totalPages={Math.ceil(searchResult.length / MAX_RESULTS_PER_PAGE)}
                totalItems={searchResult.length}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="action-buttons">
          <Button color="light" onClick={onRequestClose} type="button">
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>
          <Button
            color="green"
            form="new-intervention"
            type="button"
            onClick={handleAddTyre}
            disabled={tyreSearchMutation.isPending || isAddingTyre}
            loading={isAddingTyre}
          >
            {t("v8_add").message || "Add"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
