import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { DetailsCards, TabData, Tabs } from "components";
import { useScrollPageTop } from "hooks";
import "modules/CarDetails/CarDetails.scss";
import {
  AppointmentHistory,
  CarDrivingTrend,
  CarNotes,
  CustomerInfo,
  Details,
  ItemsNotRepaired,
  PinItems,
  SnoozedItems,
  TruckTyres,
  Tyres
} from "modules/CarDetails/components";
import { useCarDetails, useTabData } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

const CarDetails = () => {
  useScrollPageTop();
  const { id }: { id: string } = useParams();
  const t = useTranslation().t as ITranslation;
  const router = useHistory();
  const { car } = useCarDetails(Number(id));
  const { tabsData } = useTabData(Number(id));

  const [expanded, setExpanded] = useState<boolean>(true);
  const [isBackPressed, setIsBackPressed] = useState(false);

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const navigateBack = () => {
    setIsBackPressed(true);
    router.goBack();
  };

  useEffect(() => {
    return () => {
      setIsBackPressed(false);
    };
  }, []);

  return (
    <>
      <div className="carDetail-container">
        <div className="carDetail-NavigationHeader">
          <div className="carDetail-NavigationHeader-container">
            <div className="row">
              <Tabs data={tabsData} offset={-125} hideBorder />
              <div className="sub-row">
                <Button className="-appointment-status icon" size="small" onClick={toggleExpand}>
                  <Icon className={`arrows ${expanded ? "minimize" : "maximize"}`} color="green" />
                </Button>
                <Button className="-appointment-status" size="small" active loading={isBackPressed} disabled={isBackPressed} onClick={navigateBack}>
                  <Icon className="angle left" />
                  {t("v8_back").message || "Back"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Details car={car} tabData={tabsData.get("details")} car_id={Number(id)} />

        {tabsData.get("carNotes")?.data && <CarNotes tabData={tabsData.get("carNotes") as TabData} car={car} isExpanded={expanded} />}

        {tabsData.get("customerInfo")?.data && <CustomerInfo tabData={tabsData.get("customerInfo") as TabData} isExpanded={expanded} />}

        {tabsData.get("snoozedItems")?.data && <SnoozedItems tabData={tabsData.get("snoozedItems") as TabData} isExpanded={expanded} />}

        {tabsData.get("pinItems")?.data && <PinItems tabData={tabsData.get("pinItems") as TabData} isExpanded={expanded} />}

        {tabsData.get("itemsNotRepaired")?.data && <ItemsNotRepaired tabData={tabsData.get("itemsNotRepaired") as TabData} isExpanded={expanded} />}

        {tabsData.get("appointmentHistory")?.data && <AppointmentHistory tabData={tabsData.get("appointmentHistory") as TabData} isExpanded={expanded} />}

        {tabsData.get("tyres")?.id && car && (!!car.truck_tyres?.length || !!car.on_car_tyres || !!car.in_storage_tyres) && (
          <DetailsCards containerStyle="mt-20" isExpanded={expanded} id={tabsData.get("tyres")?.id} icon="tire" title={`${t("v8_tyres").message || "Tyres"}`}>
            {car.is_truck ? <TruckTyres data={car} /> : <Tyres car={car} lastAppointment={tabsData.get("appointmentHistory")?.data?.at(0)} />}
          </DetailsCards>
        )}

        {tabsData.get("appointmentHistory")?.data && tabsData.get("drivingTrend")?.id && (
          <CarDrivingTrend appointmentHistory={tabsData.get("appointmentHistory")?.data} tabData={tabsData.get("drivingTrend") as TabData} isExpanded={expanded} />
        )}
      </div>
    </>
  );
};

export default CarDetails;
