import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";

import { FormCheckbox, FormInput, FormTextArea } from "components";
import { Element } from "components/ReactScroll/index";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface OnlineCheckInProps {
  tabId: string;
}

export const OnlineCheckIn = ({ tabId }: OnlineCheckInProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_online_check_in").message || "ONLINE CHECK IN"}</h3>
      <div>
        <Form.Group className="mt-16">
          <Form.Field width={3}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="check_in_testing_mode" />
              </span>
              {t("v8_testing_mode").message || "Testing mode"}
            </p>
          </Form.Field>
          <Form.Field width={5}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <FormInput<CommunicationSettings> inputProps={{ placeholder: t("v8_email").message || "E-mail", fluid: true }} name="check_in_testing_email" />
          </Form.Field>
          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <FormInput<CommunicationSettings> inputProps={{ placeholder: t("v8_phone").message || "Phone", fluid: true, type: "Phone" }} name="check_in_testing_phone" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-16">
          <Form.Field width={8}>
            <label>{t("v8_welcome_text").message || "Welcome text"}</label>
            <FormTextArea<CommunicationSettings> name="check_in_welcome_text" textAreaProps={{ placeholder: `${t("v8_hello").message || "Hello"}!` }} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_success_text").message || "Success text"}</label>
            <FormTextArea<CommunicationSettings> name="check_in_success_text" textAreaProps={{ placeholder: `${t("v8_thank_you").message}` }} />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8} className="pr-12">
            <label>{t("v8_days_prior_to_appointment").message || "Send how many days prior to the appointment date"}</label>
            <FormInput<CommunicationSettings> inputProps={{ placeholder: "7", fluid: true, type: "number" }} name="check_in_days_prior" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-24">
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="is_sms_for_online_check_in_enabled" />
              </span>
              {t("v8_enable_sending_SMS").message || "Enable sending SMS"}
            </p>
          </Form.Field>

          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="checkin_remarks_enabled" />
              </span>
              {t("v8_enable_remarks").message || "Enable remarks"}
            </p>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="online_check_in_name_visible" />
              </span>
              {t("v8_ask_customer_enter_name").message || "Ask the Customer to enter his name"}
            </p>
          </Form.Field>
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="online_check_in_send_pin" />
              </span>
              {t("v8_key_locker_pin_for_checkin").message || "Send Key Locker PIN in Online check-in"}
            </p>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="block_automated_communication_for_lease_customer" />
              </span>
              {t("v8_online_checkin_lease_customer").message || "Do not send Online check-in or other automated mail to Lease customer"}
            </p>
          </Form.Field>
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="send_parking_gate_code" />
              </span>
              {t("v8_send_parking_gate_code").message || "Send parking gate code"}
            </p>
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};
