import { processField } from "modules/DealerReports/util/parser";
import { ReportConfig } from "modules/DealerReports/util/types";

const SNOOZE_CONFIG = {
  fields: [
    "location",
    "wo_count",
    "snooze_total",
    "no_department",
    "department_parts",
    "department_sales",
    "department_ccc",
    "department_bodyrepair",
    "department_external",
    "department_digital"
  ],
  calculations: {
    no_department: { totalField: "snooze_total" },
    department_parts: { totalField: "snooze_total" },
    department_sales: { totalField: "snooze_total" },
    department_ccc: { totalField: "snooze_total" },
    department_bodyrepair: { totalField: "snooze_total" },
    department_external: { totalField: "snooze_total" },
    department_digital: { totalField: "snooze_total" }
  },
  showTotals: true
};

export const REPORT_CONFIGS: Record<number, ReportConfig> = {
  1: {
    fields: [
      "location",
      "wo_count",
      "wo_geclaired",
      "geclaired_percentage",
      "check_count",
      "quality_checks_count",
      "quality_checks_percentage",
      "avg_qc_duration",
      "receptionist_check_count",
      "receptionist_check_percentage",
      "avg_receptionist_duration",
      "mechanic_check_count",
      "mechanic_check_percentage",
      "avg_mechanic_duration",
      "commercial_check_count",
      "commercial_check_percentage"
    ],
    calculations: {
      wo_geclaired: { totalField: "wo_count" },
      quality_checks_count: { totalField: "check_count" },
      receptionist_check_count: { totalField: "check_count" },
      mechanic_check_count: { totalField: "check_count" },
      commercial_check_count: { totalField: "check_count" }
    }
  },
  4: {
    fields: ["location", "total_cars", "recurring_cars", "percent_recurring_cars", "recurring_cars_not_worked_on"],
    calculations: {
      recurring_cars: { totalField: "total_cars" }
    }
  },
  5: {
    fields: [
      "location",
      "wo_count",
      "commercial_check_count",
      "avg_advice_point_count",
      "avg_advice_point_count_yes",
      "avg_critical_point_count",
      "avg_critical_point_count_yes",
      "avg_sold_items",
      "percent_sold_items",
      "avg_photo_taken",
      "avg_mechanic_time",
      "avg_receptionist_time"
    ]
  },
  7: {
    fields: [
      "location",
      "wo_count",
      "answered_desk_checkin_wo_percent",
      "online_checkin_wo_percent",
      "opened_online_checkin",
      "answered_online_checkin",
      "answered_checkin_wo_percent",
      "diagnose_overview_wo_percent",
      "opened_diagnose_overview",
      "answered_diagnose_overview",
      "repair_overview_wo_percent",
      "opened_repair_overview"
    ]
  },
  8: {
    fields: ["location", "services_count", "services_accepted"],
    dynamicFields: {
      pattern: "",
      customProcessor: (result, row, config) => {
        const orderedResult = new Map();

        config.fields.forEach(key => {
          orderedResult.set(key, result[key]);
        });

        const dynamicFields = Object.keys(row)
          .filter(key => !config.fields.includes(key) && key !== "Others")
          .sort((a, b) => a.localeCompare(b));

        dynamicFields.forEach(key => {
          orderedResult.set(key, {
            value: processField(row[key], key),
            params: {
              tag: key,
              translationKey: "tag_accepted"
            }
          });
        });

        if ("Others" in row) orderedResult.set("Others", processField(row.Others, "Others"));
        return Object.fromEntries(orderedResult);
      }
    },
    showTotals: false
  },
  10: {
    fields: [
      "location",
      "wo_count",
      "wo_geclaired",
      "wo_commercial_count",
      "check_count",
      "commercial_check_count",
      "avg_advice_point_count",
      "avg_advice_point_count_yes",
      "percent_advice_sold_items",
      "avg_critical_point_count",
      "avg_critical_point_count_yes",
      "percent_critical_sold_items",
      "percent_sold_items"
    ],
    calculations: {
      wo_geclaired: { totalField: "wo_count", colorType: "geclaired" },
      wo_commercial_count: { totalField: "wo_geclaired" },
      commercial_check_count: { totalField: "check_count" },
      avg_advice_point_count: {
        totalCalculation: totals => (totals.advice_point_count_no + totals.advice_point_count_yes) / totals.commercial_check_count
      },
      avg_advice_point_count_yes: {
        totalCalculation: totals => totals.advice_point_count_yes / totals.commercial_check_count
      },
      percent_advice_sold_items: {
        totalCalculation: totals => (totals.advice_point_count_yes / (totals.advice_point_count_no + totals.advice_point_count_yes)) * 100
      },
      avg_critical_point_count: {
        totalCalculation: totals => (totals.critical_point_count_no + totals.critical_point_count_yes) / totals.commercial_check_count
      },
      avg_critical_point_count_yes: {
        totalCalculation: totals => totals.critical_point_count_yes / totals.commercial_check_count
      },
      percent_critical_sold_items: {
        totalCalculation: totals => (totals.critical_point_count_yes / (totals.critical_point_count_no + totals.critical_point_count_yes)) * 100
      },
      percent_sold_items: {
        totalCalculation: totals => ((totals.advice_point_count_yes + totals.critical_point_count_yes) / totals.point_count) * 100
      }
    },
    showTotals: true
  },
  11: {
    fields: ["location", "wo_count", "canceled_count"],
    dynamicFields: {
      pattern: "cancel_reason_"
    }
  },
  12: {
    fields: [
      "location",
      "wo_count",
      "wo_lease_count",
      "wo_geclaired",
      "wo_commercial_count",
      "check_count",
      "commercial_check_count",
      "avg_advice_point_count",
      "avg_advice_point_count_yes",
      "percent_advice_sold_items",
      "avg_critical_point_count",
      "avg_critical_point_count_yes",
      "percent_critical_sold_items",
      "percent_sold_items"
    ],
    calculations: {
      wo_lease_count: { totalField: "wo_count" },
      wo_geclaired: { totalField: "wo_lease_count", colorType: "geclaired" },
      wo_commercial_count: { totalField: "wo_geclaired" },
      commercial_check_count: { totalField: "check_count" },
      avg_advice_point_count: {
        totalCalculation: totals => (totals.advice_point_count_no + totals.advice_point_count_yes) / totals.commercial_check_count
      },
      avg_advice_point_count_yes: {
        totalCalculation: totals => totals.advice_point_count_yes / totals.commercial_check_count
      },
      percent_advice_sold_items: {
        totalCalculation: totals => (totals.advice_point_count_yes / (totals.advice_point_count_no + totals.advice_point_count_yes)) * 100
      },
      avg_critical_point_count: {
        totalCalculation: totals => (totals.critical_point_count_no + totals.critical_point_count_yes) / totals.commercial_check_count
      },
      avg_critical_point_count_yes: {
        totalCalculation: totals => totals.critical_point_count_yes / totals.commercial_check_count
      },
      percent_critical_sold_items: {
        totalCalculation: totals => (totals.critical_point_count_yes / (totals.critical_point_count_no + totals.critical_point_count_yes)) * 100
      },
      percent_sold_items: {
        totalCalculation: totals => ((totals.advice_point_count_yes + totals.critical_point_count_yes) / totals.point_count) * 100
      }
    },
    showTotals: true
  },
  13: {
    fields: ["location", "wo_count", "snooze_total", "next_visit_customer", "make_offer", "make_new_appointment", "remind_customer"],
    calculations: {
      next_visit_customer: { totalField: "snooze_total" },
      make_offer: { totalField: "snooze_total" },
      make_new_appointment: { totalField: "snooze_total" },
      remind_customer: { totalField: "snooze_total" }
    },
    showTotals: true
  },
  14: SNOOZE_CONFIG,
  15: SNOOZE_CONFIG,
  16: SNOOZE_CONFIG,
  17: SNOOZE_CONFIG,
  18: {
    fields: ["location", "wo_count", "key_drop_check_in", "key_drop_phone_number", "key_drop_reg_number", "key_drop_for_appointment", "key_drop_back_for_appointment"]
  },
  20: {
    fields: [
      "location",
      "wo_count",
      "online_checkin_sms_sent",
      "online_checkin_sms_confirmation",
      "keylocker_checkin_sms_sent",
      "diagnose_overview_sms_sent",
      "diagnose_overview_sms_confirmation",
      "repair_overview_sms_sent"
    ]
  },
  22: {
    fields: ["location", "offered", "mounted"]
  },
  23: {
    fields: ["location", "offered", "ordered", "mounted"]
  },
  24: {
    fields: [
      "user_location",
      "user_name",
      "user_role",
      "wo_location",
      "wo_handled",
      "check_aanname",
      "check_commercial",
      "checks_found_items",
      "check_media",
      "check_not_commercial",
      "check_extra_parts",
      "check_warranty",
      "check_count",
      "status_calculating",
      "status_pricing_ok",
      "status_info",
      "status_customer_ok",
      "status_car_ready",
      "status_quality",
      "cc_extra_services",
      "cc_diagnose_overview",
      "cc_repair_overview",
      "dc_checkin",
      "dc_checkout"
    ]
  },
  25: {
    fields: ["location", "wo_count", "started", "completed", "remarks"]
  }
};
