import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form, Grid, Icon, Input, Loader, Modal, Popup } from "semantic-ui-react";

import { Appointment, QuestionResult } from "models";
import { TYRE_TEAM_ORDER_STATUS, TyreLocationData } from "modules/AppointmentDetails/components";
import { useTyreReplacementOrder } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/hooks";
import { getTyreSeasonIcon } from "util/common";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

interface ReplacementOrderModalProps {
  data: QuestionResult | null;
  open: boolean;
  appointment: Appointment;
  tireData: TyreLocationData;
  onClose: () => void;
}

export const ReplacementOrderModal = ({ data, open, appointment, tireData, onClose }: ReplacementOrderModalProps) => {
  const t = useTranslation().t as ITranslation;

  const {
    loading,
    ref5,
    ref6,
    replacementToOrder,
    handleInputChange,
    handleQuantityChange,
    handlePlaceOrders,
    getDeliveryChannel,
    getDelivery,
    getQuantityOptions,
    fetchStockMutation
  } = useTyreReplacementOrder({
    data,
    appointment,
    tireData,
    onClose,
    open
  });

  const renderStatus = (status: TYRE_TEAM_ORDER_STATUS) => {
    switch (status) {
      case TYRE_TEAM_ORDER_STATUS.Pending:
        return <Loader active inline size="mini" />;
      case TYRE_TEAM_ORDER_STATUS.Fulfilled:
        return <Icon className="check" color="green" />;
      case TYRE_TEAM_ORDER_STATUS.Rejected:
        return <Icon className="close" color="red" />;
      default:
        return null;
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handlePlaceOrders();
  };

  const renderDeliveryPopup = (delivery: string) => {
    const type = getDelivery(delivery);
    if (!type) return null;

    return <Popup content={type.content} trigger={<span>{type.trigger}</span>} />;
  };

  const isOrderButtonDisabled =
    loading ||
    replacementToOrder.every(item => (item.quantity || 0) < 1) ||
    replacementToOrder.some(item => {
      const quantity = item.quantity || 0;
      if (quantity === 0) return false;
      if (item.stock?.includes(">")) return false;
      return quantity > Number(item.stock || 0);
    });

  return (
    <Modal className="TyreReplacementModal" closeOnEscape size="fullscreen" open={open} onClose={onClose} closeOnDimmerClick={true}>
      <Modal.Header>
        {t("v8_place_order").message || "Place Order"}

        <Button className="-modal-x-button" size="small" onClick={onClose}>
          <Icon className="xmark grey" size="big" />
        </Button>
      </Modal.Header>
      <Modal.Content scrolling className="modal-content-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field width={8}>
              <Input
                name="ref5"
                value={ref5}
                onChange={handleInputChange}
                icon={<Icon className="pencil square order-ref-icon" />}
                iconPosition="left"
                placeholder={t("v8_packing_slip_reference").message || "Packing slip / Invoice reference"}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Input
                name="ref6"
                value={ref6}
                onChange={handleInputChange}
                icon={<Icon className="pencil square order-ref-icon" />}
                iconPosition="left"
                placeholder={t("v8_packing_slip_reference").message || "Packing slip / Invoice reference"}
              />
            </Form.Field>
          </Form.Group>

          <Grid>
            {fetchStockMutation.isPending && (
              <div className="tyre-team-list-loading">
                <Loader active inline size="large">
                  {t("v8_loading_tyres").message || "Loading tyres"}...
                </Loader>
              </div>
            )}

            {!fetchStockMutation.isPending && (
              <div className="tyre-team-list">
                <div className="tyre-row header">
                  <div className="tyre-col season"></div>
                  <div className="tyre-col description">{t("v8_description").message || "Description"}</div>
                  <div className="tyre-col brand">{t("v8_brand").message || "Brand"}</div>
                  <div className="tyre-col artean">{"Art / EAN"} #</div>
                  <div className="tyre-col gross">{t("v8_gross").message || "Gross"} €</div>
                  <div className="tyre-col neto">{t("v8_neto").message || "Net"} €</div>
                  <div className="tyre-col load">{t("v8_load").message || "Load"}</div>
                  <div className="tyre-col speed">{t("v8_speed").message || "Speed"}</div>
                  <div className="tyre-col flat-tire">{t("v8_runflat").message || "Runflat"}</div>
                  <div className="tyre-col channel">{t("v8_channel").message || "Channel"}</div>
                  <div className="tyre-col delivery">{t("v8_delivery").message || "Delivery"}</div>
                  <div className="tyre-col stock">{t("v8_stock").message || "Stock"}</div>
                  <div className="tyre-col quantity">{t("v8_quantity").message || "Quantity"}</div>
                  <div className="tyre-col status">{t("v8_status").message || "Status"}</div>
                </div>

                {replacementToOrder.map((item, index) => (
                  <div className="tyre-row tyre-order-row" key={index}>
                    <div className="tyre-col season">
                      <Icon className={getTyreSeasonIcon(Number(item.tyre?.season))} />
                    </div>
                    <div className="tyre-col description">
                      <Popup content={item.tyre?.description} trigger={<div className="truncated-text">{item.tyre?.description}</div>} />
                    </div>
                    <div className="tyre-col brand">{item.tyre?.manufacturer}</div>
                    <div className="tyre-col artean">
                      {item.tyre_team_system_number}
                      {item.tyre_team_system_number && item.tyre?.ean && " / "}
                      {item.tyre?.ean && <Popup hoverable trigger={<span className="replacement-tyre-ean">{item.tyre.ean}</span>} content={item.tyre.ean} />}
                    </div>
                    <div className="tyre-col gross">{item.stock && item.gross_price ? formatPrice(item.gross_price) : "0.0"}</div>
                    <div className="tyre-col neto">{item.stock && item.net_price ? formatPrice(item.net_price) : "0.0"}</div>
                    <div className="tyre-col load">{item.tyre?.carry_capacity}</div>
                    <div className="tyre-col speed">{item.tyre?.speed}</div>
                    <div className="tyre-col flat-tire">{item.tyre?.run_flat_tire ? <Icon name="check circle" color="green" /> : ""}</div>
                    <div className="tyre-col channel">{getDeliveryChannel(item.tyre_team_channel)}</div>
                    <div className="tyre-col delivery">{item.tyre_team_delivery && renderDeliveryPopup(item.tyre_team_delivery)}</div>
                    <div className="tyre-col stock">{item.stock || 0}</div>
                    <div className="tyre-col quantity">
                      <Dropdown
                        disabled={loading}
                        options={getQuantityOptions(item.initial_quantity || 0)}
                        value={item.quantity || 0}
                        onChange={(_, data) => handleQuantityChange(item.tyre?.ean || "", item.tyre_team_delivery || "", Number(data.value))}
                        selection
                        fluid
                        className={item.quantity > 0 && (item.stock?.includes(">") ? false : item.quantity > Number(item.stock || 0)) ? "invalid-quantity" : ""}
                      />
                    </div>
                    <div className="tyre-col status">{renderStatus(item.status as TYRE_TEAM_ORDER_STATUS)}</div>
                  </div>
                ))}
              </div>
            )}
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" type="button" disabled={isOrderButtonDisabled} loading={loading} onClick={handlePlaceOrders}>
          {t("v8_order").message || "Order"}
          <Icon className="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
