import { PATHS } from "router/paths";

export type DocumentationSupportedLanguages = "en" | "nl" | "fr";
export enum SUPPORT_PAGES {
  DealerReportSupport = PATHS.DEALER_REPORTS,
  LocationReportSupport = PATHS.LOCATION_REPORTS,
  LeadsSupport = PATHS.LEADS_DASHBOARD,
  WarrantySupport = PATHS.WARRANTY_DASHBOARD,
  ManufacturerDashboard = PATHS.MANUFACTURER_DASHBOARD,
  BackordersSupport = PATHS.BACKORDERS_DASHBOARD
}

type DocumentationLink = {
  [K in DocumentationSupportedLanguages]: string;
};

type DocumentationLinks = {
  [K in SUPPORT_PAGES]: DocumentationLink;
};

const documentationLinks: DocumentationLinks = {
  [SUPPORT_PAGES.DealerReportSupport]: {
    en: "https://support.claireit.eu/portal/en/kb/articles/article-17-1-2020",
    nl: "https://support.claireit.eu/portal/en/kb/articles/dealer-rapport",
    fr: "https://support.claireit.eu/portal/en/kb/articles/rapports-concessionnaire"
  },
  [SUPPORT_PAGES.LocationReportSupport]: {
    en: "https://support.claireit.eu/portal/en/kb/articles/analysing-new-reports-for-single-location",
    nl: "https://support.claireit.eu/portal/en/kb/articles/locatie-rapporten",
    fr: "https://support.claireit.eu/portal/en/kb/articles/rapports-atelier"
  },
  [SUPPORT_PAGES.LeadsSupport]: {
    en: "https://support.claireit.eu/portal/en/kb/articles/optimizing-the-leads-feature",
    nl: "https://support.claireit.eu/portal/en/kb/articles/optimaliseren-van-de-leads-functie",
    fr: "https://support.claireit.eu/portal/en/kb/articles/optimisation-de-la-fonction-leads"
  },
  [SUPPORT_PAGES.WarrantySupport]: {
    en: "https://support.claireit.eu/portal/en/kb/articles/warranty-dashboard-v8",
    nl: "https://support.claireit.eu/portal/en/kb/articles/garantie-dashboard-v8",
    fr: "https://support.claireit.eu/portal/en/kb/articles/utilisation-du-tableau-de-bord-garantie"
  },
  [SUPPORT_PAGES.ManufacturerDashboard]: {
    en: "https://support.claireit.eu/portal/en/kb/articles/manufacturer-dashboard-v8",
    nl: "https://support.claireit.eu/portal/en/kb/articles/fabrikant-dashboard-v8",
    fr: ""
  },
  [SUPPORT_PAGES.BackordersSupport]: {
    en: "https://support.claireit.eu/portal/en/kb/articles/backorders-dashboard",
    nl: "https://support.claireit.eu/portal/en/kb/articles/backorder-dashboard",
    fr: "https://support.claireit.eu/portal/en/kb/articles/tableau-de-bord-backorders"
  }
};

export default documentationLinks;
