import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Check, QuestionResult, TyreDiscount, TyreReplacement } from "models";
import { Delivery, TyreData } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/components";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export type TyreSearch = {
  height: number | null;
  id: number;
  manufacturer: string | null;
  season: number | null;
  size: number | null;
  width: number | null;
  in_stock?: boolean;
  tyre_team_delivery_ids?: string[];
};

type AddTyreReplacementResponse = {
  tyre_replacement_id: number;
};

// TODO: cleanup like other hooks
const useTyre = (appointment_id: number) => {
  const checklistQueryKey = AppointmentDetailsKeys.checks(appointment_id);
  const { selectedLocation, selectedDealer } = useDealersLocations();
  const queryClient = useQueryClient();

  const [isAddingTyre, setIsAddingTyre] = useState(false);

  const getBrands = async () => {
    const res = await ApiInstance.get((queryKeys.appointments.tyreBrands as unknown as BackendQueryKey).endpoint, ENV.carBaseUrl);
    return res.data.brands;
  };

  const getDelivery = async () => {
    const res = await ApiInstance.post(
      (queryKeys.appointments.deliveryTypes as unknown as BackendQueryKey).endpoint,
      { dealer_location_id: selectedLocation?.id },
      (queryKeys.appointments.deliveryTypes as unknown as BackendQueryKey).baseUrl
    );
    return res.data.deliveries as Delivery[];
  };

  const getDiscounts = async () => {
    const response = await ApiInstance.post("/discounts/list_discounts", { dealer_id: selectedDealer?.id }, ENV.tyreteamBaseURL);
    return response.data as TyreDiscount[];
  };

  const brandsQuery = useQuery({
    queryKey: [{ ...queryKeys.appointments.tyreBrands }],
    queryFn: getBrands,
    retry: false
  });

  const deliveryQuery = useQuery({
    queryKey: [{ ...queryKeys.appointments.deliveryTypes, params: { dealer_location_id: selectedLocation?.id } }],
    queryFn: getDelivery,
    retry: false,
    enabled: Boolean(selectedLocation?.id && selectedLocation.is_tyre_team_enabled)
  });

  const discountsQuery = useQuery({
    queryKey: [{ ...queryKeys.appointments.tyreDiscounts, params: { dealer_id: selectedDealer?.id } }],
    queryFn: getDiscounts,
    enabled: Boolean(selectedLocation?.is_tyre_team_enabled && selectedDealer?.id)
  });

  const searchTyreTeamTyres = async (data: TyreSearch) => {
    const res = await ApiInstance.post(
      (queryKeys.appointments.tyreSearch as unknown as BackendQueryKey).endpoint,
      data,
      (queryKeys.appointments.tyreSearch as unknown as BackendQueryKey).baseUrl
    );
    return res.data.tyres;
  };

  const searchTyres = async (data: TyreSearch) => {
    const res = await ApiInstance.post("/tyres/search", data, ENV.carBaseUrl);
    return res.data.tyres;
  };

  const tyreSearchMutation = useMutation({
    mutationFn: selectedLocation?.is_tyre_team_enabled ? searchTyreTeamTyres : searchTyres,
    onError: e => toast.error(e.message)
  });

  const addTyre = async (data: TyreData) => {
    const res = await ApiInstance.post("/tyres/replacements/add", data, ENV.carBaseUrl);
    return res.data as AddTyreReplacementResponse;
  };

  const addTyreTeamTyre = async (data: TyreData) => {
    const res = await ApiInstance.post("/replacements/replacements/add", { ...data, tyre_id: data.tyre_team.id }, ENV.tyreteamBaseURL);
    return res.data as AddTyreReplacementResponse;
  };

  const addTyreMutation = useMutation({
    mutationFn: selectedLocation?.is_tyre_team_enabled ? addTyreTeamTyre : addTyre,
    onError: e => toast.error(e.message)
  });

  const addTyres = async (selection: Record<string, TyreData>) => {
    setIsAddingTyre(true);
    return new Promise<void>((resolve, reject) => {
      if (!selection || Object.keys(selection).length === 0) {
        resolve();
        return;
      }

      const promises: unknown[] = [];

      Object.values(selection).forEach(item => {
        promises.push(addTyreMutation.mutateAsync(item));
      });

      Promise.all(promises)
        .then(async () => {
          await queryClient.invalidateQueries({ queryKey: checklistQueryKey });
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          setIsAddingTyre(false);
        });
    });
  };

  const deleteTyreReplacementFromChecklist = (data: TyreReplacement) => {
    const checklistSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);
    if (!checklistSnapshot) return { checklistSnapshot };

    const shouldRemoveReplacement = (replacement: TyreReplacement) => replacement.tyre_replacement_id === data.tyre_replacement_id;

    const updatedSnapshot = checklistSnapshot.map(check => {
      if (!check.question_items) return check;

      const updatedQuestionItems = check.question_items.map(question => {
        if (!question.tyre_replacements) return question;

        const filteredReplacements = question.tyre_replacements.filter(replacement => !shouldRemoveReplacement(replacement));
        return { ...question, tyre_replacements: filteredReplacements };
      });

      return { ...check, question_items: updatedQuestionItems };
    });

    queryClient.setQueryData(checklistQueryKey, updatedSnapshot);
    return { checklistSnapshot };
  };

  const updateTyreReplacementRemark = async (data: TyreReplacement) => {
    await ApiInstance.post("/tyres/replacements/update", data, ENV.carBaseUrl);
  };

  const updateTyreReplacementRemarkFromChecklist = (data: TyreReplacement) => {
    const checklistSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);
    if (!checklistSnapshot) return { checklistSnapshot };

    const shouldUpdateRemark = (replacement: TyreReplacement) => replacement.tyre_replacement_id === data.tyre_replacement_id;
    const updateReplacementRemark = (replacement: TyreReplacement) => (shouldUpdateRemark(replacement) ? { ...replacement, remark: data.remark } : replacement);

    const updatedSnapshot = checklistSnapshot.map(check => {
      if (!check.question_items) return check;

      const updatedQuestionItems = check.question_items.map(question => {
        if (!question.tyre_replacements) return question;

        const updatedReplacements = question.tyre_replacements.map(updateReplacementRemark);
        return { ...question, tyre_replacements: updatedReplacements };
      });

      return { ...check, question_items: updatedQuestionItems };
    });

    queryClient.setQueryData(checklistQueryKey, updatedSnapshot);
    return { checklistSnapshot };
  };

  const tyreReplacementRemarkMutation = useMutation({
    mutationFn: updateTyreReplacementRemark,
    onMutate: updateTyreReplacementRemarkFromChecklist,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.checklistSnapshot) {
        queryClient.setQueryData(checklistQueryKey, context.checklistSnapshot);
      }
    }
  });

  const deleteReplacement = async (data: TyreReplacement) => {
    const res = await ApiInstance.post("/tyres/replacements/delete", { tyre_replacement_id: data.tyre_replacement_id }, ENV.carBaseUrl);
    return res.data;
  };

  const tyreReplacementDeleteMutation = useMutation({
    mutationFn: deleteReplacement,
    onMutate: deleteTyreReplacementFromChecklist,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.checklistSnapshot) {
        queryClient.setQueryData(checklistQueryKey, context.checklistSnapshot);
      }
    }
  });

  const updateTyreRemark = async (data: QuestionResult) => {
    const res = await ApiInstance.post("/checks/update_question_item", {
      appointment_id: data.appointment_id,
      dealer_location_id: data.dealer_location_id,
      id: data.id,
      raw: data.raw
    });
    return res.data;
  };

  const updateTyreRemarkFromChecklist = (data: QuestionResult) => {
    const checklistSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);

    if (checklistSnapshot) {
      const updatedSnapshot = checklistSnapshot.map(item => {
        if (item.question_items) {
          const updatedQuestionItems = item.question_items.map(question => {
            if (question.id === data.id) {
              return data;
            }
            return question;
          });
          return { ...item, question_items: updatedQuestionItems };
        }
        return item;
      });

      queryClient.setQueryData(checklistQueryKey, updatedSnapshot);
    }

    return { checklistSnapshot };
  };

  const tyreRemarkMutation = useMutation({
    mutationFn: updateTyreRemark,
    onMutate: updateTyreRemarkFromChecklist,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.checklistSnapshot) {
        queryClient.setQueryData(checklistQueryKey, context.checklistSnapshot);
      }
    }
  });

  return {
    brandsQuery,
    deliveryQuery,
    tyreSearchMutation,
    addTyres,
    isAddingTyre,
    tyreReplacementDeleteMutation,
    tyreReplacementRemarkMutation,
    tyreRemarkMutation,
    discountsQuery
  };
};

export default useTyre;
