import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { DATE_FORMATS_FNS, DetailsCards, ReactTable, TabData } from "components";
import { CriticalHistory, QUESTION_STATUSES } from "models";
import "modules/CarDetails/components/ItemsNotRepaired/ItemsNotRepaired.scss";
import { formatDate, toDate } from "util/dateHelperFunctions";
import { formatPrice } from "util/formatPrice";
import { ITranslation } from "util/interfaces";

type ItemsNotRepairedProps = {
  tabData: TabData;
  isExpanded: boolean;
};

export const ItemsNotRepaired = ({ tabData, isExpanded }: ItemsNotRepairedProps) => {
  const t = useTranslation().t as ITranslation;

  const columnHelper = createColumnHelper<CriticalHistory>();
  const columns = [
    columnHelper.accessor(row => row.created_on, {
      id: "date",
      header: t("v8_appointment_date").message || "Appointment Date",
      cell: info => {
        const value = info.renderValue();
        const formattedDate = value !== null ? formatDate(toDate(value), DATE_FORMATS_FNS.dateMonthYear) : null;
        return formattedDate;
      },
      size: 185
    }),
    columnHelper.accessor(row => row.wo_nr, {
      id: "workOrder",
      header: "WO #",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.title, {
      id: "item",
      header: t("v8_item").message || "Item",
      size: 200,
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium">{info.renderValue()}</p>} />
    }),
    columnHelper.accessor(row => row.raw, {
      id: "remark",
      header: t("v8_remarks").message || "Remarks",
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium">{info.renderValue()}</p>} />
    }),
    columnHelper.accessor(row => row.price, {
      id: "excl",
      header: t("v8_excl_vat").message || "Excl.VAT",
      cell: info => formatPrice(info.renderValue())
    }),
    columnHelper.accessor(row => row.price, {
      id: "incl",
      header: t("v8_incl_vat").message || "Incl.VAT",
      cell: info => formatPrice(info.renderValue())
    }),
    columnHelper.accessor(row => row, {
      id: "icons",
      header: "",
      size: 300,
      cell: info => {
        const data = info.renderValue() as CriticalHistory;
        return (
          <div>
            {data.pinned && (
              <Button className="wdRed af" color="orange">
                <Icon className="shield halved" />
              </Button>
            )}
            {data.snoozed && (
              <Button className="wdSkyblue af" color="orange">
                <Icon className="clock" />
              </Button>
            )}
            <div className="item-buttons">
              {data.status === QUESTION_STATUSES.Advice && <Icon className="triangle exclamation i-yellow" />}
              {data.status === QUESTION_STATUSES.Necessary && <Icon className="triangle exclamation i-maroon" />}
              <div>
                <Icon className={`user check i-${data.customer_approved ? "green" : "grey"}`} />
              </div>
            </div>
          </div>
        );
      }
    })
  ];

  return (
    <DetailsCards
      containerStyle="mt-20"
      isExpanded={isExpanded}
      id={tabData.id}
      icon="clock rotate left"
      title={`${t("v8_items_not_repaired").message || "Items not Repaired"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
    >
      <div className="ItemsNotRepaired">
        <ReactTable columns={columns} data={tabData.data} />
      </div>
    </DetailsCards>
  );
};
