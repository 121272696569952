import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import zxcvbn from "zxcvbn";

import { useAuth } from "hooks";
import { useResetPasswordUser } from "modules/Auth/components/ResetPassword/hooks/useResetPasswordUser";
import "modules/Auth/components/ResetPassword/ResetPassword.scss";
// import { ResetPasswordProps } from "modules/Auth/interfaces";
import { ITranslation } from "util/interfaces";

export const ResetPassword = () => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<number | null>(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");

  const t = useTranslation().t as ITranslation;
  const { resetPasswordMutation } = useAuth();
  const { isPending: isLoading, error: resetPasswordError } = resetPasswordMutation;

  const location = useLocation();

  const resetHash = new URLSearchParams(location.search).get("hash");

  const { userData } = useResetPasswordUser(resetHash);

  useEffect(() => {
    setErrorMsg("");
  }, [newPassword, newPasswordConfirmation]);

  useEffect(() => {
    if (resetPasswordError) {
      setErrorMsg((resetPasswordError as AxiosError<any>).response?.data.errors.join(" "));
    }
  }, [resetPasswordError]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);

      const { score } = zxcvbn(value, ["claire", "auto", userData?.email || "", userData?.first_name || "", userData?.last_name || "", userData?.username || ""]);
      setPasswordStrength(score);
    } else {
      setNewPasswordConfirmation(value);
    }
  };

  const handleResetPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!resetHash) return;

    if (newPassword !== newPasswordConfirmation) {
      setErrorMsg(t("v8_passwords_do_not_match").message || "Passwords do not match");
      return;
    }

    if (!passwordStrength || passwordStrength < 4) {
      setErrorMsg(t("v8_choose_stronger_password").message || "Please choose a stronger password");
      return;
    }

    const data = await resetPasswordMutation.mutateAsync({ hash: resetHash, new_password: newPassword, new_password_confirm: newPasswordConfirmation });
    if (data) {
      setSuccessMsg(t("v8_password_successfully_reset").message || "Your password was successfully reset!");
      setNewPassword("");
      setNewPasswordConfirmation("");
      setPasswordStrength(null);
    } else {
      setErrorMsg(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
      return;
    }
  };

  return (
    <div className="ResetPassword">
      <div className="ResetPassword-header">
        <p>{t("v8_reset_password").message || "Reset Password"}</p>
        <Link to={`/login`} onClick={() => setSuccessMsg("")} className="ResetPassword-backBtn">
          <Icon className="angle left" />
          {t("v8_back_to_login").message || "Back to Login"}
        </Link>
      </div>
      <div className="mt-15">
        {!successMsg ? (
          <p>
            {t("v8_reset_password_description").message ||
              "Your new password must be secure. A secure password cannot contain your first name, last name, email, Claire’ or common words like ‘password’ You don’t need to use any special characters. A secure password contains two or more uncommon words."}
          </p>
        ) : (
          <div className="ResetPassword-success">{successMsg}</div>
        )}
        <Form onSubmit={handleResetPasswordSubmit}>
          {passwordStrength !== null && (
            <div className="strength-meter">
              <div className="strength-meter-fill" data-strength={passwordStrength}></div>
            </div>
          )}
          <Form.Field className="password" error={!!errorMsg}>
            <Input
              name="newPassword"
              id="newPassword"
              value={newPassword}
              onChange={handlePasswordChange}
              icon={`key ${errorMsg ? "" : "green"}`}
              iconPosition="left"
              type={showPassword ? "text" : "password"}
              placeholder={t("v8_new_password").message || "New Password"}
              required
              error={!!errorMsg}
            />
            <Icon
              className={showPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
            />
          </Form.Field>

          <Form.Field className="password" error={!!errorMsg}>
            <Input
              name="newPasswordConfirmation"
              id="newPasswordConfirmation"
              value={newPasswordConfirmation}
              onChange={handlePasswordChange}
              icon={`key ${errorMsg ? "" : "green"}`}
              iconPosition="left"
              type={showConfirmPassword ? "text" : "password"}
              placeholder={t("v8_confirm_new_password").message || "Confirm New Password"}
              required
              error={!!errorMsg}
            />
            <Icon
              className={showConfirmPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
              onMouseDown={() => setShowConfirmPassword(true)}
              onMouseUp={() => setShowConfirmPassword(false)}
            />
          </Form.Field>
          <div className="mt-25">
            <Button disabled={isLoading} loading={isLoading} type="submit" id="submit-reset-password" color="green" fluid>
              {t("v8_reset_password").message || "Reset Password"}
            </Button>
          </div>
        </Form>
      </div>

      {errorMsg && (
        <Form>
          <div className="mt-15">
            <Form.Field error>
              <Input value={errorMsg} readOnly />
            </Form.Field>
          </div>
        </Form>
      )}
    </div>
  );
};
