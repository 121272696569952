import { Label, Loader } from "semantic-ui-react";

import { AppointmentSearchOption, AppointmentStatusCell, DATE_FORMATS_FNS, SEARCH_OPTION_TYPE } from "components";
import "components/NavBarSearchAppointments/components/SearchDropdown/SearchDropdown.scss";
import { formatDate } from "util/dateHelperFunctions";

type SearchResultRowProps = {
  selectedId: string | null;
  isPending: boolean;
  item: AppointmentSearchOption;
  handleOnClick: (id: string, type: SEARCH_OPTION_TYPE) => void;
};

export const SearchResultRow = ({ item, handleOnClick, isPending, selectedId }: SearchResultRowProps) => {
  const date = item.due_in ? `${formatDate(item.due_in, DATE_FORMATS_FNS.dateMonthYear)} | ` : "";
  const woNr = item.wo_nr ? `${item.wo_nr} | ` : "";
  const regNr = item.reg_number ? `${item.reg_number} | ` : "";
  const showLoader = isPending && selectedId === item.id;

  return (
    <div key={item.id} className="search-list" onClick={() => handleOnClick(item.id, item.type)}>
      {item.type !== SEARCH_OPTION_TYPE.Claire && (
        <Label>
          {item.type === SEARCH_OPTION_TYPE.Keyloop && <span>Keyloop</span>}
          {item.type === SEARCH_OPTION_TYPE.Nextlane && <span>NextLane</span>}
          {item.type === SEARCH_OPTION_TYPE.Wincar && <span>WinCar</span>}
        </Label>
      )}

      <p className="search-title">{`${item.car_make} ${item.car_model}`.trim()}</p>

      <div className="search-row">
        <p>{`${date}${woNr}${regNr} ${item.dealer_location?.name || ""}`}</p>
        {showLoader ? (
          <Loader active={isPending} inline size="small" />
        ) : (
          <AppointmentStatusCell
            value={{
              id: item.appointment_status_identifier,
              customcom_status: item.customcom_status,
              check_paused_at: item.check_paused_at,
              car_out_of_shop: item.car_out_of_shop
            }}
          />
        )}
      </div>
    </div>
  );
};
