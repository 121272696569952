import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ColorKeys } from "components";
import { useDealersLocations, useUser } from "hooks";
import { IndicatorFilters } from "modules/Appointments/components";
import { getPreference, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";
import { queryKeys } from "util/keyFactory";

interface SpecialIndicatorQueryTypes {
  is_star?: string;
  is_money?: string;
  is_shop?: string;
  is_shop_green?: string;
  is_shop_orange?: string;
  is_shop_blue?: string;
  is_shop_yellow?: string;
  is_recurring?: string;
  is_count?: string;
  is_shop_red?: string;
  pin_count?: string;
}

const PREFERENCE_KEY_CART_FILTERS = "preferences-cart-filters";
const PREFERENCE_KEY_SPECIAL_INDICATORS = "preferences-special-indicators";

type IndicatorsPreferenceKeys = typeof PREFERENCE_KEY_CART_FILTERS | typeof PREFERENCE_KEY_SPECIAL_INDICATORS;

const indicatorsFiltrationLogic = (current: IndicatorFilters[], index: number, preferenceKey: IndicatorsPreferenceKeys) => {
  const updatedFilters = current.map((filter, idx) => (idx === index ? { ...filter, active: !filter.active } : filter));
  const preferenceValues = updatedFilters.map(({ filter, active }) => ({ filter, active }));
  setPreference(preferenceKey, preferenceValues);

  return updatedFilters;
};

const initializeIdentifiers = (defaultValues: IndicatorFilters[], preferenceKey: IndicatorsPreferenceKeys) => {
  const preferenceValues: Partial<IndicatorFilters[]> | null = getPreference(preferenceKey, null);
  if (!preferenceValues) return defaultValues;

  return defaultValues.map(defaultValue => {
    const matchingPreferenceValue = preferenceValues.find(preference => preference?.filter === defaultValue.filter);
    return { ...defaultValue, active: Boolean(matchingPreferenceValue?.active) };
  });
};

export const useGetSpecialIndicatorsInitialState = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;
  const specialIndicatorsQueryData: SpecialIndicatorQueryTypes = queryClient.getQueryData([{ ...queryKeys.appointments.specialIndicators }])!;

  const defaultCartVisible = !(
    user?.shop_status_same_day_filter_enabled ||
    user?.shop_status_next_day_filter_enabled ||
    user?.shop_status_to_order_filter_enabled ||
    user?.shop_status_backorder_filter_enabled ||
    user?.shop_status_arrived_parts_filter_enabled
  );

  const specialIndicators: IndicatorFilters[] = [
    { icon: "is_money", active: !!specialIndicatorsQueryData?.is_money, filter: "is_money" },
    { icon: "is_star", active: !!specialIndicatorsQueryData?.is_star, filter: "is_star" },
    ...(selectedLocation?.pin_visible ? [{ icon: "pin" as ColorKeys, active: !!specialIndicatorsQueryData?.pin_count, filter: "pin_count" }] : []),
    { icon: "triangle exclamation", active: !!specialIndicatorsQueryData?.is_recurring, filter: "is_recurring" }
  ];

  const cartIndicators: IndicatorFilters[] = [
    ...(defaultCartVisible ? [{ icon: "is_shop" as ColorKeys, active: !!specialIndicatorsQueryData?.is_shop, filter: "is_shop" }] : []),
    ...(user?.shop_status_same_day_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_green,
            filter: "is_shop_green",
            borderColor: "green",
            text: t("v8_shopping_cart_same_day").message || "Same day"
          }
        ]
      : []),
    ...(user?.shop_status_next_day_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_orange,
            filter: "is_shop_orange",
            borderColor: "orange",
            text: t("v8_shopping_cart_tomorrow").message || "Tomorrow"
          }
        ]
      : []),
    ...(user?.shop_status_to_order_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_blue,
            filter: "is_shop_blue",
            borderColor: "blue",
            text: t("v8_shopping_cart_to_order").message || "To order"
          }
        ]
      : []),
    ...(user?.shop_status_backorder_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_red,
            filter: "is_shop_red",
            borderColor: "red",
            text: t("v8_shopping_cart_backorder").message || "Backorder"
          }
        ]
      : []),
    ...(user?.shop_status_arrived_parts_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_yellow,
            filter: "is_shop_yellow",
            borderColor: "yellow",
            text: t("v8_arrived").message || "Arrived"
          }
        ]
      : [])
  ];

  const [indicatorFilters, setIndicatorFilters] = useState<IndicatorFilters[]>(initializeIdentifiers(specialIndicators, PREFERENCE_KEY_SPECIAL_INDICATORS));
  const [cartFilters, setCartFilters] = useState<IndicatorFilters[]>(initializeIdentifiers(cartIndicators, PREFERENCE_KEY_CART_FILTERS));

  const updateIndicatorsQueryData = useCallback(() => {
    const indicatorFiltersObj: Record<string, string> = {};
    [...cartFilters, ...indicatorFilters].forEach(item => {
      if (item.active && item.filter) {
        indicatorFiltersObj[item.filter] = item.filter;
      }
    });

    const currentFilterData = queryClient.getQueryData([queryKeys.appointments.specialIndicators]);
    if (JSON.stringify(currentFilterData) === JSON.stringify(indicatorFiltersObj)) return;
    queryClient.setQueryData([queryKeys.appointments.specialIndicators], indicatorFiltersObj);
  }, [cartFilters, indicatorFilters]);

  useEffect(() => {
    updateIndicatorsQueryData();
  }, [updateIndicatorsQueryData]);

  const handleIndicatorFilters = (index: number) => {
    setIndicatorFilters(current => indicatorsFiltrationLogic(current, index, PREFERENCE_KEY_SPECIAL_INDICATORS));
  };

  const handleCartFilters = (index: number) => {
    setCartFilters(current => indicatorsFiltrationLogic(current, index, PREFERENCE_KEY_CART_FILTERS));
  };

  const handleSelectAllCarts = () => {
    if (cartFilters.every(e => e.active)) return setCartFilters(curr => curr.map(cart => ({ ...cart, active: false })));
    setCartFilters(current => current.map(cart => ({ ...cart, active: true })));
  };

  const handleResetSpecialIndicatorFilters = () => {
    setPreference(PREFERENCE_KEY_CART_FILTERS, null);
    setPreference(PREFERENCE_KEY_SPECIAL_INDICATORS, null);
  };

  return { indicatorFilters, handleIndicatorFilters, cartFilters, handleCartFilters, handleSelectAllCarts, handleResetSpecialIndicatorFilters };
};
