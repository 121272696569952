import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { PonRecallResponseModel } from "components/PonRecall/types";
import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import ApiInstance from "util/Api";

export const usePonRecall = (carId: number) => {
  const { selectedLocation } = useDealersLocations();
  const [ponRecallData, setPonRecallData] = useState<PonRecallResponseModel | null>(null);

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async () => ApiInstance.post("/pon/recall", { car_id: carId }, ENV.carBaseUrl)
  });

  useEffect(() => {
    const fetchPonData = async () => {
      try {
        const response = await mutateAsync();
        setPonRecallData(response.data);
      } catch (err) {
        if (err instanceof Error) console.error(err.message);
      }
    };

    fetchPonData();
  }, []);

  if (!selectedLocation?.is_pon_recall_enabled) return null;

  return { isPending, ponRecallData, error };
};
