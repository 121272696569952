import ENV from "config/Env";
import { ManufacturerFilterType } from "modules/ManufacturerDashboard/hooks";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

type Params = {
  manufacturerFilters: ManufacturerFilterType | undefined;
};

const component = "ManufacturerDashboard";

export const ManufacturerQueryKeys = {
  manufacturerListRequests: ({ manufacturerFilters }: Params) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/manufacturers/requests/list",
      params: { filters: manufacturerFilters }
    })
  ],

  ManufacturerRequestCountKey: [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.apiBase,
      endpoint: "/manufacturers/pending_requests_count"
    })
  ],

  manufacturerFilters: [localQueryKey({ component, data: "manufacturer_filters" })] as const
};
