import { useState } from "react";
import { Button } from "semantic-ui-react";

import { PonRecallModal } from "components/PonRecall/components";
import { usePonRecall } from "components/PonRecall/hooks/usePonRecall";
import "components/PonRecall/styles.scss";
import { useDealersLocations } from "hooks";

type PonRecallPropTypes = { carId: number };

export const PonRecall = ({ carId }: PonRecallPropTypes) => {
  const [showModal, setShowModal] = useState(false);
  const ponRecall = usePonRecall(carId);

  const { selectedLocation } = useDealersLocations();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  if (!selectedLocation?.is_pon_recall_enabled) return null;

  return (
    <>
      <Button
        type="button"
        disabled={ponRecall?.isPending}
        loading={ponRecall?.isPending}
        onClick={openModal}
        className={ponRecall?.ponRecallData ? "PonRecall__button-active" : ""}
        basic={ponRecall?.ponRecallData ? false : true}
      >
        PON
      </Button>
      <PonRecallModal showModal={showModal} closeModal={closeModal} data={ponRecall?.ponRecallData} error={ponRecall?.error} />
    </>
  );
};
