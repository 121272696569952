import { useState } from "react";

import { QUESTION_STATUSES } from "models";
import { QualityCheckReportData } from "modules/DealerReports/components/DealerQualityChecksTabular";

type Props = {
  data: QualityCheckReportData[];
};

export const useDealerQualityChecksTabular = ({ data }: Props) => {
  const [mechanics, setMechanics] = useState<number | undefined>(undefined);
  const [locations, setLocations] = useState<string | undefined>(undefined);
  const [qcOfficers, setQcOfficers] = useState<number | undefined>(undefined);
  const [qcStatuses, setQcStatuses] = useState<QUESTION_STATUSES | undefined>(undefined);
  const [qcChecklist, setQcChecklist] = useState<string | undefined>(undefined);

  const filteredData = data.filter(
    item =>
      (!mechanics || item.mechanic_id === mechanics) &&
      (!locations || item.location === locations) &&
      (!qcOfficers || item.qc_officer_id === qcOfficers) &&
      (!qcStatuses || item.qc_status === qcStatuses) &&
      (!qcChecklist || item.qc_checklist === qcChecklist)
  );

  return { filteredData, mechanics, locations, qcOfficers, qcStatuses, qcChecklist, setMechanics, setLocations, setQcOfficers, setQcStatuses, setQcChecklist };
};
